import React, { useState } from "react";
import AddSubscriptionPlan from "./add_subscription_plan";
import { SubscriptionPlanTable } from "./subscription_plan_table";

const SubscriptionPlan = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <>
      <AddSubscriptionPlan modal={modal} toggle={toggle} />
      <SubscriptionPlanTable toggle={toggle} />
    </>
  );
};
export default SubscriptionPlan;
