const ContactTypeFormState = {
  title: "",
  description: "",
};
const ContactTypeInitState = {
  contact_type_list: [],
  current_contact_type_obj: ContactTypeFormState,
  is_processing: false,
  loading_contact_types: true,
};

export const ContactTypeReducer = (
  prevState = ContactTypeInitState,
  action
) => {
  const form_state = prevState.current_contact_type_obj;
  let new_formState;
  switch (action.type) {
    case "CONTACT_TYPE_ERROR":
      return { ...prevState };
    case "LOADING_CONTACT_TYPES":
      return {
        ...prevState,
        loading_contact_types: action.payload,
      };
    case "FETCH_CONTACT_TYPE_SUCCESS":
      return { ...prevState, contact_type_list: action.payload.data };
    case "CONTACT_TYPE_INPUT_CHANGE":
      new_formState = { ...form_state, ...action.changedField };
      return { ...prevState, current_contact_type_obj: new_formState };
    case "CLEAR_CONTACT_TYPE_FORM":
      return { ...prevState, current_contact_type_obj: ContactTypeFormState };
    case "EDIT_CONTACT_TYPE":
      // new_formState = { ...action.current_contact_type_obj }
      return { ...prevState, current_contact_type_obj: action.obj };
    case "DELETE_CONTACT_TYPE_SUCCESS":
      let new_list = prevState.contact_type_list.filter(
        (current_rec) => current_rec.id !== action.record_id
      );
      return { ...prevState, contact_type_list: new_list };
    case "ADD_CONTACT_TYPE_SUCCESS":
      let contact_type_new = true;
      // console.log(action.new_record)
      let contact_type_list = prevState.contact_type_list.map(
        (contact_type) => {
          if (contact_type.id === action.new_record.id) {
            contact_type_new = false;
            return action.new_record;
          } else {
            return contact_type;
          }
        }
      );
      contact_type_new && contact_type_list.push(action.new_record);
      const new_state = { ...prevState, contact_type_list };
      if (contact_type_new) {
        new_state.current_contact_type_obj = ContactTypeFormState;
      }
      return new_state;
    case "CONTACT_TYPE_REQUEST_PROCESSING":
      return { ...prevState, is_processing: action.value };
    default:
      return prevState;
  }
};
