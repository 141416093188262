import React, { useState, useEffect } from "react";
import { AddCompany } from "./add_company";
import CompanyTable from "./table";
import { Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { FetchMerchantCompanies } from "../../../../../actions/company_action";

const Company = ({ match }) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  useEffect(() => {
    match.params.merchant_id &&
      dispatch(FetchMerchantCompanies(match.params.merchant_id));
  }, [dispatch, match.params.merchant_id]);
  return (
    <>
      <AddCompany
        modal={modal}
        toggle={toggle}
        merchant_id={match.params.merchant_id}
      />
      <Button
        className="payment-btn my-3 shadow-none mx-2"
        onClick={() => toggle()}
      >
        New Company
      </Button>
      <CompanyTable merchant_id={match.params.merchant_id} />
    </>
  );
};
export default Company;
