import React from "react";
import { Button } from "reactstrap";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import { CapitalizeFirstLetter } from "../constants";
import moment from "moment";

const ExportCSV = ({ csvData, fileName, headers }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (csvData, fileName) => {
    let header = headers;
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [header]);
    XLSX.utils.sheet_add_json(ws, csvData, {
      skipHeader: true,
      origin: "A2",
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
    color="light"
      onClick={(e) =>
        exportToCSV(
          csvData,
          CapitalizeFirstLetter(fileName + "-" + moment().toISOString())
        )
      }
      title="Export table data to Excel file"
    >
      <i className="fa fa-file-excel-o" aria-hidden="true"></i>
    </Button>
  );
};

export default ExportCSV;
