import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Actions } from "../../../../../constants/constants";
import TblComp from "../../../../../react_table";
import { Button } from "reactstrap";
import ExportCSV from "../../../../../constants/Exports/xlsExport";
import {
  editServiceProviderCountry,
  HandleServiceProviderCountryDelete,
  HandleServiceProviderCountryFetch,
} from "../../../../../actions/settings/service_provider/service_provider_country_actions";
import { clearServiceProviderCountryForm } from "../../../../../actions/settings/service_provider/service_provider_country_actions";
import DeleteModal from "../../../../../constants/deleteModal";

const ServiceProviderCountryTable = (props) => {
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState(false);
  const toggle = () => setModal(!modal);
  const dispatch = useDispatch();
  const { is_processing, service_provider_country_list } = useSelector(
    (state) => state.serviceprovidercountry,
    shallowEqual
  );

  useEffect(() => {
    props.sp_id && dispatch(HandleServiceProviderCountryFetch(props.sp_id));
  }, [dispatch, props.sp_id]);
  const headers = ["Id", "Country", "Country Code", "SPC code"];

  return (
    <>
      <DeleteModal
        modal={modal}
        toggle={toggle}
        name="Service Provider country"
        is_processing={is_processing}
        handleDelete={() =>
          dispatch(HandleServiceProviderCountryDelete(selected))
        }
      />
      <Button
        className="btn ticket-btn my-3"
        onClick={(e) => {
          dispatch(clearServiceProviderCountryForm(e));
          props.toggle();
        }}
      >
        Add Service Provider Country
      </Button>

      <ExportCSV
        csvData={service_provider_country_list}
        fileName="Serviceprovidercountry"
        headers={headers}
      />

      <TblComp
        columns={[
          {
            Header: "Country",
            accessor: (props) => props.country_name,
            filter: "fuzzyText",
          },
          {
            Header: "Country Code",
            accessor: "country_code",
            filter: "fuzzyText",
          },
          {
            Header: "SPC code",
            accessor: "spc_code",
            filter: "fuzzyText",
          },
          {
            id: "actions", // Required because our accessor is not a string
            Header: "Action",
            maxWidth: 100,
            accessor: (full) => (
              <Actions
                editHandler={(selected_row) => [
                  dispatch(editServiceProviderCountry(selected_row)),
                  props.toggle(),
                ]}
                data_item={full}
                link_url={"/settings/service_providers/product/"}
                deleteHandler={(selected_id) => [
                  toggle(),
                  setSelected(selected_id.id),
                ]}
              />
            ),
          },
        ]}
        data={service_provider_country_list}
      />
    </>
  );
};
export default ServiceProviderCountryTable;
