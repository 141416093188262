import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

export const Dropdown_item = (props) => {
  const { item_name, path } = props;
  if (props.history.location.pathname.includes(path)) {
    return (
      <li classNameName=" nav-link mt-2 user-name">
        <Link classNameName="active-link" to={path}>
          {item_name}
        </Link>
      </li>
    );
  }
  return (
    <li classNameName="nav-link mt-2 user-name">
      <Link to={props.path}> {item_name}</Link>
    </li>
  );
};
// const nav_arr = [
//   { path: "/account_settings", item_name: "Account Settings" },
//   { path: "/subscriptions", item_name: "Subscriptions" },
//   { path: "/companies", item_name: "Companies" },
//   { path: "/withdraw_history", item_name: "Withdraw History" },
//   { path: "/login", item_name: "Login" },
// ];

export const TopNav = (props) => {
  return (
    <div className="header sticky-top">
      <div className="container-fluid">
        <Row>
          <Col md={5} lg={5} sm={12}>
            <div className="logo">
              <Row>
                <Col lg={4} md={4} className="small-12">
                  <h1 className="">
                    <img
                      alt=""
                      className="sentepay_logo"
                      src="/images/logo.svg"
                    />
                  </h1>
                </Col>
                <Col lg={7} md={7} className="small-12 show-for-large">
                  <img
                    className="mt-1 show-for-large"
                    src="/images/separator.png"
                    alt=""
                    height="50px"
                    width=""
                  />
                  <span className="admin-text pl-3 mt-1">ADMIN DASHBOARD</span>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={2} lg={2} sm={12}>
            <div className="logo"></div>
          </Col>
          <Col md={5} lg={5} sm={12}>
            <div className="" role="banner">
              <ul className="nav float-end">
                <li className="nav-link mt-2 account-link">
                  <img
                    src="/images/noti-bell.svg"
                    height="15px"
                    width="15px"
                    alt=""
                  />
                </li>
                <li className="nav-link mt-2 user-name">Ahmed Musa</li>
                <li className="nav-link user-name">
                  <img
                    alt=""
                    className="avatar-img img-thumbnail"
                    src="/images/avatar.svg"
                  />
                </li>
                <Link to="staffdetails">
                  <li
                    className="mt-2 nav-link account-link "
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    MyAccount
                  </li>{" "}
                </Link>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
