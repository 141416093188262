import React, { useEffect, useState } from "react";
import MaskInput from "react-maskinput/lib";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import {
  editCompanyContact,
  HandleCompanyContactDelete,
  HandleCompanyContactFetch,
  HandleCompanyContactInput,
  HandleCompanyContactSubmit,
} from "../../../../../../actions/company_contact_actions";
// import { HandleSubscriptionPlanFetch } from '../../../../../../actions/settings/subscription_plans/subscription_plan_actions'
import { HandleContactTypeFetch } from "../../../../../../actions/settings/types/contact_type_actions";
import { Actions, Select } from "../../../../../../constants/constants";
import TblComp from "../../../../../../react_table";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Container,
  FormGroup,
  Form,
  Label,
} from "reactstrap";

export const CompanyContacts = (props) => {
  const [contactmodal, setModal] = useState(false);
  const contacttoggle = () => setModal(!contactmodal);
  const dispatch = useDispatch();
  const formState = useSelector(
    (state) => state.companyContactsState.current_company_contact_obj,
    shallowEqual
  );
  const contact_type_list = useSelector(
    (state) => state.contactTypeState.contact_type_list,
    shallowEqual
  );
  const company_contact_list = useSelector(
    (state) => state.companyContactsState.company_contact_list,
    shallowEqual
  );

  useEffect(() => {
    company_contact_list.length === 0 &&
      props.match.params.company_id &&
      dispatch(HandleCompanyContactFetch(props.match.params.company_id));
    contact_type_list.length === 0 && dispatch(HandleContactTypeFetch());
  }, [
    company_contact_list.length,
    contact_type_list.length,
    dispatch,
    props.match.params.company_id,
  ]);

  const handleInput = (e) => dispatch(HandleCompanyContactInput(e));

  const CompanyTypes = (full) => {
    if (typeof contact_type_list !== "undefined") {
      const contactType = contact_type_list.find(
        (contact) => contact.id === full.contact_type_id
      );
      if (typeof contactType !== "undefined") {
        return contactType.title;
      }
    }
    return null;
  };
  return (
    <>
      <Modal isOpen={contactmodal} toggle={contacttoggle}>
        <ModalHeader toggle={contacttoggle}>
          <span className="col-11">Company Contacts</span>
        </ModalHeader>
        <ModalBody>
          <Form
            autoComplete="off"
            method="post"
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(HandleCompanyContactSubmit(formState, props.id));
            }}
          >
            <Container>
              <FormGroup>
                <Row className="mt-3">
                  <Col className="ml-2 ">
                    <Label className="ml-3 lead">Select Type</Label>
                    <Select
                      name="contact_type_id"
                      placeholder="--Select type--"
                      index_key="id"
                      label_key="title"
                      value={formState.contact_type_id}
                      options={contact_type_list}
                      handleChange={handleInput}
                    />
                  </Col>
                  <Col lg={12} sm={12} md={12} className="mt-2">
                    <MaskInput
                      alwaysShowMask
                      name="masked_contact"
                      mask={"+256(000) 000 - 000"}
                      showMask
                      maskChar="_"
                      className="form-control"
                      value={
                        formState.id
                          ? formState.contact_details
                          : formState.masked_contact
                      }
                      onChange={handleInput}
                      autoComplete="off"
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Container>
            <Button
              type="submit"
              className="btn pay-btn btn-block text-white"
              block
            >
              <span className="pay-btn-text ">Save</span>
            </Button>
          </Form>
        </ModalBody>
      </Modal>

      <Button
        className="btn ticket-btn my-3 shadow-none"
        onClick={() => contacttoggle}
      >
        Add Company Contact
      </Button>

      <TblComp
        columns={[
          {
            id: "type", // Required because our accessor is not a string
            Header: "Contact Type",
            maxWidth: 100,
            accessor: (full) => CompanyTypes(full),
          },
          {
            Header: "Contact",
            accessor: "contact_details",
            filter: "fuzzyText",
          },
          {
            id: "actions", // Required because our accessor is not a string
            Header: "Actions",
            maxWidth: 100,
            accessor: (full) => (
              <Actions
                editHandler={(current_row) =>
                  dispatch(editCompanyContact(current_row))
                }
                data_item={full}
                modal_id="company_contact-modal"
                deleteHandler={(rec_id) =>
                  dispatch(HandleCompanyContactDelete(rec_id))
                }
              />
            ),
          },
        ]}
        data={
          typeof props.company_contact_list !== "undefined"
            ? props.company_contact_list
            : []
        }
      />
    </>
  );
};
export default CompanyContacts;
