import React, { useState, useEffect, useRef } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button, Col, Label, Container, FormGroup, Row } from "reactstrap";
import { HandleexhangeRateSubmit } from "../../../../actions/settings/exchange_actions";
import { HandleCurrencyFetch } from "../../../../actions/settings/currency_actions";
import { TextField, CustomModal } from "../../../../constants";

export const Addexchangerate = (props) => {
  const dispatch = useDispatch();
  const { currency_list } = useSelector(
    (state) => state.currencyState,
    shallowEqual
  );
  const { is_processing, current_exchange_obj } = useSelector(
    (state) => state.exchangeState,
    shallowEqual
  );

  const formikRef = useRef();

  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

  const validate = Yup.object({
    rate_amount: Yup.number().required("Rate amount is required"),
    from_currency_id: Yup.number().required("From currency is required"),
    to_currency_id: Yup.number().required("To currency is required"),
    apply_datetime_from: Yup.string().required("Apply date is required"),
    apply_datetime_to: Yup.string().required("Apply to date is required"),
  });
  const { modal, toggle } = props;

  useEffect(() => {
    currency_list.length === 0 && dispatch(HandleCurrencyFetch());
  }, [currency_list.length,dispatch]);

  return (
    <>
      <CustomModal
        modal={modal}
        toggle={toggle}
        tittle="Set Exchange Rate"
        is_processing={is_processing}
      >
        <Formik
          enableReinitialize
          initialValues={{
            from_currency_id: current_exchange_obj.from_currency_id,
            rate_amount: current_exchange_obj.rate_amount,
            to_currency_id: current_exchange_obj.to_currency_id,
            apply_datetime_from: current_exchange_obj.apply_datetime_from,
            apply_datetime_to: current_exchange_obj.apply_datetime_to,
          }}
          validationSchema={validate}
          validateOnChange={validateAfterSubmit}
          validateOnBlur={true}
          ref={formikRef}
          onSubmit={(values, { setErrors, setSubmitting }) => {
            setValidateAfterSubmit(true);
            dispatch(
              HandleexhangeRateSubmit(values, toggle, setErrors, setSubmitting)
            );
          }}
        >
          {(formik) => (
            <Container>
              <Form>
                <FormGroup>
                  <Row className="mt-3 ml-1">
                    <Col lg={6} md={6} sm={12} className="my-2">
                      <Label htmlFor="from_currency_id">Base Currency</Label>
                      <Field
                        as="select"
                        name="from_currency_id"
                        className={`custom-select form-control ${
                          formik.errors?.from_currency_id
                            ? `is-invalid mb-2`
                            : ""
                        }`}
                      >
                        <option defaultValue>--select--</option>
                        {currency_list.map((currency) => (
                          <option value={currency.id} key={currency.id}>
                            {currency.currency_code}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="from_currency_id"
                        className="error"
                      />
                    </Col>

                    <Col lg={6} md={6} sm={12} className="my-2">
                      <Label htmlFor="to_currency_id">Target Currency</Label>
                      <Field
                        as="select"
                        name="to_currency_id"
                        className={`custom-select form-control ${
                          formik.errors?.to_currency_id ? `is-invalid mb-2` : ""
                        }`}
                      >
                        <option defaultValue>--select--</option>
                        {currency_list.map((currency) => (
                          <option value={currency.id} key={currency.id}>
                            {currency.currency_code}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="to_currency_id"
                        className="error"
                      />
                    </Col>
                    <Col lg={12} md={12} sm={12} className="my-2">
                      <label htmlFor="">Rate Amount</label>
                      <TextField
                        name="rate_amount"
                        type="text"
                        value={formik.values.rate_amount}
                        placeholder="Rate Amount"
                      />
                    </Col>
                    <Col lg={12} md={12} sm={12} className="my-2">
                      <Label htmlFor="">Starting From</Label>
                      <TextField
                        name="apply_datetime_from"
                        type="datetime-local"
                        value={formik.values.apply_datetime_from}
                      />
                    </Col>
                    <Col lg={12} md={12} sm={12} className="my-2">
                      <Label htmlFor="">Valid To</Label>
                      <TextField
                        name="apply_datetime_to"
                        type="datetime-local"
                        value={formik.values.apply_datetime_to}
                      />
                    </Col>

                    <Col lg={12} md={12} sm={12} className="mt-3">
                      <Button
                        type="submit"
                        className="btn btn-pay text-white btn-block"
                        block
                        disabled={is_processing}
                      >
                        <span className="pay-btn-text">
                          {is_processing ? "... Creating" : "Create"}
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
              </Form>
            </Container>
          )}
        </Formik>
      </CustomModal>
    </>
  );
};
