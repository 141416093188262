import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import TblComp from "../../../../react_table";
import { Actions } from "../../../../constants/constants";
import { clearCurrencyForm } from "../../../../actions/settings/currency_actions";
import { Button } from "reactstrap";
import {
  HandleCurrencyFetch,
  editCurrency,
  HandleCurrencyDelete,
} from "../../../../actions/settings/currency_actions";
import ExportCSV from "../../../../constants/Exports/xlsExport";
import DeleteModal from "../../../../constants/deleteModal";

export const CurrencyTable = (props) => {
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState(false);
  const toggle = () => setModal(!modal);
  const dispatch = useDispatch();
  const { loading_currencies, currency_list, is_processing } = useSelector(
    (state) => state.currencyState,
    shallowEqual
  );

  useEffect(() => {
    currency_list.length === 0 && dispatch(HandleCurrencyFetch());
  }, [currency_list.length, dispatch]);

  const headers = ["Id", "Currency Name", "Currency Code", "Description"];

  return (
    <>
      <DeleteModal
        modal={modal}
        toggle={toggle}
        name="Currency"
        handleDelete={() => dispatch(HandleCurrencyDelete(selected, toggle))}
        is_processing={is_processing}
      />
      <Button
        className="btn payment-btn my-3 me-2"
        onClick={(e) => {
          props.toggle();
          dispatch(clearCurrencyForm(e));
        }}
      >
        Add Currency
      </Button>
      <ExportCSV
        csvData={currency_list}
        fileName="currencies"
        headers={headers}
      />
      <TblComp
        columns={[
          {
            Header: "Currency Name",
            accessor: "currency_name",
            filter: "fuzzyText",
          },
          {
            Header: "Currency Code",
            accessor: "currency_code",
            filter: "fuzzyText",
          },
          {
            Header: "Description",
            accessor: "description",
            filter: "fuzzyText",
          },
          {
            id: "actions", // Required because our accessor is not a string
            Header: "Action",
            maxWidth: 100,
            accessor: (full) => (
              <Actions
                current_table="Currencies"
                editHandler={(selected_row) =>
                  dispatch(editCurrency(selected_row))
                }
                data_item={full}
                link_url={""}
                modal_id="currency-modal"
                deleteHandler={(selected_id) => [
                  toggle(),
                  setSelected(selected_id.id),
                ]}
              />
            ),
          },
        ]}
        data={currency_list}
        loading={loading_currencies}
      />
    </>
  );
};
