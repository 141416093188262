import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { HandleDocumentReview } from "../../../../../../actions/company_document_actions";

const DocumentPreview = (props) => {
  const url = props.location.pathname.toString().split("/");

  const document_id = url[7];
  useEffect(() => {
    props.Previewdocument(document_id);
  }, [document_id, props]);

  return React.createElement(
    "iframe",
    {
      src: props.curent_document.dope_name,
      height: "500px",
      className: "container-fluid",
    },
    null
  );
};

const mapCompanyDocumentPreviewStateToProps = (state) => {
  return {
    curent_document: state.companyDocumentState.curent_preview_document,
    company_document_list: state.companyDocumentState.company_document_list,
  };
};
const mapCompanyDocumentPreviewDispatchToProps = (dispatch) => {
  return {
    Previewdocument: (url_id) => dispatch(HandleDocumentReview(url_id)),
  };
};
export const DocumentPreviewReduxComp = withRouter(
  connect(
    mapCompanyDocumentPreviewStateToProps,
    mapCompanyDocumentPreviewDispatchToProps
  )(DocumentPreview)
);
