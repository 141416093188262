const initformState = {
  firstname: "",
  lastname: "",
  othernames: "",
  phone_number: "",
  email: "",
  address: "",
  role_id: "",
  gender: "",
  password: "",
  password_confirmation: "",
  roles:[],
};

const initialState = {
  staff_list: [],
  current_staff_obj: initformState,
  is_processing: false,
};

export default function staffReducer(prevState = initialState, action) {
  let new_state = prevState;
  const form_state = prevState.current_staff_obj;
  switch (action.type) {
    case "CREATE_STAFF_SUCCESS":
      let staff_new = true;

      let staff_list = prevState.staff_list.map((staff) => {
        if (staff.id === action.new_staff.id) {
          staff_new = false;
          return action.new_staff;
        } else {
          return staff;
        }
      });
      staff_new && staff_list.push(action.new_staff);
      new_state = { ...prevState, staff_list };
      if (staff_new) {
        new_state.current_staff_obj = initformState;
      }
      return new_state;
    case "STAFF_INPUT_CHANGE":
      let new_formState = { ...form_state, ...action.changedField };
      return { ...prevState, current_staff_obj: new_formState };
    case "CLEAR_STAFF_FORM":
      return { ...prevState, current_staff_obj: initformState };
    case "FETCH_STAFF_SUCCESS":
      return { ...prevState, staff_list: action.payload };
    case "STAFF_ERROR":
      return { ...prevState };
    case "EDIT_STAFF":
      let currentState = { ...action.current_staff_obj };
      return { ...prevState, current_staff_obj: currentState };
    case "DELETE_STAFF_SUCCESS":
      let new_list = prevState.staff_list.filter(
        (current_rec) => current_rec.id !== action.record_id
      );
      return { ...prevState, staff_list: new_list };
    case "STAFF_REQUEST_PROCESSING":
      return { ...prevState, is_processing: action.value };
    default:
      return new_state;
  }
}
