import React, { useState, useRef } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import { Button, Col, Row, FormGroup } from "reactstrap";
import { HandleCountriesSubmit } from "../../../../actions/settings/countries_actions";
import { TextField, CustomModal } from "../../../../constants";
import * as Yup from "yup";

const AddCountry = (props) => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const { is_processing, current_countries_obj } = useSelector(
    (state) => state.countryState,
    shallowEqual
  );

  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

  const validate = Yup.object({
    country_name: Yup.string().required("Country name is required"),
    country_code: Yup.string().required("Country code is required"),
  });

  const { modal, toggle } = props;

  return (
    <CustomModal
      modal={modal}
      toggle={toggle}
      tittle="Add Country"
      is_processing={is_processing}
    >
      <Formik
        enableReinitialize
        initialValues={{
          country_name: current_countries_obj.country_name,
          country_code: current_countries_obj.country_code,
        }}
        validationSchema={validate}
        validateOnChange={validateAfterSubmit}
        validateOnBlur={true}
        ref={formikRef}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          setValidateAfterSubmit(true);
          dispatch(
            HandleCountriesSubmit(values, toggle, setErrors, setSubmitting)
          );
        }}
      >
        {(formik) => (
          <div className="container-fluid">
            <Form>
              <FormGroup>
                <Row className="mt-3 ml-1">
                  <Col lg={12} md={12} sm={12} className="my-2">
                    <TextField
                      name="country_name"
                      type="text"
                      value={formik.values.country_name}
                      placeholder="Country Name"
                    />
                  </Col>
                  <Col lg={12} md={12} sm={12} className="my-2">
                    <TextField
                      name="country_code"
                      type="text"
                      value={formik.values.country_code}
                      placeholder="Country Code"
                    />
                  </Col>
                  <Col lg={12} md={12} sm={12} className="mt-3">
                    <Button
                      type="submit"
                      className="btn btn-pay btn-block text-white"
                      block
                      disabled={is_processing}
                    >
                      <span className="pay-btn-text ">
                        {is_processing ? "... Saving" : "Save"}{" "}
                      </span>
                    </Button>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </div>
        )}
      </Formik>
    </CustomModal>
  );
};
export default AddCountry;
