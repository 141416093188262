import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import TblComp from "../../../../react_table";
import { Actions } from "../../../../constants/constants";
import { clearexhangeRateForm } from "../../../../actions/settings/exchange_actions";
import { Button } from "reactstrap";
import {
  HandleexhangeRateFetch,
  editexhangeRate,
  HandleexhangeRateDelete,
} from "../../../../actions/settings/exchange_actions";
import moment from "moment/moment";
import { currFormat } from "../../../../constants/GenHelpers";
import ExportCSV from "../../../../constants/Exports/xlsExport";
import DeleteModal from "../../../../constants/deleteModal";

export const ExchangerateTable = (props) => {
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState(false);
  const toggle = () => setModal(!modal);
  const dispatch = useDispatch();
  const { loading_exchange_rates, exchange_rates, is_processing } = useSelector(
    (state) => state.exchangeState,
    shallowEqual
  );

  useEffect(() => {
    exchange_rates.length === 0 && dispatch(HandleexhangeRateFetch());
  }, [exchange_rates.length, dispatch]);

  const headers = [
    "Id",
    "Base currency",
    "Target currency",
    "Rate amount",
    "From",
    "To",
  ];

  return (
    <>
      <DeleteModal
        modal={modal}
        toggle={toggle}
        name="Exchange Rate"
        handleDelete={() => dispatch(HandleexhangeRateDelete(selected, toggle))}
        is_processing={is_processing}
      />
      <Button
        className="btn ticket-btn my-3 me-2"
        onClick={(e) => [props.toggle(), dispatch(clearexhangeRateForm(e))]}
      >
        Add Exchange Rate
      </Button>
      <ExportCSV
        csvData={exchange_rates}
        fileName="exchangerates"
        headers={headers}
      />
      <TblComp
        columns={[
          {
            Header: "Base Currency",
            accessor: (props) =>
              props.from_currency != null
                ? props.from_currency.currency_code
                : "DELETED",
            filter: "fuzzyText",
          },
          {
            Header: "Target Currency",
            accessor: (props) => props.to_currency.currency_code,
            filter: "fuzzyText",
          },
          {
            Header: "Rate Amount",
            accessor: "rate_amount",
            Cell: ({ value }) => (value ? currFormat(value * 1) : ""),
            filter: "fuzzyText",
          },
          {
            Header: "From",
            accessor: "apply_datetime_from",
            Cell: ({ value }) => (value ? moment(value).format("lll") : ""),
            filter: "fuzzyText",
          },
          {
            Header: "To",
            accessor: "apply_datetime_to",
            Cell: ({ value }) => (value ? moment(value).format("lll") : ""),
            filter: "fuzzyText",
          },

          {
            id: "actions", // Required because our accessor is not a string
            Header: "Action",
            maxWidth: 100,
            accessor: (full) => (
              <Actions
                current_table="ExchangeRates"
                editHandler={(selected_row) =>
                  dispatch(editexhangeRate(selected_row))
                }
                data_item={full}
                link_url={""}
                modal_id="exchange-modal"
                deleteHandler={(selected_id) => [
                  toggle(),
                  setSelected(selected_id.id),
                ]}
              />
            ),
          },
        ]}
        data={exchange_rates}
        loading={loading_exchange_rates}
      />
    </>
  );
};
