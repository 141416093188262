import React, { useState } from "react";
import AddTransactionType from "./add_transaction_type";
import { TransactionTypesTable } from "./transaction_types_table";

const TransactionTypes = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <>
      <AddTransactionType modal={modal} toggle={toggle} />
      <TransactionTypesTable toggle={toggle} />
    </>
  );
};

export default TransactionTypes;
