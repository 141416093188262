import React, { useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Actions } from "../../../../../constants/constants";
import TblComp from "../../../../../react_table";
import { Button } from "reactstrap";
import {
  handleChargeRateFetch,
  handleChargeRateDelete,
  editChargeRate,
} from "../../../../../actions/settings/subscription_plans/charge_rate_actions";
import { clearChargeRateForm } from "../../../../../actions/settings/subscription_plans/charge_rate_actions";

export const ChargeRateTable = (props) => {
  const dispatch = useDispatch();
  const chargeRateState = useSelector(
    (state) => state.chargerate,
    shallowEqual
  );
  useEffect(() => {
    dispatch(handleChargeRateFetch(props.sub_plan_id));
  }, [dispatch, props.sub_plan_id]);
  return (
    <>
      <Button
        className="btn payment-btn my-3 ml-2 text-white"
        onClick={(e) => {
          dispatch(clearChargeRateForm(e));
          props.toggle();
        }}
      >
        Add Charge Rate
      </Button>
      <TblComp
        columns={[
          {
            id: "provider",
            Header: "Provider",
            accessor: "spc_product",
            Cell: ({ value }) => (
              <>
                {typeof value.sp_country !== "undefined" &&
                typeof value.sp_country.service_provider !== "undefined"
                  ? `${value.sp_country.service_provider.service_provider_name} (${value.sp_country.country.country_code})`
                  : "NA"}
              </>
            ),
            filter: "fuzzyText",
          },
          {
            id: "product",
            Header: "Product",
            accessor: "spc_product",
            Cell: ({ value }) => (
              <>
                {typeof value.product !== "undefined"
                  ? value.product.product_name
                  : "NA"}
              </>
            ),
            filter: "fuzzyText",
          },
          {
            Header: "Rate Name",
            accessor: "charge_rate_name",
            filter: "fuzzyText",
          },
          {
            Header: "Rate Value",
            accessor: "rate_value",
            filter: "fuzzyText",
          },
          {
            Header: "Percentage",
            accessor: "percentage_or",
            Cell: ({ value }) => <>{value ? "Yes" : "No"}</>,
            filter: "fuzzyText",
          },
          {
            Header: "Min Value",
            accessor: "min_value",
            filter: "fuzzyText",
          },
          {
            Header: "Max Value",
            accessor: "max_value",
            filter: "fuzzyText",
          },
          {
            Header: "Min Trans",
            accessor: "min_trans",
            filter: "fuzzyText",
          },
          {
            Header: "Max Trans",
            accessor: "max_trans",
            filter: "fuzzyText",
          },

          // { Header: 'Description', accessor: 'description', filter: 'fuzzyText' },
          {
            id: "actions", // Required because our accessor is not a string editHandler={props.editHandler}
            Header: "Action",
            maxWidth: 100,
            accessor: (full) => (
              <Actions
                editHandler={(selected_row) =>
                  dispatch(editChargeRate(selected_row))
                }
                data_item={full}
                link_url={""}
                modal_id="charge_rate"
                deleteHandler={(selected_id) =>
                  dispatch(handleChargeRateDelete(selected_id))
                }
              />
            ),
          },
        ]}
        data={chargeRateState.charge_rate_list}
      />
    </>
  );
};
