import React, { useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { FetchOneMerchant } from "../../../../actions/merchant_action";

const selectorFn = (state) => state.merchantState.current_merchant_obj;

export const DetailHeader = ({merchant_id}) => {
  const dispatch = useDispatch();
  const merchant = useSelector(selectorFn, shallowEqual);

  useEffect(() => {
    (typeof merchant === "undefined" || merchant.id !== merchant_id) &&
      dispatch(FetchOneMerchant(merchant_id));
  }, [merchant_id,dispatch,merchant]);

  if (typeof merchant !== "undefined" && merchant.full_name) {
    return (
      <div className="d-flex ">
        <span>
          <h5 className="text-uppercase mt-2">{merchant.full_name} </h5>
        </span>
      </div>
    );
  } else {
    return <></>;
  }
};
