import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Col, NavbarToggler, Navbar, Collapse } from "reactstrap";
import { signOut } from "../../actions/api/login_api";

export const NavItem = (props) => {
  const { icon, icon_active, item_name, path, toggleNavbar, history } = props;
  if (history.location.pathname.includes(path)) {
    return (
      <li className="current" onClick={toggleNavbar}>
        <Link to={path}>
          <img
            src={icon_active}
            height={15}
            width={15}
            className="me-1"
            alt=""
          />
          {item_name}
          <img
            src={"/images/caret.svg"}
            className="float-end mt-1"
            height="15px"
            width="15px"
            alt=""
          />
        </Link>
      </li>
    );
  }
  return (
    <li onClick={toggleNavbar}>
      <Link to={path}>
        <img alt="" src={icon} height={15} width={15} className="mr-1" />{" "}
        {item_name}
      </Link>
    </li>
  );
};
let nav_arr1 = [
  {
    path: "/",
    item_name: "Dashboard",
    icon_active: "/images/dashboard-icon.svg",
    icon: "/images/dashboard-white.svg",
  },
  {
    path: "/merchant",
    item_name: "Merchants",
    icon_active: "/images/merchant-active.svg",
    icon: "/images/merchant.svg",
  },
  {
    path: "/business",
    item_name: "Businesses",
    icon_active: "/images/merchant-active.svg",
    icon: "/images/merchant.svg",
  },
  {
    path: "/transactions",
    item_name: "Transactions",
    icon: "/images/payments.svg",
    icon_active: "/images/payments-active.svg",
  },
  /*{
    path: "/reports",
    item_name: "Reports",
    icon: "/images/reports.svg",
    icon_active: "/images/reports-active.svg",
  },*/
  {
    path: "/support",
    item_name: "Support",
    icon: "/images/support.svg",
    icon_active: "/images/support-active.svg",
  },
];
const nav_arr2 = [
  {
    path: "/staff",
    item_name: "Staff",
    icon_active: "/images/staff-active.svg",
    icon: "/images/staff.svg",
  },
  {
    path: "/settings",
    item_name: "Settings",
    icon: "/images/settings.svg",
    icon_active: "/images/settings-active.svg",
  },
  {
    path: "/logout",
    item_name: "Logout",
    icon: "/images/logout.svg",
    icon_active: "/images/logout.svg",
  },
];
export const SideBar = (props) => {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = React.useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <>
      <Col md={3} lg={2} sm={12}>
        <div className="mobile_view fixed-sidebar">
          <div
            className="sidebar content-box"
            style={{
              display: "block",
              background: "#122025 0% 0% no-repeat padding-box",
              borderRadius: 12,
            }}
          >
            <ul className="nav" style={{ display: "list-item", margin: "1px" }}>
              {typeof props !== "undefined" &&
                nav_arr1.map((item, ky) => {
                  return (
                    <NavItem
                      key={ky}
                      icon_active={item.icon_active}
                      icon={item.icon}
                      item_name={item.item_name}
                      path={item.path}
                      history={props.history}
                    />
                  );
                })}
            </ul>
          </div>
          <div
            className="sidebar content-box sidebar2 mt-2"
            style={{
              display: "block",
              background: "#122025 0% 0% no-repeat padding-box",
              borderRadius: 12,
            }}
          >
            <ul className="nav" style={{ display: "list-item", margin: 1 }}>
              {typeof props !== "undefined" &&
                nav_arr2.map((item, ky) => {
                  if (item.item_name === "Logout") {
                    return (
                      <li key={ky} onClick={(e) => dispatch(signOut(e))}>
                        <a href="#!">
                          <img
                            alt=""
                            src={item.icon}
                            height={15}
                            width={15}
                            className="mr-1"
                          />{" "}
                          {item.item_name}
                        </a>
                      </li>
                    );
                  }
                  return (
                    <NavItem
                      key={ky}
                      icon_active={item.icon_active}
                      icon={item.icon}
                      item_name={item.item_name}
                      path={item.path}
                      history={props.history}
                    />
                  );
                })}
            </ul>
          </div>
        </div>
        <Navbar light className="navbar-toggler-left" fixed="top">
          <NavbarToggler onClick={toggleNavbar} className="mr-2" />
        </Navbar>
        <Collapse isOpen={!collapsed} navbar>
          <div
            className="sidebar content-box"
            style={{
              display: "block",
              background: "#122025 0% 0% no-repeat padding-box",
              borderRadius: 12,
            }}
          >
            <ul className="nav" style={{ display: "list-item", margin: "1px" }}>
              {typeof props !== "undefined" &&
                nav_arr1.map((item, ky) => {
                  return (
                    <NavItem
                      key={ky}
                      icon_active={item.icon_active}
                      icon={item.icon}
                      item_name={item.item_name}
                      toggleNavbar={toggleNavbar}
                      path={item.path}
                      history={props.history}
                    />
                  );
                })}
            </ul>
          </div>
          <div
            className="sidebar content-box sidebar2"
            style={{
              display: "block",
              background: "#122025 0% 0% no-repeat padding-box",
              borderRadius: 12,
            }}
          >
            <ul className="nav" style={{ display: "list-item", margin: 1 }}>
              {typeof props !== "undefined" &&
                nav_arr2.map((item, ky) => {
                  if (item.item_name === "Logout") {
                    return (
                      <li key={ky} onClick={(e) => props.logOutUser(e)}>
                        <a href="#!">
                          <img
                            alt=""
                            src={item.icon}
                            height={15}
                            width={15}
                            className="mr-1"
                          />{" "}
                          {item.item_name}
                        </a>
                      </li>
                    );
                  }
                  return (
                    <NavItem
                      key={ky}
                      icon_active={item.icon_active}
                      icon={item.icon}
                      item_name={item.item_name}
                      path={item.path}
                      toggleNavbar={toggleNavbar}
                      history={props.history}
                    />
                  );
                })}
            </ul>
          </div>
        </Collapse>
      </Col>
    </>
  );
};
