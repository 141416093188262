import NotFound from "../app/notFound";
import ProductsComp from "../app/transactions/products";
import WithdrawRequests from "../app/transactions/withdraws/component";
import AllTicketsComp from "../app/support_tickets/component";
import { Settings } from "../app/settings/component";
import Businesses from "../app/businesses";
import StaffComponent from "../app/staff/component";
import { MerchantRoutes } from "../app/merchant";
import DashboardHome from "../app/dashboard/component";

//admin route
const allRoutes = [
  {
    name: "Dashboard",
    path: "/",
    component: DashboardHome,
    exact: true,
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    component: DashboardHome,
  },
  {
    name: "Transactions",
    path: "/transactions",
    component: ProductsComp,
  },
  {
    name: "Businesses",
    path: "/business",
    component: Businesses,
  },

  {
    name: "Staff",
    path: "/staff",
    component: StaffComponent,
  },

  {
    name: "Support Tickets",
    path: "/support",
    component: AllTicketsComp,
  },
  {
    name: "Withdraw Requests",
    path: "/withdrawRequests",
    component: WithdrawRequests,
  },
  {
    name: "NotFound",
    path: "/notFound",
    component: NotFound,
  },
  {
    name: "Settings",
    path: "/settings",
    component: Settings,
  },
  {
    name: "Merchants",
    path: "/merchant",
    component: MerchantRoutes,
  },
];

export default allRoutes;
