import React from "react";
import { Select } from "../../../../../constants/constants";
import {
  HandleCompanyInput,
  HandleCompanySubmit,
} from "../../../../../actions/company_action";
import { HandleCountriesFetch } from "../../../../../actions/settings/countries_actions";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Col,
  Container,
  FormGroup,
  Row,
  Input,
} from "reactstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Loader from "../../../../../constants/loader";

export const AddCompany = ({ merchant_id, toggle, modal }) => {
  const dispatch = useDispatch();
  const { is_processing, current_company_obj } = useSelector(
    (state) => state.companyState,
    shallowEqual
  );
  const countries = useSelector((state) => state.countryState.countries_list);

  React.useEffect(() => {
    countries.length === 0 && dispatch(HandleCountriesFetch());
  }, [countries.length, dispatch]);

  const handleInput = (e) => dispatch(HandleCompanyInput(e));

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <span className="col-11">Company Details</span>
        </ModalHeader>
        {is_processing ? (
          <div className="loader-overlay">
            <Loader isModal />
          </div>
        ) : (
          <></>
        )}
        <ModalBody>
          <Form
            autoComplete="off"
            method="post"
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(
                HandleCompanySubmit(current_company_obj, merchant_id, toggle)
              );
            }}
          >
            <Container>
              <FormGroup>
                <Row className="mt-3">
                  <Col lg={12} md={12} sm={12} className="my-2">
                    <Input
                      autoComplete="off"
                      type="text"
                      name="company_name"
                      placeholder="Company Name"
                      className="form-control "
                      value={current_company_obj.company_name}
                      onChange={handleInput}
                    />
                  </Col>
                  <Col lg={12} md={12} sm={12} className="my-2">
                    <Select
                      name="company_type"
                      value={current_company_obj.company_type}
                      handleChange={handleInput}
                      placeholder="--Company Type--"
                      index_key="name"
                      label_key="label"
                      options={[
                        { name: "Technology Firm", label: "Tech Firm" },
                        { name: "Construction", label: "Construction" },
                        { name: "Consultancy", label: "Consultancy" },
                        { name: "Real Estate", label: "Real Estate" },
                        { name: "Security Agency", label: "Security Agency" },
                      ]}
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} className="my-2">
                    <Input
                      autoComplete="off"
                      type="text"
                      name="no_of_employees"
                      placeholder="No. of employees"
                      className="form-control "
                      value={current_company_obj.no_of_employees}
                      onChange={handleInput}
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} className="my-2">
                    <input
                      autoComplete="off"
                      type="text"
                      name="reg_no"
                      placeholder="Registration No."
                      className="form-control "
                      value={current_company_obj.reg_no}
                      onChange={handleInput}
                    />
                  </Col>
                  <Col lg={12} md={12} sm={12} className="my-1">
                    <Select
                      name="country_id"
                      index_key="id"
                      label_key="country_name"
                      placeholder="--Country--"
                      value={current_company_obj.country_id}
                      options={countries}
                      handleChange={handleInput}
                    />
                  </Col>
                  <Col lg={12} md={12} sm={12} className="my-2">
                    <textarea
                      type="text"
                      name="description"
                      placeholder="Description"
                      className=" form-control "
                      value={current_company_obj.description}
                      onChange={handleInput}
                    ></textarea>
                  </Col>
                  <Col lg={12} md={12} sm={12} className="my-2">
                    <textarea
                      type="text"
                      name="address"
                      placeholder="Location"
                      className=" form-control "
                      value={current_company_obj.address}
                      onChange={handleInput}
                    ></textarea>
                  </Col>
                </Row>
              </FormGroup>
            </Container>
            <Button
              type="submit"
              className="btn pay-btn btn-block text-white"
              block
              disabled={is_processing}
            >
              <span className="pay-btn-text">
                {is_processing ? "... Saving" : "Save"}{" "}
              </span>
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
