import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import {
  HandleCompanySubFetch,
  HandleCompanySubInput,
  HandleCompanySubSubmit,
} from "../../../../../../actions/company_sub_actions";
import { HandleSubscriptionPlanFetch } from "../../../../../../actions/settings/subscription_plans/subscription_plan_actions";
import { Select } from "../../../../../../constants/constants";
import TblComp from "../../../../../../react_table";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Label,
  Form,
  FormGroup,
  Container,
  Badge,
} from "reactstrap";

const StatusChange = (status_id) => {
  // 1=created and Awaiting Payment, 2=Paid Up, 3=Activated
  if (status_id === 1) {
    return (
      <Badge pill color="warning">
        Payment Pending
      </Badge>
    );
  } else if (status_id === 2) {
    return (
      <Badge pill color="info">
        Paid{" "}
      </Badge>
    );
  } else if (status_id === 3) {
    return (
      <Badge color="success" pill>
        Activated
      </Badge>
    );
  } else {
    return (
      <Badge colo="danger" pill>
        Expired
      </Badge>
    );
  }
};
export const CompanySubscription = (props) => {
  const dispatch = useDispatch();

  const formState = useSelector(
    (state) => state.companySubState.current_company_sub_obj,
    shallowEqual
  );
  const company_sub_list = useSelector(
    (state) => state.companySubState.company_sub_list,
    shallowEqual
  );
  const subscription_plan_list = useSelector(
    (state) => state.subscriptionplanreducer.subscription_plan_list,
    shallowEqual
  );

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    typeof props.company_sub_list !== "undefined" &&
      dispatch(HandleCompanySubFetch(props.match.params.company_id));
    typeof props.subscription_plan_list !== "undefined" &&
      dispatch(HandleSubscriptionPlanFetch());
  }, [
    dispatch,
    props.company_sub_list,
    props.match.params.company_id,
    props.subscription_plan_list,
  ]);

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <span className="col-11">Subscription</span>
        </ModalHeader>
        <ModalBody>
          <Form
            autoComplete="off"
            method="post"
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(HandleCompanySubSubmit(formState, props.id));
            }}
          >
            <Container>
              <FormGroup>
                <Row className="mt-3">
                  <Col lg={12} md={12} sm={12} className="ml-2">
                    <Label className="ml-3 lead">Select a plan</Label>
                    <Select
                      name="subscription_plan_id"
                      index_key="id"
                      label_key="subscription_plan_name"
                      value={formState.subscription_plan_id}
                      options={subscription_plan_list}
                      handleChange={(e) => dispatch(HandleCompanySubInput(e))}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Container>
            <Button
              type="submit"
              className="btn pay-btn btn-block text-white"
              block
            >
              <span className="pay-btn-text ">Add</span>
            </Button>
          </Form>
        </ModalBody>
      </Modal>

      <Button className="btn ticket-btn my-3 text-white" onClick={toggle}>
        Add Subscription
      </Button>

      <TblComp
        columns={[
          {
            id: "subscription_plan", // Required because our accessor is not a string
            Header: "Plan",
            accessor: "subscription_plan",
            maxWidth: 100,
            Cell: (props) =>
              props.value ? props.value.subscription_plan_name : "",
          },
          {
            id: "amount", // Required because our accessor is not a string
            Header: "Amount",
            accessor: "subscription_plan",
            maxWidth: 100,
            Cell: (props) => (props.value ? props.value.amount : ""),
          },
          {
            id: "status_change", // Required because our accessor is not a string
            Header: "Status",
            maxWidth: 100,
            accessor: (full) => StatusChange(full.status),
          },
        ]}
        data={company_sub_list}
      />
    </>
  );
};

export default CompanySubscription;
