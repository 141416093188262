import allconstants from "../../../constants";

export const HandleTransactionTypeInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return {
    type: "TRANSACTION_TYPE_INPUT_CHANGE",
    changedField: { [name]: value },
  };
};

export const processingTransactionTypeRequest = (value) => {
  return { type: "TRANSACTION_TYPE_REQUEST_PROCESSING", value };
};
export const clearTransactionTypeForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_TRANSACTION_TYPE_FORM" };
};
export const IsLoading = (payload) => {
  return { type: "LOADING_TRANS_TYPES", payload };
};
export const TransactionTypeFetchSuccess = (payload, msg) => {
  return { type: "FETCH_TRANSACTION_TYPE_SUCCESS", payload };
};
export const TransactionTypeAddSuccess = (new_record, update = false) => {
  if (update) {
  } else {
  }
  return { type: "ADD_TRANSACTION_TYPE_SUCCESS", new_record };
};
export const TransactionTypeDeleteSuccess = (msg, record_id) => {
  return { type: "DELETE_TRANSACTION_TYPE_SUCCESS", record_id };
};
export const TransactionTypeReqError = (msg) => {
  return { type: "TRANSACTION_TYPE_ERROR" };
};

export const editTransactionType = (form_state) => {
  return { type: "EDIT_TRANSACTION_TYPE", form_state };
};
export const HandleTransactionTypeSubmit =
  (form_data, toggle, setErrors, setSubmitting) => (dispatch) => {
    dispatch(processingTransactionTypeRequest(true));

    const url = form_data.id
      ? allconstants.apiurl + "transaction_type/" + form_data.id
      : allconstants.apiurl + "transaction_type";

    const req_type = form_data.id
      ? allconstants.axios.put
      : allconstants.axios.post;
    req_type(url, form_data, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setSubmitting(false);
          if (form_data.id) {
            dispatch(TransactionTypeAddSuccess(response.data, true));
            dispatch(processingTransactionTypeRequest(false));
          } else {
            dispatch(TransactionTypeAddSuccess(response.data));
            dispatch(processingTransactionTypeRequest(false));
          }
          toggle();
        }
      })
      .catch((error) => {
        if (error.response) {
          setErrors(error.response.data.errors);
          dispatch(processingTransactionTypeRequest(false));
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Bad/No internet connection");
        }
      });

    return null;
  };
export const HandleTransactionTypeDelete =
  (record_id, toggle) => (dispatch) => {
    const url = allconstants.apiurl + "transaction_type/" + record_id;
    dispatch(processingTransactionTypeRequest(true));
    allconstants.axios
      .delete(url, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          dispatch(TransactionTypeDeleteSuccess(response.message, record_id));
          dispatch(processingTransactionTypeRequest(false));
          toggle();
        }
      });

    return null;
  };
export const HandleTransactionTypeFetch = () => (dispatch) => {
  const url = allconstants.apiurl + "transaction_type";
  allconstants.axios
    .get(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 200) {
        dispatch(TransactionTypeFetchSuccess(response.data));
        dispatch(IsLoading(false));
      }
    })
    .catch((error) => {
      if (error.response) {
        dispatch(IsLoading(false));
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Bad/No internet connection");
      }
    });
};
