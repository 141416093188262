import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Card, CardHeader, Row, Col, CardBody } from "reactstrap";

export const CompanyProfile = () => {
  const company_info = useSelector(
    (state) => state.companyState.single_record,
    shallowEqual
  );

  const {
    address,
    company_name,
    company_type,
    description,
    no_of_employees,
    decision_by,
    reason_for_decision,
    status_text,
    status,
  } = company_info;
  return (
    <>
      <Card className="bg-white">
        <CardHeader>
          <span className="account-title">Account Details</span>
        </CardHeader>
        <CardBody>
          <Row>
            <Col lg={6} sm={12} md={12}>
              <span className="title-header d-block">Company Name</span>
              <span className="title-text">
                {company_name ? company_name : "LOADING..."}
              </span>
            </Col>
            <Col lg={6} sm={12} md={12}>
              <span className="title-header d-block">Company Type</span>
              <span className="title-text">
                {company_type ? company_type : "LOADING..."}
              </span>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col lg={6} sm={12} md={12}>
              <span className="title-header d-block">Company Address</span>
              <span className="title-text">
                {address ? address : "LOADING..."}
              </span>
            </Col>
            <Col lg={6} sm={12} md={12}>
              <span className="title-header d-block">Status</span>
              <span className="title-text-blue">
                {status ? status_text : "LOADING..."}
              </span>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col lg={6} sm={12} md={12}>
              <span className="title-header d-block">Description</span>
              <span className="title-text">
                {description ? description : "LOADING..."}
              </span>
            </Col>

            <Col lg={6} sm={12} md={12}>
              <span className="title-header d-block">No. of Employees</span>
              <span className="title-text">
                {no_of_employees ? no_of_employees : "LOADING..."}
              </span>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col lg={6} sm={12} md={12}>
              <span className="title-header d-block">Decision by</span>
              <span className="title-text">
                {" "}
                {decision_by ? decision_by : "LOADING..."}
              </span>
            </Col>
            <Col lg={6} sm={12} md={12}>
              <span className="title-header d-block">Reason for Decision</span>
              <span className="title-text">
                {" "}
                {reason_for_decision ? reason_for_decision : "LOADING..."}
              </span>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};
