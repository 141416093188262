import moment from "moment";
import React, { useState } from "react";
import { currFormat } from "../../../../../../../constants/GenHelpers";
import TblComp from "../../../../../../../react_table";
import { Button, Badge } from "reactstrap";
import TransactionDetail from "../../../../../transactions/transactionDetail";

export const TransactionsTable = (props) => {
  const [isLoading] = useState(false);
  const [transaction, setSingle] = useState();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <div className="content-box-header panel-heading table-container">
      <TransactionDetail
        transaction={transaction}
        isOpen={modal}
        toggle={toggle}
      />
      <Button
        className="btn payment-btn my-3 ms-2"
        onClick={() => {
          props.fetchData({ page: 1, per_page: 10 }, props.company.id);
        }}
      >
        {isLoading ? "Loading..." : "Reload"}
      </Button>
      <TblComp
        columns={[
          {
            id: "transaction_date",
            Header: "Date",
            accessor: "transaction_date",
            filter: "fuzzyText",
            Cell: ({ value }) => (value ? moment(value).format("lll") : ""),
          },
          {
            Header: "Ref no.",
            accessor: "request_ref",
            filter: "fuzzyText",
          },
          {
            id: "debit", // Required because our accessor is not a string
            Header: "Debit",
            accessor: "debit",
            filter: "fuzzyText",
            Cell: ({ value }) => (value ? currFormat(value * 1) : ""),
          },
          {
            id: "credit", // Required because our accessor is not a string
            Header: "Credit",
            accessor: "credit",
            filter: "fuzzyText",
            Cell: ({ value }) => (value ? currFormat(value * 1) : ""),
          },
          {
            id: "charges",
            Header: "Charges",
            accessor: "charges",
            filter: "fuzzyText",
            Cell: ({ value }) => (value ? currFormat(value * 1) : ""),
          },
          {
            id: "balance", // Required because our accessor is not a string
            Header: "Balance",
            accessor: "balance",
            // maxWidth: 100,
            Cell: ({ value }) => (value ? currFormat(value * 1) : ""),
          },
          { Header: "Phone", accessor: "msisdn", filter: "fuzzyText" },
          {
            Header: "Narrative",
            accessor: "transaction_note",
            filter: "fuzzyText",
          },
          { Header: "Status", accessor: "status_text", filter: "fuzzyText" },
          {
            id: "actions", // Required because our accessor is not a string
            Header: "Action",
            maxWidth: 150,
            accessor: (full) => (
              <div className="d-flex">
                <Badge
                  href="#transaction_details"
                  onClick={(e) => {
                    toggle();
                    e.preventDefault();
                    setSingle(full);
                  }}
                  title="View Transaction Details"
                  color="info"
                >
                  <img
                    src="/images/view.svg"
                    height="15px"
                    width="15px"
                    alt="view"
                    className="ml-1"
                  />
                </Badge>
                {/* <ApproveActions/> */}
                {/* <Actions  editHandler={props.editHandler}  data_item={full} link_url ={`company_details/`} modal_id="company-modal" deleteHandler={props.deleteHandler}/> */}
              </div>
            ),
          },
        ]}
        {...props}
      />
    </div>
  );
};
