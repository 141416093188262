import React, { useEffect, useState } from "react";
import { decData } from "../../constants/constants";
import Axios from "axios";
import { useIdleTimer } from "react-idle-timer";
import { Button, Modal, ModalBody, Container, ModalHeader, ModalFooter } from "reactstrap";

export default function SessionTimeoutPrompt({
  expiryTime,
  logOutUser,
  tokenRefresh,
}) {
  const token = decData(sessionStorage.getItem("accessor"));
  Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  // Set timeout values
  const timeout = 1000 * 60 * 3; //don't allow more than 3 minutes of inactivity
  const promptTimeout = 1000 * 60 * 1.5; //Prompt for 1.5 minutes

  // Modal open state
  const [open, setOpen] = useState(false);
  // Time before idle
  const [remaining, setRemaining] = useState(0);

  const onPrompt = () => {
    //called after the timeout value is reached
    setOpen(true);
    setRemaining(promptTimeout / 1000);
  };

  const onIdle = () => {
    //called after the promptTimeout is reached
    setOpen(false);
    setRemaining(0);
    logOutUser();
  };

  const onActive = () => {
    setOpen(false);
    setRemaining(0);
  };

  const { getRemainingTime, isPrompted, reset } = useIdleTimer({
    timeout,
    onPrompt,
    onIdle,
    promptTimeout,
    debounce: 500,
    onActive,
  });

  const handleSessionRestore = () => {
    setOpen(false);
    reset();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      } else {
        const remainingMinutes = getRemainingMinutes(expiryTime);
        if (remainingMinutes < 0) {
          logOutUser();
        } else if (remainingMinutes <= 5 && token != null) {
          //if we are left with five minutes before expiry of the token, we should refresh the token
          //we chose 5 minutes to ensure the session doesn't expire even while the prompt is on (for 2minutes)
          tokenRefresh();
        } else {
          //do nothing
        }
      }
    }, 1000 * 30); //check every 30 seconds
    return () => {
      clearInterval(interval);
    };
  }, [expiryTime, getRemainingTime, isPrompted,logOutUser,token,tokenRefresh]);

  //get relative time
  const getRemainingMinutes = (expTs) => {
    // This function computes the delta between the
    // provided timestamp and the current time
    var currentTs = new Date().getTime(); // Gets the current timestamp (milliseconds since epoch time)
    var currTs = Math.floor(currentTs / 1000);
    return Math.ceil((expTs - currTs) / 60);
  };

  return (
    <Modal isOpen={open} size="md">
      <ModalHeader>
        <h2>Session Timeout</h2>
      </ModalHeader>
      <ModalBody>
        <Container>
          <p>You've been inactive for long.</p>
          <p>
            You will be logged out in <strong>{remaining}</strong> seconds
          </p>
        </Container>
      </ModalBody>
      <ModalFooter>
          <Button
            className="shadow-none"
            color="primary"
            onClick={handleSessionRestore}
            block
          >
            Restore Session
          </Button>
      </ModalFooter>
    </Modal>
  );
}
