import React, { useState, useRef } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Button, Container, Col, Row, FormGroup } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { HandleDocumentTypeSubmit } from "../../../../../actions/settings/types/document_type_actions";
import { TextField, CustomModal } from "../../../../../constants";

const AddDocumentType = (props) => {
  const dispatch = useDispatch();
  const { is_processing, form_state } = useSelector(
    (state) => state.documenttypereducer,
    shallowEqual
  );
  const { modal, toggle } = props;

  const formikRef = useRef();

  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

  const validate = Yup.object({
    document_name: Yup.string().required("Document name  is required"),
    description: Yup.string().nullable(),
  });

  return (
    <CustomModal
      modal={modal}
      toggle={toggle}
      tittle={
        form_state.id !== undefined ? "Edit Document Type" : "Add Document Type"
      }
      is_processing={is_processing}
    >
      <Formik
        enableReinitialize
        initialValues={{
          document_name: form_state.document_name,
          description: form_state.description,
        }}
        validationSchema={validate}
        validateOnChange={validateAfterSubmit}
        validateOnBlur={true}
        ref={formikRef}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          setValidateAfterSubmit(true);
          dispatch(
            HandleDocumentTypeSubmit(values, toggle, setErrors, setSubmitting)
          );
        }}
      >
        {(formik) => (
          <Container>
            <Form>
              <FormGroup>
                <Row className="mt-3 ml-1">
                  <Col lg={12} md={12} sm={12} className="my-2 ">
                    <TextField
                      name="document_name"
                      type="text"
                      value={formik.values.document_name}
                      placeholder="Document type"
                    />
                  </Col>
                  <Col lg={12} md={12} sm={12} className="my-2">
                    <TextField
                      name="description"
                      type="text"
                      value={formik.values.description}
                      placeholder="Description"
                    />
                  </Col>
                  <Col lg={12} md={12} sm={12} className="mt-3">
                    <Button
                      type="submit"
                      className="btn btn-pay btn-block text-white"
                      block
                      disabled={is_processing}
                    >
                      {form_state.id
                        ? is_processing
                          ? "... Updating"
                          : "Update"
                        : is_processing
                        ? "... Saving"
                        : "Save"}
                    </Button>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </Container>
        )}
      </Formik>
    </CustomModal>
  );
};
export default AddDocumentType;
