import Axios from "axios";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { decData } from "../../constants/constants";
import allRoutes from "../routes/routes";
import { FootNote } from "./footer";
import { TopNav } from "./header";
import NotFound from "./notFound";
import { SideBar } from "./sidebar";

export const AuthenicatedRoutes = (props) => {
  var torken = sessionStorage.getItem("accessor");
  torken = decData(torken);

  Axios.defaults.headers.common["Authorization"] = `Bearer ${
    props.token ? props.token : torken
  }`;
  Axios.defaults.headers.common["refresh"] = `${sessionStorage.getItem(
    "refresh"
  )}`;

  return (
    <>
      <TopNav {...props} />
      <div className="page-content">
        <div className="row">
          <SideBar {...props} />
          <Switch>
            {allRoutes.map((page, key) => {
              return (
                <Route
                  exact={typeof page.exact !== "undefined" ? page.exact : false}
                  path={page.path}
                  component={page.component}
                  key={key}
                />
              );
            })}
            <Route component={NotFound} />
          </Switch>
        </div>
      </div>
      <div id="footer-style">
        <FootNote />
      </div>
    </>
  );
};
