import React from "react";
import { Route, Switch } from "react-router-dom";
import CompanyComp from "./component";
import CompanyDetailsComp from "./company_details/component";

export default function Companies() {
  const parent_url = "/merchant/detail/:merchant_id/company";
  return (
    <Switch>
      <Route
        path={`${parent_url}/:company_id`}
        component={CompanyDetailsComp}
      />
      <Route path={parent_url} component={CompanyComp} />
    </Switch>
  );
}
