import React from "react";
import { useDispatch } from 'react-redux';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Container,
} from "reactstrap";
import {
  HandleCompanySubSubmit,
} from "../../../../../../actions/company_sub_actions";

export const AddSubPayment = (props) => {
  const dispatch = useDispatch();
  const { toggle, modal } = props;
  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        {props.wallet_bal &&
          props.subplan &&
          parseInt(props.wallet_bal) > parseInt(props.subplan.amount) && (
            <>
              <ModalBody>
                <Container className="mt-1">
                  <span className="lead graph-text-active">
                    Wallet Balance:
                  </span>
                  <b>
                    (UGX)
                    {props.wallet_bal &&
                      parseInt(props.wallet_bal).toLocaleString("en")}
                  </b>
                  <Row className="my-2">
                    <span className="upgrade-2">Upgrading to: </span>{" "}
                    <span className="upgrade-1 ml-1">
                      {" "}
                      {props.subplan.subscription_plan_name}
                    </span>
                  </Row>
                  <Row>
                    <span className="upgrade-2">
                      You're paying UGX{" "}
                      {parseInt(props.subplan.amount).toLocaleString("en")} for
                      a {props.subplan.subscription_plan_name} package using
                      your wallet.
                    </span>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter>
                <Button data-dismiss="modal" className="btn clear-close-text">
                  Cancel
                </Button>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(HandleCompanySubSubmit(
                      { subscription_plan_id: props.subplan.id },
                      props.company_id
                    ));
                  }}
                  className="btn pay-btn"
                >
                  <span className="pay-btn-text ">Subscribe</span>
                </Button>
              </ModalFooter>
            </>
          )}
        {props.wallet_bal &&
          Object.keys(props.subplan).length > 0 &&
          parseInt(props.wallet_bal) < parseInt(props.subplan.amount) && (
            <ModalBody>
              <span className="d-flex justify-content-center lead">
                Your Wallet balance is low, please first top up account.
              </span>
              <Row className="d-flex justify-content-center my-2 pt-2">
                <a
                  className="btn "
                  href="#!"
                  title="click to top up"
                >
                  {" "}
                  <img
                    src="https://www.flaticon.com/premium-icon/icons/svg/1992/1992082.svg"
                    alt=""
                    width="100px"
                    height="100px"
                  />
                </a>
              </Row>
            </ModalBody>
          )}
        {!props.wallet_bal && (
          <ModalBody>
            <span className="d-flex justify-content-center lead">
              Your Wallet balance is low, please proceed to collections to top
              up.
            </span>
            <Row className="d-flex justify-content-center my-2 pt-2">
              <a
                className="btn "
                href="#!"
                title="click to top up"
              >
                {" "}
                <img
                  src="https://www.flaticon.com/premium-icon/icons/svg/1992/1992082.svg"
                  alt=""
                  width="100px"
                  height="100px"
                />
              </a>
            </Row>
          </ModalBody>
        )}
      </Modal>
    </>
  );
};
