import allconstants from "../constants";

export const HandleMerchantInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return { type: "MERCHANT_INPUT_CHANGE", changedField: { [name]: value } };
};

export const clearMerchantForm = (e) => {
  e && e.preventDefault();
  return { type: "CLEAR_MERCHANT_FORM" };
};

export const MerchantFetchSuccess = (payload, msg) => {
  return { type: "FETCH_MERCHANT_SUCCESS", payload };
};

export const MerchantAggregateFetchPendingSuccess = (payload, msg) => {
  return { type: "FETCH_AGGREGATE_PENDING_MERCHANT_SUCCESS", payload };
};
export const MerchantAggregateFetchLiveSuccess = (payload, msg) => {
  return { type: "FETCH_AGGREGATE_LIVE_MERCHANT_SUCCESS", payload };
};

export const FetchOneMerchantSuccess = (merchant) => {
  return { type: "FETCH_ONE_MERCHANT_SUCCESS", merchant };
};

export const MerchantAddSuccess = (new_record, update = false) => {
  if (update) {
  } else {
  }

  return { type: "ADD_MERCHANT_SUCCESS", new_record };
};
export const MerchantDeleteSuccess = (msg, record_id) => {
  return { type: "DELETE_MERCHANT_SUCCESS", record_id };
};
export const MerchantReqError = (msg) => {
  return { type: "MERCHANT_ERROR" };
};

export const processingMerchantRequest = (value) => {
  return { type: "MERCHANT_REQUEST_PROCESSING", value };
};

export const editMerchant = (obj) => {
  return { type: "EDIT_MERCHANT", current_merchant_obj: obj };
};
export const HandleMerchantSubmit =
  (form_data, toggleState, setErrors, setSubmitting) => (dispatch) => {
    dispatch(processingMerchantRequest(true));
    const url = form_data.id
      ? allconstants.apiurl + "merchant/" + form_data.id
      : allconstants.apiurl + "merchant";

    const req_type = form_data.id
      ? allconstants.axios.put
      : allconstants.axios.post;
    req_type(url, form_data, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 201) {
          setSubmitting(false);
          dispatch(MerchantAddSuccess(response.data));
          dispatch(processingMerchantRequest(false));
          toggleState();
        } else if (response.status === 200) {
          dispatch(editMerchant(response.data));
          dispatch(processingMerchantRequest(false));
          toggleState();
        }
      })
      .catch((error) => {
        dispatch(processingMerchantRequest(false));
        if (error.response) {
          setErrors(error.response.data.errors);
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Bad/No internet connection");
        }
      });
  };
export const HandleMerchantDelete = (record_id, toggle) => (dispatch) => {
  const url = allconstants.apiurl + "merchant/" + record_id;
  dispatch(processingMerchantRequest(true));
  allconstants.axios
    .delete(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 201 || response.status === 204) {
        dispatch(MerchantDeleteSuccess(response.data.message, record_id));
        dispatch(processingMerchantRequest(false));
        toggle();
      }
    })
    .catch((err) => {
      console.error(err);
      dispatch(processingMerchantRequest(true));
      dispatch(MerchantReqError("Network/Server Error"));
    });
};

export const MCFetchSuccess = (payload, type) => {
  return { type: type, payload };
};
export const IsLoading = (payload) => {
  return { type: "LOADING_MERCHANTS", payload };
};
export const HandleMerchantFetch =
  (params = {}) =>
  (dispatch) => {
    dispatch(IsLoading(true));
    allconstants.axios
      .get(allconstants.apiurl + "merchant", {
        headers: allconstants.headers,
        params,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(MerchantFetchSuccess(response.data));
          dispatch(IsLoading(false));
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch(IsLoading(false));
        dispatch(MerchantReqError("Network/Server Error"));
      });
  };

export const FetchAggregateMerchantData =
  (params = {}) =>
  (dispatch) => {
    allconstants.axios
      .get(allconstants.apiurl + "dashboard/merchant", {
        headers: allconstants.headers,
        params,
      })
      .then((response) => {
        if (response.status === 200) {
          if (params.status === "pending") {
            dispatch(
              MerchantAggregateFetchPendingSuccess(response.data.pending)
            );
          } else if (params.status === "live") {
            dispatch(MerchantAggregateFetchLiveSuccess(response.data.live));
          }
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch(MerchantReqError("Network/Server Error"));
      });
  };

export const FetchOneMerchant = (merchant_id) => (dispatch) => {
  dispatch(clearMerchantForm());
  allconstants.axios
    .get(allconstants.apiurl + "merchant/" + merchant_id, {
      headers: allconstants.headers,
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch(FetchOneMerchantSuccess(response.data));
      }
    })
    .catch((err) => {
      console.error(err);
      dispatch(MerchantReqError("Network/Server Error"));
    });
};
