import React, { useState } from "react";
import AddCurrency from "./add_currency";
import { CurrencyTable } from "./currency_table";

const Currency = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <>
      <AddCurrency modal={modal} toggle={toggle} />
      <CurrencyTable toggle={toggle} />
    </>
  );
};

export default Currency;
