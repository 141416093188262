import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import allconstants from "../../constants/index";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { doAuthAction } from "../../actions/api/login_api";
import classnames from "classnames";
import { useForm } from "react-hook-form";
import { ClienIp } from "../../constants/constants";
import { Form, Col, Button, FormGroup, Row } from "reactstrap";

const selectorFn = (state) => state.authState;

const Login = () => {
  const dispatch = useDispatch();
  const authState = useSelector(selectorFn, shallowEqual);
  const { message, is_loading } = authState;

  const { handleSubmit, register, errors } = useForm();
  const onSubmit = (values) => dispatch(doAuthAction({ ...values, ipAddress }));
  let ipAddress;
  useEffect(() => {
    ClienIp((response) => {
      // ipAddress = response;
    });
  }, []);

  return (
    <>
      <Row style={{ height: "100vh" }}>
        <Col lg={5} md={5} sm={12} className="left-login-bg">
          <Row className="offset-1">
            <div>
              <div className="sente-pay-welcome">
                <span className="white-bg-line">&nbsp;</span>Welcome
              </div>
            </div>
            <Col lg={12} className="height-40">
              &nbsp;
            </Col>
            <Col lg={6}>
              <img
                src="/images/logo.svg"
                alt="SentePay"
                style={{ width: "100%" }}
              />
            </Col>
            <Col lg={12} className="show-for-large pl-0">
              <span className="sente-pay-text-bold-organge">
                Administration Panel
              </span>
            </Col>
            <Col lg={12} className="show-for-large">
              <img
                src={allconstants.login_img}
                height="300px"
                alt="Login"
                className="login_img"
              />
            </Col>
          </Row>
        </Col>
        <Col lg={7} md={7} sm={12} className="pe-0">
          <Row>
            <Col lg={6}> </Col>
            <Col lg={6}>
              <img
                alt=""
                className="float-end"
                src={allconstants.login_svg_img}
                height="200px"
                width="220px"
              />
            </Col>
          </Row>
          <Row>
            <div className="login-wrapper">
              <div className="box login-dialog-box">
                <div className="content-wrap">
                  <Form
                    autoComplete="off"
                    className="border border-light p-3"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <p className="h4 mb-4 text-left login_title">Login</p>
                    {message && !is_loading && (
                      <div className="mt-2">
                        <div
                          className="alert alert-warning fade show"
                          role="alert"
                        >
                          <strong>{message}</strong>
                        </div>
                      </div>
                    )}
                    <FormGroup>
                      <Row>
                        <input
                          autoComplete="off"
                          type="text"
                          placeholder="Enter your email"
                          className={classnames("form-control", {
                            "is-valid": errors?.email === false,
                          })}
                          {...register("username", {
                            required: true,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Invalid email address",
                            },
                          })}
                          style={{ borderColor: errors?.email && "red" }}
                        />
                        <div className="invalid-feedback">
                          {errors?.username && errors?.username.message}
                        </div>

                        <input
                          autoComplete="off"
                          type="password"
                          placeholder="Enter your password"
                          className={classnames("form-control", {
                            "is-valid": errors?.password === true,
                          })}
                          {...register("password", {
                            required: true,
                            minLength: 6,
                          })}
                          style={{ borderColor: errors?.password && "red" }}
                        />
                        <div className="invalid-feedback">
                          {errors?.password && errors?.password.message}
                        </div>

                        {is_loading === true ? (
                          <>
                            {" "}
                            <Button
                              className="btn btn-info sign-in-btn btn-block my-1 shadow-none text-white"
                              type="submit"
                            >
                              <span
                                className="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Logging in...
                            </Button>
                          </>
                        ) : (
                          <Button
                            className="btn btn-info sign-in-btn btn-block my-1 shadow-none text-white"
                            type="submit"
                          >
                            Sign in
                          </Button>
                        )}
                        <div className="d-flex justify-content-left mt-2">
                          <div className="float-end">
                            <Link to="forgot-password" className="forgot-pwd">
                              Forgot password?
                            </Link>
                          </div>
                        </div>
                      </Row>
                    </FormGroup>
                  </Form>
                </div>
              </div>
              <div className="mt-5">
                <span className="rights-text">
                  All Rights Reserved. SentePay. A Product of GMT Consults LTD
                </span>
              </div>
            </div>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default Login;
