const initialState = {
  pending: "",
  all: "",
  active: "",
  pending_trans: "",
  rejected_trans: "",
};

export const DashboardReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case "FETCH_PENDING_SUCCESS":
      return { ...prevState, pending: action.payload.data.length };
    case "FETCH_ALL_SUCCESS":
      return { ...prevState, all: action.payload.data.length };
    case "FETCH_ACTIVE_SUCCESS":
      return { ...prevState, active: action.payload.data.length };
    case "FETCH_PENDING_TRANS_SUCCESS":
      return { ...prevState, pending_trans: action.payload.data.length };
    case "FETCH_REJECTED_TRANS_SUCCESS":
      return { ...prevState, rejected_trans: action.payload.data.length };
    default:
      return prevState;
  }
};
