import allconstants from "../constants";

export const HandleCompanyInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return { type: "COMPANY_INPUT_CHANGE", changedField: { [name]: value } };
};

export const clearCompanyForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_COMPANY_FORM" };
};

export const CompanyFetchSuccess = (payload) => {
  return { type: "FETCH_COMPANY_SUCCESS", payload };
};

export const IsLoading = (payload) => {
  return { type: "LOADING_BUSINESS", payload };
};
export const CompanyAddSuccess = (new_record, update = false) => {
  if (update) {
  } else {
  }

  return { type: "ADD_COMPANY_SUCCESS", new_record };
};
export const CompanyDeleteSuccess = (msg, record_id) => {
  return { type: "DELETE_COMPANY_SUCCESS", record_id };
};
export const processingCompanyRequest = (value) => {
  return { type: "COMPANY_REQUEST_PROCESSING", value };
};
export const CompanyReqError = (msg) => {
  return { type: "COMPANY_ERROR" };
};
export const editCompany = (obj) => {
  return { type: "EDIT_COMPANY", current_company_obj: obj };
};
export const HandleCompanySubmit =
  (form_data, url_id, toggle) => (dispatch) => {
    dispatch(processingCompanyRequest(true));

    const url = form_data.id
      ? allconstants.apiurl + "company/" + form_data.id
      : allconstants.apiurl + "merchant/" + url_id + "/company";

    const req_type = form_data.id
      ? allconstants.axios.put
      : allconstants.axios.post;
    req_type(url, form_data, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 201) {
          dispatch(CompanyAddSuccess(response.data));
          dispatch(processingCompanyRequest(false));
        } else if (response.status === 200) {
          dispatch(CompanyAddSuccess(response.data, true));
          dispatch(processingCompanyRequest(false));
        } else {
          dispatch(CompanyReqError("Something went wrong try again"));
        }
        toggle();
      })
      .catch((err) => {
        dispatch(CompanyReqError("Something went wrong try again"));
        dispatch(processingCompanyRequest(false));
      });
    return null;
  };
export const HandleCompanyDelete = (record_id, toggle) => (dispatch) => {
  const url = allconstants.apiurl + "company/" + record_id;
  dispatch(processingCompanyRequest(true));
  allconstants.axios
    .delete(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 201 || response.status === 204) {
        dispatch(CompanyDeleteSuccess(response.data.message, record_id));
        dispatch(processingCompanyRequest(false));
        toggle();
      }
    })
    .catch((network_response) => {
      console.error(network_response);
      dispatch(processingCompanyRequest(false));
      dispatch(CompanyReqError("Something went wrong try again"));
    });
};
export const FetchSingleCompany = (company_id) => (dispatch) => {
  allconstants.axios
    .get(allconstants.apiurl + "company/" + company_id, {
      headers: allconstants.headers,
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch(CompanyFetchSuccess({ single_record: response.data }));
      }
    })
    .catch((network_response) => {
      console.error(network_response);
      dispatch(CompanyReqError("Something went wrong. Please, try again!"));
    });
};

export const FetchAllCompanines =
  (params = {}) =>
  (dispatch) => {
    dispatch(IsLoading(true));
    allconstants.axios
      .get(allconstants.apiurl + "company", {
        headers: allconstants.headers,
        params,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(CompanyFetchSuccess({ company_list: response.data.data }));
          dispatch(IsLoading(false));
        }
      })
      .catch((network_response) => {
        console.error(network_response);
        dispatch(IsLoading(false));
        dispatch(CompanyReqError("Something went wrong. Please, try again!"));
      });
  };

export const FetchMerchantCompanies =
  (merchant_id, params = {}) =>
  (dispatch) => {
    allconstants.axios
      .get(allconstants.apiurl + "merchant/" + merchant_id + "/company", {
        headers: allconstants.headers,
        params,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(CompanyFetchSuccess({ company_list: response.data.data }));
        }
      })
      .catch((network_response) => {
        console.error(network_response);
        dispatch(CompanyReqError("Something went wrong try again"));
      });
  };

export const handleApprovalStatusChange = (form_data) => (dispatch) => {
  const url =
    allconstants.apiurl + `company/${form_data.id}/action/${form_data.status}`;
  const data = {
    reason_for_decision: form_data.reason_for_decision,
  };

  allconstants.axios
    .post(url, data, { headers: allconstants.headers })
    .then((response) => {
      if (parseInt(response.status) === 200) {
        dispatch(FetchSingleCompany(form_data.id, true));
      }
    })
    .catch((err) => {
      console.error("Network/Server error", err);
      dispatch(CompanyReqError("Something went wrong try again"));
    });
};
