const productFormState = {
  product_name: "",
  description: "",
};

const ProductInitailState = {
  product_list: [],
  current_product_obj: productFormState,
  is_processing: false,
  loading_products: true,
};

export const ProductReducer = (prevState = ProductInitailState, action) => {
  let form_state = prevState.current_product_obj;
  let new_formState;
  switch (action.type) {
    case "CREATE_PRODUCT_SUCCESS":
      let product_new = true;

      let product_list = prevState.product_list.map((product) => {
        if (product.id === action.new_product.id) {
          product_new = false;
          return action.new_product;
        } else {
          return product;
        }
      });
      product_new && product_list.push(action.new_product);
      const new_state = { ...prevState, product_list };
      if (product_new) {
        new_state.current_product_obj = productFormState;
      }
      return new_state;
    case "PRODUCT_INPUT_CHANGE":
      new_formState = { ...form_state, ...action.changedField };
      return { ...prevState, current_product_obj: new_formState };
    case "CLEAR_PRODUCT_FORM":
      return { ...prevState, current_product_obj: productFormState };
    case "LOADING_PRODUCTS":
      return {
        ...prevState,
        loading_products: action.payload,
      };
    case "FETCH_PRODUCT_SUCCESS":
      return { ...prevState, product_list: action.payload.data };
    case "PRODUCT_ERROR":
      return { ...prevState };

    case "EDIT_PRODUCT":
      new_formState = { ...action.current_product_obj };
      return { ...prevState, current_product_obj: new_formState };
    case "DELETE_PRODUCT_SUCCESS":
      let new_list = prevState.product_list.filter(
        (current_rec) => current_rec.id !== action.record_id
      );
      return { ...prevState, product_list: new_list };
    case "PRODUCT_REQUEST_PROCESSING":
      return { ...prevState, is_processing: action.value };
    default:
      return { ...prevState };
  }
};
