const MerchantSupportFormState = {
  id: "",
  ticket_type_id: "",
  description: "",
  file: "",
};
let respForm = { id: "", comment_text: "" };
let actionForm = { id: "", action_id: "", comment_text: "" };
const MerchantSupportInitState = {
  merchant_ticket_list: [],
  responseView: false,
  ticket_types: [],
  ticket_response: [],
  current_merchant_ticket_obj: MerchantSupportFormState,
  resp_obj: respForm,
  action_obj: actionForm,
  loading_tickets: true,
  is_processing: false,
};

export const MerchantTicketsReducer = (
  prevState = MerchantSupportInitState,
  action
) => {
  const form_state = prevState.current_merchant_ticket_obj;
  const resp_state = prevState.resp_obj;
  const action_state = prevState.action_obj;
  let new_formState;
  switch (action.type) {
    case "MERCHANT_TICKET_ERROR":
      return { ...prevState };
    case "FETCH_MERCHANT_TICKET_SUCCESS":
      if (action.isTicket === "ticket") {
        return { ...prevState, ticket_types: action.payload.data };
      } else if (action.isTicket === "response") {
        return { ...prevState, ticket_response: action.payload.data.data };
      } else {
        return { ...prevState, merchant_ticket_list: action.payload.data };
      }
    case "MERCHANT_TICKET_INPUT_CHANGE":
      let new_obj = {};
      if (action.resp) {
        new_formState = { ...resp_state, ...action.changedField };
        new_obj = { ...prevState, resp_obj: new_formState };
      } else if (action.ticket_action) {
        new_formState = { ...action_state, ...action.changedField };
        new_obj = { ...prevState, action_obj: new_formState };
      } else {
        new_formState = { ...form_state, ...action.changedField };
        new_obj = { ...prevState, current_merchant_ticket_obj: new_formState };
      }
      return new_obj;
    case "CLEAR_MERCHANT_TICKET_FORM":
      return {
        ...prevState,
        current_merchant_ticket_obj: MerchantSupportFormState,
      };
    case "LOADING_TICKETS":
      return {
        ...prevState,
        loading_tickets: action.payload,
      };
    case "MERCHANT_SUPPORT_REQUEST_PROCESSING":
      return { ...prevState, is_processing: action.value };
    case "CHANGE_MERCHANT_TICKET_VIEW":
      return { ...prevState, responseView: action.resp };
    case "CLEAR_MERCHANT_RESP_FORM":
      return { ...prevState, resp_obj: respForm };
    case "CLEAR_MERCHANT_TICKET_ACTION_FORM":
      return { ...prevState, action_obj: actionForm };
    case "EDIT_MERCHANT_TICKET":
      new_formState = { ...action.current_merchant_ticket_obj };
      return { ...prevState, current_merchant_ticket_obj: new_formState };
    case "DELETE_MERCHANT_TICKET_SUCCESS":
      if (action.resp) {
        let new_list = prevState.ticket_response.filter(
          (current_rec) => current_rec.id !== action.record_id
        );
        return { ...prevState, ticket_response: new_list };
      } else {
        let new_list = prevState.merchant_ticket_list.filter(
          (current_rec) => current_rec.id !== action.record_id
        );
        return { ...prevState, merchant_ticket_list: new_list };
      }
    case "ADD_MERCHANT_TICKET_SUCCESS":
      // console.log(action.new_record)
      let new_state = null;
      let new_rec = null;
      if (action.new_record === "resolve") {
        new_rec = { ...form_state, status_text: "Resolved" };
      } else if (action.new_record === "cancel") {
        new_rec = { ...form_state, status_text: "Cancelled" };
      } else if (action.new_record === "assign_to_staff") {
        new_rec = { ...form_state, status_text: "Assigned to staff" };
      } else {
        let merchant_ticket_new = true;
        let merchant_ticket_list = prevState.merchant_ticket_list.map(
          (merchant_ticket) => {
            if (merchant_ticket.id === action.new_record.id) {
              merchant_ticket_new = false;
              return action.new_record;
            } else {
              return merchant_ticket;
            }
          }
        );
        merchant_ticket_new && merchant_ticket_list.push(action.new_record);
        new_state = { ...prevState, merchant_ticket_list };
        if (merchant_ticket_new) {
          new_state.current_merchant_ticket_obj = MerchantSupportFormState;
        }
      }
      if (new_rec) {
        new_state = { ...prevState, current_merchant_ticket_obj: new_rec };
      }
      return new_state;
    default:
      return prevState;
  }
};
