import allconstants from "../constants";

const MasterAccountFetchSuccess = (account) => {
  return { type: "FETCH_MASTER_ACCOUNT_SUCCESS", account };
};

const MasterAccountReqError = () => {
  return { type: "MASTER_ACCOUNT_ERROR", is_processing: true };
};

export const HandleMasterAccountFetch =
  (acc_type = "") =>
  (dispatch) => {
    const url = allconstants.apiurl + "account_details" + acc_type;

    allconstants.axios
      .get(url, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          dispatch(MasterAccountFetchSuccess(response.data));
        }
      })
      .catch((network_response) => {
        console.error(network_response);
        dispatch(MasterAccountReqError("Something went wrong try again"));
      });
  };
