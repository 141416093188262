import React, { useState } from "react";
import { AddRoles } from "./add_roles";
import { RolesTable } from "./roles_table";

export const Roles = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <div className="pb-5">
      <AddRoles modal={modal} toggle={toggle} />
      <RolesTable toggle={toggle} />
    </div>
  );
};

export default Roles;
