import React, { useState } from "react";
import AddDocumentType from "./add_document_type";
import { DocumentTypesTable } from "./document_types_table";

const DocumentTypes = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <>
      <AddDocumentType modal={modal} toggle={toggle} />
      <DocumentTypesTable toggle={toggle} />
    </>
  );
};

export default DocumentTypes;
