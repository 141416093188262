import allconstants from "../../../constants/index";

export const HandleSPC_ProductInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return {
    type: "PRODUCT_SPC_PRODUCT_CHANGE",
    changedField: { [name]: value },
  };
};

export const clearspc_ProductForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_SPC_PRODUCT_FORM" };
};

export const spc_ProductFetchSuccess = (payload, msg) => {
  return { type: "FETCH_SPC_PRODUCT_SUCCESS", payload };
};

export const spc_productAddSuccess = (payload) => {
  return { type: "CREATE_SPC_PRODUCT_SUCCESS", payload };
};
export const spc_productReqError = () => {
  return { type: "PRODUCT_ERROR" };
};

export const spc_editProduct = (obj) => {
  return { type: "EDIT_SPC_PRODUCT", current_product_obj: obj };
};
export const spc_ProductDeleteSuccess = (msg, record_id) => {
  return { type: "DELETE_SPC_PRODUCT_SUCCESS", record_id };
};
export const processingServiceProviderCountryProductRequest = (value) => {
  return { type: "PROVIDER_COUNTRY_PRODUCT_REQUEST_PROCESSING", value };
};

export const HandleSPC_ProductSubmit =
  (form_data, id, toggle, setErrors, setSubmitting) => (dispatch) => {
    dispatch(processingServiceProviderCountryProductRequest(true));
    const url = form_data.id
      ? allconstants.apiurl + "products/" + form_data.id
      : allconstants.apiurl + "sp_country/" + id + "/products";

    const req_type = form_data.id
      ? allconstants.axios.put
      : allconstants.axios.post;

    req_type(url, form_data, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setSubmitting(false);
          dispatch(HandleSPC_ProductFetch(id));
          dispatch(processingServiceProviderCountryProductRequest(false));
          toggle();
        }
      })
      .catch((error) => {
        if (error.response) {
          setErrors(error.response.data.errors);
          dispatch(processingServiceProviderCountryProductRequest(false));
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Bad/No internet connection");
        }
      });

    return null;
  };

export const HandleSPC_ProductFetch = (id) => (dispatch) => {
  const url = allconstants.apiurl + "sp_country/" + id + "/products";
  allconstants.axios
    .get(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 200) {
        dispatch(spc_ProductFetchSuccess(response.data));
      }
    });
};

export const HandleSPC_ProductDelete = (record_id) => (dispatch) => {
  const url = allconstants.apiurl + "products/" + record_id;
  allconstants.axios
    .delete(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(spc_ProductDeleteSuccess(response.message, record_id));
      }
    });
};
