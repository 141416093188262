import React, { useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { FetchSingleCompany } from "../../../../../../actions/company_action";
import { CompanyProfile } from "./company_profile";
import CompanyContactComp from "./company_contacts";
import CompanySubComp from "./subscription";
import { CompanyDocReduxComp } from "./documents";
import TransactionsReduxComp from "./transactions";
import { ApprovalComponent } from "../approval_component";
import CompanyCardSubComp from "./subscription_cards";
import { NavLink, Route, Switch } from "react-router-dom";
import { CardHeader, Col, Card, Nav, NavItem, CardBody } from "reactstrap";
import { DocumentPreviewReduxComp } from "./document_preview";
import ApprovalLogs from "./approvalLogs";
import { currFormat } from "../../../../../../constants/GenHelpers";

export const CompanyDetails = (props) => {
  const dispatch = useDispatch();
  const company_info = useSelector(
    (state) => state.companyState.single_record,
    shallowEqual
  );
  const { match } = props;

  useEffect(() => {
    dispatch(FetchSingleCompany(match.params.company_id));
  }, [dispatch, match.params.company_id]);
  const parent_url =
    "/merchant/detail/" +
    match.params.merchant_id +
    "/company/" +
    match.params.company_id;
  const route_url = "/merchant/detail/:merchant_id/company/:company_id";

  return (
    <>
      <Col lg={12} md={12} sm={12}>
        <div className="d-flex justify-content-between ">
          <span>
            <h6 className="text-uppercase mt-2">{company_info.company_name}</h6>
          </span>
          <span>
            <h6 className="text-uppercase mt-2">
              {typeof company_info.acc_balance !== "undefined" &&
                `Wallet(UGX): ${currFormat(
                  (company_info.acc_balance || 0) * 1
                )}`}
            </h6>
          </span>
          <ApprovalComponent />
        </div>
        <Card>
          <CardHeader>
            <Nav pills>
              <NavItem>
                <NavLink
                  activeClassName="ticket-tab-active"
                  className="nav-item nav-link"
                  to={`${parent_url}`}
                >
                  <span className="btn mb-1">Details</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  activeClassName="ticket-tab-active"
                  className="nav-item nav-link"
                  to={`${parent_url}/contacts`}
                >
                  <span className="btn mb-1">Contacts</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  activeClassName="ticket-tab-active"
                  className="nav-item nav-link"
                  to={`${parent_url}/documents`}
                >
                  <span className="btn mb-1">Documents</span>
                </NavLink>
              </NavItem>
              <NavItem>
                {company_info.active_plan &&
                  company_info.status === "approved" && (
                    <NavLink
                      activeClassName="ticket-tab-active"
                      className="nav-item nav-link"
                      to={`${parent_url}/subscriptions`}
                    >
                      <span className="btn mb-1">Subscriptions</span>
                    </NavLink>
                  )}
              </NavItem>
              {company_info.active_plan && company_info.status === "approved" && (
                <NavItem>
                  <NavLink
                    activeClassName="ticket-tab-active"
                    className="nav-item nav-link"
                    to={`${parent_url}/subscription_cards`}
                  >
                    <span className="btn mb-1"> Subscriptions</span>
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink
                  activeClassName="ticket-tab-active"
                  className="nav-item nav-link"
                  to={`${parent_url}/logs`}
                >
                  <span className="btn mb-1">Logs</span>
                </NavLink>
              </NavItem>
              {company_info.active_plan && company_info.status === "approved" && (
                <NavItem>
                  <NavLink
                    activeClassName="ticket-tab-active"
                    className="nav-item nav-link"
                    to={`${parent_url}/transactions`}
                  >
                    <span className="btn mb-1">Transactions</span>
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </CardHeader>

          <CardBody>
            <Switch>
              <Route
                path={`${route_url}/subscriptions`}
                component={CompanySubComp}
              />
              <Route
                path={`${route_url}/subscription_cards`}
                component={CompanyCardSubComp}
              />
              <Route
                path={`${route_url}/contacts`}
                component={CompanyContactComp}
              />
              <Route
                path={`${route_url}/documents`}
                component={CompanyDocReduxComp}
              />
              <Route
                path={`${route_url}/transactions`}
                component={TransactionsReduxComp}
              />
              <Route
                path={`${route_url}/document`}
                component={DocumentPreviewReduxComp}
              />
              <Route
                path={`${route_url}/document/:id`}
                component={DocumentPreviewReduxComp}
              />
              <Route path={`${route_url}/logs`} component={ApprovalLogs} />
              <Route path={`${route_url}`} component={CompanyProfile} />
            </Switch>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};
export default CompanyDetails;
