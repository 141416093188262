import React from "react";
import { Route, Switch } from "react-router-dom";
import SubscriptionPlanComp from "./component";
import SubPlanChargeRateComp from "./charge_rates/component";

export default function SubscriptionPlans() {
  const parent_url = "/settings/subscription_plans";
  return (
    <Switch>
      <Route
        path={`${parent_url}/charge_rates/:id`}
        component={SubPlanChargeRateComp}
      />
      <Route component={SubscriptionPlanComp} />
    </Switch>
  );
}
