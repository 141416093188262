import { checkInArray } from "../constants/GenHelpers";

const initialState = {
  selected_staff_roles:[],
    is_processing: false,
  };
  
  export default function staffRolesReducer(prevState = initialState, action) {
    switch (action.type) {
      case "EDIT_STAFF_ROLES":
        return {...prevState,selected_staff_roles:action.roles.map((role)=>role.id)};
      case "STAFF_ROLE_INPUT_CHECKED":
        const { value } = action;
        if (checkInArray(prevState.selected_staff_roles,parseInt(value))) {
          return {...prevState,selected_staff_roles:[...prevState.selected_staff_roles.filter(staffRoleId => staffRoleId !== parseInt(value))]};
        } else {
          return {...prevState,selected_staff_roles:[...prevState.selected_staff_roles,parseInt(value)]};
        }
      case "STAFF_ROLE_ASSIGN_SUCCESS":
        return { ...prevState };
      case "STAFF_ROLE_ERROR":
        return { ...prevState };
      case "STAFF_ROLE_REQUEST_PROCESSING":
        return { ...prevState, is_processing: action.value };
      default:
        return {...prevState};
    }
  }
  