import React, { useState } from "react";
import AddContactType from "./add_contact_type";
import { ContactTypeTable } from "./contact_type_table";

export const ContactType = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <>
      <AddContactType modal={modal} toggle={toggle} />
      <ContactTypeTable toggle={toggle} />
    </>
  );
};
export default ContactType;
