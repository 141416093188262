const transacnFormState = {
  id: "",
  credit: "",
  debit: null,
  transaction_date: "",
  transaction_type_id: 3,
  transaction_note: "ACCOUNT TOP UP",
  transaction_mode_id: 2,
  status: 3,
};

const initialState = {
  transactions: [],
  transaction: transacnFormState,
  all_transactions: "",
  completed: "",
  pending: "",
  transaction_sum: "",
  requesting: false,
  loading_transactions: true,
};

export const TransactionReducer = (prevState = initialState, action) => {
  let form_state = prevState.transaction;
  let new_formState;
  switch (action.type) {
    case "FETCH_TRANSMERCHANT_SUCCESS":
      const { total, last_page: pageCount } = action.payload;
      return {
        ...prevState,
        total,
        pageCount,
        transactions: action.payload.data,
      };
    case "FETCH_AGGREGATE_TRANS_SUCCESS":
      const { all_transactions, completed, transaction_sum, pending } =
        action.payload;
      return {
        ...prevState,
        all_transactions,
        completed,
        transaction_sum,
        pending,
      };
    case "CREATE_TRANSACTION_SUCCESS":
      /*let transaction_new = true;
      let transactions = prevState.transactions.map((transaction) => {
        if (transaction.id === action.new_transaction.id) {
          transaction_new = false;
          return action.new_transaction;
        } else {
          return transaction;
        }
      });
      transaction_new && transactions.push(action.new_transaction);
      const new_state = { ...prevState, transactions };
      if (transaction_new) {
        new_state.transaction = transacnFormState;
      }
      return new_state;*/
      return {
        ...prevState,
        transaction: transacnFormState,
        requesting: false,
      };
    case "LOADING_TRANSACTIONS":
      return {
        ...prevState,
        loading_transactions: action.payload,
      };
    case "TRANSACTION_INPUT_CHANGE":
      new_formState = { ...form_state, ...action.changedField };
      return { ...prevState, transaction: new_formState };
    case "CLEAR_TRANSACTION_FORM":
      return { ...prevState, transaction: transacnFormState };
    case "FETCH_TRANSACTION_SUCCESS":
      return { ...prevState, transactions: action.payload.data };
    case "INIT_TRANSACTION":
      return { ...prevState, requesting: true };
    case "TRANSACTION_REQ_ERROR":
      return { ...prevState, requesting: false };
    default:
      return prevState;
  }
};
