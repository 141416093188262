import allconstants from "../../../constants";

export const HandleDocumentTypeInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return {
    type: "DOCUMENT_TYPE_INPUT_CHANGE",
    changedField: { [name]: value },
  };
};

export const processingDocumentTypeRequest = (value) => {
  return { type: "DOCUMENT_TYPE_REQUEST_PROCESSING", value };
};
export const clearDocumentTypeForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_DOCUMENT_TYPE_FORM" };
};
export const IsLoading = (payload) => {
  return { type: "LOADING_DOC_TYPES", payload };
};

export const DocumentTypeFetchSuccess = (payload, msg) => {
  return { type: "FETCH_DOCUMENT_TYPE_SUCCESS", payload };
};
export const DocumentTypeAddSuccess = (new_record, update = false) => {
  if (update) {
  } else {
  }
  return { type: "ADD_DOCUMENT_TYPE_SUCCESS", new_record };
};
export const DocumentTypeDeleteSuccess = (msg, record_id) => {
  return { type: "DELETE_DOCUMENT_TYPE_SUCCESS", record_id };
};
export const DocumentTypeReqError = (msg) => {
  return { type: "DOCUMENT_TYPE_ERROR" };
};

export const editDocumentType = (form_state) => {
  return { type: "EDIT_DOCUMENT_TYPE", form_state };
};

export const HandleDocumentTypeSubmit =
  (form_data, toggle, setErrors, setSubmitting) => (dispatch) => {
    dispatch(processingDocumentTypeRequest(true));

    const url = form_data.id
      ? allconstants.apiurl + "doc_type/" + form_data.id
      : allconstants.apiurl + "doc_type";

    const req_type = form_data.id
      ? allconstants.axios.put
      : allconstants.axios.post;
    req_type(url, form_data, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setSubmitting(false);
          if (form_data.id) {
            dispatch(DocumentTypeAddSuccess(response.data, true));
            dispatch(processingDocumentTypeRequest(false));
          } else {
            dispatch(DocumentTypeAddSuccess(response.data));
            dispatch(processingDocumentTypeRequest(false));
          }
          toggle();
        }
      })
      .catch((error) => {
        dispatch(processingDocumentTypeRequest(false));
        if (error.response) {
          setErrors(error.response.data.errors);
          dispatch(DocumentTypeReqError(error));
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Bad/No internet connection");
        }
      });
  };
export const HandleDocumentTypeDelete = (record_id, toggle) => (dispatch) => {
  const url = allconstants.apiurl + "doc_type/" + record_id;
  dispatch(processingDocumentTypeRequest(true));
  allconstants.axios
    .delete(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(DocumentTypeDeleteSuccess(response.message, record_id));
        dispatch(processingDocumentTypeRequest(false));
        toggle();
      }
    })
    .catch((err) => {
      console.error("Network/Server Error", err);
      dispatch(processingDocumentTypeRequest(false));
      dispatch(DocumentTypeReqError("Network/Server Error"));
    });
};
export const HandleDocumentTypeFetch = () => (dispatch) => {
  const url = allconstants.apiurl + "doc_type";
  allconstants.axios
    .get(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 200) {
        dispatch(DocumentTypeFetchSuccess(response.data));
        dispatch(IsLoading(false));
      }
    })
    .catch((err) => {
      dispatch(IsLoading(false));
      console.error("Network/Server Error", err);
      dispatch(DocumentTypeReqError("Network/Server Error"));
    });
};
