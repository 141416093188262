import React, { useCallback, useRef, useState } from "react";
import { connect } from "react-redux";
import { FetchCompanyTransactions } from "../../../../../../../actions/collection_actions";
import { Select } from "../../../../../../../constants/constants";
import { TransactionsTable } from "./table";

const Transactions = (props) => {
  const [transaction_status, setTxnStatus] = useState("");
  const fetchIdRef = useRef(0);

  const fetchData = useCallback(
    (pageOptions) => {
      const fetchId = ++fetchIdRef.current;
      if (fetchId === fetchIdRef.current) {
        props.dispatch(
          FetchCompanyTransactions(
            {
              ...pageOptions,
              status: transaction_status || null,
            },
            props.company.id
          )
        );
      }
    },
    [props, transaction_status]
  );

  let tableProps = {
    total: props.total,
    data: props.transactions,
    pageCount: props.pageCount,
    fetchData,
    serverSide: true,
    dispatch: props.dispatch,
  };

  if (props.company === undefined) {
    return <>N/A</>;
  }
  return (
    <>
      <div className="float-none w-25 mb-2">
        {typeof props.company.id !== "undefined" && (
          <Select
            placeholder="--Check Status--"
            name="transaction_status"
            index_key="id"
            label_key="status_name"
            value={transaction_status}
            options={[
              { id: 2, status_name: "Pending Approval" },
              { id: 3, status_name: "Successful" },
              { id: 4, status_name: "Failed" },
            ]}
            handleChange={(e) => {
              setTxnStatus(e.target.value);
            }}
          />
        )}
      </div>

      {typeof props.transactions !== "undefined" ? (
        <TransactionsTable {...tableProps} fetchData={fetchData} />
      ) : (
        <span className="lead m-5">Loading table...</span>
      )}
    </>
  );
};
// connect the store for the company dispatches and state
const mapTransactionStateToProps = (state) => {
  return {
    company: state.companyState.single_record,
    current_payment_obj: state.paymentState.current_payment_obj,
    current_collection_obj: state.collectionState.current_collection_obj,
    status_name: state.collectionState.status_name,
    initCollection: state.collectionState.initCollection,
    transactions: state.collectionState.transactions,
    total: state.collectionState.total,
    pageCount: state.collectionState.pageCount,
  };
};

const TransactionsReduxComp = connect(mapTransactionStateToProps)(Transactions);
export default TransactionsReduxComp;
