import allconstants from "../constants";

export const PaymentWithdrawRequestSuccess = (payload) => {
  return { type: "FETCH_WITHDRAW_REQUEST_SUCCESS", payload };
};

export const PaymentWithdrawApproveSuccess = (payload) => {
  return { type: "WITHDRAW_REQUEST_APPROVE_SUCCESS", payload };
};

export const IsProcessing = (payload) => {
  return { type: "PROCESSING", payload };
};

export const DeleteWithdrawRequestSuccess = (payload, trans_id) => {
  return { type: "DELETE_WITHDRAW_REQUEST_SUCCESS", payload, trans_id };
};

export const IsLoading = (payload) => {
  return { type: "LOADING_WITHDRAW", payload };
};

export const WithdrawReqError = (msg) => {
  return { type: "WITHDRAW_ERROR" };
};
export const WithdrawReqDeleteError = (msg) => {
  return { type: "WITHDRAW_DELETE_ERROR" };
};

export const HandleWithdrawApproval = (trans_id, setModal) => (dispatch) => {
  dispatch(IsProcessing(true));
  let url = allconstants.apiurl + "funds/transfer/" + trans_id;

  allconstants.axios
    .put(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 200) {
        dispatch(PaymentWithdrawApproveSuccess(response.data));
        setModal(false);
        dispatch(IsProcessing(false));
      }
    })
    .catch((err) => {
      console.error(err);
      dispatch(IsProcessing(false));
      dispatch(WithdrawReqError("Network/Server Error"));
    });
};

export const HandleWithdrawFetch =
  (params = {}) =>
  (dispatch) => {
    let url = allconstants.apiurl + "transaction?status=1&mode=2";
    allconstants.axios
      .get(url, { headers: allconstants.headers, params })
      .then((response) => {
        if (response.status === 200) {
          dispatch(PaymentWithdrawRequestSuccess(response.data));
          dispatch(IsLoading(false));
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch(IsLoading(false));
        dispatch(WithdrawReqError("Network/Server Error"));
      });
  };

export const HandleWithdrawDelete =
  (trans_id, setDeleteModal) => (dispatch) => {
    const url = allconstants.apiurl + "funds/liquidation/" + trans_id;
    const req_type = allconstants.axios.delete;

    req_type(url, {}, { headers: allconstants.headers })
      .then((response) => {
        dispatch(DeleteWithdrawRequestSuccess(response.data, trans_id));
        setDeleteModal(false);
      })
      .catch((error) => {
        if (error.response) {
          WithdrawReqDeleteError(error.response.message);
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.error("Poor/No internet connection");
        }
      });
  };
