import React, { useState } from "react";
import { Link } from "react-router-dom";
import { checkInArray } from "./GenHelpers";
import { Row, Col, Input, Badge } from "reactstrap";
const CryptoJS = require("crypto-js");

export const Accordion = (props) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="accordion-wrapper">
      <div
        className={`accordion-title ${isOpen ? "open" : ""}`}
        onClick={() => setOpen(!isOpen)}
      >
        <Row style={{ width: "100%" }}>
          <Col md={8} sm={9} lg={8}>
            {" "}
            {props.title}
          </Col>
          <Col md={4} sm={3} lg={4}>
            {" "}
            {props.action}
          </Col>
        </Row>
      </div>
      <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
        <div className="accordion-content">{props.content}</div>
      </div>
    </div>
  );
};
export const InputUnit = (props) => {
  return (
    <Input
      autoComplete="off"
      className="form-control inputSize "
      id={props.name}
      name={props.name}
      type={props.type}
      value={props.value}
      disabled={props.disabled ? props.disabled : false}
      min={props.min ? props.min : props.type === "number" ? 0 : null}
      max={props.max ? props.max : null}
      step={props.step ? props.step : props.type === "number" ? 1 : null}
      onChange={props.handleChange}
      placeholder={props.placeholder}
      style={props.style}
      onKeyUp={props.keyUp}
    />
  );
};

export const InputChecked = (props) => {
  return (
    <Input
      autoComplete="off"
      className="form-check-input"
      id={props.name}
      name={props.name}
      type={props.type}
      value={props.value}
      checked={props.checked}
      onChange={props.handleChange}
      placeholder={props.placeholder}
    />
  );
};

export const CapitalizeFirstLetter = (str) => {
  return str.length ? str.charAt(0).toUpperCase() + str.slice(1) : str;
};
/*Input */
export const InputField = (props) => {
  return (
    <>
      <InputUnit {...props} />
    </>
  );
};

/*Input Group */

/*Select.jsx*/
export const Select = (props) => {
  return (
    <select
      className="form-control"
      name={props.name}
      value={props.value}
      onChange={props.handleChange}
      style={props.style}
    >
      <option value="">{props.placeholder}</option>
      {props.options.map((option) => {
        return (
          <option key={option[props.index_key]} value={option[props.index_key]}>
            {get_labels(option, props.label_key)}
          </option>
        );
      })}
    </select>
  );
};

export const get_labels = (option_obj, keys) => {
  let labels = "";
  if (Array.isArray(keys)) {
    keys.forEach((key) => {
      labels += option_obj[key] + " ";
    });
  } else {
    labels = option_obj[keys];
  }
  return labels;
};
/* CheckBox.jsx */

export const CheckBox = (props) => {
  return (
    <Input
      autoComplete="off"
      className="form-check-input"
      id={props.id}
      name={props.name}
      value={props.value}
      checked={props.selectedOptions.indexOf(props.value) > -1}
      onChange={props.handleChange}
      type="checkbox"
    />
  );
};

export const CheckBoxGroup = (props) => {
  return (
    <div>
      {/* {props.title?<p className="control-label mt-3 col-form-label-sm">{props.title}</p>:''} */}
      <div className="checkbox-group">
        {props.options.map((option) => {
          return (
            <div
              className="form-check form-check-inline"
              key={option[props.index_key]}
            >
              <CheckBox {...props} value={option[props.index_key]} />
              <label className="form-check-label">
                {" "}
                {option[props.label_key]}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const CheckBoxGroup2 = (props) => {
  return (
    <div>
      {props.title ? (
        <p className="control-label col-form-label-sm">{props.title}</p>
      ) : (
        ""
      )}
      <div className="checkbox-group">
        {props.options.map((option, idx) => {
          const handleChange = props.handleChange(idx);
          return (
            <div
              className="form-check form-check-inline"
              key={option[props.index_key]}
            >
              <CheckBox
                {...props}
                name={props.name + idx}
                handleChange={handleChange}
                value={option[props.index_key]}
              />
              <label className="form-check-label">
                {" "}
                {option[props.label_key]}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const RadioGroup2 = (props) => {
  return (
    <div>
      {props.title ? (
        <p className="control-label col-form-label-sm">{props.title}</p>
      ) : (
        ""
      )}
      <div className="checkbox-group">
        {props.options.map((option, idx) => {
          const handleChange = props.handleChange(idx);
          return (
            <div
              className="form-check form-check-inline"
              key={option[props.index_key]}
            >
              <InputChecked
                {...props}
                name={props.name + idx}
                handleChange={handleChange}
                value={option[props.index_key]}
                type="radio"
                checked={
                  props.selectedOptions.indexOf(option[props.index_key]) > -1
                }
              />
              <label className="form-check-label">
                {option[props.label_key]}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const RadioGroup = (props) => {
  return (
    <div>
      <div className="checkbox-group">
        {props.options.map((option) => {
          return (
            <div
              className="form-check form-check-inline"
              key={option[props.index_key]}
            >
              <InputChecked
                {...props}
                value={option[props.index_key]}
                type="radio"
                checked={
                  props.selectedOptions.indexOf(option[props.index_key]) > -1
                }
              />
              <label className="form-check-label">
                {option[props.label_key]}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

/*TextArea.jsx */
export const TextArea = (props) => {
  return (
    <textarea
      className="form-control no-resize"
      disabled={props.disabled}
      id={props.name}
      name={props.name}
      value={props.value}
      cols={props.cols}
      rows={props.rows}
      onChange={props.handleChange}
      placeholder={props.placeholder}
    />
  );
};

export const Actions = (props) => {
  const profile = decData(sessionStorage.getItem("profile"));
  if (!profile) {
    return <></>;
  }
  const user_permissions = profile.roles[0].permissions;
  const { editHandler, data_item, link_url, modal_id, deleteHandler } = props;
  // const toastrConfirmOptions = {
  //   onOk: () => deleteHandler(data_item.id),
  //   onCancel: () => console.info("Process Cancelled"),
  //   okText: "Delete",
  //   cancelText: "Cancel",
  // };

  switch (props.current_table) {
    case "Merchants":
      return (
        <>
          {checkInArray(user_permissions, "view-merchant","name") && link_url && (
            <Link
              title="View Details"
              to={{ pathname: link_url + data_item.id }}
              key={data_item.id}
            >
              <Badge color="info" className="mx-1">
                <img
                  src="/images/view.svg"
                  height="15px"
                  width="15px"
                  alt="view"
                  className="ms-1"
                />
              </Badge>
            </Link>
          )}
          {checkInArray(user_permissions, "edit-merchant","name") &&
            editHandler && (
              <a
                title="Edit"
                href={"#" + modal_id}
                data-toggle="modal"
                onClick={(e) => editHandler(data_item)}
              >
                <Badge color="warning" className="mx-1">
                  <img
                    src="/images/edit.svg"
                    height="15px"
                    width="15px"
                    className="ms-1"
                    alt="edit"
                  />
                </Badge>
              </a>
            )}
          {checkInArray(user_permissions, "trash-merchant","name") &&
            deleteHandler && (
              <a
                href={"#!"}
                title="Delete"
                onClick={(e) => deleteHandler(data_item)}
              >
                <Badge color="danger" className="mx-1">
                  <img
                    src="/images/deleted.svg"
                    height="15px"
                    width="15px"
                    alt="delete"
                    className="ms-1"
                  />
                </Badge>
              </a>
            )}
        </>
      );
    case "Subscriptions":
      return (
        <>
          {checkInArray(user_permissions, "view-subscription_plan","name") &&
            link_url && (
              <Link
                title="View Details"
                to={{ pathname: link_url + data_item.id }}
                key={data_item.id}
              >
                <Badge color="info" className="mx-1">
                  <img
                    src="/images/view.svg"
                    height="15px"
                    width="15px"
                    alt="view"
                    className="ms-1"
                  />
                </Badge>
              </Link>
            )}
          {checkInArray(user_permissions, "edit-subscription_plan","name") &&
            editHandler && (
              <a
                title="Edit"
                href={"#" + modal_id}
                data-toggle="modal"
                onClick={(e) => editHandler(data_item)}
              >
                <Badge color="warning" className="mx-1">
                  <img
                    src="/images/edit.svg"
                    height="15px"
                    width="15px"
                    className="ms-1"
                    alt="edit"
                  />
                </Badge>
              </a>
            )}
          {checkInArray(user_permissions, "trash-subscription_plan","name") &&
            deleteHandler && (
              <a
                href={"#!"}
                title="Delete"
                onClick={(e) => deleteHandler(data_item)}
              >
                <Badge color="danger" className="mx-1">
                  <img
                    src="/images/deleted.svg"
                    height="15px"
                    width="15px"
                    alt="delete"
                    className="ms-1"
                  />
                </Badge>
              </a>
            )}
        </>
      );
    case "Products":
      return (
        <>
          {checkInArray(user_permissions, "view-products","name") && link_url && (
            <Link
              title="View Details"
              to={{ pathname: link_url + data_item.id }}
              key={data_item.id}
            >
              <Badge color="info" className="mx-1">
                <img
                  src="/images/view.svg"
                  height="15px"
                  width="15px"
                  alt="view"
                  className="ms-1"
                />
              </Badge>
            </Link>
          )}
          {checkInArray(user_permissions, "edit-product","name") &&
            editHandler && (
              <a
                title="Edit"
                href={"#" + modal_id}
                data-toggle="modal"
                onClick={(e) => editHandler(data_item)}
              >
                <Badge color="warning" className="mx-1">
                  <img
                    src="/images/edit.svg"
                    height="15px"
                    width="15px"
                    className="ms-1"
                    alt="edit"
                  />
                </Badge>
              </a>
            )}
          {checkInArray(user_permissions, "trash-product","name") &&
            deleteHandler && (
              <a
                href={"#!"}
                title="Delete"
                onClick={(e) => deleteHandler(data_item)}
              >
                <Badge color="danger" className="mx-1">
                  <img
                    src="/images/deleted.svg"
                    height="15px"
                    width="15px"
                    alt="delete"
                    className="ms-1"
                  />
                </Badge>
              </a>
            )}
        </>
      );
    case "Services":
      return (
        <>
          {checkInArray(user_permissions, "view-services","name") && link_url && (
            <Link
              title="View Details"
              to={{ pathname: link_url + data_item.id }}
              key={data_item.id}
            >
              <Badge color="info" className="mx-1">
                <img
                  src="/images/view.svg"
                  height="15px"
                  width="15px"
                  alt="view"
                  className="ms-1"
                />
              </Badge>
            </Link>
          )}
          {checkInArray(user_permissions, "edit-service","name") &&
            editHandler && (
              <a
                title="Edit"
                href={"#" + modal_id}
                data-toggle="modal"
                onClick={(e) => editHandler(data_item)}
              >
                <Badge color="warning" className="mx-1">
                  <img
                    src="/images/edit.svg"
                    height="15px"
                    width="15px"
                    className="ms-1"
                    alt="edit"
                  />
                </Badge>
              </a>
            )}
          {checkInArray(user_permissions, "trash-service","name") &&
            deleteHandler && (
              <a
                href={"#!"}
                title="Delete"
                // onClick={() =>
                //   toastr.confirm(
                //     "Are you sure about this action?",
                //     toastrConfirmOptions
                //   )
                // }
              >
                <Badge color="danger" className="mx-1">
                  <img
                    src="/images/deleted.svg"
                    height="15px"
                    width="15px"
                    alt="delete"
                    className="ms-1"
                  />
                </Badge>
              </a>
            )}
        </>
      );
    case "ServiceProvider":
      return (
        <>
          {checkInArray(user_permissions, "view-service_providers","name") &&
            link_url && (
              <Link
                title="View Details"
                to={{ pathname: link_url + data_item.id }}
                key={data_item.id}
              >
                <Badge color="info">
                  <img
                    src="/images/view.svg"
                    height="15px"
                    width="15px"
                    alt="view"
                    className="ml-1"
                  />
                </Badge>
              </Link>
            )}
          {checkInArray(user_permissions, "edit-serviceP_provider","name") &&
            editHandler && (
              <a
                title="Edit"
                href={"#" + modal_id}
                data-toggle="modal"
                onClick={(e) => editHandler(data_item)}
              >
                <Badge color="warning" className="mx-1">
                  <img
                    src="/images/edit.svg"
                    height="15px"
                    width="15px"
                    className="ml-1"
                    alt="edit"
                  />
                </Badge>
              </a>
            )}
          {checkInArray(user_permissions, "trash-service_provider","name") &&
            deleteHandler && (
              <a
                href={"#!"}
                title="Delete"
                // onClick={() =>
                //   toastr.confirm(
                //     "Are you sure about this action?",
                //     toastrConfirmOptions
                //   )
                // }
              >
                <Badge color="danger" className="mx-1">
                  <img
                    src="/images/deleted.svg"
                    height="15px"
                    width="15px"
                    alt="delete"
                    className="ms-1"
                  />
                </Badge>
              </a>
            )}
        </>
      );
    case "Countries":
      return (
        <>
          {checkInArray(user_permissions, "view-countries","name") && link_url && (
            <Link
              title="View Details"
              to={{ pathname: link_url + data_item.id }}
              key={data_item.id}
            >
              <Badge color="info" className="mx-1">
                <img
                  src="/images/view.svg"
                  height="15px"
                  width="15px"
                  alt="view"
                  className="ms-1"
                />
              </Badge>
            </Link>
          )}
          {checkInArray(user_permissions, "edit-country","name") &&
            editHandler && (
              <a
                title="Edit"
                href={"#" + modal_id}
                data-toggle="modal"
                onClick={(e) => editHandler(data_item)}
              >
                <Badge color="warning" className="mx-1">
                  <img
                    src="/images/edit.svg"
                    height="15px"
                    width="15px"
                    className="ms-1"
                    alt="edit"
                  />
                </Badge>
              </a>
            )}
          {checkInArray(user_permissions, "trash-country","name") &&
            deleteHandler && (
              <a
                href={"#!"}
                title="Delete"
                onClick={(e) => deleteHandler(data_item)}
              >
                <Badge color="danger" className="mx-1">
                  <img
                    src="/images/deleted.svg"
                    height="15px"
                    width="15px"
                    alt="delete"
                    className="ms-1"
                  />
                </Badge>
              </a>
            )}
        </>
      );
    case "Currencies":
      return (
        <>
          {checkInArray(user_permissions, "view-currencies","name") &&
            link_url && (
              <Link
                title="View Details"
                to={{ pathname: link_url + data_item.id }}
                key={data_item.id}
              >
                <Badge color="info" className="mx-1">
                  <img
                    src="/images/view.svg"
                    height="15px"
                    width="15px"
                    alt="view"
                    className="ms-1"
                  />
                </Badge>
              </Link>
            )}
          {checkInArray(user_permissions, "edit-currency","name") &&
            editHandler && (
              <a
                title="Edit"
                href={"#" + modal_id}
                data-toggle="modal"
                onClick={(e) => editHandler(data_item)}
              >
                <Badge color="warning" className="mx-1">
                  <img
                    src="/images/edit.svg"
                    height="15px"
                    width="15px"
                    className="ms-1"
                    alt="edit"
                  />
                </Badge>
              </a>
            )}
          {checkInArray(user_permissions, "trash-currency","name") &&
            deleteHandler && (
              <a
                href={"#!"}
                title="Delete"
                onClick={(e) => deleteHandler(data_item)}
              >
                <Badge color="danger" className="mx-1">
                  <img
                    src="/images/deleted.svg"
                    height="15px"
                    width="15px"
                    alt="delete"
                    className="ms-1"
                  />
                </Badge>
              </a>
            )}
        </>
      );
    case "ExchangeRates":
      return (
        <>
          {checkInArray(user_permissions, "view-exchange_rates","name") &&
            link_url && (
              <Link
                title="View Details"
                to={{ pathname: link_url + data_item.id }}
                key={data_item.id}
              >
                <Badge color="info" className="mx-1">
                  <img
                    src="/images/view.svg"
                    height="15px"
                    width="15px"
                    alt="view"
                    className="ms-1"
                  />
                </Badge>
              </Link>
            )}
          {checkInArray(user_permissions, "edit-exchange_rate","name") &&
            editHandler && (
              <a
                title="Edit"
                href={"#" + modal_id}
                data-toggle="modal"
                onClick={(e) => editHandler(data_item)}
              >
                <Badge color="warning" className="mx-1">
                  <img
                    src="/images/edit.svg"
                    height="15px"
                    width="15px"
                    className="ms-1"
                    alt="edit"
                  />
                </Badge>
              </a>
            )}
          {checkInArray(user_permissions, "trash-exchange_rate","name") &&
            deleteHandler && (
              <a
                href={"#!"}
                title="Delete"
                onClick={(e) => deleteHandler(data_item)}
              >
                <Badge color="danger" className="mx-1">
                  <img
                    src="/images/deleted.svg"
                    height="15px"
                    width="15px"
                    alt="delete"
                    className="ms-1"
                  />
                </Badge>
              </a>
            )}
        </>
      );
    default:
      return (
        <>
          {link_url && (
            <Link
              title="View Details"
              to={{ pathname: link_url + data_item.id }}
              key={data_item.id}
            >
              <Badge color="info" className="mx-1">
                <img
                  src="/images/view.svg"
                  height="15px"
                  width="15px"
                  alt="view"
                  className="ms-1"
                />
              </Badge>
            </Link>
          )}
          {editHandler && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              title="Edit"
              data-toggle="modal"
              onClick={(e) => editHandler(data_item)}
            >
              <Badge color="warning" className="mx-1">
                <img
                  src="/images/edit.svg"
                  height="15px"
                  width="15px"
                  className="ms-1"
                  alt="edit"
                />
              </Badge>
            </a>
          )}
          {deleteHandler && (
            <a
              href={"#!"}
              title="Delete"
              onClick={(e) => deleteHandler(data_item)}
            >
              <Badge color="danger" className="mx-1">
                <img
                  src="/images/deleted.svg"
                  height="15px"
                  width="15px"
                  alt="delete"
                  className="ms-1"
                />
              </Badge>
            </a>
          )}
        </>
      );
  }
};

export const closeModal = (modal_id) => {
  var modalInstance = document.getElementById(modal_id);
  modalInstance.classList.remove("show");
  modalInstance.setAttribute("aria-hidden", "true");
  modalInstance.setAttribute("style", "display:none");
};

export const validateInput = (formstate) => {
  let keys = Object.keys(formstate);
  let emptyFields = keys.filter((key) => {
    return (
      !formstate[key] &&
      key !== "id" &&
      key !== "roll_over" &&
      key !== "auto_renew"
    );
  });
  if (emptyFields.length) {
    // toastr.warning("OOps!", "You have some empty feilds");
  }
  return emptyFields;
};

export const invalidInput = {
  borderColor: "red",
};

export const validateLimits = (min_limit, max_limit) => {
  if (parseInt(max_limit) > parseInt(min_limit)) {
    return true;
  }
  return false;
};

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

// ency
export const encData = (data) => {
  if (data) {
    const d = btoa(JSON.stringify(data));
    var encyData =
      makeid(100) + CryptoJS.AES.encrypt(d, "fa05daa0385e0995b7c1b94e942c1cb0");
    return encyData;
  }
};

export const decData = (data) => {
  if (data) {
    const decryptData = CryptoJS.AES.decrypt(
      data.slice(100),
      "fa05daa0385e0995b7c1b94e942c1cb0"
    );
    const dataDec = atob(decryptData.toString(CryptoJS.enc.Utf8));
    return JSON.parse(dataDec);
  }
};

export const getCookieItem = (key) => {
  var cookieArr = document.cookie.split(";");
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");

    /* Removing whitespace at the beginning of the cookie name
    and compare it with the given string */
    if (key === cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }

  // Return null if not found
  return null;
};

export function deleteCookies() {
  var allCookies = document.cookie.split(";");

  for (var i = 0; i < allCookies.length; i++)
    document.cookie = allCookies[i] + "=;expires=" + new Date(0).toUTCString();
  window.location.reload();
}

export const clearCookies = (key) => {
  document.cookie = key + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const ClienIp = (callback) => {
  const url =
    "https://geolocation-db.com/json/7733a990-ebd4-11ea-b9a6-2955706ddbf3";
  fetch(url)
    .then((response) => response.json())
    .then(callback)
    .catch((err) => console.error("cant access ip", err));
};
