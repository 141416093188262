import React from "react";
export const FootNote = () => {
  return (
    <>
      <div className="main-footer">
        <span
          className="footer-text col-sm-12 text-white"
          style={{ paddingLeft: "25px" }}
        >
          Privacy Policy
        </span>
        <span
          className="footer-text col-sm-12 text-white"
          style={{ paddingLeft: "50px" }}
        >
          Terms &amp; Conditions
        </span>
        <span
          className="footer-copy float-end text-white"
          style={{ paddingRight: "50px" }}
        >
          All Rights Reserved GMT {new Date().getFullYear()}
        </span>
      </div>
    </>
  );
};
