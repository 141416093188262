import allconstants from "../../constants";

export const HandleProductInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return { type: "PRODUCT_INPUT_CHANGE", changedField: { [name]: value } };
};

export const clearProductForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_PRODUCT_FORM" };
};
export const IsLoading = (payload) => {
  return { type: "LOADING_PRODUCTS", payload };
};
export const ProductFetchSuccess = (payload) => {
  return { type: "FETCH_PRODUCT_SUCCESS", payload };
};
export const processingProductRequest = (value) => {
  return { type: "PRODUCT_REQUEST_PROCESSING", value };
};
export const productAddSuccess = (payload, update = false) => {
  if (update) {
  } else {
  }
  return { type: "CREATE_PRODUCT_SUCCESS", new_product: payload };
};
export const productReqError = (message) => {
  return { type: "PRODUCT_ERROR" };
};

export const editProduct = (obj) => {
  return { type: "EDIT_PRODUCT", current_product_obj: obj };
};
export const ProductDeleteSuccess = (msg, record_id) => {
  return { type: "DELETE_PRODUCT_SUCCESS", msg, record_id };
};

export const HandleProductSubmit =
  (form_data, toggle, setErrors, setSubmitting) => (dispatch) => {
    dispatch(processingProductRequest(true));

    const url = form_data.id
      ? allconstants.apiurl + "product/" + form_data.id
      : allconstants.apiurl + "product";
    const req_type = form_data.id
      ? allconstants.axios.put
      : allconstants.axios.post;

    req_type(url, form_data, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          toggle();
          setSubmitting(false);
          if (form_data.id) {
            dispatch(productAddSuccess(response.data, true));
            dispatch(processingProductRequest(false));
          } else {
            dispatch(productAddSuccess(response.data));
            dispatch(processingProductRequest(false));
          }
        }
      })
      .catch((error) => {
        dispatch(processingProductRequest(false));
        setSubmitting(false);
        if (error.response) {
          setErrors(error.response.data.errors);
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Bad/No internet connection");
        }
      });
  };

export const HandleProductFetch = () => (dispatch) => {
  const url = allconstants.apiurl + "product";
  allconstants.axios
    .get(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        dispatch(ProductFetchSuccess(response.data));
        dispatch(IsLoading(false));
      }
    })
    .catch((error) => {
      if (error.response) {
        dispatch(IsLoading(false));
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
      }
    });
};

export const HandleProductDelete = (record_id, toggle) => (dispatch) => {
  const url = allconstants.apiurl + "product/" + record_id;
  dispatch(processingProductRequest(true));
  allconstants.axios
    .delete(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 201 || response.status === 204) {
        dispatch(ProductDeleteSuccess(response.message, record_id));
        dispatch(processingProductRequest(false));
        toggle();
      }
    })
    .catch((error) => {
      if (error.response) {
        dispatch(processingProductRequest(false));
        toggle();
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
      }
    });

  return null;
};
