const CompanyFormState = {
  id: "",
  company_name: "",
  company_type: "",
  description: "",
  country_id: "",
  reg_no: "",
  no_of_employees: "",
  address: "",
};
const CompanyInitState = {
  company_list: [],
  single_record: false,
  current_company_obj: CompanyFormState,
  is_processing: false,
  loading_business: false,
};

export const CompanyReducer = (prevState = CompanyInitState, action) => {
  const form_state = prevState.current_company_obj;
  let new_formState;
  switch (action.type) {
    case "COMPANY_ERROR":
      return { ...prevState, is_processing: false };
    case "LOADING_BUSINESS":
      return { ...prevState, loading_business: action.payload };
    case "FETCH_COMPANY_SUCCESS":
      return { ...prevState, ...action.payload };
    case "COMPANY_INPUT_CHANGE":
      new_formState = { ...form_state, ...action.changedField };
      return { ...prevState, current_company_obj: new_formState };
    case "CLEAR_COMPANY_FORM":
      return { ...prevState, current_company_obj: CompanyFormState };
    case "EDIT_COMPANY":
      return {
        ...prevState,
        current_company_obj: { ...action.current_company_obj },
        is_processing: false,
      };
    case "DELETE_COMPANY_SUCCESS":
      let new_list = prevState.company_list.filter(
        (current_rec) => current_rec.id !== action.record_id
      );
      return { ...prevState, company_list: new_list };
    case "ADD_COMPANY_SUCCESS":
      let company_new = true;
      let company_list = prevState.company_list.map((company) => {
        if (company.id === action.new_record.id) {
          company_new = false;
          return action.new_record;
        } else {
          return company;
        }
      });
      company_new && company_list.push(action.new_record);
      const new_state = { ...prevState, company_list };
      if (company_new) {
        new_state.current_company_obj = CompanyFormState;
      }
      return new_state;
    case "COMPANY_REQUEST_PROCESSING":
      return { ...prevState, is_processing: action.value };
    default:
      return prevState;
  }
};
