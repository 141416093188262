import React from "react";
import { MerchantBio } from "./merchant_bio";
import MerchantTicketComp from "./support_tickets/component";
import Companies from "./companies";
import { DetailHeader } from "./header";
import { NavLink, Route, Switch } from "react-router-dom";
import { CardBody, CardHeader, Nav, NavItem } from "reactstrap";
import { Card, Col } from "reactstrap";

export const MerchantDetails = (props) => {
  const { match} = props;

  const parent_url = "/merchant/detail/" + match.params.id;
  const route_url = "/merchant/detail/:merchant_id";

  return (
    <Col className="pl-0">
      <DetailHeader merchant_id={match.params.id} />
      <Card className="ml-2 my-2">
        <CardHeader>
          <Nav pills>
            <NavItem>
              <NavLink activeClassName="ticket-tab-active" className="nav-item nav-link" to={`${parent_url}`}>
                <span className="btn mb-1" >Profile</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink activeClassName="ticket-tab-active" className="nav-item nav-link" to={`${parent_url}/tickets`}>
                <span className="btn mb-1">Tickets</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink activeClassName="ticket-tab-active" className="nav-item nav-link" to={`${parent_url}/company`}>
                <span className="btn mb-1">Companies</span>
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody>
          <Switch>
            <Route path={`${route_url}`} exact component={MerchantBio} />
            <Route path={`${route_url}/tickets`} component={MerchantTicketComp} />
            <Route path={`${route_url}/company`} component={Companies}/>
            <Route component={MerchantBio} />
          </Switch>
        </CardBody>
      </Card>
    </Col>
  );
};
export default MerchantDetails;
