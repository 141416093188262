import React, { useRef, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Button, FormGroup, Col, Row, Container } from "reactstrap";
import { HandleTicketTypeSubmit } from "../../../../../actions/settings/types/ticket_type_actions";
import { TextField, CustomModal } from "../../../../../constants";
import { Formik, Form } from "formik";
import * as Yup from "yup";

const AddTicketType = (props) => {
  const dispatch = useDispatch();
  const { is_processing, form_state } = useSelector(
    (state) => state.tickettypereducer,
    shallowEqual
  );

  const formikRef = useRef();

  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

  const validate = Yup.object({
    ticket_type_name: Yup.string().required("Ticket type name  is required"),
    description: Yup.string().required("Description is required"),
  });
  const { toggle, modal } = props;
  return (
    <CustomModal
      modal={modal}
      toggle={toggle}
      tittle={form_state?.id ? "Edit Ticket Type" : "Add Ticket Type"}
      is_processing={is_processing}
    >
      <Formik
        enableReinitialize
        initialValues={{
          description: form_state.description,
          ticket_type_name: form_state.ticket_type_name,
        }}
        validationSchema={validate}
        validateOnChange={validateAfterSubmit}
        validateOnBlur={true}
        ref={formikRef}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          setValidateAfterSubmit(true);
          dispatch(
            HandleTicketTypeSubmit(form_state, toggle, setErrors, setSubmitting)
          );
        }}
      >
        {(formik) => (
          <Container fluid>
            <Form>
              <FormGroup>
                <Row className="mt-3 ml-1">
                  <Col lg={12} md={12} sm={12} className="my-2 ">
                    <TextField
                      name="ticket_type_name"
                      type="text"
                      value={formik.values.ticket_type_name}
                      placeholder="Ticket type"
                    />
                  </Col>
                  <Col lg={12} md={12} sm={12} className="my-2">
                    <TextField
                      name="description"
                      type="text"
                      value={formik.values.description}
                      placeholder="Description"
                    />
                  </Col>
                  <Col lg={12} md={12} sm={12} className="mt-3">
                    <Button
                      type="submit"
                      className="btn btn-pay btn-block text-white"
                      block
                      disabled={is_processing}
                    >
                      {form_state?.id
                        ? is_processing
                          ? "... Updating"
                          : "Update"
                        : is_processing
                        ? "... Saving"
                        : "Save"}
                    </Button>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </Container>
        )}
      </Formik>
    </CustomModal>
  );
};
export default AddTicketType;
