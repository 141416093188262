import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Button } from "reactstrap";
import { Actions } from "../../../../constants/constants";
import TblComp from "../../../../react_table";
import {
  editSubscriptionPlan,
  HandleSubscriptionPlanDelete,
  HandleSubscriptionPlanFetch,
} from "../../../../actions/settings/subscription_plans/subscription_plan_actions";
import { decData } from "../../../../constants/constants";
import { clearSubscriptionPlanForm } from "../../../../actions/settings/subscription_plans/subscription_plan_actions";
import { checkInArray, currFormat } from "../../../../constants/GenHelpers";
import ExportCSV from "../../../../constants/Exports/xlsExport";
import DeleteModal from "../../../../constants/deleteModal";

export const SubscriptionPlanTable = (props) => {
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState(false);
  const toggle = () => setModal(!modal);
  const dispatch = useDispatch();
  const { subscription_plan_list, loading_sub_plans, is_processing } =
    useSelector((state) => state.subscriptionplanreducer, shallowEqual);

  const user_permissions = decData(sessionStorage.getItem("profile")).roles[0]
    .permissions;
  useEffect(() => {
    subscription_plan_list.length === 0 &&
      dispatch(HandleSubscriptionPlanFetch());
  }, [dispatch, subscription_plan_list.length]);

  const headers = ["Id", "Title", "Description", "Amount", "Currency"];

  const subscriptions = subscription_plan_list.map(
    ({ id, subscription_plan_name, description, amount, currency }) => ({
      id,
      subscription_plan_name,
      description,
      amount,
      currency_name: currency.currency_name,
    })
  );

  return (
    <>
      <DeleteModal
        modal={modal}
        toggle={toggle}
        name="subscription plan"
        is_processing={is_processing}
        handleDelete={() =>
          dispatch(HandleSubscriptionPlanDelete(selected, toggle))
        }
      />

      {checkInArray(user_permissions, "create-subscription_plan","name") && (
        <Button
          className="ticket-btn my-3 pulse shadow-none me-2"
          onClick={
            ((e) => dispatch(clearSubscriptionPlanForm(e)), props.toggle)
          }
        >
          Add Subscription Plan{" "}
        </Button>
      )}

      <ExportCSV
        csvData={subscriptions}
        fileName="subscriptionplans"
        headers={headers}
      />

      <TblComp
        columns={[
          {
            Header: "Title",
            accessor: "subscription_plan_name",
            filter: "fuzzyText",
          },
          {
            Header: "Description",
            accessor: "description",
            filter: "fuzzyText",
          },
          {
            Header: "Amount",
            accessor: "amount",
            Cell: ({ value }) => (value ? currFormat(value * 1) : 0),
            filter: "fuzzyText",
          },
          {
            Header: "Currency",
            accessor: (info) => {
              return <> {info.currency.currency_name} </>;
            },
            filter: "fuzzyText",
          },
          {
            id: "actions", // Required because our accessor is not a string
            Header: "Action",
            maxWidth: 100,
            accessor: (full) => (
              <Actions
                current_table="Subscriptions"
                editHandler={(selected_row) =>
                  dispatch(editSubscriptionPlan(selected_row))
                }
                data_item={full}
                link_url={"/settings/subscription_plans/charge_rates/"}
                modal_id="subscription-plan-modal"
                deleteHandler={(selected_id) => [
                  toggle(),
                  setSelected(selected_id.id),
                ]}
              />
            ),
          },
        ]}
        data={subscription_plan_list}
        loading={loading_sub_plans}
      />
    </>
  );
};
