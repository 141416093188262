import allconstants from "../constants";

export const HandlePermissionsInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return { type: "PERMISSION_INPUT_CHANGE", changedField: { [name]: value } };
};

export const clearPermissionsForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_PERMISSION_FORM" };
};

export const PermissionsFetchSuccess = (payload) => {
  return { type: "FETCH_PERMISSION_SUCCESS", payload };
};
export const PermissionsFetchByRoleSuccess = (payload) => {
  return { type: "FETCH_PERMISSION_BY_ROLE_SUCCESS", payload };
};
export const processingPermissionsRequest = (value) => {
  return { type: "PERMISSION_REQUEST_PROCESSING", value };
};
export const permissionAddSuccess = (payload, update = false) => {
  if (update) {
  } else {
  }
  return { type: "CREATE_PERMISSION_SUCCESS", new_permission: payload };
};
export const permissionReqError = (message) => {
  return { type: "PERMISSION_ERROR" };
};

export const editPermissions = (form_state) => {
  return { type: "EDIT_PERMISSION", form_state };
};
export const PermissionsDeleteSuccess = (msg, record_id) => {
  return { type: "DELETE_PERMISSION_SUCCESS", record_id };
};

export const HandlePermissionsSubmit =
  (form_data, toggle, setErrors, setSubmitting) => (dispatch) => {
    dispatch(processingPermissionsRequest(true));

    const url = form_data.id
      ? allconstants.apiurl + "permission/" + form_data.id
      : allconstants.apiurl + "permission";
    const req_type = form_data.id
      ? allconstants.axios.put
      : allconstants.axios.post;

    req_type(url, form_data, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setSubmitting(false);
          if (form_data.id) {
            dispatch(permissionAddSuccess(response.data, true));
            dispatch(processingPermissionsRequest(false));
          } else {
            dispatch(permissionAddSuccess(response.data));
            dispatch(processingPermissionsRequest(false));
          }
          toggle();
        }
      })
      .catch((error) => {
        dispatch(processingPermissionsRequest(false));
        if (error.response) {
          setErrors(error.response.data.errors);
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Bad/No internet connection");
        }
      });
  };

export const HandlePermissionsFetch = () => (dispatch) => {
  const url = allconstants.apiurl + "permission";
  allconstants.axios
    .get(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        dispatch(PermissionsFetchSuccess(response.data));
      }
    })
    .catch((err) => {
      console.error(err);
      dispatch(permissionReqError("Network/Server Error"));
    });
};

export const HandlePermissionsDelete = (record_id) => (dispatch) => {
  const url = allconstants.apiurl + "permission/" + record_id;
  allconstants.axios
    .delete(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(PermissionsDeleteSuccess(response.message, record_id));
      }
    })
    .catch((err) => {
      console.error(err);
      dispatch(permissionReqError("Network/Server Error"));
    });
};
