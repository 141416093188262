import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import {
  InputUnit,
  invalidInput,
  validateInput,
} from "../../../../../constants/constants";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  Label,
  Input,
} from "reactstrap";
import {
  handleChargeRateInput,
  handleChargeRateSubmit,
} from "../../../../../actions/settings/subscription_plans/charge_rate_actions";
import { HandleSubscriptionPlanFetch } from "../../../../../actions/settings/subscription_plans/subscription_plan_actions";
import {
  clearServiceProviderCountryForm,
  HandleServiceProviderCountryFetch,
} from "../../../../../actions/settings/service_provider/service_provider_country_actions";
import { HandleProvidersFetch } from "../../../../../actions/settings/service_provider/service_provider_actions";
import {
  clearspc_ProductForm,
  HandleSPC_ProductFetch,
} from "../../../../../actions/settings/service_provider/products_actions";
import Loader from "../../../../../constants/loader";

export const AddChargeRate = (props) => {
  const dispatch = useDispatch();
  const { is_processing, current_charge_rate_obj } = useSelector(
    (state) => state.chargerate,
    shallowEqual
  );
  const service_providers = useSelector(
    (state) => state.serviceprovider.service_provider_list
  );
  const service_provider_countries = useSelector(
    (state) => state.serviceprovidercountry.service_provider_country_list
  );
  const spc_product_list = useSelector(
    (state) => state.spcProductsState.spc_product_list
  );
  const { modal, toggle } = props;

  useEffect(() => {
    dispatch(HandleSubscriptionPlanFetch(props.sub_plan_id));
    service_providers.length === 0 && dispatch(HandleProvidersFetch());
  }, [dispatch, props.sub_plan_id, service_providers.length]);

  const [valid, setvalid] = useState(true);

  const HandleSubmit = () => {
    let falseValues = validateInput(current_charge_rate_obj);

    if (falseValues.length) {
      setvalid(false);
    } else {
      dispatch(
        handleChargeRateSubmit(
          current_charge_rate_obj,
          props.sub_plan_id,
          toggle
        )
      );
    }
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <span className="font-weight-bold modal-header-txt">
            Add Charge Rate
          </span>
        </ModalHeader>
        {is_processing ? (
          <div className="loader-overlay">
            <Loader isModal />
          </div>
        ) : (
          <></>
        )}
        <ModalBody>
          <Container>
            <Form
              autoComplete="off"
              method="post"
              onSubmit={(e) => {
                e.preventDefault();
                HandleSubmit();
              }}
            >
              <FormGroup>
                <Row className="mt-3 ml-1">
                  <Col lg={6} md={6} sm={12} className="my-2 form-select">
                    <Label>Service Provider</Label>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        dispatch(clearServiceProviderCountryForm(e));
                        dispatch(
                          HandleServiceProviderCountryFetch(e.target.value)
                        );
                      }}
                    >
                      <option>--Select--</option>
                      {service_providers.map((val, key) => {
                        return (
                          <option key={key} value={val.id}>
                            {val.service_provider_name}
                          </option>
                        );
                      })}
                    </select>
                  </Col>
                  <Col lg={6} sm={12} md={6} className="my-2 form-select">
                    <Label>Country</Label>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        dispatch(clearspc_ProductForm(e));
                        dispatch(HandleSPC_ProductFetch(e.target.value));
                      }}
                    >
                      <option>--Select--</option>
                      {service_provider_countries !== undefined
                        ? service_provider_countries.map((val, key) => {
                            return (
                              <option key={key} value={val.sp_country.id}>
                                {val.country_name}
                              </option>
                            );
                          })
                        : ""}
                    </select>
                  </Col>
                  <Col lg={6} md={6} sm={12} className="my-2">
                    <Label>Charge Rate Name</Label>
                    <InputUnit
                      style={
                        !current_charge_rate_obj.charge_rate_name && !valid
                          ? invalidInput
                          : {}
                      }
                      name="charge_rate_name"
                      placeholder="Charge Rate Name"
                      defaultValue={current_charge_rate_obj.charge_rate_name}
                      handleChange={(e) => dispatch(handleChargeRateInput(e))}
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} className="my-2 form-select">
                    <Label>Product</Label>
                    <select
                      style={
                        !current_charge_rate_obj.spc_product_id && !valid
                          ? invalidInput
                          : {}
                      }
                      className="form-control"
                      name="spc_product_id"
                      onChange={(e) => dispatch(handleChargeRateInput(e))}
                      value={current_charge_rate_obj.spc_product_id}
                    >
                      <option value="">--select--</option>
                      {spc_product_list !== undefined
                        ? spc_product_list.map((val, key) => {
                            return (
                              <option key={key} value={val.id}>
                                {val.product.product_name}
                              </option>
                            );
                          })
                        : ""}
                    </select>
                  </Col>

                  <Col lg={6} md={6} sm={12} className="my-2">
                    <Label>Rate Value</Label>
                    <InputUnit
                      style={
                        !current_charge_rate_obj.rate_value && !valid
                          ? invalidInput
                          : {}
                      }
                      name="rate_value"
                      placeholder="Rate value"
                      defaultValue={current_charge_rate_obj.rate_value}
                      handleChange={(e) => dispatch(handleChargeRateInput(e))}
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} className="my-2 d-flex">
                    <div className="form-check block">
                      <Label className="form-check-label">
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          name="percentage_or"
                          style={
                            !current_charge_rate_obj.percentage_or && !valid
                              ? invalidInput
                              : {}
                          }
                          onChange={(e) => dispatch(handleChargeRateInput(e))}
                          value={1}
                        />{" "}
                        Percentage Amount
                      </Label>
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={12} className="my-2">
                    <Label>Minmum Transaction</Label>
                    <InputUnit
                      style={
                        !current_charge_rate_obj.min_trans && !valid
                          ? invalidInput
                          : {}
                      }
                      name="min_trans"
                      placeholder="Min Transaction"
                      defaultValue={current_charge_rate_obj.min_trans}
                      handleChange={(e) => dispatch(handleChargeRateInput(e))}
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} className="my-2">
                    <Label>Maximum Transaction</Label>
                    <InputUnit
                      name="max_trans"
                      placeholder="Max Transaction"
                      defaultValue={current_charge_rate_obj.max_trans}
                      handleChange={(e) => dispatch(handleChargeRateInput(e))}
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} className="my-2">
                    <Label> Minimum Value</Label>
                    <InputUnit
                      style={
                        !current_charge_rate_obj.min_value && !valid
                          ? invalidInput
                          : {}
                      }
                      name="min_value"
                      placeholder="Min Value"
                      defaultValue={current_charge_rate_obj.min_value}
                      handleChange={(e) => dispatch(handleChargeRateInput(e))}
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} className="my-2">
                    <Label> Maximum Value</Label>
                    <InputUnit
                      style={
                        !current_charge_rate_obj.max_value && !valid
                          ? invalidInput
                          : {}
                      }
                      name="max_value"
                      placeholder="Max Value"
                      defaultValue={current_charge_rate_obj.max_value}
                      handleChange={(e) => dispatch(handleChargeRateInput(e))}
                    />
                  </Col>
                  <Col lg={12} md={12} sm={12} className="mt-3">
                    <Button
                      type="submit"
                      className="btn btn-pay btn-block"
                      disabled={is_processing}
                      block
                    >
                      <span className="pay-btn-text">
                        {is_processing ? "... Saving" : "Save"}{" "}
                      </span>
                    </Button>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};
