const CountriesFormState = {
  country_name: "",
  country_code: "",
};
const CountriesInitState = {
  countries_list: [],
  current_countries_obj: CountriesFormState,
  is_processing: false,
  loading_countries: true,
};

export const CountriesReducer = (prevState = CountriesInitState, action) => {
  const form_state = prevState.current_countries_obj;
  let new_formState;
  switch (action.type) {
    case "COUNTRIES_ERROR":
      return { ...prevState };
    case "FETCH_COUNTRIES_SUCCESS":
      return { ...prevState, countries_list: action.payload.data };
    case "LOADING_COUNTRIES":
      return {
        ...prevState,
        loading_countries: action.payload,
      };
    case "COUNTRIES_INPUT_CHANGE":
      new_formState = { ...form_state, ...action.changedField };
      return { ...prevState, current_countries_obj: new_formState };
    case "CLEAR_COUNTRIES_FORM":
      return { ...prevState, current_countries_obj: CountriesFormState };
    case "EDIT_COUNTRIES":
      new_formState = { ...action.current_countries_obj };
      return { ...prevState, current_countries_obj: new_formState };
    case "DELETE_COUNTRIES_SUCCESS":
      let new_list = prevState.countries_list.filter(
        (current_rec) => current_rec.id !== action.record_id
      );
      return { ...prevState, countries_list: new_list };
    case "ADD_COUNTRIES_SUCCESS":
      let countries_new = true;
      let countries_list = prevState.countries_list.map((countries) => {
        if (countries.id === action.new_record.id) {
          countries_new = false;
          return action.new_record;
        } else {
          return countries;
        }
      });
      countries_new && countries_list.push(action.new_record);
      const new_state = { ...prevState, countries_list };
      if (countries_new) {
        new_state.current_countries_obj = CountriesFormState;
      }
      return new_state;
    case "COUNTRIES_REQUEST_PROCESSING":
      return { ...prevState, is_processing: action.value };
    default:
      return prevState;
  }
};
