const providerFormState = {
  service_provider_name: "",
  description: "",
};

const initialServiceProviderState = {
  service_provider_list: [],
  current_provider_obj: providerFormState,
  single_record: {},
  loading_service_providers: true,
  is_processing: false,
};

export const ServiceProviderReducer = (
  prevState = initialServiceProviderState,
  action
) => {
  const form_state = prevState.current_provider_obj;
  let new_formState;
  switch (action.type) {
    case "PROVIDER_ERROR":
      return { ...prevState };
    case "PROVIDER_REQUEST_PROCESSING":
      return { ...prevState, is_processing: action.value };
    case "FETCH_PROVIDER_SUCCESS":
      if (action.single_record) {
        return { ...prevState, single_record: action.payload };
      } else {
        return { ...prevState, service_provider_list: action.payload.data };
      }
    case "PROVIDER_INPUT_CHANGE":
      new_formState = { ...form_state, ...action.changedField };
      return { ...prevState, current_provider_obj: new_formState };
    case "LOADING_PROVIDERS":
      return {
        ...prevState,
        loading_service_providers: action.payload,
      };
    case "CLEAR_PROVIDER_FORM":
      return { ...prevState, current_provider_obj: providerFormState };
    case "ADD_PROVIDER_SUCCESS":
      let provider_new = true;
      let service_provider_list = prevState.service_provider_list.map(
        (provider) => {
          if (provider.id === action.new_provider.id) {
            provider_new = false;
            return action.new_provider;
          } else {
            return provider;
          }
        }
      );
      provider_new && service_provider_list.push(action.new_provider);
      const new_state = { ...prevState, service_provider_list };
      if (provider_new) {
        new_state.current_provider_obj = providerFormState;
      }
      return new_state;
    case "EDIT_PROVIDER":
      new_formState = { ...action.current_provider_obj };
      return { ...prevState, current_provider_obj: new_formState };
    case "DELETE_PROVIDER_SUCCESS":
      let new_list = prevState.service_provider_list.filter(
        (current_rec) => current_rec.id !== action.record_id
      );
      console.log(new_list);
      return { ...prevState, service_provider_list: new_list };
    default:
      return prevState;
  }
};
