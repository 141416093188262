import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Loader from "./loader";

const CustomModal = ({
  modal,
  toggle,
  tittle,
  is_processing,
  children = "",
}) => {
  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <span className="font-weight-bold modal-header-txt">{tittle}</span>
        </ModalHeader>
        {is_processing ? (
          <div className="loader-overlay">
            <Loader isModal />
          </div>
        ) : (
          <></>
        )}
        <ModalBody>{children}</ModalBody>
      </Modal>
    </>
  );
};

export default CustomModal;
