import React, { useState, useRef } from "react";
import { Button, FormGroup, Row, Col, Container } from "reactstrap";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { TextField, CustomModal } from "../../../../constants";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { HandleproviderSubmit } from "../../../../actions/settings/service_provider/service_provider_actions";

const AddServiceProvider = (props) => {
  const dispatch = useDispatch();
  const { is_processing, current_provider_obj } = useSelector(
    (state) => state.serviceprovider,
    shallowEqual
  );
  const formikRef = useRef();

  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

  const validate = Yup.object({
    service_provider_name: Yup.string().required(
      "Service provider name is required"
    ),
    description: Yup.string().required("Description is required"),
  });

  const { modal, toggle } = props;

  return (
    <CustomModal
      modal={modal}
      toggle={toggle}
      tittle={
        current_provider_obj.id !== undefined
          ? "Edit Service Provider"
          : "Add Service Provider"
      }
      is_processing={is_processing}
    >
      <Formik
        enableReinitialize
        initialValues={{
          service_provider_name: current_provider_obj.service_provider_name,
          description: current_provider_obj.description,
        }}
        validationSchema={validate}
        validateOnChange={validateAfterSubmit}
        validateOnBlur={true}
        ref={formikRef}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          setValidateAfterSubmit(true);
          dispatch(
            HandleproviderSubmit(values, toggle, setErrors, setSubmitting)
          );
        }}
      >
        {(formik) => (
          <Container>
            <Form>
              <FormGroup>
                <Row className="mt-3 ml-1">
                  <Col lg={12} sm={12} md={12} className="my-2">
                    <TextField
                      name="service_provider_name"
                      type="text"
                      value={formik.values.service_provider_name}
                      placeholder="Service Providers Name"
                    />
                  </Col>

                  <Col lg={12} md={12} sm={12} className=" my-2 ">
                    <Field
                      as="textarea"
                      name="description"
                      value={formik.values.description}
                      placeholder="product description"
                      className={`custom-select form-control ${
                        formik.errors.description ? `is-invalid mb-2` : ""
                      }`}
                    ></Field>
                    <ErrorMessage
                      component="div"
                      name="description"
                      className="error"
                    />
                  </Col>
                  <Col lg={12} md={12} sm={12} className="mt-3 ">
                    <Button
                      type="submit"
                      className="btn btn-pay btn-block text-white"
                      block
                    >
                      {current_provider_obj.id !== undefined ? "Update" : "Add"}
                    </Button>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </Container>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddServiceProvider;
