import React from "react";
import { Route, Switch } from "react-router-dom";
import ServiceProviderComp from "./component";
import ServiceProviderCountryComp from "./countries/component";
import SPCProductComp from "./products/component";
export default function ServiceProvider() {
  const parent_url = "/settings/service_providers";
  return (
    <Switch>
      <Route
        path={`${parent_url}/countries/:id`}
        component={ServiceProviderCountryComp}
      />
      <Route
        path={`${parent_url}/product/:id`}
        component={SPCProductComp}
      />
      <Route component={ServiceProviderComp} />
    </Switch>
  );
}
