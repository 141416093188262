const permissionsFormState = {
  name: "",
};

const RoleInitailState = {
  permission_list: [],
  permission_list_by_role:[],
  form_state: permissionsFormState,
  is_processing: false,
};

export const permissionsReducer = (prevState = RoleInitailState, action) => {
  switch (action.type) {
    case "CREATE_PERMISSION_SUCCESS":
      let permission_new = true;
      let permission_list = prevState.permission_list.map((permission) => {
        if (permission.id === action.new_permission.id) {
          permission_new = false;
          return action.new_permission;
        } else {
          return permission;
        }
      });
      permission_new && permission_list.push(action.new_permission);
      const new_state = { ...prevState, permission_list };
      if (permission_new) {
        new_state.form_state = permissionsFormState;
      }
      return new_state;
    case "PERMISSION_INPUT_CHANGE":
      return { ...prevState, form_state: { ...prevState.form_state, ...action.changedField } };
    case "CLEAR_PERMISSION_FORM":
      return {
        ...prevState,
        form_state: permissionsFormState,
      };
    case "FETCH_PERMISSION_SUCCESS":
      return { ...prevState, permission_list: action.payload.data };
    case "FETCH_PERMISSION_BY_ROLE_SUCCESS":
      return { ...prevState, permission_list_by_role: action.payload.data };
    case "PERMISSION_ERROR":
      return { ...prevState };
    case "EDIT_PERMISSION":
      return { ...prevState, form_state: { ...action.form_state } };
    case "DELETE_PERMISSION_SUCCESS":
      let new_list = prevState.permission_list.filter(
        (current_rec) => current_rec.id !== action.record_id
      );
      return { ...prevState, permission_list: new_list };
    case "PERMISSION_REQUEST_PROCESSING":
      return { ...prevState, is_processing: action.value };
    default:
      return { ...prevState };
  }
};
