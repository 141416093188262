import React from "react";
import { shallowEqual, useSelector } from 'react-redux';
import { AddMerchant } from "../add_merchant";
import { Button, Card, CardBody, CardHeader, CardFooter, Col, Row } from "reactstrap";

const selectorFn = (state) => state.merchantState.current_merchant_obj;
export const MerchantBio = (props) => {
  const [modalState, setModalState] = React.useState(false);
  const merchant = useSelector(selectorFn, shallowEqual);

  const toggleState = () => setModalState(!modalState);
  return (
    <>
      <AddMerchant modalState={modalState} toggleState={toggleState} />

      <Card className="bg-white ml-2">
        <CardHeader>
          <span className="account-title">Merchant Bio</span>
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm={12} lg={6} md={6}>
              <span className="title-header d-block">Merchant Name</span>
              <span className="title-text">
                {typeof merchant !== "undefined"
                  ? merchant.full_name
                  : "Loading..."}{" "}
              </span>
            </Col>
            <Col sm={12} lg={6} md={6}>
              <span className="title-header d-block">Gender</span>
              <span className="title-text">
                {typeof merchant !== "undefined"
                  ? merchant.gender_text
                  : "Loading..."}
              </span>
            </Col>
          </Row>

          <Row className="mt-1">
            <Col sm="12" lg="6" md="6">
              <span className="title-header d-block">Email</span>
              <span className="title-text">
                {typeof merchant !== "undefined"
                  ? merchant.email
                  : "Loading..."}
              </span>
            </Col>
            <Col sm="12" lg="6" md="6">
              <span className="title-header d-block">Phone Contact</span>
              <span className="title-text">
                {typeof merchant !== "undefined"
                  ? merchant.phone_number
                  : "Loading..."}
              </span>
            </Col>
          </Row>
          <Row className=" mt-1">
            <Col sm="12" lg="6" md="6">
              <span className="title-header d-block">Address</span>
              <span className="title-text">
                {" "}
                {typeof merchant !== "undefined"
                  ? merchant.address
                  : "Loading..."}
              </span>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>

        <Button
          className="text-white btn payment-btn my-3 mx-2 shadow-none"
          onClick={(e)=>{toggleState(e);}}
        >
          {" "}
          Edit
        </Button>
        </CardFooter>
      </Card>
    </>
  );
};
