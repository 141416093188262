const initialState = {
  airtel_balance: 0,
  mtn_disbursement: 0,
  mtn_collection: 0,
  is_processing: false,
};

export default function masterAccountReducer(prevState = initialState, action) {
  switch (action.type) {
    case "FETCH_MASTER_ACCOUNT_SUCCESS":
      return { ...prevState, ...action.account };
    case "MASTER_ACCOUNT_ERROR":
      return { ...prevState };
    case "MASTER_ACCOUNT_REQUEST_PROCESSING":
      return { ...prevState, is_processing: action.is_processing };
    default:
      return prevState;
  }
}
