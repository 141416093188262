import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import TblComp from "../../../../react_table";
import { currFormat } from "../../../../constants/GenHelpers";
import moment from "moment";
import { Button, ButtonGroup } from "reactstrap";
import TransactionDetail from "../transactionDetail";
import DeleteModal from "../../../../constants/deleteModal";
import {
  HandleWithdrawDelete,
  HandleWithdrawApproval,
} from "../../../../actions/liquidation_request_actions";

export const WithdrawsTable = (props) => {
  const dispatch = useDispatch();
  const { loading_withdraw, withdraw_request_list, total, pageCount } =
    useSelector((state) => state.withdrawState, shallowEqual);
  const [transaction, setSingle] = useState();
  const [selected, setSelected] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [deletemodal, setDeleteModal] = useState(false);
  const deletetoggle = () => setDeleteModal(!deletemodal);

  return (
    <>
      <TransactionDetail
        transaction={transaction}
        isOpen={modal}
        handleApprove={() =>
          dispatch(HandleWithdrawApproval(selected, setModal))
        }
        toggle={toggle}
        isWithdraw={true}
      />
      <TblComp
        columns={[
          {
            id: "transaction_date",
            Header: "Date",
            accessor: "transaction_date",
            filter: "fuzzyText",
            Cell: ({ value }) => (value ? moment(value).format("lll") : ""),
          },
          {
            Header: "Ref No.",
            maxWidth: 200,
            accessor: "request_ref",
            filter: "fuzzyText",
          },
          {
            id: "debit",
            Header: "Debit",
            accessor: "debit",
            filter: "fuzzyText",
            Cell: ({ value }) => (value ? currFormat(value * 1) : ""),
          },
          {
            id: "credit",
            Header: "Credit",
            accessor: "credit",
            filter: "fuzzyText",
            Cell: ({ value }) => (value ? currFormat(value * 1) : ""),
          },
          {
            Header: "Charge",
            accessor: "charges",
            filter: "fuzzyText",
            Cell: ({ value }) => (value ? currFormat(value * 1) : ""),
          },
          {
            id: "balance", // Required because our accessor is not a string
            Header: "Balance",
            accessor: "balance",
            Cell: ({ value }) => (value ? currFormat(value * 1) : ""),
          },
          {
            Header: "Narrative",
            accessor: "transaction_note",
            filter: "fuzzyText",
          },
          { Header: "Phone", accessor: "msisdn", filter: "fuzzyText" },
          {
            Header: "Company",
            maxWidth: 200,
            accessor: "company_account",
            filter: "fuzzyText",
            Cell: ({ value }) =>
              value.company ? value.company.company_name : value.account_no,
          },
          { Header: "Status", accessor: "status_text", filter: "fuzzyText" },
          {
            id: "actions", // Required because our accessor is not a string
            Header: "Action",
            maxWidth: 150,
            accessor: (full) => (
              <>
                <ButtonGroup>
                  <Button
                    size="sm"
                    onClick={(e) => {
                      toggle();
                      e.preventDefault();
                      setSingle(full);
                      setSelected(full.id);
                    }}
                    title="View Transaction Details"
                    color="info"
                  >
                    <img
                      src="/images/view.svg"
                      height="15px"
                      width="15px"
                      alt="view"
                    />
                  </Button>
                  <Button
                    size="sm"
                    onClick={(e) => {
                      deletetoggle();
                      e.preventDefault();
                      setSelected(full.id);
                    }}
                    color="danger"
                    className="ms-2"
                  >
                    <img
                      src="/images/deleted.svg"
                      height="15px"
                      width="15px"
                      alt="delete"
                      className="ms-1"
                    />
                  </Button>
                </ButtonGroup>
              </>
            ),
          },
        ]}
        data={withdraw_request_list}
        total={total}
        pageCount={pageCount}
        loading={loading_withdraw}
        serverSide={true}
        fetchData={props.fetchData}
      />
      <DeleteModal
        modal={deletemodal}
        toggle={deletetoggle}
        name="liquidation request"
        handleDelete={() =>
          dispatch(HandleWithdrawDelete(selected, setDeleteModal))
        }
      />
    </>
  );
};
