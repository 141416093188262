import React, { useState } from "react";
import AddServiceProvider from "./add_service_provider";
import { ServiceProviderTable } from "./service_provider_table";

const ServiceProviders = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <>
      <AddServiceProvider modal={modal} toggle={toggle} />
      <ServiceProviderTable toggle={toggle} />
    </>
  );
};

export default ServiceProviders;
