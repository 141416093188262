import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Col, Container, Label, Button, Row, FormGroup } from "reactstrap";
import { CustomModal } from "../../../../../constants";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { HandleProductFetch } from "../../../../../actions/settings/product_actions";
import { HandleSPC_ProductSubmit } from "../../../../../actions/settings/service_provider/products_actions";

const AddProviderCountryProduct = (props) => {
  const dispatch = useDispatch();
  const { is_processing } = useSelector(
    (state) => state.spcProductsState,
    shallowEqual
  );
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  const formikRef = useRef();
  const { product_list } = useSelector(
    (state) => state.productState,
    shallowEqual
  );

  const validate = Yup.object({
    product_id: Yup.string().required("Product is required"),
  });

  const { toggle, modal } = props;

  useEffect(() => {
    product_list.length === 0 && dispatch(HandleProductFetch());
  }, [dispatch, product_list.length]);

  return (
    <>
      <CustomModal
        modal={modal}
        toggle={toggle}
        tittle="Add Product"
        is_processing={is_processing}
      >
        <Formik
          enableReinitialize
          initialValues={{
            product_id: "",
          }}
          validationSchema={validate}
          validateOnChange={validateAfterSubmit}
          validateOnBlur={true}
          ref={formikRef}
          onSubmit={(values, { setErrors, setSubmitting }) => {
            setValidateAfterSubmit(true);
            dispatch(
              HandleSPC_ProductSubmit(
                values,
                props.spc_id,
                toggle,
                setErrors,
                setSubmitting
              )
            );
          }}
        >
          {(formik) => (
            <Container>
              <Form>
                <FormGroup>
                  <Row className="mt-3 ml-1">
                    <Col lg={12} md={12} sm={12} className="my-2">
                      <Label htmlFor="">Products</Label>
                      <Field
                        as="select"
                        name="product_id"
                        className={`custom-select form-control ${
                          formik.errors?.product_id ? `is-invalid mb-2` : ""
                        }`}
                      >
                        <option defaultValue>--select--</option>
                        {product_list.map((product) => (
                          <option value={product.id} key={product.id}>
                            {product.product_name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="product_id"
                        className="error"
                      />
                    </Col>
                    <Col lg={12} sm={12} mb={12} className="mt-3">
                      <Button
                        type="submit"
                        className="btn btn-pay btn-block"
                        block
                      >
                        <span className="pay-btn-text ">Create</span>
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
              </Form>
            </Container>
          )}
        </Formik>
      </CustomModal>
    </>
  );
};

export default AddProviderCountryProduct;
