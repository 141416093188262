export const is_Loading = (value) => {
  return { type: "IS_LOADING", value };
};

export const doAuthSuccess = (payload, cb = null) => {
  return { type: "DO_AUTH_SUCCESS", payload, cb };
};
export const doLogoutSuccess = (message) => {
  return { type: "SIGNOUT_USER" };
};
export const doAuthError = (payload) => {
  return { type: "DO_AUTH_ERROR", message: payload };
};
export const confirm_logout = (logout) => {
  return { type: "LOGOUT_CONFIRMATION", logout };
};
export const profileFetchSuccess = (payload) => {
  return { type: "FETCH_USER_PROFILE", payload };
};
