import React, { useState, useEffect, useRef } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import {
  editPermissions,
  HandlePermissionsDelete,
  HandlePermissionsFetch,
  HandlePermissionsSubmit,
} from "../../../../actions/permission_actions";
import * as Yup from "yup";
import { Actions } from "../../../../constants/constants";
import TblComp from "../../../../react_table";
import { Button, Container, FormGroup, Col, Row } from "reactstrap";
import DeleteModal from "../../../../constants/deleteModal";
import { TextField, CustomModal } from "../../../../constants";
import { Formik, Form } from "formik";

export const AddPermission = (props) => {
  const dispatch = useDispatch();
  const { is_processing, form_state } = useSelector(
    (state) => state.permissionState,
    shallowEqual
  );
  const { modal, toggle } = props;

  const formikRef = useRef();

  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

  const validate = Yup.object({
    name: Yup.string().required("name  is required"),
  });

  return (
    <>
      <CustomModal
        modal={modal}
        toggle={toggle}
        tittle="Create Permission"
        is_processing={is_processing}
      >
        <Formik
          enableReinitialize
          initialValues={{
            name: form_state.name,
          }}
          validationSchema={validate}
          validateOnChange={validateAfterSubmit}
          validateOnBlur={true}
          ref={formikRef}
          onSubmit={(values, { setErrors, setSubmitting }) => {
            setValidateAfterSubmit(true);
            dispatch(
              HandlePermissionsSubmit(values, toggle, setErrors, setSubmitting)
            );
          }}
        >
          {(formik) => (
            <Container>
              <Form>
                <FormGroup>
                  <Row className="mt-3 ml-1">
                    <Col lg={12} md={12} sm={12} className="my-2">
                      <TextField
                        name="name"
                        type="text"
                        value={formik.values.name}
                        placeholder="Permission Name"
                      />
                    </Col>
                    <Col lg={12} md={12} sm={12} className="mt-3 ">
                      <Button
                        type="submit"
                        className="btn btn-pay btn-block text-white"
                        block
                      >
                        <span className="pay-btn-text ">Create</span>
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
              </Form>
            </Container>
          )}
        </Formik>
      </CustomModal>
    </>
  );
};
const PermissionTable = () => {
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState(false);
  const toggle = () => setModal(!modal);
  const dispatch = useDispatch();
  const permission_list = useSelector(
    (state) => state.permissionState.permission_list,
    shallowEqual
  );
  useEffect(() => {
    permission_list.length === 0 && dispatch(HandlePermissionsFetch());
  }, [dispatch, permission_list.length]);
  return (
    <>
      <DeleteModal
        modal={modal}
        toggle={toggle}
        name="permission"
        handleDelete={() => dispatch(HandlePermissionsDelete(selected))}
      />
      <TblComp
        columns={[
          {
            Header: "Permision Name",
            accessor: "name",
            filter: "fuzzyText",
          },
          {
            id: "actions", // Required because our accessor is not a string
            Header: "Action",
            maxWidth: 100,
            accessor: (full) => (
              <Actions
                editHandler={(selected_row) =>
                  dispatch(editPermissions(selected_row))
                }
                data_item={full}
                link_url={""}
                modal_id="product-modal"
                deleteHandler={(selected_id) => [
                  toggle(),
                  setSelected(selected_id.id),
                ]}
              />
            ),
          },
        ]}
        data={permission_list}
      />
    </>
  );
};
const Permissions = () => {
  const [admodal, setAdModal] = useState(false);
  const addtoggle = () => setAdModal(!admodal);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <>
      <div className="pb-1">
        <AddPermission modal={admodal} toggle={addtoggle} />
        <Button
          className="btn payment-btn my-3 ml-2 text-white"
          onClick={(e) => {
            addtoggle();
          }}
        >
          Add Permission
        </Button>
        <PermissionTable toggle={toggle} />
      </div>
    </>
  );
};

export default Permissions;
