import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { AddServiceProviderCountry } from "./add_service_provider_country";
import SPCTable from "./service_provider_country_table";
import { HandleProvidersFetch } from "../../../../../actions/settings/service_provider/service_provider_actions";

export const ServiceProviderCountry = (props) => {
  const dispatch = useDispatch();
  const spState = useSelector((state) => state.serviceprovider, shallowEqual);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  useEffect(() => {
    dispatch(HandleProvidersFetch(props.match.params.id));
  }, [dispatch, props.match.params.id]);
  return (
    <>
      <AddServiceProviderCountry
        modal={modal}
        toggle={toggle}
        sp_id={props.match.params.id}
      />

      <div className="d-flex">
        <h4>
          {" "}
          {typeof spState.single_record == "object" ? (
            <>{spState.single_record.service_provider_name}</>
          ) : (
            <>Loading......</>
          )}
        </h4>
      </div>
      <SPCTable sp_id={props.match.params.id} toggle={toggle} />
    </>
  );
};
export default ServiceProviderCountry;
