import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import allconstants from "../../constants/index";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { forgotPassword } from "../../actions/api/login_api";
import { Form, Col, Input, Button, FormGroup, Row } from "reactstrap";

const selectorFn = (state) => state.authState.resetPassword;
const ForgotPassword = () => {
  const dispatch = useDispatch();
  const mail_success = useSelector(selectorFn, shallowEqual);
  const [email, setemail] = useState("");

  return (
    <>
      {mail_success === true ? (
        <>
          {" "}
          <Redirect to="/mailsent" />{" "}
        </>
      ) : (
        <Row style={{ height: "100vh" }}>
          <Col lg={5} md={5} sm={12} className="left-login-bg">
            <Row className="offset-1">
              <div>
                <div className="sente-pay-welcome">
                  <span className="white-bg-line">&nbsp;</span>Welcome Back
                </div>
              </div>
              <Col lg={12} className="height-40">
                &nbsp;
              </Col>
              <Col lg={6}>
                <img
                  src="/images/logo.svg"
                  alt="SentePay"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col lg={12} className="show-for-large pl-0">
                <span className="sente-pay-text-bold-organge">
                  Administration Panel
                </span>
              </Col>
              <Col lg={12} className="show-for-large">
                <img
                  src={allconstants.login_img}
                  height="300px"
                  alt="Login"
                  className="login_img"
                />
              </Col>
            </Row>
          </Col>
          <Col lg={7} md={7} sm={12} className="pr-0">
            <Row className="row-in-login">
              <Col lg={6}></Col>
              <Col lg={6} className="login-right-top">
                <img
                  alt=""
                  className="float-end"
                  src={allconstants.login_svg_img}
                  height="200px"
                  width="220px"
                />
              </Col>
            </Row>
            <Row>
              <div className="login-wrapper">
                <div className="box login-dialog-box">
                  <div className="content-wrap">
                    <Form
                      autoComplete="off"
                      className="border border-light p-3"
                      onSubmit={(e) => {
                        e.preventDefault();
                        const data = {
                          email: email,
                        };
                        dispatch(forgotPassword(data));
                      }}
                    >
                      <p className="h4 mb-4 text-left login_title">
                        Forgot Password
                      </p>
                      <FormGroup>
                        <label>
                          A code will be sent to your email. You will be
                          required to fill it in here.
                        </label>
                        <Input
                          autoComplete="off"
                          type="text"
                          name="username"
                          placeholder="Enter your email"
                          className="form-control"
                          onChange={(e) => setemail(e.target.value)}
                        />
                      </FormGroup>

                      <Button
                        className="btn btn-info sign-in-btn btn-block my-1  text-white"
                        type="submit"
                        block
                      >
                        Proceed
                      </Button>
                      <div className="d-flex justify-content-left"></div>
                      <div className="text-center">
                        <div className="already">
                          <p className="sign-up-text">&nbsp;</p>
                          <Link to="/sign-up" className="sign-up-text2">
                            &nbsp;
                          </Link>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="mt-5 ">
                  <span>
                    All rights reserved to SentePay. A product of GMT Consults
                    LTD
                  </span>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ForgotPassword;
