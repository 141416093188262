import React from "react";
import { currFormat } from "../../../constants/GenHelpers";
import moment from "moment";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Button,
  ModalFooter,
} from "reactstrap";
import { useSelector, shallowEqual } from "react-redux";

const TransactionDetail = ({
  toggle,
  transaction,
  isOpen,
  isWithdraw,
  handleApprove,
}) => {
  const processing = useSelector(
    (state) => state.withdrawState.is_processing,
    shallowEqual
  );
  return (
    <>
      <Modal size="lg" isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Transaction Details</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={6} sm={12} md={6} className="font-weight-bold">
              Reference Number
            </Col>
            <Col lg={6} sm={12} md={6}>
              {typeof transaction !== "undefined" && transaction.request_ref}
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={12} md={6} className="font-weight-bold ">
              Amount
            </Col>
            <Col lg={6} sm={12} md={6} className="my-2">
              {typeof transaction !== "undefined" &&
                (transaction.debit
                  ? currFormat(transaction.debit * 1)
                  : currFormat(transaction.credit * 1))}
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={12} md={6} className="font-weight-bold">
              Charges
            </Col>
            <Col lg={6} sm={12} md={6}>
              {typeof transaction !== "undefined" &&
                currFormat(transaction.charges * 1)}
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={12} md={6} className="font-weight-bold ">
              Balance
            </Col>
            <Col lg={6} sm={12} md={6} className="my-2">
              {typeof transaction !== "undefined" &&
                typeof transaction.balance !== "undefined" &&
                transaction.balance}
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={12} md={6} className="my-2 font-weight-bold ">
              Status
            </Col>
            <Col lg={6} sm={12} md={6} className="my-2">
              <b>
                {typeof transaction !== "undefined" && transaction.status_text}
              </b>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={12} md={6} className="font-weight-bold ">
              Phone
            </Col>
            <Col lg={6} sm={12} md={6} className="my-2">
              {typeof transaction !== "undefined" && transaction.msisdn}
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={12} md={6} className="my-2 font-weight-bold ">
              Narrative
            </Col>
            <Col lg={6} sm={12} md={6} className="my-2">
              {typeof transaction !== "undefined" &&
                transaction.transaction_note}
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={12} md={6} className="my-2 font-weight-bold ">
              Service Provider (Code)
            </Col>
            <Col lg={6} sm={12} md={6} className="my-2">
              {typeof transaction !== "undefined" &&
                typeof transaction.sp_country !== "undefined" &&
                transaction.sp_country &&
                transaction.sp_country.service_provider &&
                transaction.sp_country.service_provider
                  .service_provider_name}{" "}
              (
              {typeof transaction !== "undefined" &&
                typeof transaction.sp_country !== "undefined" &&
                transaction.sp_country &&
                transaction.sp_country.spc_code}
              )
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={12} md={6} className="my-2 font-weight-bold ">
              Transaction Date and Time
            </Col>
            <Col lg={6} sm={12} md={6} className="my-2">
              {typeof transaction !== "undefined"
                ? moment(transaction.transaction_date).format("lll")
                : "Loading..."}
            </Col>
          </Row>
        </ModalBody>
        {isWithdraw ? (
          <ModalFooter>
            <Button color="primary" onClick={() => handleApprove()}>
              {processing ? "processing ..." : "Approve"}
            </Button>{" "}
          </ModalFooter>
        ) : (
          ""
        )}
      </Modal>
    </>
  );
};
export default TransactionDetail;
