import allconstants from "../constants";

export const HandleCompanyContactInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return {
    type: "COMPANY_CONTACT_INPUT_CHANGE",
    changedField: { [name]: value },
  };
};

export const clearCompanyContactForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_COMPANY_CONTACT_FORM" };
};

export const CompanyContactFetchSuccess = (payload, msg) => {
  return { type: "FETCH_COMPANY_CONTACT_SUCCESS", payload };
};
export const CompanyContactAddSuccess = (new_record) => {
  return { type: "ADD_COMPANY_CONTACT_SUCCESS", new_record };
};
export const CompanyContactDeleteSuccess = (msg, record_id) => {
  return { type: "DELETE_COMPANY_CONTACT_SUCCESS", record_id };
};
export const CompanyContactReqError = (message) => {
  return { type: "COMPANY_CONTACT_ERROR" };
};

export const editCompanyContact = (obj) => {
  return { type: "EDIT_COMPANY_CONTACT", current_obj: obj };
};
export const HandleCompanyContactSubmit = (form_data, url_id) => (dispatch) => {
  const url = form_data.id
    ? allconstants.apiurl + "contact/" + form_data.id
    : allconstants.apiurl + "company/" + url_id + "/contact";
  var name =
    typeof form_data.masked_contact === "undefined"
      ? form_data.contact_details
      : form_data.masked_contact.replace(/\D+/g, "");
  const req_type = form_data.id
    ? allconstants.axios.put
    : allconstants.axios.post;
  req_type(
    url,
    { ...form_data, contact_details: "+" + name },
    { headers: allconstants.headers }
  )
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(CompanyContactAddSuccess(response.data));
      }
    })
    .catch((err) => {
      dispatch(CompanyContactReqError("Something went wrong try again"));
      console.error("Network/Server error:", err);
    });
};
export const HandleCompanyContactDelete = (record_id) => (dispatch) => {
  const url = allconstants.apiurl + "contact/" + record_id;
  allconstants.axios
    .delete(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(CompanyContactDeleteSuccess(response.data.message, record_id));
      }
    })
    .catch((err) => {
      dispatch(CompanyContactReqError("Something went wrong try again"));
      console.error("Network/Server error:", err);
    });
};
export const HandleCompanyContactFetch = (url_id) => (dispatch) => {
  const url = allconstants.apiurl + "company/" + url_id + "/contact";
  allconstants.axios
    .get(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 200) {
        dispatch(CompanyContactFetchSuccess(response.data));
      }
    })
    .catch((err) => {
      dispatch(CompanyContactReqError("Something went wrong try again"));
      console.error("Network/Server error:", err);
    });
};
