import allconstants from "../constants";

export const HandleStaffInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return { type: "STAFF_INPUT_CHANGE", changedField: { [name]: value } };
};

export const clearStaffForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_STAFF_FORM" };
};

export const StaffFetchSuccess = (payload) => {
  return { type: "FETCH_STAFF_SUCCESS", payload };
};
export const processingStaffRequest = (value) => {
  return { type: "STAFF_REQUEST_PROCESSING", value };
};
export const staffAddSuccess = (payload, update = false) => {
  if (update) {
  } else {
  }
  return { type: "CREATE_STAFF_SUCCESS", new_staff: payload };
};
export const staffReqError = (message) => {
  return { type: "STAFF_ERROR" };
};

export const editStaff = (obj) => {
  return { type: "EDIT_STAFF", current_staff_obj: obj };
};
export const StaffDeleteSuccess = (msg, record_id) => {
  return { type: "DELETE_STAFF_SUCCESS", record_id };
};

export const HandleStaffSubmit = (form_data, toggle) => (dispatch) => {
  dispatch(processingStaffRequest(true));
  const url = form_data.id
    ? allconstants.apiurl + "admean/" + form_data.id
    : allconstants.apiurl + "admean";
  const req_type = form_data.id
    ? allconstants.axios.put
    : allconstants.axios.post;
  req_type(url, form_data, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        if (form_data.id) {
          dispatch(staffAddSuccess(response.data, true));
          dispatch(processingStaffRequest(false));
        } else {
          dispatch(staffAddSuccess(response.data));
          dispatch(processingStaffRequest(false));
        }
        toggle();
      }
    })
    .catch((err) => {
      console.error(err);
      dispatch(processingStaffRequest(false));
      dispatch(staffReqError("Network/Server Error"));
    });
};

export const HandleStaffFetch = () => (dispatch) => {
  const url = allconstants.apiurl + "admean";
  allconstants.axios
    .get(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        dispatch(StaffFetchSuccess(response.data));
      }
    })
    .catch((err) => {
      console.error(err);
      dispatch(staffReqError("Network/Server Error"));
    });
};

export const HandleStaffDelete = (record_id, toggle) => (dispatch) => {
  dispatch(processingStaffRequest(true));
  const url = allconstants.apiurl + "admean/" + record_id;
  allconstants.axios
    .delete(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(StaffDeleteSuccess(response.message, record_id));
        dispatch(processingStaffRequest(false));
        toggle();
      }
    })
    .catch((err) => {
      console.error(err);
      dispatch(processingStaffRequest(false));
      dispatch(staffReqError("Network/Server Error"));
    });
};
