import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, Col } from "reactstrap";
import { FetchAllCompanines } from "../../../actions/company_action.js";
import CompanyTable from "../merchant/merchant_details/companies/table.jsx";

export default function Businesses() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(FetchAllCompanines());
  }, [dispatch]);
  return (
    <Col md={9} sm={12} lg={10}>
      <Card>
        <CardBody className="bg-white">
          <CompanyTable fetchAll={true} />
        </CardBody>
      </Card>
    </Col>
  );
}
