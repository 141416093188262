import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

export const DashCard = ({
  name,
  route,
  color,
  icon,
  count,
  status,
  status2,
  status1,
  status3,
}) => {
  return (
    <>
      <Link to={route}>
        <div className="card" class={color}>
          <Row>
            <Col>
              <h6>{name}</h6>
              <span>{count}</span>
              <span>
                <i class={icon}></i>
              </span>
            </Col>
            <Col>
              <p>{status}</p>
              <span>
                <small>{status1}</small>
              </span>
            </Col>
            <Col>
              <p>{status2}</p>
              <span>
                <small>{status3}</small>
              </span>
            </Col>
          </Row>
        </div>
      </Link>
    </>
  );
};
