import React, { useState } from "react";
import AddTicketType from "./add_ticket_type";
import { TicketTypesTable } from "./ticket_types_table";

const TicketTypes = (props) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <>
      <AddTicketType modal={modal} toggle={toggle} {...props} />
      <TicketTypesTable {...props} toggle={toggle} />
    </>
  );
};

export default TicketTypes;
