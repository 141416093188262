import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { HandlePermissionsFetch } from "../../../../actions/permission_actions";
import {
  HandleRolesFetch,
  saveRolePermissions,
} from "../../../../actions/user_roles_actions";
import { Card, Row, Col, CardBody, Button } from "reactstrap";

export const RolePermisionForm = (props) => {
  const role_permissions = useSelector(
    (state) => state.rolesState.role_permissions,
    shallowEqual
  );
  const permission_list = useSelector(
    (state) => state.permissionState.permission_list,
    shallowEqual
  );
  const items = permission_list.filter((pm) => {
    let str_chunk = pm.name.split("-");
    return str_chunk[1] === props.option1 || str_chunk[1] === props.option2;
  });

  return (
    <>
      <div className="d-flex my-3 py-2">
        <div className="font-weight-bold col-2 ">{props.module_name}</div>
        {items.map((mp, i) => {
          return (
            <>
              <div key={`rpfm${i}`} className="mx-2">
                <div className="form-check text-center ">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={mp.name}
                    onChange={(e) => props.handleChange(e)}
                    name={mp.name}
                    value={1}
                    defaultChecked={role_permissions.includes(mp.name)}
                  />
                  <span>{mp.name}</span>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export const RolePermissions = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(HandlePermissionsFetch());
    dispatch(HandleRolesFetch(props.match.params.id));
  }, [props.match.params.id,dispatch]);

  const [permisions, setPermission] = useState([]);

  const handleChange = (e) => {
    const { name, checked } = e.target;
    let p = permisions;

    if (checked) {
      p.push({ name: name });
    } else {
      p = permisions.filter((pm) => pm.name === name);
    }
    setPermission(p);
  };

  return (
    <>
      <Col md={12} sm={12} lg={12} className="pl-0">
        <Card>
          <Row className="ml-2 my-2">
            <CardBody>
              <RolePermisionForm
                handleChange={handleChange}
                option1="merchant"
                option2="merchants"
                module_name="Merchants"
              />
              <RolePermisionForm
                handleChange={handleChange}
                option1="subscription_plan"
                option2="subscription_plans"
                module_name="Subscription Plans"
              />
              <RolePermisionForm
                handleChange={handleChange}
                option1="charge_rate"
                option2="charge_rates"
                module_name="Charge Rates"
              />
              <RolePermisionForm
                handleChange={handleChange}
                option1="product"
                option2="products"
                module_name="Products"
              />
              <RolePermisionForm
                handleChange={handleChange}
                option1="currencies"
                option2="currency"
                module_name="Currency"
              />
              <RolePermisionForm
                handleChange={handleChange}
                option1="country"
                option2="countries"
                module_name="Countries"
              />
              <RolePermisionForm
                handleChange={handleChange}
                option1="serviceProvider"
                option2="serviceProviders"
                module_name="ServiceProviders"
              />
              <RolePermisionForm
                handleChange={handleChange}
                option1="exchange_ate"
                option2="exchange_rates"
                module_name="Exchange Rates"
              />
              <RolePermisionForm
                handleChange={handleChange}
                option1="role"
                option2="roles"
                module_name="Roles"
              />
              <RolePermisionForm
                handleChange={handleChange}
                option1="permission"
                option2="permissions"
                module_name="Permissions"
              />
              <Button
                type="button"
                className="btn-pay btn col-sm-4 offset-sm-4"
                onClick={(e) => {
                  e.preventDefault();
                  if (permisions.length) {
                    dispatch(
                      saveRolePermissions(props.match.params.id, permisions)
                    );
                  } else {
                    // toastr.info(
                    //   "No permissions have been set. please select a permission before saving"
                    // );
                  }
                }}
              >
                Save
              </Button>
            </CardBody>
          </Row>
        </Card>
      </Col>
    </>
  );
};

export default RolePermissions;
