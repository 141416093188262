import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  HandleStaffFetch,
  HandleStaffSubmit,
  HandleStaffInput,
} from "../../../actions/staff_actions";
import { HandleRolesFetch } from "../../../actions/user_roles_actions";
import { Select } from "../../../constants/constants";
import { StaffTable } from "./staff_table";
import CustomModal from "../../../constants/customModal";
import {
  Button,
  Row,
  Card,
  Col,
  FormGroup,
  Container,
  Form,
  Input,
  CardBody,
} from "reactstrap";

export const Staff = () => {
  const dispatch = useDispatch();
  const { is_processing, current_staff_obj } = useSelector(
    (state) => state.staffState,
    shallowEqual
  );

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    dispatch(HandleRolesFetch());
    dispatch(HandleStaffFetch());
  }, [dispatch]);

  return (
    <>
      <CustomModal
        modal={modal}
        toggle={toggle}
        tittle={current_staff_obj.id !== undefined ? "Edit Staff" : "Add Staff"}
      >
        <Container>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(HandleStaffSubmit(current_staff_obj, toggle));
            }}
          >
            <FormGroup>
              <Row className="mt-0 ml-1">
                <Col lg={12} md={12} sm={12}>
                  <Input
                    autoComplete="off"
                    name="firstname"
                    value={current_staff_obj.firstname}
                    onChange={(e) => dispatch(HandleStaffInput(e))}
                    type="text"
                    placeholder="first name"
                    className=" form-control "
                  />
                </Col>
                <Col lg={12} md={12} sm={12} className="mt-3">
                  <Input
                    autoComplete="off"
                    name="lastname"
                    value={current_staff_obj.lastname}
                    onChange={(e) => dispatch(HandleStaffInput(e))}
                    type="text"
                    placeholder="Last name "
                    className=" form-control "
                  />
                </Col>

                <Col lg={12} md={12} sm={12} className="mt-3">
                  <Input
                    autoComplete="off"
                    name="othernames"
                    value={current_staff_obj.othernames}
                    onChange={(e) => dispatch(HandleStaffInput(e))}
                    type="text"
                    placeholder="othernames"
                    className=" form-control "
                  />
                </Col>
                <Col lg={12} md={12} sm={12} className="mt-3">
                  <Input
                    autoComplete="off"
                    type="email"
                    name="email"
                    value={current_staff_obj.email}
                    onChange={(e) => dispatch(HandleStaffInput(e))}
                    placeholder="Email"
                    className=" form-control "
                  />
                </Col>
                <Col lg={12} md={12} sm={12} className="mt-3">
                  <Input
                    autoComplete="off"
                    type="text"
                    name="phone_number"
                    value={current_staff_obj.phone_number}
                    onChange={(e) => dispatch(HandleStaffInput(e))}
                    placeholder="phone number"
                    className=" form-control "
                  />
                </Col>
                <Col lg={12} sm={12} md={12} className="mt-3">
                  <Input
                    autoComplete="off"
                    type="text"
                    name="address"
                    value={current_staff_obj.address}
                    onChange={(e) => dispatch(HandleStaffInput(e))}
                    placeholder="Address"
                    className=" form-control "
                  />
                </Col>
                <Col lg={12} sm={12} md={12} className="mt-3">
                  <Select
                    placeholder="Gender"
                    index_key="id"
                    options={[
                      { id: 1, value: "male" },
                      { id: 0, value: "female" },
                    ]}
                    name="gender"
                    value={current_staff_obj.gender}
                    label_key="value"
                    handleChange={(e) => dispatch(HandleStaffInput(e))}
                  />
                </Col>
                <Col lg={12} md={12} sm={12} className="mt-3">
                  <Input
                    autoComplete="off"
                    name="password"
                    value={current_staff_obj.password}
                    onChange={(e) => dispatch(HandleStaffInput(e))}
                    type="password"
                    placeholder="Enter Password"
                    className=" form-control "
                  />
                </Col>
                <Col lg={12} md={12} sm={12} className="mt-3">
                  <Input
                    autoComplete="off"
                    name="password_confirmation"
                    value={current_staff_obj.password_confirmation}
                    onChange={(e) => dispatch(HandleStaffInput(e))}
                    type="password"
                    placeholder="ConfirmPassword"
                    className=" form-control "
                  />
                </Col>

                <Col lg={12} sm={12} md={12} className="mt-3">
                  <Button
                    type="submit"
                    className="btn btn-pay btn-block text-white"
                    block
                    disabled={is_processing}
                  >
                    <span className="pay-btn-text">
                      {is_processing ? "... Creating" : "Create"}{" "}
                    </span>
                  </Button>
                </Col>
              </Row>
            </FormGroup>
          </Form>
        </Container>
      </CustomModal>

      <Col md={9} sm={12} lg={10}>
        <Card className="bg-white">
          <CardBody>
            <StaffTable toggle={toggle} />
          </CardBody>
        </Card>
      </Col>
    </>
  );
};
export default Staff;
