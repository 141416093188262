import React from "react";
// import { Switch, Route, withRouter } from 'react-router-dom';
// import ReduxToastr from 'react-redux-toastr';
import MainAppRoutes from "./app/index";
// import { NotificationContainer } from 'react-notifications';

function App() {
  //this is the application entry point
  return (
    <>
      <MainAppRoutes />
      {/* <NotificationContainer/> */}
    </>
  );
}

export default App;
