import React, { useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Select } from "../../../../../constants/constants";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  FormText,
} from "reactstrap";
import {
  HandleMerchantTicketInput,
  HandleMerchantTicketSubmit,
  HandleTicketFetchTypes,
} from "../../../../../actions/merchant_support_ticket_action";
import Loader from "../../../../../constants/loader";

export const AddMerchantTicket = (props) => {
  const dispatch = useDispatch();

  const merchant = useSelector(
    (state) => state.merchantState.current_merchant_obj,
    shallowEqual
  );
  const ticket_types = useSelector(
    (state) => state.merchantTicketState.ticket_types,
    shallowEqual
  );
  const { is_processing, current_merchant_ticket_obj } = useSelector(
    (state) => state.merchantTicketState,
    shallowEqual
  );

  useEffect(() => {
    dispatch(HandleTicketFetchTypes());
  }, [dispatch, merchant.id]);

  const { toggle, modal } = props;

  const handleInput = (e) => dispatch(HandleMerchantTicketInput(e));
  if (merchant.id) {
    return (
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <span className="col-11">Merchant Ticket</span>
        </ModalHeader>
        {is_processing ? (
          <div className="loader-overlay">
            <Loader isModal />
          </div>
        ) : (
          <></>
        )}
        <ModalBody>
          <Form
            autoComplete="off"
            method="post"
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(
                HandleMerchantTicketSubmit(
                  current_merchant_ticket_obj,
                  merchant.id,
                  toggle
                )
              );
            }}
          >
            <Container>
              <FormGroup>
                <Row className="mt-3">
                  <Col lg={12} sm={12} md={12} className="my-1">
                    <Label>Select a Ticket Category</Label>
                    <Select
                      name="ticket_type_id"
                      index_key="id"
                      label_key="ticket_type_name"
                      value={current_merchant_ticket_obj.ticket_type_id}
                      options={ticket_types}
                      handleChange={handleInput}
                    />
                  </Col>
                  <Col lg={12} sm={12} md={12} className="my-2">
                    <textarea
                      type="text"
                      name="description"
                      placeholder="Description"
                      className=" form-control "
                      value={current_merchant_ticket_obj.description}
                      onChange={handleInput}
                    ></textarea>
                  </Col>
                  <Col lg={12} sm={12} md={12} className="my-2">
                    <Input
                      autoComplete="off"
                      id="input"
                      type="file"
                      name="file"
                      placeholder=""
                      className="form-control "
                      onChange={handleInput}
                    />
                    {current_merchant_ticket_obj.id && (
                      <strong className="text-danger">
                        <FormText>
                          *A new file upload replaces the existing one{" "}
                        </FormText>
                      </strong>
                    )}
                  </Col>
                </Row>
              </FormGroup>
            </Container>

            <Button
              type="submit"
              className="btn pay-btn btn-block text-white"
              block
            >
              <span className="pay-btn-text">
                {is_processing ? "... Submitting" : "Submit Ticket"}{" "}
              </span>
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    );
  } else {
    return (
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <span className="col-11">Merchant Ticket</span>
        </ModalHeader>
        <ModalBody>
          <Form
            autoComplete="off"
            method="post"
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(
                HandleMerchantTicketSubmit(
                  current_merchant_ticket_obj,
                  merchant.id,
                  toggle
                )
              );
            }}
          >
            <Container>
              <FormGroup>
                <Row className="mt-3">
                  <Col lg={12} sm={12} md={12} className="my-1">
                    <Label>Select a Ticket Category</Label>
                    <Select
                      name="ticket_type_id"
                      index_key="id"
                      label_key="ticket_type_name"
                      value={current_merchant_ticket_obj.ticket_type_id}
                      options={ticket_types}
                      handleChange={handleInput}
                    />
                  </Col>
                  <Col lg={12} sm={12} md={12} className="my-2">
                    <textarea
                      type="text"
                      name="description"
                      placeholder="Description"
                      className=" form-control "
                      value={current_merchant_ticket_obj.description}
                      onChange={handleInput}
                    ></textarea>
                  </Col>
                  <Col lg={12} sm={12} md={12} className="my-2">
                    <Input
                      autoComplete="off"
                      id="input"
                      type="file"
                      name="file"
                      placeholder=""
                      className="form-control "
                      onChange={handleInput}
                    />
                    {current_merchant_ticket_obj.id && (
                      <strong className="text-danger">
                        <FormText>
                          *A new file upload replaces the existing one{" "}
                        </FormText>
                      </strong>
                    )}
                  </Col>
                </Row>
              </FormGroup>
            </Container>

            <Button
              type="submit"
              className="btn pay-btn btn-block text-white"
              block
            >
              <span className="pay-btn-text ">
                {is_processing ? "... Submitting" : "Submit Ticket"}{" "}
              </span>
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    );
  }
};
