import React from "react";
import { useState } from "react";
import { useDispatch /*, useSelector, shallowEqual*/ } from "react-redux";
// import { toastr } from "react-redux-toastr";
import { Link } from "react-router-dom";
import { resetPassword } from "../../actions/api/login_api";
import allconstants from "../../constants";
import { Col, Row, Form, FormGroup, Button, Input } from "reactstrap";

//const selectorFn = (state) => state.authState.resetPassword;
export const ResetAdminPassword = ({ match }) => {
  const dispatch = useDispatch();
  //const reset_success = useSelector(selectorFn, shallowEqual)

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, confirmation] = useState("");

  const handleSubmit = () => {
    const data = {
      token: match.params.token,
      email: email,
      password: password,
      password_confirmation: password_confirmation,
    };

    if (password_confirmation !== password) {
      // toastr.warning("Opps!", " The passwords do not match!");
    } else {
      dispatch(resetPassword(data));
    }
  };

  return (
    <>
      <Row>
        <Col lg={5} md={5} sm={12} className="left-login-bg">
          <Row className="offset-1">
            <div>
              <div className="sente-pay-welcome">
                <span className="white-bg-line">&nbsp;</span>Welcome Back
              </div>
            </div>
            <Col lg={12} className="height-40">
              &nbsp;
            </Col>
            <Col lg={6}>
              <img
                src="/images/logo.svg"
                alt="SentePay"
                style={{ width: "100%" }}
              />
            </Col>
            <Col lg={12} className="show-for-large pl-0">
              <span className="sente-pay-text-bold-organge">
                Administration Panel
              </span>
            </Col>
            <Col lg={12} className="show-for-large">
              <img
                src={allconstants.login_img}
                height="300px"
                alt="Login"
                className="login_img"
              />
            </Col>
          </Row>
        </Col>
        <Col lg={7} md={7} sm={12} className="pe-0">
          <Row className="row-in-login">
            <Col lg={6}></Col>
            <Col lg={6} className="login-right-top">
              <img
                alt=""
                className="float-end"
                src={allconstants.login_svg_img}
                height="200px"
                width="220px"
              />
            </Col>
          </Row>
          <Row>
            <div className="login-wrapper">
              <div className="box login-dialog-box">
                <div className="content-wrap">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                    className="border border-light p-3"
                  >
                    <p className="h4 mb-4 text-left login_title">
                      Reset Password
                    </p>
                    <FormGroup>
                      <Input
                        type="email"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        className="form-control"
                      />
                      {/* <div className="invalid-feedback">{errors.username && errors.username.message}</div> */}
                    </FormGroup>
                    <FormGroup>
                      <Input
                        name="password"
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        className="form-control"
                        placeholder="password"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        name="password_conirmation"
                        onChange={(e) => confirmation(e.target.value)}
                        type="password"
                        className="form-control"
                        placeholder="confirm password"
                      />
                    </FormGroup>

                    <Button
                      className="btn btn-info sign-in-btn btn-block my-1"
                      type="submit"
                    >
                      Proceed
                    </Button>
                    <div className="d-flex justify-content-left"></div>
                    <div className="text-center">
                      <div className="already">
                        <p className="sign-up-text">&nbsp;</p>
                        <Link to="#" className="sign-up-text2">
                          &nbsp;
                        </Link>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="mt-5 ">
                <span>
                  All rights reserved to SentePay. A product of GMT Consults LTD
                </span>
              </div>
            </div>
          </Row>
        </Col>
      </Row>
    </>
  );
};
