import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import TblComp from "../../../../react_table";
import { Actions } from "../../../../constants/constants";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import ExportCSV from "../../../../constants/Exports/xlsExport";
import {
  HandleProvidersFetch,
  editProvider,
  HandleProvideDelete,
} from "../../../../actions/settings/service_provider/service_provider_actions";
import { clearproviderForm } from "../../../../actions/settings/service_provider/service_provider_actions";
import DeleteModal from "../../../../constants/deleteModal";

export const ServiceProviderTable = (props) => {
  const dispatch = useDispatch();
  const { loading_service_providers, service_provider_list, is_processing } =
    useSelector((state) => state.serviceprovider, shallowEqual);

  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    service_provider_list.length === 0 && dispatch(HandleProvidersFetch());
  }, [dispatch, service_provider_list.length]);

  const headers = ["Id", "Service Provider Name", "Description"];
  return (
    <>
      <DeleteModal
        modal={modal}
        toggle={toggle}
        name="Service Provider"
        is_processing={is_processing}
        handleDelete={() => dispatch(HandleProvideDelete(selected, toggle))}
      />

      <Button
        className="btn ticket-btn my-3 me-2 text-white"
        onClick={(e) => {
          dispatch(clearproviderForm(e));
          props.toggle();
        }}
      >
        Add Service Provider
      </Button>
      <ExportCSV
        csvData={service_provider_list}
        fileName="serviceproviders"
        headers={headers}
      />

      <TblComp
        columns={[
          {
            Header: "Service Provider Name",
            accessor: (props) => (
              <Link to={`service_providers/countries/${props.id}`}>
                {props.service_provider_name}
              </Link>
            ),
            filter: "fuzzyText",
          },
          {
            Header: "Description",
            accessor: "description",
            filter: "fuzzyText",
          },
          {
            id: "actions", // Required because our accessor is not a string
            Header: "Action",
            maxWidth: 100,
            accessor: (full) => (
              <Actions
                editHandler={(selected_row) =>
                  dispatch(editProvider(selected_row))
                }
                data_item={full}
                link_url={"/settings/service_providers/countries/"}
                modal_id="serviceProvider-modal"
                deleteHandler={(selected_id) => [
                  toggle(),
                  setSelected(selected_id.id),
                ]}
              />
            ),
          },
        ]}
        data={service_provider_list}
        loading={loading_service_providers}
      />
    </>
  );
};
