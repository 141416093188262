const TransactionTypeFormState = {
  title: "",
  description: "",
};
const TransactionTypeInitState = {
  transaction_type_list: [],
  form_state: TransactionTypeFormState,
  is_processing: false,
  loading_trans_types: true,
};

export const TransactionTypeReducer = (
  prevState = TransactionTypeInitState,
  action
) => {
  const form_state = prevState.form_state;
  switch (action.type) {
    case "TRANSACTION_TYPE_ERROR":
      return { ...prevState };
    case "LOADING_TRANS_TYPES":
      return {
        ...prevState,
        loading_trans_types: action.payload,
      };
    case "FETCH_TRANSACTION_TYPE_SUCCESS":
      return { ...prevState, transaction_type_list: action.payload.data };
    case "TRANSACTION_TYPE_INPUT_CHANGE":
      return {
        ...prevState,
        form_state: { ...form_state, ...action.changedField },
      };
    case "CLEAR_TRANSACTION_TYPE_FORM":
      return {
        ...prevState,
        form_state: TransactionTypeFormState,
      };
    case "EDIT_TRANSACTION_TYPE":
      return { ...prevState, form_state: { ...action.form_state } };
    case "DELETE_TRANSACTION_TYPE_SUCCESS":
      let new_list = prevState.transaction_type_list.filter(
        (current_rec) => current_rec.id !== action.record_id
      );
      console.log(new_list);
      return { ...prevState, transaction_type_list: new_list };
    case "ADD_TRANSACTION_TYPE_SUCCESS":
      let transaction_type_new = true;
      let transaction_type_list = prevState.transaction_type_list.map(
        (transaction_type) => {
          if (transaction_type.id === action.new_record.id) {
            transaction_type_new = false;
            return action.new_record;
          } else {
            return transaction_type;
          }
        }
      );
      transaction_type_new && transaction_type_list.push(action.new_record);
      const new_state = { ...prevState, transaction_type_list };
      if (transaction_type_new) {
        new_state.form_state = TransactionTypeFormState;
      }
      return new_state;
    case "TRANSACTION_TYPE_REQUEST_PROCESSING":
      return { ...prevState, is_processing: action.value };
    default:
      return prevState;
  }
};
