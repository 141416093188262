import allconstants from "../constants";
import { FetchSingleCompany } from "./company_action";

export const HandleCompanySubInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return { type: "COMPANY_SUB_INPUT_CHANGE", changedField: { [name]: value } };
};

export const clearCompanySubForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_COMPANY_SUB_FORM" };
};

export const CompanySubFetchSuccess = (payload) => {
  return { type: "FETCH_COMPANY_SUB_SUCCESS", payload };
};
export const CompanySubAddSuccess = (new_record) => {
  return { type: "ADD_COMPANY_SUB_SUCCESS", new_record };
};
export const SetBalance = (new_record) => {
  return { type: "SET_BAL", new_record };
};
export const CompanySubDeleteSuccess = (msg, record_id) => {
  return { type: "DELETE_COMPANY_SUB_SUCCESS", record_id };
};
export const CompanySubReqError = (msg) => {
  return { type: "COMPANY_SUB_ERROR" };
};

export const HandleCompanySubSubmit = (form_data, url_id) => (dispatch) => {
  const url = allconstants.apiurl + "company/" + url_id + "/subplan";

  const req_type = form_data.id
    ? allconstants.axios.put
    : allconstants.axios.post;
  req_type(url, form_data, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(CompanySubAddSuccess(response.data));
        dispatch(FetchSingleCompany(url_id));
      } else if (response.status === 400 && response.data.balance) {
        dispatch(SetBalance(response.data.balance));
      }
    })
    .catch((err) => {
      dispatch(CompanySubReqError("Something went wrong try again"));
      console.error("Network/Server error:", err);
    });
};
export const HandleCompanySubDelete = (record_id) => (dispatch) => {
  const url = allconstants.apiurl + "subplan/" + record_id;

  allconstants.axios
    .delete(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(CompanySubDeleteSuccess(response.data.message, record_id));
      }
    })
    .catch((err) => {
      dispatch(CompanySubReqError("Something went wrong try again"));
      console.error("Network/Server error:", err);
    });
};
export const HandleCompanySubFetch = (url_id) => (dispatch) => {
  const url = allconstants.apiurl + "company/" + url_id + "/subplan";
  allconstants.axios
    .get(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 200) {
        dispatch(CompanySubFetchSuccess(response.data));
      }
    })
    .catch((err) => {
      dispatch(CompanySubReqError("Something went wrong try again"));
      console.error("Network/Server error:", err);
    });
};
