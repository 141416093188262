import React, { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  clearMerchantActionForm,
  clearMerchantRespForm,
  HandleChangeView,
  HandleMerchantTicketInput,
  HandleMerchantTicketRespDelete,
  HandleSubmitMerchantTicketAction,
  HandleSubmitMerchantTicketResp,
  HandleTicketResponse,
} from "../../../../../actions/merchant_support_ticket_action";
import user_image from "../../../../../assets/img/user_image.svg";
import attach from "../../../../../assets/img/attach.svg";
import { Select, TextArea } from "../../../../../constants/constants";
import moment from "moment";
import ViewDocument from "./view_document";
import { Card, Col, Row, Form, Button } from "reactstrap";
const pointer = { cursor: "pointer" };
const scroll = { overflowY: "auto" };

export const SupportResponse = (props) => {
  const dispatch = useDispatch();

  const merchantTicketState = useSelector(
    (state) => state.merchantTicketState,
    shallowEqual
  );
  const merchantData = useSelector(
    (state) => state.merchantState.current_merchant_obj,
    shallowEqual
  );

  useEffect(() => {
    merchantTicketState.current_merchant_ticket_obj &&
      dispatch(
        HandleTicketResponse(merchantTicketState.current_merchant_ticket_obj.id)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantTicketState.current_merchant_ticket_obj]);

  const { current_merchant_ticket_obj: ticket } = merchantTicketState;

  const ticketType = Object.keys(ticket).length ? ticket.ticket_type : {};
  let opts = ticket.possible_transitions.map((name, key) => {
    return { id: key, opt_name: name };
  });
  let last_message = merchantTicketState.ticket_response.length - 1;
  let older_msg = last_message - 3 > 0;

  return (
    <>
      <Row>
        <Col md={7} lg={7}>
          <Card>
            <Col className="m-1">
              <img alt="" width="20px" height="20px" src={user_image} />
              <span className="ticket-header-text ml-1">
                {`${merchantData ? merchantData.firstname : "Not Available"}`} (
                {`${merchantData ? merchantData.email : "Not Available"}`})
                Opened this ticket on {ticket.date_created}{" "}
              </span>
            </Col>
            <hr />

            <Col lg={12} sm={12} md={12} className="m-1">
              <img alt="" width="20px" height="20px" src={user_image} />
              <span className="ticket-detail-text-2 ">
                {" "}
                {`${merchantData ? merchantData.firstname : "Not Available"}`}
              </span>
              <span className="ticket-detail-text-1">
                ({`${merchantData ? merchantData.email : "Not Available"}`})
              </span>
              <span className="ticket-detail-text-3"> wrote:</span>
            </Col>
            <span className="ticket-detail-text-4 pl-5">
              <b>{ticket.description}</b>
            </span>
            <Col lg={12} md={12} sm={12} className="m-1">
              <img width="20px" height="20px" src={user_image} alt="user" />
              <span className="ticket-detail-text-5"> You</span>
              <span className="ticket-detail-text-3"> wrote:</span>
            </Col>
            {merchantTicketState.ticket_response ? (
              <div style={scroll}>
                {older_msg && (
                  <small
                    data-target="#more"
                    data-toggle="collapse"
                    style={pointer}
                    className="text-info pl-3"
                  >
                    {" "}
                    Toggle older messages...
                  </small>
                )}
                <div id="more" className="collapse">
                  {merchantTicketState.ticket_response.map((resp, key) => {
                    if (key < last_message - 4) {
                      return (
                        <>
                          <span
                            style={pointer}
                            data-target={`#delete${key}`}
                            data-toggle="collapse"
                            className="  ticket-detail-text-4 pl-5 py-1 row "
                          >
                            {resp.comment_text}
                            <b className="text-info pl-1">
                              {moment(resp.date_created)
                                .startOf("hour")
                                .fromNow()}
                            </b>
                          </span>
                          <div id={`delete${key}`} className="collapse">
                            <b
                              style={pointer}
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch(
                                  HandleMerchantTicketRespDelete(resp.id)
                                );
                              }}
                              className="ml-5"
                            >
                              <small className="text-danger">Delete</small>
                            </b>
                          </div>
                        </>
                      );
                    }
                    return <></>;
                  })}
                </div>
                {merchantTicketState.ticket_response.map((response, key) => {
                  if (key === last_message) {
                    return (
                      <>
                        <span
                          style={pointer}
                          data-target={`#delete${key}`}
                          data-toggle="collapse"
                          className="  ticket-detail-text-4 pl-5 py-1 row"
                        >
                          <b>{response.comment_text}</b>{" "}
                          <b className="text-info pl-1">
                            {moment(response.date_created).format(
                              "Do MMM, h:mm a"
                            )}
                          </b>
                        </span>
                        <div id={`delete${key}`} className="collapse">
                          <b
                            style={pointer}
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(
                                HandleMerchantTicketRespDelete(response.id)
                              );
                            }}
                            className="ml-5"
                          >
                            <small className="text-danger">Delete</small>
                          </b>
                        </div>
                      </>
                    );
                  }
                  if (key > last_message - 4) {
                    return (
                      <>
                        <span
                          style={pointer}
                          data-target={`#delete${key}`}
                          data-toggle="collapse"
                          className=" ticket-detail-text-4 pl-5 py-1 row "
                        >
                          {response.comment_text}
                          <b className="text-info pl-1">
                            {moment(response.date_created).format(
                              "Do MMM, h:mm a"
                            )}
                          </b>
                        </span>
                        <div id={`delete${key}`} className="collapse">
                          <b
                            style={pointer}
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(
                                HandleMerchantTicketRespDelete(response.id)
                              );
                            }}
                            className="ml-5"
                          >
                            <small className="text-danger">Delete</small>
                          </b>
                        </div>
                      </>
                    );
                  }
                  return <></>;
                })}
              </div>
            ) : (
              "Nothing yet"
            )}

            <Form
              autoComplete="off"
              onSubmit={(e) => {
                e.preventDefault();
                dispatch(
                  HandleSubmitMerchantTicketResp(
                    merchantTicketState.resp_obj,
                    ticket.id
                  )
                );
                dispatch(clearMerchantRespForm(e));
              }}
            >
              <Col lg={12} sm={12} md={12} className="m-1">
                <TextArea
                  name="comment_text"
                  className="form-control"
                  placeholder="Reply..."
                  value={merchantTicketState.resp_obj.comment_text}
                  handleChange={(e) => dispatch(HandleMerchantTicketInput(e))}
                />
              </Col>
              <Col lg={12} sm={12} md={12} className="my-2">
                <img width="20px" height="20px" src={attach} alt="failed" />
                <span className="ticket-detail-text-1"> Attach files</span>
              </Col>
              <Col lg={12} sm={12} md={12} className="my-2">
                <Button type="submit" className="btn pay-btn m-2">
                  <span className="pay-btn-text ">Reply</span>
                </Button>
              </Col>
            </Form>
          </Card>
        </Col>

        <Col md={5} lg={5} sm={12}>
          <Row>
            <Card>
              <span className="col-12 title-text">Ticket info</span>
              <Col lg={12} sm={12} md={12} className="my-2">
                <span>Ticket Reference:</span>
                <span className="title-text">ticket.id</span>
              </Col>
              {ticket?.file_path && (
                <Col lg={12} sm={12} md={12} className="my-2">
                  <span>Uploads:</span>
                  <a
                    href={`blob:${ticket?.file_path}`}
                    data-toggle="modal"
                    data-target="#viewDoc"
                    className="ticket-detail-text-5"
                  >
                    Document
                  </a>
                </Col>
              )}
              <Col lg={12} sm={12} md={12} className="my-2">
                <span>Category:</span>
                <span className="title-text">
                  {typeof ticket.ticket_type === "undefined"
                    ? ticketType.ticket_type_name
                    : ticket.ticket_type.ticket_type_name}
                </span>
              </Col>
              <Col lg={12} sm={12} md={12} className="my-2">
                <span>Status:</span>
                <span className="title-text">{ticket.status_text}</span>
              </Col>
              {(ticket.status_text !== "Canceled" ||
                ticket.status_text !== "Resolved") && (
                <Form
                  autoComplete="off"
                  onSubmit={(e) => {
                    e.preventDefault();
                    dispatch(
                      HandleSubmitMerchantTicketAction(
                        merchantTicketState.action_obj,
                        ticket.id,
                        merchantData.id
                      )
                    );
                    dispatch(clearMerchantActionForm(e));
                  }}
                >
                  <Col lg={12} sm={12} md={12} className="my-2">
                    <Select
                      placeholder="select ticket action"
                      name="ticket_action"
                      index_key="opt_name"
                      label_key="opt_name"
                      value={merchantTicketState.action_obj.action_id}
                      options={opts}
                      handleChange={(e) =>
                        dispatch(HandleMerchantTicketInput(e))
                      }
                    />
                  </Col>
                  <Col lg={12} sm={12} md={12} className="my-2">
                    <TextArea
                      name="ticket_comment"
                      placeholder="Comment"
                      value={merchantTicketState.action_obj.comment_text}
                      handleChange={(e) =>
                        dispatch(HandleMerchantTicketInput(e))
                      }
                    />
                  </Col>
                  <Button type="submit" className="pay-btn m-2">
                    <span className="clear-close-text">Take Action</span>
                  </Button>
                </Form>
              )}
            </Card>
          </Row>

          <Button
            color="info"
            onClick={() => {
              dispatch(HandleChangeView(false));
            }}
            className="text-white m-2"
          >
            <span className="clear-close-text">Back to table</span>
          </Button>
        </Col>
      </Row>
      <ViewDocument {...props} />
    </>
  );
};

export default SupportResponse;
