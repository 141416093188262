import allconstants from "../constants";
// import { HandleCompanyFetch } from "./company_action";

export const HandleCollectionInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return { type: "COLLECTION_INPUT_CHANGE", changedField: { [name]: value } };
};

export const clearCollectionForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_COLLECTION_FORM" };
};

export const CollectionFetchSuccess = (payload, isCollection = false) => {
  return { type: "FETCH_COLLECTION_SUCCESS", payload, isCollection };
};
export const CollectionAddSuccess = (payload) => {
  return { type: "ADD_COLLECTION_SUCCESS", new_record: payload.request };
};
export const CollectionDeleteSuccess = (msg, record_id) => {
  return { type: "DELETE_COLLECTION_SUCCESS", record_id };
};
export const CollectionReqError = (msg) => {
  return { type: "COLLECTION_ERROR" };
};
export const editCollection = (obj) => {
  return { type: "EDIT_COLLECTION", current_collection_obj: obj };
};
export const InitCollection = (isTrue) => {
  return { type: "INIT_COLLECTION", initCollection: isTrue };
};
export const HandleCollectionSubmit = (form_data, url_id) => (dispatch) => {
  if (form_data.amount.match(/^[0-9]+$/) == null) {
  } else {
    var name = form_data.masked_contact.replace(/\D+/g, "");
    const url = allconstants.apiurl + "company/" + url_id + "/transcn/collctn";

    const req_type = form_data.id
      ? allconstants.axios.put
      : allconstants.axios.post;
    req_type(
      url,
      { ...form_data, msisdn: "+" + name },
      { headers: allconstants.headers }
    ).then((response) => {
      if (response.status === 202) {
        dispatch(InitCollection(true));
        dispatch(CollectionAddSuccess(response.data));
      }
    });
  }
  return null;
};
export const HandleCollectionDelete = (record_id) => (dispatch) => {
  const url = allconstants.apiurl + "collection/" + record_id;

  allconstants.axios
    .delete(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(CollectionDeleteSuccess(response.data.message, record_id));
      }
    });
  return null;
};

export const FetchCompanyTransactions =
  (params = { page: 1 }, company_id) =>
  (dispatch) => {
    allconstants.axios
      .get(allconstants.apiurl + "company/" + company_id + "/transcn", {
        headers: allconstants.headers,
        params,
      })
      .then((response) => {
        dispatch(CollectionFetchSuccess(response.data.data));
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Bad/No internet connection");
        }
      });
  };
export const HandleCollectionFetch =
  (url_id, status = false) =>
  (dispatch) => {
    let status_url = "";
    if (status) {
      status_url =
        allconstants.apiurl + "company/" + url_id + "/transcn?status=" + status;
    } else {
      if (typeof url_id === "object") {
        status_url =
          allconstants.apiurl + "/transcn/collctn/" + url_id.ref_no + "/status";
      } else {
        status_url =
          typeof url_id === "string" && url_id.includes("page")
            ? url_id
            : allconstants.apiurl + "company/" + url_id + "/transcn";
      }
    }
    allconstants.axios
      .get(status_url, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.data.total) {
            dispatch(CollectionFetchSuccess(response.data.data, true));
          } else {
            dispatch(CollectionFetchSuccess(response.data.data));
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error(error.response);
        } else if (error.request) {
          console.error(error.request);
        } else {
        }
      });
  };

export const handleApprovalStatusChange = (form_data) => (dispatch) => {
  const url =
    allconstants.apiurl +
    `collection/${form_data.id}/action/${form_data.status}`;
  const data = {
    reason_for_decision: form_data.reason_for_decision,
  };

  allconstants.axios
    .post(url, data, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 200) {
        dispatch(HandleCollectionFetch(form_data.id, true));
        dispatch(CollectionAddSuccess(response.data, true));
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
      }
    });
};
