import allconstants from "../../../constants";
import { validateLimits } from "../../../constants/constants";

export const handleChargeRateInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return { type: "CHARGE_RATE_INPUT_CHANGE", changedField: { [name]: value } };
};
export const clearChargeRateForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_CHARGE_RATE_FORM" };
};
export const processingChargeRateRequest = (value) => {
  return { type: "CHARGE_RATE_REQUEST_PROCESSING", value };
};

export const chargeRateFetchSuccess = (payload, msg) => {
  return { type: "FETCH_CHARGE_RATE_SUCCESS", payload };
};
export const chargeRateAddSuccess = (new_record) => {
  return { type: "ADD_CHARGE_RATE_SUCCESS", new_record };
};
export const chargeRateDeleteSuccess = (msg, record_id) => {
  return { type: "DELETE_CHARGE_RATE_SUCCESS", record_id };
};
export const chargeRateReqError = (msg) => {
  return { type: "CHARGE_RATE_ERROR" };
};
export const editChargeRate = (obj) => {
  return { type: "EDIT_CHARGE_RATE", current_charge_rate_obj: obj };
};
export const handleChargeRateSubmit =
  (form_data, id = "", toggle) =>
  (dispatch) => {
    if (!validateLimits(form_data.min_value, form_data.max_value)) {
      dispatch(
        chargeRateReqError(
          "The minimum value cannot be greater than the maximum value"
        )
      );
      return false;
    }
    if (!validateLimits(form_data.min_trans, form_data.max_trans)) {
      dispatch(
        chargeRateReqError(
          "The minimum transaction  cannot be greater than the maximum transaction "
        )
      );
      return false;
    }

    const url = allconstants.apiurl + "subplan/" + id + "/charge_rate";
    dispatch(processingChargeRateRequest(true));
    allconstants.axios
      .post(url, form_data, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(chargeRateAddSuccess(response.data));
          dispatch(processingChargeRateRequest(false));
          toggle();
        }
      })
      .catch((err) => {
        dispatch(processingChargeRateRequest(false));
        dispatch(chargeRateReqError("Network/Server error", err));
      });
  };
export const handleChargeRateDelete = (record_id, toggle) => (dispatch) => {
  dispatch(processingChargeRateRequest(true));
  const url = allconstants.apiurl + "charge_rate/" + record_id;
  allconstants.axios
    .delete(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(chargeRateDeleteSuccess(response.data.message, record_id));
        dispatch(processingChargeRateRequest(false));
        toggle();
      }
    })
    .catch((err) => {
      dispatch(processingChargeRateRequest(false));
      dispatch(chargeRateReqError("Network/Server error", err));
    });
};
export const handleChargeRateFetch = (plan_id) => (dispatch) => {
  const url = allconstants.apiurl + "subplan/ " + plan_id + " /charge_rate";
  allconstants.axios
    .get(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 200) {
        dispatch(chargeRateFetchSuccess(response.data));
      }
    })
    .catch((err) => {
      dispatch(chargeRateReqError("Network/Server error", err));
    });
};
