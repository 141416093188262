import allconstants from "../constants";
import {HandleStaffFetch} from "./staff_actions"

export const HandleStaffRolesInput = (e) => {
  const { value } = e.target ? e.target : e;
  return { type: "STAFF_ROLE_INPUT_CHECKED", value };
};
const processingRolesRequest = (value) => {
  return { type: "STAFF_ROLE_REQUEST_PROCESSING", value };
};

const roleAssignSuccess = (payload) => {
  return {
    type: "STAFF_ROLE_ASSIGN_SUCCESS",
    payload,
  };
};

export const editStaffRoles = (roles) => {
  return { type: "EDIT_STAFF_ROLES", roles };
};

export const assignStaffRoles = (id, data) => (dispatch) => {
  dispatch(processingRolesRequest(true));
  const url = allconstants.apiurl + "admin/" + id + "/role";
  allconstants.axios
    .post(url, { roles: data }, { headers: allconstants.headers })
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        dispatch(roleAssignSuccess(res.data));
        dispatch(processingRolesRequest(false));
        dispatch(HandleStaffFetch());
      }
    })
    .catch((error) => {
      if (error.response) {
        dispatch(processingRolesRequest(false));
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
      }
    });
};
