const DocumentTypeFormState = {
  document_name: "",
  description: "",
};
const DocumentTypeInitState = {
  document_type_list: [],
  form_state: DocumentTypeFormState,
  is_processing: false,
  loading_doc_types: true,
};

export const DocumentTypeReducer = (
  prevState = DocumentTypeInitState,
  action
) => {
  const doc_form_state = prevState.form_state;
  switch (action.type) {
    case "DOCUMENT_TYPE_ERROR":
      return { ...prevState };
    case "LOADING_DOC_TYPES":
      return {
        ...prevState,
        loading_doc_types: action.payload,
      };
    case "FETCH_DOCUMENT_TYPE_SUCCESS":
      return { ...prevState, document_type_list: action.payload };
    case "DOCUMENT_TYPE_INPUT_CHANGE":
      return {
        ...prevState,
        form_state: { ...doc_form_state, ...action.changedField },
      };
    case "CLEAR_DOCUMENT_TYPE_FORM":
      return { ...prevState, form_state: DocumentTypeFormState };
    case "EDIT_DOCUMENT_TYPE":
      return { ...prevState, form_state: { ...action.form_state } };
    case "DELETE_DOCUMENT_TYPE_SUCCESS":
      let new_list = prevState.document_type_list.filter(
        (current_rec) => current_rec.id !== action.record_id
      );
      return { ...prevState, document_type_list: new_list };
    case "ADD_DOCUMENT_TYPE_SUCCESS":
      let document_type_new = true;
      let document_type_list = prevState.document_type_list.map(
        (document_type) => {
          if (document_type.id === action.new_record.id) {
            document_type_new = false;
            return action.new_record;
          } else {
            return document_type;
          }
        }
      );
      document_type_new && document_type_list.push(action.new_record);
      const new_state = { ...prevState, document_type_list };
      if (document_type_new) {
        new_state.form_state = DocumentTypeFormState;
      }
      return new_state;
    case "DOCUMENT_TYPE_REQUEST_PROCESSING":
      return { ...prevState, is_processing: action.value };
    default:
      return prevState;
  }
};
