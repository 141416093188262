import Axios from "axios";
import allconstants from "../constants";

export const HandleMerchantTicketInput = (e) => {
  const { value, name, files } = e.target ? e.target : e;
  if (name === "comment_text") {
    return {
      type: "MERCHANT_TICKET_INPUT_CHANGE",
      changedField: { [name]: value },
      resp: true,
    };
  } else if (name === "ticket_action") {
    return {
      type: "MERCHANT_TICKET_INPUT_CHANGE",
      changedField: { action_id: value },
      ticket_action: true,
    };
  } else if (name === "ticket_comment") {
    return {
      type: "MERCHANT_TICKET_INPUT_CHANGE",
      changedField: { comment_text: value },
      ticket_action: true,
    };
  } else {
    if (typeof files === "undefined" || files === null) {
      return {
        type: "MERCHANT_TICKET_INPUT_CHANGE",
        changedField: { [name]: value },
      };
    } else {
      return {
        type: "MERCHANT_TICKET_INPUT_CHANGE",
        changedField: { file: files[0] },
      };
    }
  }
};

export const clearMerchantTicketForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_MERCHANT_TICKET_FORM" };
};
export const HandleChangeView = (status) => {
  return { type: "CHANGE_MERCHANT_TICKET_VIEW", resp: status };
};
export const clearMerchantRespForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_MERCHANT_RESP_FORM" };
};
export const clearMerchantActionForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_MERCHANT_TICKET_ACTION_FORM" };
};

export const MerchantTicketFetchSuccess = (payload, isTicket = false) => {
  return { type: "FETCH_MERCHANT_TICKET_SUCCESS", payload, isTicket };
};
export const MerchantTicketAddSuccess = (new_record, update = false) => {
  console.log(new_record);
  if (typeof new_record !== "string") {
    if (update) {
    } else {
    }
  }

  return { type: "ADD_MERCHANT_TICKET_SUCCESS", new_record };
};
export const MerchantTicketDeleteSuccess = (msg, record_id, resp = false) => {
  return { type: "DELETE_MERCHANT_TICKET_SUCCESS", record_id, resp };
};
export const MerchantTicketReqError = (msg) => {
  return { type: "MERCHANT_TICKET_ERROR" };
};
export const IsLoading = (payload) => {
  return { type: "LOADING_TICKETS", payload };
};
export const processingMerchantSupportRequest = (value) => {
  return { type: "MERCHANT_SUPPORT_REQUEST_PROCESSING", value };
};
export const editMerchantTicket = (obj) => {
  return { type: "EDIT_MERCHANT_TICKET", current_merchant_ticket_obj: obj };
};
export const HandleMerchantTicketSubmit =
  (form_data, url_id, toggle) => (dispatch) => {
    dispatch(processingMerchantSupportRequest(true));
    const url = form_data.id
      ? allconstants.apiurl + "support_ticket/" + form_data.id
      : allconstants.apiurl + "merchant/" + url_id + "/support_ticket";
    const req_type = form_data.id ? Axios.put : Axios.post;
    const formData = new FormData();
    formData.append("description", form_data.description);
    formData.append("id", form_data.id);
    formData.append("ticket_type_id", form_data.ticket_type_id);
    formData.append("attached_file", form_data.file);
    form_data.id && formData.append("_method", "PUT");
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    req_type(url, formData, config)
      .then((response) => {
        if (response.status === 201) {
          dispatch(MerchantTicketAddSuccess(response.data));
        } else if (response.status === 200) {
          dispatch(MerchantTicketAddSuccess(response.data, true));
        }
        toggle();
        dispatch(processingMerchantSupportRequest(false));
      })
      .catch((err) => {
        console.error(err);
        dispatch(MerchantTicketReqError("Network/Server Error"));
        dispatch(processingMerchantSupportRequest(false));
      });
  };
export const HandleSubmitMerchantTicketAction =
  (form_data, url_id, merchant_id) => (dispatch) => {
    const url =
      allconstants.apiurl +
      "support_ticket/" +
      url_id +
      "/" +
      form_data.action_id;

    const req_type = allconstants.axios.put;
    req_type(url, form_data, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 200) {
          dispatch(MerchantTicketAddSuccess(form_data.action_id));
          dispatch(HandleMerchantTicketFetch(merchant_id));
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Bad/No internet connection");
        }
      });
  };
export const HandleSubmitMerchantTicketResp =
  (form_data, url_id) => (dispatch) => {
    const url =
      allconstants.apiurl + "support_ticket/" + url_id + "/ticket_response";

    const req_type = form_data.id
      ? allconstants.axios.put
      : allconstants.axios.post;
    req_type(url, form_data, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 201) {
          dispatch(HandleTicketResponse(response.data.support_ticket_id));
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Bad/No internet connection");
        }
      });
  };
export const HandleMerchantTicketDelete = (record_id, toggle) => (dispatch) => {
  const url = allconstants.apiurl + "support_ticket/" + record_id;
  dispatch(processingMerchantSupportRequest(true));
  allconstants.axios
    .delete(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(MerchantTicketDeleteSuccess(response.data.message, record_id));
        dispatch(processingMerchantSupportRequest(false));
        toggle();
      }
    })
    .catch((err) => {
      console.error(err);
      dispatch(processingMerchantSupportRequest(false));
      dispatch(MerchantTicketReqError("Network/Server Error"));
    });
};
export const HandleMerchantTicketRespDelete = (record_id) => (dispatch) => {
  const url = allconstants.apiurl + "ticket_response/" + record_id;

  allconstants.axios
    .delete(url, { headers: allconstants.headers })
    .then((response) => {
      if (
        response.status === 201 ||
        response.status === 200 ||
        response.status === 202
      ) {
        dispatch(
          MerchantTicketDeleteSuccess(response.data.message, record_id, true)
        );
      }
    })
    .catch((err) => {
      console.error(err);
      dispatch(MerchantTicketReqError("Network/Server Error"));
    });
};
export const HandleMerchantTicketFetch =
  (url_id, all_tickets = false) =>
  (dispatch) => {
    const url = all_tickets
      ? allconstants.apiurl + "support_ticket"
      : allconstants.apiurl + "merchant/" + url_id + "/support_ticket";
    allconstants.axios
      .get(url, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 200) {
          dispatch(MerchantTicketFetchSuccess(response.data));
          dispatch(IsLoading(false));
        }
      })
      .catch((network_response) => console.error(network_response));
    return null;
  };
export const HandleTicketFetchTypes = () => (dispatch) => {
  const url = allconstants.apiurl + "ticket_type";
  allconstants.axios
    .get(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 200) {
        dispatch(MerchantTicketFetchSuccess(response, "ticket"));
      }
    })
    .catch((err) => {
      console.error(err);
      dispatch(MerchantTicketReqError("Network/Server Error"));
    });
};
export const HandleTicketResponse = (ticket_id) => (dispatch) => {
  const url =
    allconstants.apiurl + "support_ticket/" + ticket_id + "/ticket_response";
  allconstants.axios
    .get(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 200) {
        dispatch(MerchantTicketFetchSuccess(response, "response"));
      }
    })
    .catch((err) => {
      console.error(err);
      dispatch(MerchantTicketReqError("Network/Server Error"));
    });
};
