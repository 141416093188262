import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  clearCompanyDocumentForm,
  editCompanyDocument,
  HandleApproval,
  HandleCompanyDocumentDelete,
  HandleCompanyDocumentFetch,
  HandleCompanyDocumentInput,
  HandleCompanyDocumentSubmit,
} from "../../../../../../actions/company_document_actions";
import { HandleDocumentTypeFetch } from "../../../../../../actions/settings/types/document_type_actions";
import { Actions, Select } from "../../../../../../constants/constants";
import TblComp from "../../../../../../react_table";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Row,
  Input,
  FormGroup,
  Badge,
  Container,
  Col,
  Label,
} from "reactstrap";

const DocLink = (link) => {
  return (
    <>
      <a href={link} download>
        <svg
          title="Download Document"
          className="ml-3"
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          viewBox="0 0 18 15"
        >
          <g>
            <g>
              <path
                fill="#f87f0f"
                d="M9.75 5h-1.5v3H6l3 3.75L12 8H9.75zm2.886 8.24l-.13.01H5.494l-.13-.01a3.375 3.375 0 0 1-1.522-6.232l.604-.377.09-.706a4.5 4.5 0 0 1 8.927 0l.09.706.606.377a3.375 3.375 0 0 1-1.523 6.232zM.75 9.875a4.899 4.899 0 0 0 4.5 4.875l7.5-.014a4.875 4.875 0 0 0 2.202-9 6 6 0 0 0-11.904 0A4.872 4.872 0 0 0 .75 9.875z"
              />
            </g>
          </g>
        </svg>
      </a>
    </>
  );
};
export const CompanyDocument = (props) => {
  const [value, setVal] = useState("");
  const [value2, setVal2] = useState("");
  const [doc_id, setID] = useState("");
  const [isActive, setActiveTab] = useState();
  const [currentDoc, setCurrentDoc] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [prevModal, setprevModal] = useState(false);
  const PrevToggle = () => setprevModal(!prevModal);

  const [docModal, setdocModal] = useState(false);
  const docToggle = () => setdocModal(!docModal);

  const {
    current_company_document_obj: formState,
    company_document_list,
    deleteHandler,
    handleInput,
    submitHandler,
    document_type_list,
    editHandler,
    match,
  } = props;
  useEffect(() => {
    //props.company_document_list.length === 0 &&
    props.fetchCompanyDocs(match.params.company_id);
    props.document_type_list.length === 0 && props.fetchDocumentType();
  }, [match.params.company_id, props]);

  const DocTypes = (full) => {
    if (typeof props.document_type_list.length) {
      const currentDocType = props.document_type_list.find(
        (document) => document.id === full.document_type_id
      );
      if (typeof currentDocType !== "undefined") {
        return currentDocType.document_name;
      }
    }
    return <></>;
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalHeader toggle={toggle}>
          <span className="col-11">Company Document</span>
        </ModalHeader>
        <ModalBody>
          <Form
            autoComplete="off"
            method="post"
            onSubmit={(e) => {
              e.preventDefault();
              submitHandler(formState, props.id);
            }}
          >
            <Container>
              <FormGroup>
                <Row>
                  <Col lg={12} sm={12} md={12} className="mt-2">
                    <Label className="ml-3 lead">Select doc type</Label>
                    <Select
                      name="document_type_id"
                      index_key="id"
                      label_key="document_name"
                      value={formState.document_type_id}
                      options={document_type_list}
                      handleChange={handleInput}
                    />
                  </Col>

                  <Col lg={12} sm={12} md={12} className="mt-2">
                    <Input
                      autoComplete="off"
                      id="input"
                      type="file"
                      name="doc_file"
                      placeholder=""
                      className="form-control "
                      onChange={handleInput}
                    />
                    {formState.id && (
                      <strong className="text-danger">
                        *Your currently saved document will be deleted on
                        uploading another one{" "}
                      </strong>
                    )}
                  </Col>
                </Row>
              </FormGroup>
            </Container>
            <Button
              type="submit"
              className="btn pay-btn btn-block text-white shadow-none"
              block
            >
              <span className="pay-btn-text ">Add</span>
            </Button>
          </Form>
        </ModalBody>
      </Modal>
      {/* preview document modal */}
      <Modal size="lg" isOpen={prevModal} toggle={PrevToggle}>
        <ModalHeader toggle={PrevToggle}>
          <span className="col-11">Company Documents</span>
        </ModalHeader>
        <ModalBody>
          <Form
            autoComplete="off"
            method="post"
            onSubmit={(e) => {
              e.preventDefault();
              props.ApproveDoc(value, value2, doc_id, match.params.id);
            }}
          >
            <div style={{ height: "400px" }}>
              <ul class="nav nav-tabs mb-2" role="tablist">
                {props.company_document_list.map((document, id) => (
                  <li class="nav-item pr-2">
                    <Button
                      onClick={() => [
                        setCurrentDoc(document.dope_name),
                        setActiveTab(id),
                      ]}
                      key={document.id}
                      className={
                        parseInt(isActive) === parseInt(id)
                          ? "bg-warning text-white nav-link"
                          : "nav-link"
                      }
                      data-toggle="tab"
                      href="#tabs-3"
                      role="tab"
                    >
                      {DocTypes(document)}
                    </Button>
                  </li>
                ))}
              </ul>

              <iframe
                title="
                "
                src={currentDoc}
                frameBorder="0"
                scrolling="auto"
                height="70%"
                width="100%"
              ></iframe>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      {/* end of preview modal */}
      <Modal isOpen={docModal} toggle={docToggle}>
        <ModalHeader toggle={docToggle}>
          <span className="font-weight-bold modal-header-txt">
            {" "}
            Approve Document
          </span>
        </ModalHeader>
        <ModalBody>
          <Form
            autoComplete="off"
            method="post"
            onSubmit={(e) => {
              e.preventDefault();
              props.ApproveDoc(value, value2, doc_id, match.params.id);
            }}
          >
            <FormGroup>
              <Row>
                <Container>
                  <Row className="mt-3">
                    <Col lg={12} md={12} sm={12} className="mt-2">
                      <select
                        className="form-control"
                        name=""
                        onChange={(e) => {
                          setVal2(e.target.value);
                        }}
                      >
                        {[
                          { id: false, value: "---select---" },
                          { id: 1, value: "Approve" },
                          { id: 0, value: "Decline" },
                        ].map((val, key) => {
                          return (
                            <option key={key} value={val.id}>
                              {val.value}
                            </option>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>
                  <Row className="mt-3 mb-3">
                    <Col lg={12} sm={12} md={12}>
                      <Input
                        autoComplete="off"
                        type="text"
                        name="doc_file"
                        placeholder="Comment"
                        className="form-control "
                        value={value}
                        onChange={(e) => {
                          setVal(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                </Container>

                <Button type="submit" className="btn pay-btn btn-block">
                  <span className="pay-btn-text ">Submit</span>
                </Button>
              </Row>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
      <Button
        className="btn payment-btn my-3 text-white shadow"
        onClick={() => toggle}
      >
        Add Document
      </Button>
      <TblComp
        columns={[
          {
            id: "document_type_id",
            Header: "Document",
            maxWidth: 100,
            accessor: (full) => DocTypes(full),
          },
          {
            id: "dope_name",
            Header: "Document Link",
            maxWidth: 100,
            accessor: (full) => DocLink(full.doc_names),
          },
          {
            id: "status",
            Header: "Status",
            maxWidth: 100,
            accessor: (full) =>
              full.approved === 0 ? (
                <Button
                  onClick={(e) => {
                    docToggle();
                    e.preventDefault();
                    setID(full.id);
                  }}
                  title="Click to approve"
                  className="btn btn-sm btn-danger text-white"
                >
                  {full.approval_text}
                </Button>
              ) : (
                <Button className="btn btn-sm btn-success">
                  {full.approval_text}
                </Button>
              ),
          },
          {
            id: "actions", // Required because our accessor is not a string
            Header: "Actions",
            maxWidth: 100,
            accessor: (full) => (
              <>
                <a
                  href
                  onClick={(e) => [
                    e.preventDefault(),
                    PrevToggle(),
                    setID(full.id),
                    setCurrentDoc(full.dope_name),
                    setActiveTab(full.document_type_id - 1),
                  ]}
                >
                  <Badge color="info">
                    <img
                      src="/images/view.svg"
                      height="15px"
                      width="15px"
                      alt="view"
                      className="ml-1"
                    />
                  </Badge>
                </a>
                <Actions
                  modal_id="company_document-modal"
                  data_item={full}
                  editHandler={editHandler}
                  deleteHandler={deleteHandler}
                />
              </>
            ),
          },
        ]}
        data={company_document_list}
      />
    </>
  );
};
// connect the store for the company dispatches and state
const mapCompanyDocumentStateToProps = (state) => {
  return {
    current_company_document_obj:
      state.companyDocumentState.current_company_document_obj,
    document_type_list: state.documenttypereducer.document_type_list,
    company_document_list: state.companyDocumentState.company_document_list,
    curent_document: state.companyDocumentState.curent_preview_document,
  };
};
const mapCompanyDocumentDispatchToProps = (dispatch) => {
  return {
    clearForm: (e) => dispatch(clearCompanyDocumentForm(e)),
    handleInput: (e) => dispatch(HandleCompanyDocumentInput(e)),
    submitHandler: (form_obj, url_id) =>
      dispatch(HandleCompanyDocumentSubmit(form_obj, url_id)),
    ApproveDoc: (comment, approval_id, doc_id, url) =>
      dispatch(HandleApproval(comment, approval_id, doc_id, url)),
    fetchCompanyDocs: (url_id) => dispatch(HandleCompanyDocumentFetch(url_id)),
    fetchDocumentType: () => dispatch(HandleDocumentTypeFetch()),
    editHandler: (record) => dispatch(editCompanyDocument(record)),
    deleteHandler: (rec_id) => dispatch(HandleCompanyDocumentDelete(rec_id)),
  };
};
export const CompanyDocReduxComp = withRouter(
  connect(
    mapCompanyDocumentStateToProps,
    mapCompanyDocumentDispatchToProps
  )(CompanyDocument)
);
