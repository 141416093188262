import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Actions } from "../../../../../constants/constants";
import TblComp from "../../../../../react_table";
import { Button } from "reactstrap";
import {
  editContactType,
  HandleContactTypeDelete,
  HandleContactTypeFetch,
} from "../../../../../actions/settings/types/contact_type_actions";
import { clearContactTypeForm } from "../../../../../actions/settings/types/contact_type_actions";
import ExportCSV from "../../../../../constants/Exports/xlsExport";
import DeleteModal from "../../../../../constants/deleteModal";

export const ContactTypeTable = (props) => {
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState(false);
  const toggle = () => setModal(!modal);
  const { loading_contact_types, contact_type_list, is_processing } =
    useSelector((state) => state.contactTypeState, shallowEqual);
  const dispatch = useDispatch();
  useEffect(() => {
    contact_type_list.length === 0 && dispatch(HandleContactTypeFetch());
  }, [contact_type_list.length, dispatch]);

  const headers = ["Id", "Title", "Description"];

  return (
    <>
      <DeleteModal
        modal={modal}
        toggle={toggle}
        name="contact type"
        handleDelete={() => dispatch(HandleContactTypeDelete(selected, toggle))}
        is_processing={is_processing}
      />
      <Button
        className="ticket-btn my-3 me-2 pulse"
        onClick={(e) => {
          dispatch(clearContactTypeForm(e));
          props.toggle();
        }}
      >
        Add Contact Type{" "}
      </Button>
      <ExportCSV
        csvData={contact_type_list}
        fileName="contactypes"
        headers={headers}
      />
      <TblComp
        columns={[
          { Header: "Title", accessor: "title", filter: "fuzzyText" },
          {
            Header: "Description",
            accessor: "description",
            filter: "fuzzyText",
          },
          {
            id: "actions", // Required because our accessor is not a string
            Header: "Action",
            maxWidth: 100,
            accessor: (full) => (
              <Actions
                editHandler={(selected_row) => [
                  dispatch(editContactType(selected_row)),
                  props.toggle(),
                ]}
                data_item={full}
                link_url={""}
                deleteHandler={(selected_id) => [
                  toggle(),
                  setSelected(selected_id.id),
                ]}
              />
            ),
          },
        ]}
        data={contact_type_list}
        loading={loading_contact_types}
      />
    </>
  );
};
