const SubscriptionPlanState = {
  subscription_plan_name: "",
  description: "",
  sub_plan_type_id: "",
  amount: "",
  roll_over: false,
  currency_id: "",
  auto_renew: false,
};
const SubscriptionPlanInitState = {
  subscription_plan_list: [],
  current_subscription_plan_obj: SubscriptionPlanState,
  is_processing: false,
  subscription_plan_types: [],
  single_record: {},
  loading_sub_plans: true,
};

export const SubscriptionPlanReducer = (
  prevState = SubscriptionPlanInitState,
  action
) => {
  const form_state = prevState.current_subscription_plan_obj;
  let new_formState;
  switch (action.type) {
    case "SUBSCRIPTION_PLAN_ERROR":
      return { ...prevState };
    case "LOADING_SUB_PLANS":
      return {
        ...prevState,
        loading_sub_plans: action.payload,
      };
    case "FETCH_SUBSCRIPTION_PLAN_SUCCESS":
      if (action.single_record) {
        return { ...prevState, single_record: action.payload };
      } else {
        return { ...prevState, subscription_plan_list: action.payload.data };
      }
    case "SUBSCRIPTION_PLAN_INPUT_CHANGE":
      new_formState = { ...form_state, ...action.changedField };
      return { ...prevState, current_subscription_plan_obj: new_formState };

    case "CLEAR_SUBSCRIPTION_PLAN_FORM":
      return {
        ...prevState,
        current_subscription_plan_obj: SubscriptionPlanState,
      };

    case "EDIT_SUBSCRIPTION_PLAN":
      new_formState = { ...action.current_subscription_plan_obj };
      return { ...prevState, current_subscription_plan_obj: new_formState };

    case "DELETE_SUBSCRIPTION_PLAN_SUCCESS":
      let new_list = prevState.subscription_plan_list.filter(
        (current_rec) => current_rec.id !== action.record_id
      );
      return { ...prevState, subscription_plan_list: new_list };

    case "ADD_SUBSCRIPTION_PLAN_SUCCESS":
      let subscription_plan_new = true;
      let subscription_plan_list = prevState.subscription_plan_list.map(
        (subscription_plan) => {
          if (subscription_plan.id === action.new_record.id) {
            subscription_plan_new = false;
            return action.new_record;
          } else {
            return subscription_plan;
          }
        }
      );
      subscription_plan_new && subscription_plan_list.push(action.new_record);
      const new_state = { ...prevState, subscription_plan_list };
      if (subscription_plan_new) {
        new_state.current_subscription_plan_obj = SubscriptionPlanState;
      }
      return new_state;

    case "SUBSCRIPTION_PLAN_REQUEST_PROCESSING":
      return { ...prevState, is_processing: action.value };

    case "FETCH_SUBSCRIPTION_PLAN_TYPE_SUCCESS":
      return { ...prevState, subscription_plan_types: action.payload.data };

    default:
      return prevState;
  }
};
