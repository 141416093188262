const TicketTypeFormState = {
  ticket_type_name: "",
  description: "",
};
const TicketTypeInitState = {
  ticket_type_list: [],
  form_state: TicketTypeFormState,
  is_processing: false,
  loading_ticket_types: true,
};

export const TicketTypeReducer = (prevState = TicketTypeInitState, action) => {
  const form_state = prevState.form_state;
  switch (action.type) {
    case "TICKET_TYPE_ERROR":
      return { ...prevState };
    case "LOADING_TICKET_TYPES":
      return {
        ...prevState,
        loading_ticket_types: action.payload,
      };
    case "FETCH_TICKET_TYPE_SUCCESS":
      return { ...prevState, ticket_type_list: action.payload.data };
    case "TICKET_TYPE_INPUT_CHANGE":
      return {
        ...prevState,
        form_state: { ...form_state, ...action.changedField },
      };
    case "CLEAR_TICKET_TYPE_FORM":
      return { ...prevState, form_state: TicketTypeFormState };
    case "EDIT_TICKET_TYPE":
      return { ...prevState, form_state: { ...action.form_state } };
    case "DELETE_TICKET_TYPE_SUCCESS":
      let new_list = prevState.ticket_type_list.filter(
        (current_rec) => current_rec.id !== action.record_id
      );
      console.log(new_list);
      return { ...prevState, ticket_type_list: new_list };
    case "ADD_TICKET_TYPE_SUCCESS":
      let ticket_type_new = true;
      let ticket_type_list = prevState.ticket_type_list.map((ticket_type) => {
        if (ticket_type.id === action.new_record.id) {
          ticket_type_new = false;
          return action.new_record;
        } else {
          return ticket_type;
        }
      });
      ticket_type_new && ticket_type_list.push(action.new_record);
      const new_state = { ...prevState, ticket_type_list };
      if (ticket_type_new) {
        new_state.form_state = TicketTypeFormState;
      }
      return new_state;
    case "TICKET_TYPE_REQUEST_PROCESSING":
      return { ...prevState, is_processing: action.value };
    default:
      return prevState;
  }
};
