import allconstants from "../../../constants";

export const HandleSubscriptionPlanInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return {
    type: "SUBSCRIPTION_PLAN_INPUT_CHANGE",
    changedField: { [name]: value },
  };
};

export const IsLoading = (payload) => {
  return { type: "LOADING_SUB_PLANS", payload };
};

export const clearSubscriptionPlanForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_SUBSCRIPTION_PLAN_FORM" };
};
export const processingSubscriptionPlanRequest = (value) => {
  return { type: "SUBSCRIPTION_PLAN_REQUEST_PROCESSING", value };
};

export const SubscriptionPlanFetchSuccess = (payload, single_record) => {
  return { type: "FETCH_SUBSCRIPTION_PLAN_SUCCESS", payload, single_record };
};
export const SubscriptionPlanAddSuccess = (new_record, update = true) => {
  if (update) {
  } else {
  }
  return { type: "ADD_SUBSCRIPTION_PLAN_SUCCESS", new_record };
};
export const SubscriptionPlanDeleteSuccess = (msg, record_id) => {
  return { type: "DELETE_SUBSCRIPTION_PLAN_SUCCESS", record_id };
};
export const SubscriptionPlanReqError = (message) => {
  return { type: "SUBSCRIPTION_PLAN_ERROR" };
};

export const editSubscriptionPlan = (obj) => {
  return { type: "EDIT_SUBSCRIPTION_PLAN", current_subscription_plan_obj: obj };
};
export const subscriptionPlanTypeFetch = (payload) => {
  return {
    type: "FETCH_SUBSCRIPTION_PLAN_TYPE_SUCCESS",
    payload,
  };
};

export const HandleSubscriptionPlanSubmit =
  (form_data, toggle, setErrors, setSubmitting) => (dispatch) => {
    dispatch(processingSubscriptionPlanRequest(true));

    const url = form_data.id
      ? allconstants.apiurl + "subscription_plan/" + form_data.id
      : allconstants.apiurl + "subscription_plan";

    const req_type = form_data.id
      ? allconstants.axios.put
      : allconstants.axios.post;
    form_data.amount = parseFloat(form_data.amount);
    req_type(url, form_data, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setSubmitting(false);
          if (form_data.id) {
            dispatch(SubscriptionPlanAddSuccess(response.data, true));
            dispatch(processingSubscriptionPlanRequest(false));
          } else {
            dispatch(SubscriptionPlanAddSuccess(response.data));
            dispatch(processingSubscriptionPlanRequest(false));
          }
          toggle();
        }
      })
      .catch((error) => {
        if (error.response) {
          setErrors(error.response.data.errors);
          dispatch(processingSubscriptionPlanRequest(false));
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Bad/No internet connection");
        }
      });
  };
export const HandleSubscriptionPlanDelete =
  (record_id, toggle) => (dispatch) => {
    const url = allconstants.apiurl + "subscription_plan/" + record_id;
    dispatch(processingSubscriptionPlanRequest(true));
    allconstants.axios
      .delete(url, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          dispatch(SubscriptionPlanDeleteSuccess(response.message, record_id));
          toggle();
          dispatch(processingSubscriptionPlanRequest(false));
        }
      })
      .catch((err_response) => {
        dispatch(processingSubscriptionPlanRequest(false));
        console.error(err_response);
      });

    return null;
  };
export const HandleSubscriptionPlanFetch =
  (url_id = null) =>
  (dispatch) => {
    const url = url_id
      ? allconstants.apiurl + "subscription_plan/" + url_id
      : allconstants.apiurl + "subscription_plan";
    allconstants.axios
      .get(url, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 200) {
          if (url_id) {
            dispatch(SubscriptionPlanFetchSuccess(response.data, true));
            dispatch(IsLoading(false));
          } else {
            dispatch(SubscriptionPlanFetchSuccess(response.data));
            dispatch(IsLoading(false));
          }
        }
      })
      .catch((err_response) => {
        dispatch(IsLoading(false));
        console.error(err_response);
      });

    return null;
  };

export const handleSubplanTypes = () => (dispatch) => {
  const url = allconstants.apiurl + "sub_plan_type";
  allconstants.axios
    .get(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 200) {
        dispatch(subscriptionPlanTypeFetch(response.data));
      }
    })
    .catch((err_response) => {
      console.error(err_response);
    });
};
