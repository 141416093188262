import Axios from "axios";
import allconstants from "../constants";

export const HandleCompanyDocumentInput = (e) => {
  const { value, name, files } = e.target ? e.target : e;
  if (typeof files === "undefined" || files === null) {
    return {
      type: "COMPANY_DOCUMENT_INPUT_CHANGE",
      changedField: { [name]: value },
    };
  } else {
    return {
      type: "COMPANY_DOCUMENT_INPUT_CHANGE",
      changedField: { doc_file: files[0] },
    };
  }
};

export const clearCompanyDocumentForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_COMPANY_DOCUMENT_FORM" };
};

export const CompanyDocumentFetchSuccess = (payload, msg) => {
  return { type: "FETCH_COMPANY_DOCUMENT_SUCCESS", payload };
};
export const CompanyDocumentAddSuccess = (new_record, status) => {
  if (status === 200) {
  } else {
  }

  return { type: "ADD_COMPANY_DOCUMENT_SUCCESS", new_record };
};
export const CompanyDocumentDeleteSuccess = (msg, record_id) => {
  return { type: "DELETE_COMPANY_DOCUMENT_SUCCESS", record_id };
};
export const CompanyDocumentReqError = (message) => {
  return { type: "COMPANY_DOCUMENT_ERROR" };
};

export const editCompanyDocument = (obj) => {
  return { type: "EDIT_COMPANY_DOCUMENT", current_obj: obj };
};

export const companyDocumentPreview = (obj) => {
  return { type: "PREVIEW_COMPANY_DOCUMENT", doc_data: obj };
};
export const HandleCompanyDocumentSubmit =
  (form_data, url_id) => (dispatch) => {
    const url = form_data.id
      ? allconstants.apiurl + "document/" + form_data.id
      : allconstants.apiurl + "company/" + url_id + "/document";
    const req_type = form_data.id ? Axios.put : Axios.post;
    const formData = new FormData();
    formData.append("document_type_id", form_data.document_type_id);
    formData.append("doc_file", form_data.doc_file);
    form_data.id && formData.append("_method", "PUT");
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    req_type(url, formData, config).then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(CompanyDocumentAddSuccess(response.data, response.status));
      }
    });
    return null;
  };
export const HandleApproval =
  (comment, approval_id, doc_id, url_id) => (dispatch) => {
    const url = allconstants.apiurl + "document/" + doc_id + "/approval";
    if (approval_id) {
      allconstants.axios
        .put(
          url,
          {
            approved: approval_id,
            comment: comment,
          },
          { headers: allconstants.headers }
        )
        .then((response) => {
          if (response.status === 200) {
            dispatch(HandleCompanyDocumentFetch(url_id));
            if (response.data.approved === 0) {
            } else {
            }
          } else {
          }
        })
        .catch((err) => {
          console.error(err);
          dispatch(CompanyDocumentReqError("Network/Server Error"));
        });
    }
  };
export const HandleCompanyDocumentDelete = (record_id) => (dispatch) => {
  const url = allconstants.apiurl + "document/" + record_id;
  allconstants.axios
    .delete(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(
          CompanyDocumentDeleteSuccess(response.data.message, record_id)
        );
      }
    })
    .catch((err) => {
      console.error(err);
      dispatch(CompanyDocumentReqError("Network/Server Error"));
    });
};
export const HandleCompanyDocumentFetch = (url_id) => (dispatch) => {
  const url = allconstants.apiurl + "company/" + url_id + "/document";
  allconstants.axios
    .get(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 200) {
        dispatch(CompanyDocumentFetchSuccess(response.data));
      }
    })
    .catch((err) => {
      console.error(err);
      dispatch(CompanyDocumentReqError("Network/Server Error"));
    });
};

export const HandleDocumentReview = (id) => (dispatch) => {
  const url = `${allconstants.apiurl}document/${id}`;
  allconstants.axios
    .get(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 200) {
        dispatch(companyDocumentPreview(response.data));
      }
    })
    .catch((err) => {
      console.error(err);
      dispatch(CompanyDocumentReqError("Network/Server Error"));
    });
};
