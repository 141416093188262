import React, { useEffect, useState } from "react";
import { HandleMerchantTicketFetch } from "../../../actions/merchant_support_ticket_action";
import { MerchantTicketTable } from "../merchant/merchant_details/support_tickets/merchant_ticket_table";
import { Select } from "../../../constants/constants";
import { Col, Row, Card, CardBody } from "reactstrap";
import { useDispatch } from "react-redux";

export const AllSupportTickets = () => {
  const dispatch = useDispatch();
  const [ticketState, setTicketState] = useState("");
  useEffect(() => {
    dispatch(HandleMerchantTicketFetch(false, true));
  }, [dispatch]);

  return (
    <>
      <Col md={9} sm={12} lg={10}>
        <Row>
          <Card className="bg-white">
            <CardBody>
              <Col md={12} className="p-0">
                <Row className="ml-2 my-2">
                  <Col lg={8} sm={12} md={8}>
                    <span className="header-bold-orange">Incoming Tickets</span>
                  </Col>

                  <Col lg={3}>
                    <Select
                      placeholder="--Check Status--"
                      name="transaction_status"
                      index_key="id"
                      label_key="status_name"
                      value={ticketState}
                      options={[
                        { id: 2, status_name: "Assigned to staff" },
                        { id: 5, status_name: "Cancelled" },
                        {
                          id: 1,
                          status_name: "	Received, pending assignment",
                        },
                        { id: 4, status_name: "Resolved" },
                      ]}
                      handleChange={(e) => {
                        setTicketState(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <MerchantTicketTable
                  ticketState={ticketState}
                  all_tickets={true}
                />
              </Col>
            </CardBody>
          </Card>
        </Row>
      </Col>
    </>
  );
};

export default AllSupportTickets;
