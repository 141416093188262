import allconstants from "../../../constants";

export const HandleContactTypeInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return { type: "CONTACT_TYPE_INPUT_CHANGE", changedField: { [name]: value } };
};

export const clearContactTypeForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_CONTACT_TYPE_FORM" };
};

export const IsLoading = (payload) => {
  return { type: "LOADING_CONTACT_TYPES", payload };
};
export const processingContactTypeRequest = (value) => {
  return { type: "CONTACT_TYPE_REQUEST_PROCESSING", value };
};
export const ContactTypeFetchSuccess = (payload, msg) => {
  return { type: "FETCH_CONTACT_TYPE_SUCCESS", payload };
};
export const ContactTypeAddSuccess = (new_record, update = false) => {
  if (update) {
  } else {
  }

  return { type: "ADD_CONTACT_TYPE_SUCCESS", new_record: new_record };
};
export const ContactTypeDeleteSuccess = (msg, record_id) => {
  return { type: "DELETE_CONTACT_TYPE_SUCCESS", record_id };
};
export const ContactTypeReqError = (msg) => {
  return { type: "CONTACT_TYPE_ERROR" };
};

export const editContactType = (obj) => {
  return { type: "EDIT_CONTACT_TYPE", obj };
};
export const HandleContactTyepSubmit =
  (form_data, toggle, setErrors, setSubmitting) => (dispatch) => {
    dispatch(processingContactTypeRequest(true));

    const url = form_data.id
      ? allconstants.apiurl + "contact_type/" + form_data.id
      : allconstants.apiurl + "contact_type";

    const req_type = form_data.id
      ? allconstants.axios.put
      : allconstants.axios.post;
    req_type(url, form_data, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setSubmitting(false);
          if (form_data.id) {
            dispatch(ContactTypeAddSuccess(response.data, true));
            dispatch(processingContactTypeRequest(false));
          } else {
            dispatch(ContactTypeAddSuccess(response.data));
            dispatch(processingContactTypeRequest(false));
          }
          toggle();
        }
      })
      .catch((error) => {
        dispatch(processingContactTypeRequest(false));
        if (error.response) {
          setErrors(error.response.data.errors);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Bad/No internet connection");
        }
      });

    return null;
  };
export const HandleContactTypeDelete = (record_id, toggle) => (dispatch) => {
  const url = allconstants.apiurl + "contact_type/" + record_id;
  dispatch(processingContactTypeRequest(true));
  allconstants.axios
    .delete(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(ContactTypeDeleteSuccess(response.message, record_id));
        dispatch(processingContactTypeRequest(false));
        toggle();
      }
    });

  return null;
};
export const HandleContactTypeFetch = () => (dispatch) => {
  const url = allconstants.apiurl + "contact_type";
  allconstants.axios
    .get(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 200) {
        dispatch(ContactTypeFetchSuccess(response.data));
        dispatch(IsLoading(false));
      }
    })
    .catch((error) => {
      if (error.response) {
        dispatch(IsLoading(false));
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Bad/No internet connection");
      }
    });
};
