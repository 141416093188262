import React, { useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Actions } from "../../../../../constants/constants";
import TblComp from "../../../../../react_table";
import conversation from "../../../../../assets/img/conversation.svg";
import TicketSupport from "./support_response";
import {
  editMerchantTicket,
  HandleMerchantTicketDelete,
  HandleChangeView,
} from "../../../../../actions/merchant_support_ticket_action";
import ExportCSV from "../../../../../constants/Exports/xlsExport";
import DeleteModal from "../../../../../constants/deleteModal";
import { Badge } from "reactstrap";

export const MerchantTicketTable = ({
  all_tickets = false,
  ticketStatus = "",
}) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState(false);
  const toggle = () => setModal(!modal);

  const response = useSelector(
    (state) => state.merchantTicketState.responseView,
    shallowEqual
  );
  const { merchant_ticket_list, loading_tickets, is_processing } = useSelector(
    (state) => state.merchantTicketState,
    shallowEqual
  );

  const FilterTickets = () =>
    ticketStatus && ticketStatus !== ""
      ? merchant_ticket_list.filter((ticket) => ticket.status === ticketStatus)
      : merchant_ticket_list;

  const headers = [
    "Id",
    "Ticket Name",
    "Merchant name",
    "Description",
    "Status",
    "Date Opened",
  ];

  const merchant_tickets = merchant_ticket_list.map(
    ({
      id,
      ticket_type,
      merchant,
      description,
      status_text,
      date_created,
    }) => ({
      id,
      ticket_type_name: ticket_type ? ticket_type.ticket_type_name : "",
      merchant: merchant ? merchant.full_name : "",
      description,
      status_text,
      date_created,
    })
  );

  if (all_tickets === true) {
    return (
      <>
        {response ? (
          <TicketSupport />
        ) : (
          <>
            <ExportCSV
              csvData={merchant_tickets || []}
              fileName="-Supportickets"
              headers={headers}
            />
            <TblComp
              columns={[
                {
                  id: "ticket_name", // Required because our accessor is not a string
                  Header: "Ticket Name",
                  maxWidth: 100,
                  accessor: "ticket_type",
                  Cell: (props) =>
                    props.value ? props.value.ticket_type_name : "",
                },
                {
                  id: "merchant", // Required because our accessor is not a string
                  Header: "Merchant name",
                  maxWidth: 100,
                  accessor: "merchant",
                  Cell: (props) =>
                    props.value
                      ? `${props.value.firstname} ${props.value.lastname}`
                      : "",
                },
                {
                  Header: "Message",
                  accessor: "description",
                  filter: "fuzzyText",
                },
                {
                  Header: "Status",
                  accessor: "status_text",
                  filter: "fuzzyText",
                },
                {
                  Header: "Date Opened",
                  accessor: "date_created",
                  filter: "fuzzyText",
                },
                {
                  id: "actions", // Required because our accessor is not a string
                  Header: "Actions",
                  maxWidth: 100,
                  accessor: (full) => (
                    <>
                      {
                        <a
                          title="Respond"
                          href={"#!"}
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(editMerchantTicket(full));
                            dispatch(HandleChangeView(true));
                          }}
                        >
                          <Badge color="success" className="mx-1">
                            <i className="fa fa-comment"></i>
                          </Badge>
                        </a>
                      }
                      <Actions
                        editHandler={(this_ticket) =>
                          dispatch(editMerchantTicket(this_ticket))
                        }
                        data_item={full}
                        link_url={""}
                        deleteHandler={(this_ticket_id) => [
                          toggle(),
                          setSelected(this_ticket_id.id),
                        ]}
                      />
                    </>
                  ),
                },
              ]}
              data={FilterTickets()}
              loading={loading_tickets}
            />
            <DeleteModal
              modal={modal}
              toggle={toggle}
              name="merchant ticket"
              is_processing={is_processing}
              handleDelete={() =>
                dispatch(HandleMerchantTicketDelete(selected, toggle))
              }
            />
          </>
        )}
      </>
    );
  } else {
    return (
      <>
        {response ? (
          <TicketSupport />
        ) : (
          <>
            <ExportCSV
              csvData={merchant_tickets || []}
              fileName="-Merchanttickets"
              headers={headers}
            />
            <TblComp
              columns={[
                {
                  id: "ticket_name", // Required because our accessor is not a string
                  Header: "Ticket Name",
                  maxWidth: 100,
                  accessor: "ticket_type",
                  Cell: (props) =>
                    props.value ? props.value.ticket_type_name : "",
                },
                {
                  Header: "Message",
                  accessor: "description",
                  filter: "fuzzyText",
                },
                {
                  Header: "Status",
                  accessor: "status_text",
                  filter: "fuzzyText",
                },
                {
                  Header: "Date Opened",
                  accessor: "date_created",
                  filter: "fuzzyText",
                },
                {
                  id: "actions", // Required because our accessor is not a string
                  Header: "Actions",
                  maxWidth: 100,
                  accessor: (full) => (
                    <>
                      {
                        <a
                          title="Respond"
                          href={"#!"}
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(editMerchantTicket(full));
                            dispatch(HandleChangeView(true));
                          }}
                        >
                          <span className="badge badge-success mx-1 btn badge-btn">
                            <img
                              src={conversation}
                              height="15px"
                              width="15px"
                              alt="failed to load"
                              className="ml-1"
                            />
                          </span>
                        </a>
                      }
                      <Actions
                        editHandler={(this_ticket) => {
                          dispatch(editMerchantTicket(this_ticket));
                        }}
                        data_item={full}
                        link_url={""}
                        modal_id="merchant_ticket-modal"
                        deleteHandler={(this_ticket_id) => [
                          toggle(),
                          setSelected(this_ticket_id.id),
                        ]}
                      />
                    </>
                  ),
                },
              ]}
              data={merchant_ticket_list}
              loading={loading_tickets}
            />
            <DeleteModal
              modal={modal}
              toggle={toggle}
              name="merchant ticket"
              is_processing={is_processing}
              handleDelete={() =>
                dispatch(HandleMerchantTicketDelete(selected, toggle))
              }
            />
          </>
        )}
      </>
    );
  }
};
