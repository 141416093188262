import React, { useState } from "react";
import { AddMerchant } from "./add_merchant";
import { MerchantTable } from "./merchant_table";

const Merchant = ({ match }) => {
  const [modalState, setModalState] = useState(false);
  const toggleState = (e) => setModalState(!modalState);

  const tableProps = { params: match.params, toggleState };

  return (
    <>
      <AddMerchant
        data-test="merchant-form"
        modalState={modalState}
        toggleState={toggleState}
      />
      <MerchantTable data-test="merchant-table" {...tableProps} />
    </>
  );
};
export default Merchant;
