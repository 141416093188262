// this is the form state
const userFormData = {
  id: "",
  fname: "",
  lname: "",
  pwd1: "",
  pwd2: "",
  country_id: "",
  email: "",
};
//this is the component
const userInitState = {
  redirectToMailSent: false,
  currentFormObj: userFormData,
};

export const userReducer = (prevState = userInitState, action) => {
  let new_state = {};
  switch (action.type) {
    case "USER_INPUT_CHANGE":
      const new_formState = {
        ...prevState.currentFormObj,
        ...action.changedField,
      };
      new_state = { ...prevState, currentFormObj: new_formState };
      break;
    case "CLEAR_USER_FORM":
      new_state = { ...prevState, currentFormObj: userFormData };
      break;
    case "ADD_USER_ERROR":
      new_state = { ...prevState, redirectToMailSent: false };
      break;
    case "ADD_USER_SUCCESS":
      new_state = {
        ...prevState,
        currentFormObj: userFormData,
        redirectToMailSent: true,
      };
      break;
    default:
      new_state = prevState;
  }
  return new_state;
};
