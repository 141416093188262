import React from "react";
import { Switch, Route } from "react-router-dom";
import ForgotPassword from "./forgotPassword";
import Login from "./login";
import { ResetAdminPassword } from "./resetPass";
import MailSuccess from "./mailSent";
export default function AuthRoutes() {
  return (
    <div>
      <Switch>
        <Route component={() => <Login />} path="/login" />
        <Route component={() => <ForgotPassword />} path="/forgot-password" />
        <Route
          component={() => <ResetAdminPassword />}
          path="/admin/reset_pwd/"
        />
        <Route component={() => <MailSuccess />} path="/mailsent" />
        <Route component={() => <Login />} />
      </Switch>
    </div>
  );
}
