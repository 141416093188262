import allconstants from "../../constants";

export const HandleexhangeRateInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return {
    type: "EXCHANGE_RATE_INPUT_CHANGE",
    changedField: { [name]: value },
  };
};

export const clearexhangeRateForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_EXCHANGE_RATE_FORM" };
};
export const IsLoading = (payload) => {
  return { type: "LOADING_EXC_RATES", payload };
};
export const processingExchangeRequest = (value) => {
  return { type: "EXCHANGE_REQUEST_PROCESSING", value };
};
export const exhangeRateFetchSuccess = (payload, msg) => {
  return { type: "FETCH_EXCHANGE_RATE_SUCCESS", payload };
};
export const exhangeRateAddSuccess = (payload) => {
  return { type: "CREATE_EXCHANGE_RATE_SUCCESS", new_exchange: payload };
};
export const exhangeRateReqError = (message) => {
  return { type: "EXCHANGE_RATE_ERROR" };
};
export const editexhangeRate = (obj) => {
  return { type: "EDIT_EXCHANGE_RATE", current_exchange_obj: obj };
};
export const exhangeRateDeleteSuccess = (msg, record_id) => {
  return { type: "DELETE_EXCHANGE_RATE_SUCCESS", record_id };
};

export const HandleexhangeRateSubmit =
  (form_data, toggle, setErrors, setSubmitting) => (dispatch) => {
    dispatch(processingExchangeRequest(true));

    const url = form_data.id
      ? allconstants.apiurl + "exchange_rate/" + form_data.id
      : allconstants.apiurl + "exchange_rate";
    //form_data.rate_amount = parseFloat(form_data.rate_amount.replace(",", ""));
    const req_type = form_data.id
      ? allconstants.axios.put
      : allconstants.axios.post;

    req_type(url, form_data, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setSubmitting(false);
          dispatch(exhangeRateAddSuccess(response.data));
          dispatch(processingExchangeRequest(false));
          toggle();
        } else {
          dispatch(
            exhangeRateReqError(
              "Whoops! Something went wrong, please try again"
            )
          );
          dispatch(processingExchangeRequest(false));
        }
      })
      .catch((error) => {
        if (error.response) {
          setErrors(error.response.data.errors);
          dispatch(processingExchangeRequest(false));
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Bad/No internet connection");
        }
      });

    return null;
  };

export const HandleexhangeRateFetch = () => (dispatch) => {
  const url = allconstants.apiurl + "exchange_rate";

  allconstants.axios
    .get(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(exhangeRateFetchSuccess(response.data));
        dispatch(IsLoading(false));
      } else {
        dispatch(
          exhangeRateReqError("Whoops! Something went wrong, please try again")
        );
      }
    })
    .catch((error) => {
      dispatch(IsLoading(false));
      dispatch(
        exhangeRateReqError("Whoops! Something went wrong, please try again")
      );
    });
  return null;
};
export const HandleexhangeRateDelete = (record_id, toggle) => (dispatch) => {
  const url = allconstants.apiurl + "exchange_rate/" + record_id;
  dispatch(processingExchangeRequest(true));
  allconstants.axios
    .delete(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(exhangeRateDeleteSuccess(response.message, record_id));
        dispatch(processingExchangeRequest(false));
        toggle();
      } else {
        dispatch(
          exhangeRateReqError("Whoops! Something went wrong, please try again")
        );
        dispatch(processingExchangeRequest(false));
      }
    })
    .catch((error) => {
      dispatch(processingExchangeRequest(false));
      dispatch(
        exhangeRateReqError("Whoops! Something went wrong, please try again")
      );
    });
  return null;
};
