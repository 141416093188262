import React, { useEffect } from "react";
import { DashCard } from "./dashCard";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { HandleAggregateTransactionFetch } from "../../../actions/transaction_action";
import { FetchAggregateMerchantData } from "../../../actions/merchant_action";
import { HandleMasterAccountFetch } from "../../../actions/merchantAccountAction";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import { currFormat } from "../../../constants/GenHelpers";

const DashboardHome = () => {
  const dispatch = useDispatch();

  const txnState = useSelector((state) => state.transaction, shallowEqual);
  const masterState = useSelector((state) => state.master, shallowEqual);
  const merchantState = useSelector(
    (state) => state.merchantState,
    shallowEqual
  );

  useEffect(() => {
    !merchantState.pending &&
      dispatch(FetchAggregateMerchantData({ status: "pending" }));
    !merchantState.all &&
      dispatch(FetchAggregateMerchantData({ status: "all" }));
    !merchantState.live &&
      dispatch(FetchAggregateMerchantData({ status: "live" }));
    !txnState.completed && dispatch(HandleAggregateTransactionFetch());
    !masterState.mtn_collection === 0 &&
      dispatch(HandleMasterAccountFetch("/collctn"));
    masterState.mtn_disbursement === 0 &&
      dispatch(HandleMasterAccountFetch("/paymt"));
    masterState.airtel_balance === 0 && dispatch(HandleMasterAccountFetch());
  }, [
    merchantState.pending,
    merchantState.all,
    merchantState.live,
    txnState.completed,
    dispatch,
    masterState.mtn_collection,
    masterState.mtn_disbursement,
    masterState.airtel_balance,
  ]);

  return (
    <>
      <Col sm="12" lg="10" md="9" className="pl-0">
        <Row className="mb-3">
          <Col sm="12" lg="4" md="12">
            <DashCard
              name="Merchants"
              status="Pending"
              status1={merchantState.pending}
              status2="Active"
              status3={merchantState.live}
              count={merchantState.all}
              color="card-counter primary"
              route="merchant"
              icon="fa fa-users"
            />
          </Col>

          <Col sm="12" lg="4" md="12">
            <DashCard
              name="Transactions"
              status="Pending"
              status2="Completed"
              status1={txnState.pending}
              status3={txnState.completed}
              count={txnState.all_transactions}
              color="card-counter danger"
              route="transactions"
              icon="fa fa-database"
            />
          </Col>

          <Col sm="12" lg="4" md="12">
            <DashCard
              name="Companies"
              status="Pending"
              status2="Active"
              status1="3"
              status3="4"
              count="11"
              color="card-counter success"
              route="#"
              icon="fa fa-user"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader>Airtel Balance</CardHeader>
              <CardBody>{currFormat(masterState.airtel_balance*1)}</CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardHeader>MTN Balance</CardHeader>
              <CardBody>
                <p>Collection: {currFormat(masterState.mtn_collection*1)}</p>
                <p>Disbursement: {currFormat(masterState.mtn_disbursement*1)}</p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default DashboardHome;
