import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Col, Row, Card, Button } from "reactstrap";
import { HandlePermissionsFetch } from "../../../../actions/permission_actions";
import {
  fetch_role_permissions,
  HandleRolesFetch,
} from "../../../../actions/user_roles_actions";
import { RolePermisionForm } from "./role_permissions";
import {
  saveRolePermissions,
  HandleRolesDelete,
  editRoles,
} from "../../../../actions/user_roles_actions";
import DeleteModal from "../../../../constants/deleteModal";
import { clearRolesForm } from "../../../../actions/user_roles_actions";

export const RolesTable = (props) => {
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState({});
  const toggle = () => setModal(!modal);
  const dispatch = useDispatch();
  const { is_processing, role_list } = useSelector(
    (state) => state.rolesState,
    shallowEqual
  );
  const all_permissions_length = useSelector(
    (state) => state.permissionState.permission_list.length,
    shallowEqual
  );
  const [activeRole, setActiveRole] = useState(1);
  const [permisions, setPermission] = useState([]);

  useEffect(() => {
    role_list.length === 0 && dispatch(HandleRolesFetch());
    all_permissions_length === 0 && dispatch(HandlePermissionsFetch());
    dispatch(fetch_role_permissions(activeRole));
  }, [all_permissions_length, activeRole, role_list.length, dispatch]);

  const handleClick = (id) => {
    dispatch(fetch_role_permissions(id));
    setActiveRole(id);
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    let p = permisions;

    if (checked) {
      p.push({ name: name });
    } else {
      p = permisions.filter((pm) => {
        return !(pm.name === name);
      });
    }
    setPermission(p);
  };
  const modules = [
    {
      option1: "merchant",
      option2: "merchants",
      module_name: "Merchants",
    },
    {
      option1: "subscription_plan",
      option2: "subscription_plans",
      module_name: "Subscription Plans",
    },
    {
      option1: "charge_rate",
      option2: "charge_rates",
      module_name: "Charge Rates",
    },
    {
      option1: "currency",
      option2: "currencies",
      module_name: "Currencies",
    },
    {
      option1: "product",
      option2: "products",
      module_name: "Products",
    },
    {
      option1: "serviceProvider",
      option2: "serviceProviders",
      module_name: "Service Providers",
    },
    {
      option1: "country",
      option2: "countries",
      module_name: "Countries",
    },
    {
      option1: "exchange_rate",
      option2: "exchange_rates",
      module_name: "Exchange Rates",
    },
    {
      option1: "role",
      option2: "roles",
      module_name: "Roles",
    },
    {
      option1: "permission",
      option2: "permissions",
      module_name: "Permissions",
    },
  ];
  return (
    <>
      <DeleteModal
        modal={modal}
        toggle={toggle}
        name="Role"
        is_processing={is_processing}
        handleDelete={() => dispatch(HandleRolesDelete(selected.id, setModal))}
      />
      <Button
        className="btn payment-btn my-3 ml-2"
        onClick={(e) => {
          dispatch(clearRolesForm(e));
          props.toggle();
        }}
      >
        Add Role
      </Button>
      <Card>
        <Row
          className="bg-white"
          style={{ boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px" }}
        >
          <Col sm={4} md={3} lg={3} xl={3}>
            <div className="roles-menu">
              <ul>
                {role_list.map((role) => (
                  <li
                    key={`role-perm-li-${role.id}`}
                    onClick={() => {
                      handleClick(role.id);
                    }}
                    className={
                      activeRole === role.id ? "active-role" : "inactive"
                    }
                  >
                    <p className="pointer-cursor ms-3">
                      {role.name}
                      <span className="role-action">
                        <Button
                          className="action-circle large"
                          size="sm"
                          color="link"
                          onClick={() => [
                            dispatch(editRoles(role)),
                            props.toggle(),
                          ]}
                        >
                          <i className="fa fa-edit delete-icon mr-2"></i>
                        </Button>
                        <Button
                          size="sm"
                          color="link"
                          className="action-circle large mx-3"
                          onClick={() => [toggle(), setSelected(role)]}
                        >
                          <i className="fa fa-trash delete-icon"></i>
                        </Button>
                      </span>
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
          <Col sm={12} md={9} lg={9} xl={9} className="card-scroll">
            {modules.map((module_data, idx) => (
              <RolePermisionForm
                handleChange={handleChange}
                {...module_data}
                key={`role-perm-form-${idx}`}
              />
            ))}

            <Button
              type="button"
              className="btn-pay btn  offset-sm-4 text-white w-25 mb-3"
              onClick={(e) => {
                e.preventDefault();
                if (permisions.length && activeRole != null) {
                  dispatch(saveRolePermissions(activeRole, permisions));
                } else {
                  // toastr.info(
                  //   "No permissions have been selected, please select the applicable permissions"
                  // );
                }
              }}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};
