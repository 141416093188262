import React from "react";
import { Nav, NavItem, Col, Card, CardHeader, CardBody } from "reactstrap";
import RolesComponent from "./user_details/roles";
import ProductComp from "./products/component";
import ServiceProvider from "./service_providers/index";
import { Types } from "./types/component";
import CountriesComp from "./countries/component";
import SubscriptionPlans from "./subscription_plan";
import ExchangeRateComp from "./exchange/component";
import CurrencyComp from "./currency/component";
import { NavLink, Route, Switch } from "react-router-dom";
import PermissionComp from "./user_details/permissions";
import RolePermissionComp from "./user_details/role_permissions";
import { decData } from "../../../constants/constants";
import { checkInArray } from "../../../constants/GenHelpers";
export const Settings = ({ location }) => {
  const user_permissions = decData(sessionStorage.getItem("profile"))
    ? decData(sessionStorage.getItem("profile")).roles[0].permissions
    : [];
  const parent_url = "/settings";
  const url = location.pathname.toString().split("/");
  const cur_location = url.length > 2 ? url[2] : url[1];
  return (
    <Col md={9} sm={12} lg={10}>
      <Card className="bg-white">
        <CardHeader>
          <Nav pills>
            {checkInArray( user_permissions, "view-subscription_plans","name") && (
              <NavItem>
                <NavLink
                  className="nav-item nav-link"
                  to={`${parent_url}/subscription_plans`}
                >
                  <span
                    className={
                      "btn mb-1 " +
                      (cur_location === "subscription_plans" ||
                      cur_location === "settings"
                        ? "ticket-tab-active"
                        : "")
                    }
                  >
                    Subscription Plans
                  </span>
                </NavLink>
              </NavItem>
            )}

            {checkInArray(user_permissions, "view-products","name") && (
              <NavItem>
                <NavLink
                  className="nav-item nav-link"
                  to={`${parent_url}/products`}
                >
                  <span
                    className={
                      "btn mb-1 " +
                      (cur_location === "products" ? "ticket-tab-active" : "")
                    }
                  >
                    Products
                  </span>
                </NavLink>
              </NavItem>
            )}

            {checkInArray(user_permissions, "view-service_providers","name") && (
              <NavItem>
                <NavLink
                  className="nav-item nav-link"
                  to={`${parent_url}/service_providers`}
                >
                  <span
                    className={
                      "btn mb-1 " +
                      (cur_location === "service_providers"
                        ? "ticket-tab-active"
                        : "")
                    }
                  >
                    Service Providers
                  </span>
                </NavLink>
              </NavItem>
            )}

            {(checkInArray(user_permissions, "view-countries","name") ||
              checkInArray(user_permissions, "view-country","name")) && (
              <NavItem>
                <NavLink
                  className="nav-item nav-link"
                  to={`${parent_url}/countries`}
                >
                  <span
                    className={
                      "btn mb-1 " +
                      (cur_location === "countries" ? "ticket-tab-active" : "")
                    }
                  >
                    Countries
                  </span>
                </NavLink>
              </NavItem>
            )}

            {(checkInArray(user_permissions, "view-exchange_rate","name") ||
              checkInArray(user_permissions, "view-exchange_rates","name")) && (
              <NavItem>
                <NavLink
                  className="nav-item nav-link"
                  to={`${parent_url}/exchange_rate`}
                >
                  <span
                    className={
                      "btn mb-1 " +
                      (cur_location === "exchange_rate"
                        ? "ticket-tab-active"
                        : "")
                    }
                  >
                    Exchange Rate
                  </span>
                </NavLink>
              </NavItem>
            )}

            {(checkInArray(user_permissions, "view-currencies","name") ||
              checkInArray(user_permissions, "view-currency","name")) && (
              <NavItem>
                <NavLink
                  className="nav-item nav-link"
                  to={`${parent_url}/currency`}
                >
                  <span
                    className={
                      "btn mb-1 " +
                      (cur_location === "currency" ? "ticket-tab-active" : "")
                    }
                  >
                    Currency
                  </span>
                </NavLink>
              </NavItem>
            )}

            {(checkInArray(user_permissions, "view-document_types","name") ||
              checkInArray(user_permissions, "view-contact_types","name")) && (
              <NavItem>
                <NavLink
                  className="nav-item nav-link"
                  to={`${parent_url}/types`}
                >
                  <span
                    className={
                      "btn mb-1 " +
                      (cur_location === "types" ? "ticket-tab-active" : "")
                    }
                  >
                    Types
                  </span>
                </NavLink>
              </NavItem>
            )}

            {checkInArray(user_permissions, "view-roles","name") && (
              <NavItem>
                <NavLink
                  className="nav-item nav-link"
                  to={`${parent_url}/roles`}
                >
                  <span
                    className={
                      "btn mb-1 " +
                      (cur_location === "roles" ? "ticket-tab-active" : "")
                    }
                  >
                    Roles
                  </span>
                </NavLink>
              </NavItem>
            )}
            {checkInArray(user_permissions, "view-permissions","name") && (
              <NavItem>
                <NavLink
                  className="nav-item nav-link"
                  to={`${parent_url}/permissions`}
                >
                  <span
                    className={
                      "btn mb-1 " +
                      (cur_location === "permissions" ||
                      cur_location === "role_permissions"
                        ? "ticket-tab-active"
                        : "")
                    }
                  >
                    Permissions
                  </span>
                </NavLink>
              </NavItem>
            )}
          </Nav>
        </CardHeader>
        <CardBody>
          <Switch>
            {checkInArray(user_permissions, "view-roles","name") && (
              <Route path={`${parent_url}/roles`} component={RolesComponent} />
            )}
            {checkInArray(
              user_permissions,
              "view-subscription_plans","name") && (
              <Route
                path={`${parent_url}/subscription_plans`}
                component={SubscriptionPlans}
              />
            )}
            {checkInArray(user_permissions, "view-products","name") && (
              <Route path={`${parent_url}/products`} component={ProductComp} />
            )}
            {checkInArray(user_permissions, "view-service_providers","name") && (
              <Route
                path={`${parent_url}/service_providers`}
                component={ServiceProvider}
              />
            )}
            {checkInArray(user_permissions, "view-countries","name") && (
              <Route
                path={`${parent_url}/countries`}
                component={CountriesComp}
              />
            )}
            {(checkInArray(user_permissions, "view-exchange_rate","name") ||
              checkInArray(user_permissions, "view-exchange_rates","name")) && (
              <Route
                path={`${parent_url}/exchange_rate`}
                component={ExchangeRateComp}
              />
            )}
            {checkInArray(user_permissions, "view-currencies","name") && (
              <Route path={`${parent_url}/currency`} component={CurrencyComp} />
            )}
            {checkInArray(user_permissions, "view-permissions","name") && (
              <Route
                path={`${parent_url}/permissions`}
                component={PermissionComp}
              />
            )}
            {checkInArray(user_permissions, "view-permissions","name") && (
              <Route
                path={`${parent_url}/role_permissions/:id`}
                component={RolePermissionComp}
              />
            )}
            {(checkInArray(user_permissions, "view-document_types","name") ||
              checkInArray(user_permissions, "view-contact_types","name")) && (
              <Route path={`${parent_url}/types`} component={Types} />
            )}
          </Switch>
        </CardBody>
      </Card>
    </Col>
  );
};
