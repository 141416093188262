import React, { useState } from "react";
import { Actions } from "../../../../../constants/constants";
import TblComp from "../../../../../react_table";
import {
  editCompany,
  HandleCompanyDelete,
} from "../../../../../actions/company_action";
import { Badge } from "reactstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { currFormat } from "../../../../../constants/GenHelpers";
import ExportCSV from "../../../../../constants/Exports/xlsExport";
import DeleteModal from "../../../../../constants/deleteModal";

const Table = ({ fetchAll }) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState(false);
  const toggle = () => setModal(!modal);
  const { company_list, loading_business, is_processing } = useSelector(
    (state) => state.companyState,
    shallowEqual
  );
  const StatusChange = (status_id, status_text) => {
    let color = "warning";
    if (status_id === "pending_review" || status_id === "awaiting_changes") {
      color = "primary";
    } else if (status_id === "rejected") {
      color = "danger";
    } else if (status_id === "accepted") {
      color = "success";
    } else if (status_id === "approved") {
      color = "info";
    }
    return (
      <Badge color={color} pill>
        {status_text}
      </Badge>
    );
  };

  const headers = [
    "Id",
    "Owner",
    "Owner Phone",
    "Owner Email",
    "Company Name",
    "Company Type",
    "Balance",
    "Address",
    "Status",
  ];

  const business = company_list.map(
    ({
      id,
      merchant,
      company_name,
      company_type,
      default_company_account,
      address,
      status_text,
    }) => ({
      id,
      lastname: merchant ? merchant.full_name : "",
      phone: merchant ? merchant.phone_number : "",
      email: merchant ? merchant.email : "",
      company_name,
      company_type,
      balance: default_company_account ? default_company_account.balance : "",
      address,
      status_text,
    })
  );

  const merchantCols = [
    {
      id: "owner_names",
      Header: "Owner",
      accessor: "merchant",
      Cell: ({ value }) => value && value.full_name,
      filter: "fuzzyText",
    },
    {
      id: "owner_phone",
      Header: "Owner Phone",
      accessor: "merchant",
      Cell: ({ value }) => value && value.phone_number,
      filter: "fuzzyText",
    },
    {
      id: "owner_email",
      Header: "Owner Email",
      accessor: "merchant",
      Cell: ({ value }) => value && value.email,
      filter: "fuzzyText",
    },
  ];
  const columns = [
    {
      Header: "Company Name",
      accessor: "company_name",
      filter: "fuzzyText",
    },
    {
      Header: "Company Type",
      accessor: "company_type",
      filter: "fuzzyText",
    },
    {
      id: "balance",
      Header: "Balance",
      accessor: "default_company_account",
      Cell: ({ value }) => (value ? currFormat(value.balance * 1) : ""),
      filter: "fuzzyText",
    },
    { Header: "Address", accessor: "address", filter: "fuzzyText" },
    {
      id: "status",
      Header: "Status",
      accessor: (data) => StatusChange(data.status, data.status_text),
      filter: "fuzzyText",
    },
    {
      id: "actions", // Required because our accessor is not a string
      Header: "Action",
      maxWidth: 150,
      accessor: (full) => (
        <div className="d-flex">
          <Actions
            editHandler={(selected_row) => dispatch(editCompany(selected_row))}
            data_item={full}
            link_url={`company/`}
            deleteHandler={(selected_id) => [
              toggle(),
              setSelected(selected_id.id),
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <ExportCSV
        csvData={business || []}
        fileName="businesses"
        headers={headers}
      />
      <TblComp
        loading={loading_business}
        columns={fetchAll ? [...merchantCols, ...columns] : columns}
        data={company_list || []}
      />
      <DeleteModal
        modal={modal}
        toggle={toggle}
        name="company"
        handleDelete={() => dispatch(HandleCompanyDelete(selected, toggle))}
        is_processing={is_processing}
      />
    </>
  );
};
export default Table;
