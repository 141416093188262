import React, { useState, useRef } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Button, Col, Container, Row, FormGroup } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { HandleTransactionTypeSubmit } from "../../../../../actions/settings/types/transaction_type_actions";
import { TextField, CustomModal } from "../../../../../constants";

const AddTransactionType = (props) => {
  const dispatch = useDispatch();
  const { is_processing, form_state } = useSelector(
    (state) => state.transactiontypereducer,
    shallowEqual
  );
  const { modal, toggle } = props;

  const formikRef = useRef();

  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

  const validate = Yup.object({
    title: Yup.string().required("Title  is required"),
    description: Yup.string().required("Description is required"),
  });

  return (
    <CustomModal
      modal={modal}
      toggle={toggle}
      tittle={form_state?.id ? "Edit Transaction Type" : "Add Transaction Type"}
      is_processing={is_processing}
    >
      <Formik
        enableReinitialize
        initialValues={{
          title: form_state.title,
          description: form_state.description,
        }}
        validationSchema={validate}
        validateOnChange={validateAfterSubmit}
        validateOnBlur={true}
        ref={formikRef}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          setValidateAfterSubmit(true);
          dispatch(
            HandleTransactionTypeSubmit(
              values,
              toggle,
              setErrors,
              setSubmitting
            )
          );
        }}
      >
        {(formik) => (
          <Container>
            <Form>
              <FormGroup>
                <Row className="mt-3 ml-1">
                  <Col lg={12} md={12} sm={12} className="my-2">
                    <TextField
                      name="title"
                      type="text"
                      value={formik.values.title}
                      placeholder="Transaction type"
                    />
                  </Col>
                  <Col lg={12} md={12} sm={12} className="my-2">
                    <TextField
                      name="description"
                      type="text"
                      value={formik.values.description}
                      placeholder="Description"
                    />
                  </Col>

                  <Col lg={12} md={12} sm={12} className="mt-3">
                    <Button
                      type="submit"
                      className="btn btn-pay btn-block text-white"
                      block
                      disabled={is_processing}
                    >
                      {form_state?.id
                        ? is_processing
                          ? "... Updating"
                          : "Update"
                        : is_processing
                        ? "..Saving"
                        : "Save"}
                    </Button>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </Container>
        )}
      </Formik>
    </CustomModal>
  );
};
export default AddTransactionType;
