import allconstants from "../../../constants/index";

export const HandleServiceProviderCountryInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return {
    type: "SERVICE_PROVIDER_COUNTRY_INPUT_CHANGE",
    changedField: { [name]: value },
  };
};

export const clearServiceProviderCountryForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_SERVICE_PROVIDER_COUNTRY_FORM" };
};

export const processingServiceProviderCountryRequest = (value) => {
  return { type: "PROVIDER_COUNTRY_REQUEST_PROCESSING", value };
};

export const ServiceProviderCountryFetchSuccess = (payload, msg) => {
  return { type: "FETCH_SERVICE_PROVIDER_COUNTRY_SUCCESS", payload };
};

export const ServiceProviderCountryAddSuccess = (new_record) => {
  return { type: "ADD_SERVICE_PROVIDER_COUNTRY_SUCCESS", new_record };
};

export const ServiceProviderCountryDeleteSuccess = (msg, record_id) => {
  return { type: "DELETE_SERVICE_PROVIDER_COUNTRY_SUCCESS", record_id };
};
export const ServiceProviderCountryReqError = (msg) => {
  return { type: "SERVICE_PROVIDER_COUNTRY_ERROR" };
};

export const editServiceProviderCountry = (obj) => {
  return {
    type: "EDIT_SERVICE_PROVIDER_COUNTRY",
    current_service_provider_country_obj: obj,
  };
};

export const HandleServiceProviderCountrySubmit =
  (form_data, id, toggle, setErrors, setSubmitting) => (dispatch) => {
    dispatch(processingServiceProviderCountryRequest(true));

    const url = form_data.id
      ? allconstants.apiurl + "sp_country/" + form_data.id
      : allconstants.apiurl + "service_provider/" + id + "/country";

    const req_type = form_data.id
      ? allconstants.axios.put
      : allconstants.axios.post;
    const data = !form_data.id
      ? {
          sp_countries: [
            {
              country_id: form_data.country_id,
              spc_code: form_data.spc_code,
              no_format: form_data.no_format,
              currency_id: form_data.currency_id,
            },
          ],
        }
      : {
          country_id: form_data.country_id,
          spc_code: form_data.spc_code,
          no_format: form_data.no_format,
          currency_id: form_data.currency_id,
        };

    req_type(url, data, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          dispatch(ServiceProviderCountryAddSuccess(response.data));
          dispatch(processingServiceProviderCountryRequest(false));
          toggle();
          setSubmitting(false);
        } else {
          dispatch(
            ServiceProviderCountryReqError(
              "Opps! something went wrong try again"
            )
          );
          dispatch(processingServiceProviderCountryRequest(false));
        }
      })
      .catch((error) => {
        if (error.response) {
          setErrors(error.response.data.errors);
          dispatch(processingServiceProviderCountryRequest(false));
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Bad/No internet connection");
        }
      });
  };
export const HandleServiceProviderCountryDelete =
  (record_id, toggle) => (dispatch) => {
    dispatch(processingServiceProviderCountryRequest(true));
    const url = allconstants.apiurl + "sp_country/" + record_id;
    allconstants.axios
      .delete(url, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          dispatch(
            ServiceProviderCountryDeleteSuccess(
              response.data.message,
              record_id
            )
          );
          dispatch(processingServiceProviderCountryRequest(false));
          toggle();
        } else if (response.status === 401) {
          dispatch(
            ServiceProviderCountryReqError(
              "The record dosent exist in the system"
            )
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          dispatch(processingServiceProviderCountryRequest(false));
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Bad/No internet connection");
        }
      });
    return null;
  };
export const HandleServiceProviderCountryFetch =
  (provider_id) => (dispatch) => {
    const url =
      allconstants.apiurl + "service_provider/" + provider_id + "/country";
    allconstants.axios
      .get(url, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 200) {
          dispatch(ServiceProviderCountryFetchSuccess(response.data));
        }
      });
    return null;
  };
