import React from "react";
import { Button } from "reactstrap";

const View_document = (props) => {
  const { current_merchant_ticket_obj: ticket } = props;

  return (
    <div>
      <div
        className="modal fade"
        id="viewDoc"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="modelTitleId"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Document</h5>
              <Button aria-label="Close">
                {/* <span className="close-modal" aria-hidden="true">&times;</span> */}
                <img
                  alt=""
                  src="../../../images/close-modal.svg"
                  style={{ height: 15, width: 15 }}
                />
              </Button>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                <iframe
                  title="This is a unique title"
                  src={`${ticket?.file_path}`}
                  width="100%"
                  height="500rem"
                  frameborder="0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View_document;
