import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Select } from "../../../constants/constants";
import { FetchMerchantCompanies } from "../../../actions/company_action";
import { HandleMerchantFetch } from "../../../actions/merchant_action";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Container,
  FormGroup,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import {
  HandleTransactionInput,
  HandleTransactionSubmit,
} from "../../../actions/transaction_action";
import Loader from "../../../constants/loader";

const AddTransaction = (props) => {
  const dispatch = useDispatch();
  const { requesting, transaction } = useSelector(
    (state) => state.transaction,
    shallowEqual
  );
  const companyState = useSelector((state) => state.companyState, shallowEqual);
  const merchantState = useSelector(
    (state) => state.merchantState,
    shallowEqual
  );

  const { modal, toggle } = props;
  const [merchant_id, setMerchant] = useState(0);
  const [company_id, setCompany] = useState(0);
  const [company_account_id, setCompanyAccount] = useState(0);

  useEffect(() => {
    merchantState?.merchant_list.length === 0 &&
      dispatch(HandleMerchantFetch({ status: "live" }));
  }, [merchantState.merchant_list,dispatch]);

  useEffect(() => {
    merchant_id &&
      dispatch(FetchMerchantCompanies(merchant_id, { status: "approved" }));
  }, [merchant_id,dispatch]);

  useEffect(() => {
    if (company_id) {
      const selected_company = companyState?.company_list.find(
        (company) => (company.id = company_id)
      );
      typeof selected_company !== "undefined" &&
        typeof selected_company?.default_company_account !== "undefined" &&
        setCompanyAccount(selected_company?.default_company_account?.id);
    }
  }, [company_id,dispatch,companyState?.company_list]);

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h4 className="modal-title-border font-weight-bold modal-header-txt ml-5 mt-2">
            Top-Up Merchant Account
          </h4>
        </ModalHeader>
        {requesting ? (
          <div className="loader-overlay">
            <Loader isModal />
          </div>
        ) : (
          <></>
        )}
        <ModalBody>
          <Container>
            <Form
              autoComplete="off"
              method="post"
              onSubmit={(e) => {
                e.preventDefault();
                company_account_id &&
                  dispatch(
                    HandleTransactionSubmit(
                      transaction,
                      company_account_id,
                      toggle
                    )
                  );
              }}
            >
              <FormGroup>
                <Row className="mt-3">
                  <Col lg={12} md={12} sm={12} className="my-2">
                    <label htmlFor="merchant_id">Merchant</label>
                    <Select
                      name="merchant_id"
                      index_key="id"
                      label_key="firstname"
                      value={merchant_id}
                      options={merchantState.merchant_list}
                      handleChange={(e) => {
                        e.preventDefault();
                        e.target.value && setMerchant(e.target.value);
                        // e.target.value && setCompany(0);
                      }}
                    />
                  </Col>
                  <Col lg={12} md={12} sm={12} className="mb-2">
                    <label htmlFor="">Company</label>
                    <Select
                      name="company_id"
                      index_key="id"
                      label_key="company_name"
                      value={company_id}
                      options={companyState.company_list}
                      handleChange={(e) => {
                        e.preventDefault();
                        e.target.value && setCompany(e.target.value);
                      }}
                    />
                  </Col>
                  <Col lg={12} md={12} sm={12} className="mb-2">
                    <Label htmlFor="transaction_date">Txn Date</Label>
                    <Input
                      type="date"
                      id="transaction_date"
                      name="transaction_date"
                      placeholder="Date"
                      className=" form-control"
                      value={transaction.transaction_date}
                      max={new Date().toISOString().split("T")[0]}
                      onChange={(e) => dispatch(HandleTransactionInput(e))}
                    />
                  </Col>
                  <Col lg={12} md={12} sm={12} className="mb-2">
                    <Label htmlFor="credit">Amount</Label>
                    <Input
                      type="number"
                      id="credit"
                      name="credit"
                      placeholder="Amount"
                      className=" form-control"
                      value={transaction.credit}
                      min="0"
                      onChange={(e) => dispatch(HandleTransactionInput(e))}
                    />
                  </Col>
                  <Col lg={12} md={12} sm={12} className="mb-2">
                    <Label htmlFor="description">Narration</Label>
                    <textarea
                      type="text"
                      id="description"
                      name="description"
                      placeholder="Narration"
                      className=" form-control "
                      value={transaction.description}
                      onChange={(e) => dispatch(HandleTransactionInput(e))}
                    ></textarea>
                  </Col>
                </Row>
              </FormGroup>
              <Col lg={12} md={12} sm={12} className="mt-2">
                <Button
                  type="submit"
                  className="btn btn-pay btn-block shadow-none text-white"
                  disabled={requesting}
                  block
                >
                  <span className="pay-btn-text">
                    {" "}
                    {requesting ? "... Saving" : "Save"}
                  </span>
                </Button>
              </Col>
            </Form>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AddTransaction;
