const MerchantFormState = {
  id: "",
  firstname: "",
  lastname: "",
  othernames: "",
  address: "",
  password: "",
  password_confirmation: "",
  email: "",
  phone_number: "",
  gender: "",
};
const MerchantInitState = {
  merchant_list: [],
  current_merchant_obj: MerchantFormState,
  is_processing: false,
  pending: "",
  live: "",
  loading_merchants: false,
};

export const MerchantReducer = (prevState = MerchantInitState, action) => {
  const form_state = prevState.current_merchant_obj;
  let new_formState;
  switch (action.type) {
    case "MERCHANT_ERROR":
      return { ...prevState };
    case "LOADING_MERCHANTS":
      return { ...prevState, loading_merchants: action.payload };
    case "FETCH_MERCHANT_SUCCESS":
      const { total, last_page: pageCount } = action.payload;
      return {
        ...prevState,
        total,
        pageCount,
        merchant_list: action.payload.data,
      };
    case "FETCH_AGGREGATE_PENDING_MERCHANT_SUCCESS":
      return {
        ...prevState,
        pending: action.payload,
      };
    case "FETCH_AGGREGATE_LIVE_MERCHANT_SUCCESS":
      return {
        ...prevState,
        live: action.payload,
      };
    case "FETCH_ONE_MERCHANT_SUCCESS":
      return {
        ...prevState,
        current_merchant_obj: action.merchant,
      };
    case "MERCHANT_INPUT_CHANGE":
      new_formState = { ...form_state, ...action.changedField };
      return { ...prevState, current_merchant_obj: new_formState };
    case "CLEAR_MERCHANT_FORM":
      return { ...prevState, current_merchant_obj: MerchantFormState };
    case "EDIT_MERCHANT":
      new_formState = { ...action.current_merchant_obj };
      return { ...prevState, current_merchant_obj: new_formState };
    case "DELETE_MERCHANT_SUCCESS":
      let new_list = prevState.merchant_list.filter(
        (current_rec) => current_rec.id !== action.record_id
      );
      return { ...prevState, merchant_list: new_list };
    case "ADD_MERCHANT_SUCCESS":
      let merchant_new = true;
      let merchant_list = prevState.merchant_list.map((merchant) => {
        if (merchant.id === action.new_record.id) {
          merchant_new = false;
          return action.new_record;
        } else {
          return merchant;
        }
      });
      merchant_new && merchant_list.push(action.new_record);
      const new_state = { ...prevState, merchant_list };
      if (merchant_new) {
        new_state.current_merchant_obj = MerchantFormState;
      }
      return new_state;

    case "MERCHANT_REQUEST_PROCESSING":
      return { ...prevState, is_processing: action.value };

    default:
      return prevState;
  }
};
