import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Actions } from "../../../../../constants/constants";
import TblComp from "../../../../../react_table";
import { Button } from "reactstrap";
import ExportCSV from "../../../../../constants/Exports/xlsExport";
import {
  HandleSPC_ProductFetch,
  spc_editProduct,
  HandleSPC_ProductDelete,
} from "../../../../../actions/settings/service_provider/products_actions";
import { clearspc_ProductForm } from "../../../../../actions/settings/service_provider/products_actions";
import DeleteModal from "../../../../../constants/deleteModal";

export const SPCProductsTable = (props) => {
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState(false);
  const toggle = () => setModal(!modal);
  const dispatch = useDispatch();
  const spcProductState = useSelector(
    (state) => state.spcProductsState,
    shallowEqual
  );
  useEffect(() => {
    spcProductState.spc_product_list.length === 0 &&
      dispatch(HandleSPC_ProductFetch(props.spc_id));
  }, [dispatch, props.spc_id, spcProductState.spc_product_list.length]);

  const headers = ["Id", "Product Name", "Description"];

  return (
    <>
      <DeleteModal
        modal={modal}
        toggle={toggle}
        name="Service Provider Country Product"
        handleDelete={() => dispatch(HandleSPC_ProductDelete(selected))}
      />
      <Button
        className="btn ticket-btn my-3 text-white"
        onClick={(e) => {
          dispatch(clearspc_ProductForm(e));
          props.toggle();
        }}
      >
        Add Product
      </Button>

      <ExportCSV
        csvData={spcProductState.spc_product_list}
        fileName="serviceproviderproduct"
        headers={headers}
      />

      <TblComp
        columns={[
          {
            Header: "Product Name",
            accessor: (props) => <>{props.product.product_name}</>,
            filter: "fuzzyText",
          },
          {
            Header: "Description",
            accessor: (props) => <>{props.product.description}/</>,
            filter: "fuzzyText",
          },
          {
            id: "actions", // Required because our accessor is not a string
            Header: "Action",
            maxWidth: 100,
            accessor: (full) => (
              <Actions
                editHandler={(selected_row) => [
                  dispatch(spc_editProduct(selected_row)),
                  props.toggle(),
                ]}
                data_item={full}
                link_url={""}
                modal_id="service-provider-product-modal"
                deleteHandler={(selected_id) => [
                  toggle(),
                  setSelected(selected_id.id),
                ]}
              />
            ),
          },
        ]}
        data={spcProductState.spc_product_list}
      />
    </>
  );
};
