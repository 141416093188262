import React from "react";
import { NavLink, Route, Switch } from "react-router-dom";
import { Nav, NavItem, Card, CardBody, Col, CardHeader } from "reactstrap";
import FiredUpMerchantComp from "./component";
import MerchantDetails from "./merchant_details";

export const MerchantRoutes = () => {
  const parent_url = "/merchant";

  return (
    <Col md={9} sm={12} lg={10}>
      <Card className="bg-white">
        <CardHeader>
          <Nav pills>
            <NavItem>
              <NavLink activeClassName="ticket-tab-active" className="nav-item nav-link" to={`${parent_url}/live`}>
                <span className={"btn mb-1"} >
                  Live
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink activeClassName="ticket-tab-active" className="nav-item nav-link" to={`${parent_url}/new`}>
                <span className={"btn mb-1"} >
                  New{" "}
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink activeClassName="ticket-tab-active" className="nav-item nav-link" to={`${parent_url}/pending`}>
                <span className={"btn mb-1"} >
                  Pending
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink activeClassName="ticket-tab-active" className="nav-item nav-link" to={`${parent_url}/deleted`}>
                <span className={"btn mb-1"} >
                  Deleted
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink activeClassName="ticket-tab-active" className="nav-item nav-link" to={`${parent_url}/all`}>
                <span className={"btn mb-1"} >
                  All Merchants{" "}
                </span>
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody>
          <Switch>
            <Route path={`${parent_url}/detail/:id`} component={MerchantDetails} />
            <Route path={`${parent_url}/:status`} exact component={FiredUpMerchantComp} />
            <Route path={`${parent_url}`} component={FiredUpMerchantComp} />
          </Switch>
        </CardBody>
      </Card>
    </Col>
  );
};
