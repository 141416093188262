import React, { useCallback, useRef } from "react";
import { HandleWithdrawFetch } from "../../../../actions/liquidation_request_actions";
import { WithdrawsTable } from "./withdrawsTable";
import { useDispatch } from "react-redux";
import { Col, Card, CardBody, Button, Row } from "reactstrap";

const WithdrawRequests = (props) => {
  const dispatch = useDispatch();

  const transaction_status = "";
  const fetchIdRef = useRef(0);

  const fetchData = useCallback(
    (pageOptions) => {
      const fetchId = ++fetchIdRef.current;
      if (fetchId === fetchIdRef.current) {
        dispatch(
          HandleWithdrawFetch({
            ...pageOptions,
            status: transaction_status || null,
          })
        );
      }
    },
    [transaction_status,dispatch]
  );

  return (
    <>
      <Row className="pb-1">
        <Col md={12} className="ps-0">
          <Card className="bg-white">
            <CardBody>
              <Button
                className="btn payment-btn my-3 ms-2 me-4 shadow-none"
                onClick={() => {
                  fetchData({ page: 2, perPage: 10 });
                }}
                disabled={props.requesting}
              >
                {props.requesting ? "Loading..." : "Refresh"}
              </Button>

              {<WithdrawsTable fetchData={fetchData} />}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default WithdrawRequests;
