import allconstants from "../../../constants/index";
export const HandleproviderInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return { type: "PROVIDER_INPUT_CHANGE", changedField: { [name]: value } };
};

export const clearproviderForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_PROVIDER_FORM" };
};
export const IsLoading = (payload) => {
  return { type: "LOADING_PROVIDERS", payload };
};

export const providerFetchSuccess = (payload, single_record = false) => {
  return { type: "FETCH_PROVIDER_SUCCESS", payload, single_record };
};
export const processingServiceProviderRequest = (value) => {
  return { type: "PROVIDER_REQUEST_PROCESSING", value };
};

export const providerAddSuccess = (payload, update = false) => {
  if (update) {
  } else {
  }
  return { type: "ADD_PROVIDER_SUCCESS", new_provider: payload };
};
export const providerReqError = (msg) => {
  return { type: "PROVIDER_ERROR" };
};

export const HandleproviderSubmit =
  (form_data, toggle, setErrors, setSubmitting) => (dispatch) => {
    dispatch(processingServiceProviderRequest(true));

    const url = form_data.id
      ? allconstants.apiurl + "service_provider/" + form_data.id
      : allconstants.apiurl + "service_provider";
    const req_type = form_data.id
      ? allconstants.axios.put
      : allconstants.axios.post;
    req_type(url, form_data, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setSubmitting(false);
          if (form_data.id) {
            dispatch(providerAddSuccess(response.data, true));
            dispatch(processingServiceProviderRequest(false));
          } else {
            dispatch(providerAddSuccess(response.data));
            dispatch(processingServiceProviderRequest(false));
          }
          toggle();
        }
      })

      .catch((error) => {
        dispatch(processingServiceProviderRequest(false));
        setSubmitting(false);
        if (error.response) {
          setErrors(error.response.data.errors);
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Bad/No internet connection");
        }
      });

    return null;
  };
export const HandleProvidersFetch =
  (url_id = null) =>
  (dispatch) => {
    const url = url_id
      ? allconstants.apiurl + "service_provider/" + url_id
      : allconstants.apiurl + "service_provider";
    allconstants.axios
      .get(url, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 200) {
          if (url_id) {
            dispatch(providerFetchSuccess(response.data, true));
          } else {
            dispatch(providerFetchSuccess(response.data));
          }
          dispatch(IsLoading(false));
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          dispatch(IsLoading(false));
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Bad/No internet connection");
        }
      });
  };

export const editProvider = (obj) => {
  return { type: "EDIT_PROVIDER", current_provider_obj: obj };
};
export const ProviderDeleteSuccess = (msg, record_id) => {
  return { type: "DELETE_PROVIDER_SUCCESS", record_id };
};

export const HandleProvideDelete = (record_id, toggle) => (dispatch) => {
  dispatch(processingServiceProviderRequest(true));
  const url = allconstants.apiurl + "service_provider/" + record_id;
  allconstants.axios
    .delete(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(ProviderDeleteSuccess(response.message, record_id));
        dispatch(processingServiceProviderRequest(false));
        toggle();
      }
    });

  return null;
};
