const ChargeRateFormState = {
  id: "",
  charge_rate_name: "",
  rate_value: "",
  percentage_or: "",
  spc_product_id: "",
  min_trans: "",
  max_trans: "",
  min_value: "",
  max_value: "",
};
const ChargeRateInitState = {
  charge_rate_list: [],
  current_charge_rate_obj: ChargeRateFormState,
  is_processing: false,
};

export const ChargeRateReducer = (prevState = ChargeRateInitState, action) => {
  const form_state = prevState.current_charge_rate_obj;
  let new_formState;
  switch (action.type) {
    case "CHARGE_RATE_ERROR":
      return { ...prevState };
    case "SUBSCRIPTION_PLAN_REQUEST_PROCESSING":
      return { ...prevState, is_processing: action.value };
    case "FETCH_CHARGE_RATE_SUCCESS":
      return { ...prevState, charge_rate_list: action.payload.data };
    case "CHARGE_RATE_INPUT_CHANGE":
      new_formState = { ...form_state, ...action.changedField };
      return { ...prevState, current_charge_rate_obj: new_formState };
    case "CLEAR_CHARGE_RATE_FORM":
      return { ...prevState, current_charge_rate_obj: ChargeRateFormState };
    case "EDIT_CHARGE_RATE":
      new_formState = { ...action.current_charge_rate_obj };
      return { ...prevState, current_charge_rate_obj: new_formState };
    case "DELETE_CHARGE_RATE_SUCCESS":
      let new_list = prevState.charge_rate_list.filter(
        (current_rec) => current_rec.id !== action.record_id
      );
      return { ...prevState, charge_rate_list: new_list };
    case "ADD_CHARGE_RATE_SUCCESS":
      let charge_rate_new = true;
      let charge_rate_list = prevState.charge_rate_list.map((charge_rate) => {
        if (charge_rate.id === action.new_record.id) {
          charge_rate_new = false;
          return action.new_record;
        } else {
          return charge_rate;
        }
      });
      charge_rate_new && charge_rate_list.push(action.new_record);
      const new_state = { ...prevState, charge_rate_list };
      if (charge_rate_new) {
        new_state.current_charge_rate_obj = ChargeRateFormState;
      }
      return new_state;
    default:
      return prevState;
  }
};
