const CollectionFormState = {
  id: "",
  amount: "",
  msisdn: "",
  spc_id: "",
  transaction_note: "",
  currency_id: "",
  masked_contact: "",
};
const CollectionInitState = {
  transactions: [],
  initCollection: false,
  current_collection_obj: CollectionFormState,
};

export const CollectionReducer = (prevState = CollectionInitState, action) => {
  const form_state = prevState.current_collection_obj;
  let new_formState;
  switch (action.type) {
    case "COLLECTION_ERROR":
      return { ...prevState };
    case "FETCH_COLLECTION_SUCCESS":
      if (action.isCollection) {
        return { ...prevState, single_record: action.payload };
      } else {
        const { total, last_page: pageCount} = action.payload;
        return {
          ...prevState,
          total,
          pageCount,transactions:action.payload.data
        };
      }
    case "COLLECTION_INPUT_CHANGE":
      new_formState = { ...form_state, ...action.changedField };
      return { ...prevState, current_collection_obj: new_formState };
    case "CLEAR_COLLECTION_FORM":
      return { ...prevState, current_collection_obj: CollectionFormState };
    case "INIT_COLLECTION":
      if (!action.init) {
        return {
          ...prevState,
          initCollection: action.initCollection,
          current_collection_obj: CollectionFormState,
        };
      } else {
        return { ...prevState, initCollection: action.init };
      }

    case "EDIT_COLLECTION":
      new_formState = { ...action.current_collection_obj };
      return { ...prevState, current_collection_obj: new_formState };
    case "DELETE_COLLECTION_SUCCESS":
      let new_list = prevState.collection_list.filter(
        (current_rec) => current_rec.id !== action.record_id
      );
      return { ...prevState, collection_list: new_list };
    case "ADD_COLLECTION_SUCCESS":
      let collection_new = true;
      let collection_list = prevState.collection_list.map((collection) => {
        if (collection.ref_no === action.new_record.ref_no) {
          collection_new = false;
          return action.new_record;
        } else {
          return collection;
        }
      });
      collection_new && collection_list.push(action.new_record);
      const new_state = { ...prevState, collection_list };
      if (collection_new) {
        new_state.current_collection_obj = CollectionFormState;
      }
      return new_state;
    default:
      return prevState;
  }
};
