import allconstants from "../constants";

export const HandleRolesInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return { type: "ROLE_INPUT_CHANGE", changedField: { [name]: value } };
};

export const clearRolesForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_ROLE_FORM" };
};

export const RolesFetchSuccess = (payload) => {
  return { type: "FETCH_ROLE_SUCCESS", payload };
};

export const RolesAdded = (payload) => {
  return { type: "ROLES_ADD_SUCCESS", payload };
};

export const RolePermissionFetchSuccess = (payload) => {
  return {
    type: "ROLE_PERMISSION_FETCH_SUCCESS",
    payload,
  };
};
export const processingRolesRequest = (value) => {
  return { type: "ROLE_REQUEST_PROCESSING", value };
};

export const createRolePermission = (payload) => {
  return {
    type: "CREATE_ROLE_PERMISSION_SUCCESS",
    payload,
  };
};
export const roleAddSuccess = (payload, update = false) => {
  if (update) {
  } else {
  }
  return { type: "CREATE_ROLE_SUCCESS", new_role: payload };
};
export const roleReqError = (message) => {
  return { type: "ROLE_ERROR" };
};

export const editRoles = (form_state) => {
  return { type: "EDIT_ROLE", form_state };
};
export const RolesDeleteSuccess = (msg, record_id) => {
  return { type: "DELETE_ROLE_SUCCESS", record_id };
};

export const HandleRolesSubmit =
  (form_data, toggle, setErrors, setSubmitting) => (dispatch) => {
    dispatch(processingRolesRequest(true));

    const url = form_data.id
      ? allconstants.apiurl + "urole/" + form_data.id
      : allconstants.apiurl + "urole";
    const req_type = form_data.id
      ? allconstants.axios.put
      : allconstants.axios.post;
    req_type(url, form_data, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setSubmitting(false);
          if (form_data.id) {
            dispatch(roleAddSuccess(response.data, true));
            dispatch(processingRolesRequest(false));
          } else {
            dispatch(roleAddSuccess(response.data));
            dispatch(processingRolesRequest(false));
          }
          toggle();
        }
      })
      .catch((error) => {
        dispatch(processingRolesRequest(false));
        if (error.response) {
          setErrors(error.response.data.errors);
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Bad/No internet connection");
        }
      });
  };

export const HandleRolesFetch =
  (params = false) =>
  (dispatch) => {
    const url = params
      ? allconstants.apiurl + "urole/" + params
      : allconstants.apiurl + "urole";
    allconstants.axios
      .get(url, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(RolesFetchSuccess(response.data));
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Bad/No internet connection");
        }
      });
  };

export const HandleRolesDelete = (record_id, setModal) => (dispatch) => {
  dispatch(processingRolesRequest(true));
  const url = allconstants.apiurl + "urole/" + record_id;
  allconstants.axios
    .delete(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(RolesDeleteSuccess(response.message, record_id));
        setModal(false);
        dispatch(processingRolesRequest(false));
      }
    })
    .catch((error) => {
      dispatch(processingRolesRequest(false));
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
      }
    });

  return null;
};

export const saveRolePermissions = (id, form_data) => (dispatch) => {
  const url = allconstants.apiurl + "urole/" + id + "/permission";
  const data = {
    permissions: form_data,
  };
  allconstants.axios
    .post(url, data, { headers: allconstants.headers })
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        dispatch(createRolePermission(res.data));
      } else {
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
      }
    });
};

export const fetch_role_permissions = (role_id) => (dispatch) => {
  const url = allconstants.apiurl + "urole/" + role_id + "/permission";
  allconstants.axios
    .get(url, { headers: allconstants.headers })
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        dispatch(RolePermissionFetchSuccess(res.data));
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Bad/No internet connection");
      }
    });
};
