import allconstants from "../../constants";

export const HandleCurrencyInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return { type: "CURRENCY_INPUT_CHANGE", changedField: { [name]: value } };
};

export const clearCurrencyForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_CURRENCY_FORM" };
};
export const IsLoading = (payload) => {
  return { type: "LOADING_CURRENCIES", payload };
};
export const processingCurrenciesRequest = (value) => {
  return { type: "CURRENCY_REQUEST_PROCESSING", value };
};
export const CurrencyFetchSuccess = (payload, msg) => {
  return { type: "FETCH_CURRENCY_SUCCESS", payload };
};
export const CurrencyAddSuccess = (payload, update = false) => {
  if (update) {
  } else {
  }

  return { type: "CREATE_CURRENCY_SUCCESS", new_currency: payload };
};
export const CurrencyReqError = () => {
  return { type: "CURRENCY_ERROR" };
};

export const HandleCurrencySubmit =
  (form_data, toggle, setErrors, setSubmitting) => (dispatch) => {
    dispatch(processingCurrenciesRequest(true));

    const req_type = form_data.id
      ? allconstants.axios.put
      : allconstants.axios.post;

    const url = form_data.id
      ? allconstants.apiurl + "currency/" + form_data.id
      : allconstants.apiurl + "currency";
    req_type(url, form_data, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setSubmitting(false);
          if (form_data.id) {
            dispatch(CurrencyAddSuccess(response.data, true));
            dispatch(processingCurrenciesRequest(false));
          } else {
            dispatch(CurrencyAddSuccess(response.data));
            dispatch(processingCurrenciesRequest(false));
          }
          toggle();
        }
      })
      .catch((error) => {
        if (error.response) {
          setErrors(error.response.data.errors);
          dispatch(processingCurrenciesRequest(false));
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Bad/No internet connection");
        }
      });

    return null;
  };

export const HandleCurrencyFetch = () => (dispatch) => {
  const url = allconstants.apiurl + "currency";
  allconstants.axios
    .get(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 200) {
        dispatch(CurrencyFetchSuccess(response.data));
        dispatch(IsLoading(false));
      }
    })
    .catch((error) => {
      if (error.response) {
        dispatch(IsLoading(false));
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
      }
    });
};

export const HandleCurrencyDelete = (record_id, toggle) => (dispatch) => {
  const url = allconstants.apiurl + "currency/" + record_id;
  dispatch(processingCurrenciesRequest(true));
  allconstants.axios
    .delete(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(CurrencyDeleteSuccess(response.message, record_id));
        dispatch(processingCurrenciesRequest(false));
        toggle();
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
      }
    });
};

export const editCurrency = (obj) => {
  return { type: "EDIT_CURRENCY", current_currency_obj: obj };
};
export const CurrencyDeleteSuccess = (msg, record_id) => {
  return { type: "DELETE_CURRENCY_SUCCESS", record_id };
};
