import React, { useEffect, useState, useRef } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Button, Col, Container, Row, FormGroup } from "reactstrap";
import { TextField, CustomModal } from "../../../../constants";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  handleSubplanTypes,
  HandleSubscriptionPlanSubmit,
} from "../../../../actions/settings/subscription_plans/subscription_plan_actions";
import { HandleCurrencyFetch } from "../../../../actions/settings/currency_actions";
import * as Yup from "yup";

const AddSubscriptionPlan = (props) => {
  const dispatch = useDispatch();
  const {
    is_processing,
    subscription_plan_types,
    current_subscription_plan_obj,
  } = useSelector((state) => state.subscriptionplanreducer, shallowEqual);
  const { currency_list } = useSelector(
    (state) => state.currencyState,
    shallowEqual
  );

  const formikRef = useRef();

  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

  const validate = Yup.object({
    subscription_plan_name: Yup.string().required(
      "Subscription plan name is required"
    ),
    sub_plan_type_id: Yup.string().required("Sub plan type is required"),
    currency_id: Yup.string().required("Currency  is required"),
    description: Yup.string().required("Description is required"),
  });

  useEffect(() => {
    subscription_plan_types.length === 0 && dispatch(handleSubplanTypes());
    currency_list.length === 0 && dispatch(HandleCurrencyFetch());
  }, [subscription_plan_types.length, currency_list.length, dispatch]);

  const { toggle, modal } = props;

  return (
    <CustomModal
      modal={modal}
      toggle={toggle}
      tittle="Add Subscription Plan"
      is_processing={is_processing}
    >
      <Formik
        enableReinitialize
        initialValues={{
          roll_over: current_subscription_plan_obj.roll_over,
          auto_renew: current_subscription_plan_obj.auto_renew,
          subscription_plan_name:
            current_subscription_plan_obj.subscription_plan_name,
          sub_plan_type_id: current_subscription_plan_obj.sub_plan_type_id,
          currency_id: current_subscription_plan_obj.currency_id,
          description: current_subscription_plan_obj.description,
        }}
        validationSchema={validate}
        validateOnChange={validateAfterSubmit}
        validateOnBlur={true}
        ref={formikRef}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          setValidateAfterSubmit(true);
          dispatch(
            HandleSubscriptionPlanSubmit(
              values,
              toggle,
              setErrors,
              setSubmitting
            )
          );
        }}
      >
        {(formik) => (
          <Container>
            <Form>
              <FormGroup>
                <Row className="mt-3 ml-1">
                  <Col lg={12} sm={12} md={12} className="my-2">
                    <TextField
                      name="subscription_plan_name"
                      type="text"
                      value={formik.values.subscription_plan_name}
                      placeholder="Subscription Plan"
                    />
                  </Col>
                  <Col lg={12} sm={12} md={12} className="my-2">
                    <Field
                      as="select"
                      name="sub_plan_type_id"
                      className={`custom-select form-control ${
                        formik.errors?.sub_plan_type_id ? `is-invalid mb-2` : ""
                      }`}
                    >
                      <option defaultValue>--select--</option>
                      {subscription_plan_types.map((subtypes) => (
                        <option value={subtypes.id} key={subtypes.id}>
                          {subtypes.plan_type_name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      component="div"
                      name="sub_plan_type_id"
                      className="error"
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} className="my-2 d-flex ">
                    <label htmlFor="sp_roll_over">Roll Over</label>
                    <div className="form-check mt-1">
                      <Field type="checkbox" name="roll_over" />
                    </div>
                    <ErrorMessage
                      component="div"
                      name="roll_over"
                      className="error mt-4"
                    />
                  </Col>

                  <Col lg={6} sm={12} md={6} className="my-2 d-flex">
                    <label htmlFor="sp_auto_renewal">Auto Renewal</label>
                    <div className="form-check mt-1">
                      <Field type="checkbox" name="auto_renew" />
                    </div>
                    <ErrorMessage
                      component="div"
                      name="auto_renew"
                      className="error mt-4"
                    />
                  </Col>

                  <Col lg={12} md={12} sm={12} className="my-2">
                    <Field
                      as="select"
                      name="currency_id"
                      className={`custom-select form-control ${
                        formik.errors?.currency_id ? `is-invalid mb-2` : ""
                      }`}
                    >
                      <option defaultValue>--select--</option>
                      {currency_list.map((currency) => (
                        <option value={currency.id} key={currency.id}>
                          {currency.currency_name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      component="div"
                      name="currency_id"
                      className="error"
                    />
                  </Col>

                  <Col lg={12} md={12} sm={12} className="my-2">
                    <TextField
                      name="amount"
                      type="text"
                      value={formik.values.amount}
                      placeholder="Amount"
                    />
                  </Col>

                  <Col lg={12} sm={12} md={12} className="my-2">
                    <Field
                      as="textarea"
                      name="description"
                      value={formik.values.description}
                      placeholder="Description"
                      className={`custom-select form-control ${
                        formik.errors.description ? `is-invalid mb-2` : ""
                      }`}
                    ></Field>
                    <ErrorMessage
                      component="div"
                      name="description"
                      className="error"
                    />
                  </Col>

                  <Col lg={12} md={12} sm={12} className="mt-3">
                    <Button
                      type="submit"
                      className="btn btn-pay btn-block text-white"
                      disabled={is_processing}
                      block
                    >
                      <span className="pay-btn-text">
                        {is_processing ? "... Saving" : "Save"}
                      </span>
                    </Button>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </Container>
        )}
      </Formik>
    </CustomModal>
  );
};
export default AddSubscriptionPlan;
