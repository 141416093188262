import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Actions } from "../../../../constants/constants";
import TblComp from "../../../../react_table";
import { decData } from "../../../../constants/constants";
import { clearProductForm } from "../../../../actions/settings/product_actions";
import { Button } from "reactstrap";
import {
  editProduct,
  HandleProductFetch,
  HandleProductDelete,
} from "../../../../actions/settings/product_actions";
import ExportCSV from "../../../../constants/Exports/xlsExport";
import DeleteModal from "../../../../constants/deleteModal";
import { checkInArray } from "../../../../constants/GenHelpers";

export const Productstable = (props) => {
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState(false);
  const toggle = () => setModal(!modal);
  const dispatch = useDispatch();
  const { product_list, loading_products, is_processing } = useSelector(
    (state) => state.productState,
    shallowEqual
  );

  useEffect(() => {
    product_list.length === 0 && dispatch(HandleProductFetch());
  }, [dispatch, product_list.length]);

  const user_permissions = decData(sessionStorage.getItem("profile")).roles[0]
    .permissions;

  const headers = ["Id", "Product name", "Description"];
  return (
    <>
      <DeleteModal
        modal={modal}
        toggle={toggle}
        name="product"
        is_processing={is_processing}
        handleDelete={() => dispatch(HandleProductDelete(selected, toggle))}
      />
      {checkInArray(user_permissions, "create-product","name") ? (
        <>
          {" "}
          <Button
            className="btn payment-btn my-3 ml-2 text-white"
            onClick={(e) => {
              props.toggle();
              dispatch(clearProductForm(e));
            }}
          >
            Create Product
          </Button>
        </>
      ) : (
        <></>
      )}
      <ExportCSV csvData={product_list} fileName="products" headers={headers} />
      <TblComp
        columns={[
          {
            Header: "Product Name",
            accessor: "product_name",
            filter: "fuzzyText",
          },
          {
            Header: "Description",
            accessor: "description",
            filter: "fuzzyText",
          },
          {
            id: "actions", // Required because our accessor is not a string
            Header: "Action",
            maxWidth: 100,
            accessor: (full) => (
              <Actions
                current_table="Products"
                editHandler={(selected_row) =>
                  dispatch(editProduct(selected_row))
                }
                data_item={full}
                link_url={""}
                modal_id="product-modal"
                deleteHandler={(selected_id) => [
                  toggle(),
                  setSelected(selected_id.id),
                ]}
              />
            ),
          },
        ]}
        data={product_list}
        loading={loading_products}
      />
    </>
  );
};
