import React, { useState } from "react";
import { Actions } from "../../../constants/constants";
import TblComp from "../../../react_table";
import { Button } from "reactstrap";
import {
  clearStaffForm,
  editStaff,
  HandleStaffDelete,
} from "../../../actions/staff_actions";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import ExportCSV from "../../../constants/Exports/xlsExport";
import DeleteModal from "../../../constants/deleteModal";
import RoleAssign from "./addRoles";
import { editStaffRoles } from "../../../actions/staff_roles_actions";

export const StaffTable = (props) => {
  const dispatch = useDispatch();
  const { is_processing, staff_list } = useSelector(
    (state) => state.staffState,
    shallowEqual
  );
  const [roleModal, setModal] = useState(false);
  const [selected, setSelected] = useState(false);
  const toggleRole = () => setModal(!roleModal);
  const [delModal, setDelModal] = useState(false);
  const toggleDel = () => setDelModal(!delModal);
  return (
    <>
      <RoleAssign toggle={toggleRole} modal={roleModal} />
      <Button
        className="btn payment-btn my-3 ms-2 me-4 shadow-none"
        onClick={(e) => {
          e.preventDefault();
          dispatch(clearStaffForm(e));
          props.toggle();
        }}
      >
        Add Staff
      </Button>
      <DeleteModal
        modal={delModal}
        toggle={toggleDel}
        name="Staff"
        is_processing={is_processing}
        handleDelete={() => dispatch(HandleStaffDelete(selected))}
      />
      <ExportCSV csvData={staff_list} fileName="staffs" />
      <TblComp
        columns={[
          {
            Header: "Name",
            accessor: "full_name",
            filter: "fuzzyText",
          },
          {
            Header: "Email",
            accessor: "email",
            filter: "fuzzyText",
          },
          {
            Header: "Phone",
            accessor: "phone_number",
            filter: "fuzzyText",
          },
          {
            Header: "Gender",
            accessor: (staff) => (
              <>{staff.gender === 1 ? <>Male</> : <>Female</>}</>
            ),
            filter: "fuzzyText",
          },
          {
            Header: "Address",
            accessor: "address",
            filter: "fuzzyText",
          },
          {
            Header: "Roles",
            accessor: (staff) => {
              return (
                <>
                  {" "}
                  <button
                    onClick={(e) => {dispatch(editStaff(staff)); dispatch(editStaffRoles(staff.roles)); toggleRole()}}
                    className="btn btn-sm text-light btn-pay"
                  >
                    Assign
                  </button>{" "}
                </>
              );
            },
            filter: "fuzzyText",
          },
          {
            id: "actions", // Required because our accessor is not a string
            Header: "Action",
            maxWidth: 100,
            accessor: (full) => (
              <Actions
                editHandler={(selected_row) => [
                  dispatch(editStaff(selected_row)),
                  props.toggle(),
                ]}
                data_item={full}
                link_url={""}
                deleteHandler={(this_ticket_id) => [
                  toggleDel(),
                  setSelected(this_ticket_id.id),
                ]}
              />
            ),
          },
        ]}
        data={staff_list}
      />
    </>
  );
};
