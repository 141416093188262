import React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Button } from "reactstrap";
import {assignStaffRoles, HandleStaffRolesInput} from "../../../actions/staff_roles_actions";
import CustomModal from "../../../constants/customModal";
import { checkInArray } from "../../../constants/GenHelpers";

const RoleAssign = (props) => {
    const dispatch = useDispatch();
    const { role_list } = useSelector((state) => state.rolesState,shallowEqual);
    const {is_processing,selected_staff_roles:selectedStaffRoles} = useSelector((state) => state.staffRolesState,shallowEqual);
    const selectedStaff = useSelector((state) => state.staffState.current_staff_obj, shallowEqual);
  
    const { toggle, modal } = props;

    const handleSubmit =  e => {
        e.preventDefault();
        dispatch(assignStaffRoles(selectedStaff.id, selectedStaffRoles));
    }
  
    return (
      <>
        <CustomModal
          modal={modal}
          toggle={toggle}
          tittle="Assign Role"
          is_processing={is_processing}
        >
          <span className="d-block">
            {selectedStaff.full_name}
          </span>
          <span className="d-block">{selectedStaff.email}</span>
          {role_list === [] || role_list === undefined ? (
            <>Loading ... ...</>
          ) : (
            role_list.map((role) => <div className="form-check p-1 my-2 mx-4" key={`staffRole${role.id}`}>
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        onChange={(e)=>dispatch(HandleStaffRolesInput(e))}
                        id={role.id}
                        className="form-check-input"
                        checked={checkInArray(selectedStaffRoles,role.id)}
                        value={role.id}
                        name={role.name}
                      />
                      {role.name}
                    </label>
                  </div>)
          )}
          <Button
            type="button"
            onClick={handleSubmit}
            className="btn btn-pay btn-block"
            block
          >
            {is_processing ? "... Saving" : "Save"}
          </Button>
        </CustomModal>
      </>
    );
  };

  export default RoleAssign;