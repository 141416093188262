import React, { useCallback, useRef, useState } from "react";
import { HandleTransactionFetch } from "../../../actions/transaction_action";
import { TransactionsTable } from "./transactionsTable";
import AddTransaction from "./add_transaction";
import { useDispatch } from "react-redux";
import { Col, Card, CardBody, Button, Row } from "reactstrap";

const Transactions = (props) => {
  const dispatch = useDispatch();

  const transaction_status = "";
  const fetchIdRef = useRef(0);

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const fetchData = useCallback(
    (pageOptions) => {
      const fetchId = ++fetchIdRef.current;
      if (fetchId === fetchIdRef.current) {
        dispatch(
          HandleTransactionFetch({
            ...pageOptions,
            status: transaction_status || null,
          })
        );
      }
    },
    [dispatch]
  );

  return (
    <>
      <AddTransaction
        modal={modal}
        toggle={toggle}
        formstate={props?.formstate}
        isRequesting={props?.requesting}
        dispatch={props?.dispatch}
        merchants={props?.merchants}
        companies={props?.companies}
      />
      <Row className="pb-1">
        <Col md={12} className="ps-0">
          <Card className="bg-white">
            <CardBody>
              <Button
                className="btn payment-btn my-3 ms-2 shadow-none"
                onClick={toggle}
              >
                Top Up Account{" "}
              </Button>

              <Button
                className="btn payment-btn my-3 ms-2 me-4 shadow-none"
                onClick={() => {
                  fetchData({ page: 2, perPage: 10 });
                }}
                disabled={props.requesting}
              >
                {props.requesting ? "Loading..." : "Refresh"}
              </Button>

              <TransactionsTable fetchData={fetchData} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Transactions;
