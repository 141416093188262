const ServicesFormState = {
  id: "",
  service_title: "",
  description: "",
};
const ServicesInitState = {
  services_list: [],
  current_services_obj: ServicesFormState,
  is_processing: false,
};

export const ServicesReducer = (prevState = ServicesInitState, action) => {
  const form_state = prevState.current_services_obj;
  let new_formState;
  switch (action.type) {
    case "SERVICES_ERROR":
      return { ...prevState };

    case "FETCH_SERVICES_SUCCESS":
      return { ...prevState, services_list: action.payload.data };

    case "SERVICES_INPUT_CHANGE":
      new_formState = { ...form_state, ...action.changedField };
      return { ...prevState, current_services_obj: new_formState };

    case "CLEAR_SERVICES_FORM":
      return { ...prevState, current_services_obj: ServicesFormState };

    case "EDIT_SERVICES":
      new_formState = { ...action.current_services_obj };
      return { ...prevState, current_services_obj: new_formState };
    case "DELETE_SERVICES_SUCCESS":
      let new_list = prevState.services_list.filter(
        (current_rec) => current_rec.id !== action.record_id
      );
      return { ...prevState, services_list: new_list };
    case "ADD_SERVICES_SUCCESS":
      let services_new = true;
      let services_list = prevState.services_list.map((services) => {
        if (services.id === action.new_record.id) {
          services_new = false;
          return action.new_record;
        } else {
          return services;
        }
      });
      services_new && services_list.push(action.new_record);
      const new_state = { ...prevState, services_list };
      if (services_new) {
        new_state.current_services_obj = ServicesFormState;
      }
      return new_state;
    case "SERVICES_REQUEST_PROCESSING":
      return { ...prevState, is_processing: action.value };
    default:
      return prevState;
  }
};
