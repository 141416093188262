import React from "react";
import { Route, Switch, NavLink } from "react-router-dom";
import { Nav, NavItem, Col } from "reactstrap";
import WithdrawRequests from "./withdraws/component";
import Transactions from "./component";

const ProductsComp = (props) => {
  const parent_url = "/transactions";
  const current_path = props.location.pathname;

  return (
    <Col md={9} sm={12} lg={10}>
      <Col>
        <Nav pills>
          <NavItem className="nav-item nav-link active">
            <NavLink className="nav-item nav-link" to={parent_url}>
              <span
                className={
                  "btn mb-1 " +
                  (current_path === parent_url ? "ticket-tab-active" : "")
                }
              >
                All Transactions
              </span>
            </NavLink>
          </NavItem>
          <NavItem className="nav-item nav-link">
            <NavLink
              className="nav-item nav-link "
              to={`${parent_url}/withdraw`}
            >
              <span
                className={
                  "btn mb-1 " +
                  (current_path === `${parent_url}/withdraw`
                    ? "ticket-tab-active"
                    : "")
                }
              >
                Withdraw Requests
              </span>
            </NavLink>
          </NavItem>
        </Nav>
      </Col>
      <hr className="col-12" />

      <Switch>
        <Route path={`${parent_url}/withdraw`} component={WithdrawRequests} />
        <Route path={parent_url} component={Transactions} />
      </Switch>
    </Col>
  );
};

export default ProductsComp;
