const PaymentFormState = {
  id: "",
  amount: "",
  msisdn: "",
  spc_id: "",
  transaction_note: "",
  masked_contact: "",
  currency_id: "",
};
const PaymentInitState = {
  payment_list: [],
  initPayment: false,
  current_payment_obj: PaymentFormState,
};

export const PaymentReducer = (prevState = PaymentInitState, action) => {
  const form_state = prevState.current_payment_obj;
  let new_formState;
  switch (action.type) {
    case "PAYMENT_ERROR":
      return { ...prevState };
    case "FETCH_PAYMENT_SUCCESS":
      if (action.isPayment) {
        return { ...prevState, single_record: action.payload };
      } else {
        const {
          first_page_url,
          prev_page_url,
          last_page_url,
          total,
          next_page_url,
        } = action.payload;
        const new_data = {
          first_page_url,
          prev_page_url,
          last_page_url,
          total,
          next_page_url,
        };
        return { ...prevState, ...new_data, payment_list: action.payload.data };
      }
    case "PAYMENT_INPUT_CHANGE":
      new_formState = { ...form_state, ...action.changedField };
      return { ...prevState, current_payment_obj: new_formState };
    case "CLEAR_PAYMENT_FORM":
      return { ...prevState, current_payment_obj: PaymentFormState };
    case "INIT_PAYMENT":
      if (!action.init) {
        return {
          ...prevState,
          initPayment: action.initPayment,
          current_payment_obj: PaymentFormState,
        };
      } else {
        return { ...prevState, initPayment: action.init };
      }
    case "EDIT_PAYMENT":
      new_formState = { ...action.current_payment_obj };
      return { ...prevState, current_payment_obj: new_formState };
    case "DELETE_PAYMENT_SUCCESS":
      let new_list = prevState.payment_list.filter(
        (current_rec) => current_rec.id !== action.record_id
      );
      return { ...prevState, payment_list: new_list };
    case "ADD_PAYMENT_SUCCESS":
      // console.log(action.new_record)
      let payment_new = true;
      let payment_list = prevState.payment_list.map((payment) => {
        if (payment.ref_no === action.new_record.ref_no) {
          payment_new = false;
          return action.new_record;
        } else {
          return payment;
        }
      });
      payment_new && payment_list.push(action.new_record);
      const new_state = { ...prevState, payment_list };
      if (payment_new) {
        new_state.current_payment_obj = PaymentFormState;
      }
      return new_state;
    default:
      return prevState;
  }
};
