import allconstants from "../../../constants";

export const HandleTicketTypeInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return { type: "TICKET_TYPE_INPUT_CHANGE", changedField: { [name]: value } };
};
export const processingTicketTypeRequest = (value) => {
  return { type: "TICKET_TYPE_REQUEST_PROCESSING", value };
};
export const clearTicketTypeForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_TICKET_TYPE_FORM" };
};

export const IsLoading = (payload) => {
  return { type: "LOADING_TICKET_TYPES", payload };
};
export const ticketTypeFetchSuccess = (payload, msg) => {
  return { type: "FETCH_TICKET_TYPE_SUCCESS", payload };
};
export const TicketTypeAddSuccess = (new_record, update = false) => {
  if (update) {
  } else {
  }

  return { type: "ADD_TICKET_TYPE_SUCCESS", new_record };
};
export const TicketTypeDeleteSuccess = (record_id) => {
  return { type: "DELETE_TICKET_TYPE_SUCCESS", record_id };
};
export const TicketTypeReqError = (msg) => {
  return { type: "TICKET_TYPE_ERROR" };
};

export const editTicketType = (form_state) => {
  return { type: "EDIT_TICKET_TYPE", form_state };
};
export const HandleTicketTypeSubmit =
  (form_data, toggle, setErrors, setSubmitting) => (dispatch) => {
    dispatch(processingTicketTypeRequest(true));

    const url = form_data.id
      ? allconstants.apiurl + "ticket_type/" + form_data.id
      : allconstants.apiurl + "ticket_type";

    const req_type = form_data.id
      ? allconstants.axios.put
      : allconstants.axios.post;
    req_type(url, form_data, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setSubmitting(false);
          if (form_data.id) {
            dispatch(TicketTypeAddSuccess(response.data, true));

            dispatch(processingTicketTypeRequest(false));
          } else {
            dispatch(TicketTypeAddSuccess(response.data));
            dispatch(processingTicketTypeRequest(false));
          }
          toggle();
        }
      })
      .catch((error) => {
        dispatch(processingTicketTypeRequest(false));
        if (error.response) {
          setErrors(error.response.data.errors);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Bad/No internet connection");
        }
      });
    return null;
  };
export const HandleTicketTypeDelete = (record_id, toggle) => (dispatch) => {
  const url = allconstants.apiurl + "ticket_type/" + record_id;
  dispatch(processingTicketTypeRequest(true));
  allconstants.axios
    .delete(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(TicketTypeDeleteSuccess(record_id));
        dispatch(processingTicketTypeRequest(false));
        toggle();
      }
    });

  return null;
};
export const HandleTicketTypeFetch = () => (dispatch) => {
  const url = allconstants.apiurl + "ticket_type";
  allconstants.axios
    .get(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 200) {
        dispatch(ticketTypeFetchSuccess(response));
        dispatch(IsLoading(false));
      }
    })
    .catch((error) => {
      if (error.response) {
        dispatch(IsLoading(false));
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Bad/No internet connection");
      }
    });
};
