const ServiceProviderCountryFormState = {
  country_id: "",
  spc_code: "",
  currency_id: "",
  no_format: "",
};
const ServiceProviderCountryInitState = {
  service_provider_country_list: [],
  current_service_provider_country_obj: ServiceProviderCountryFormState,
  is_processing: false,
};

export const ServiceProviderCountry = (
  prevState = ServiceProviderCountryInitState,
  action
) => {
  const form_state = prevState.current_service_provider_country_obj;
  let new_formState;
  switch (action.type) {
    case "SERVICE_PROVIDER_COUNTRY_ERROR":
      return { ...prevState };
    case "FETCH_SERVICE_PROVIDER_COUNTRY_SUCCESS":
      return {
        ...prevState,
        service_provider_country_list: action.payload.data,
      };
    case "SERVICE_PROVIDER_COUNTRY_INPUT_CHANGE":
      new_formState = { ...form_state, ...action.changedField };
      return {
        ...prevState,
        current_service_provider_country_obj: new_formState,
      };
    case "CLEAR_SERVICE_PROVIDER_COUNTRY_FORM":
      return {
        ...prevState,
        current_service_provider_country_obj: ServiceProviderCountryFormState,
      };
    case "PROVIDER_COUNTRY_REQUEST_PROCESSING":
      return { ...prevState, is_processing: action.value };
    case "EDIT_SERVICE_PROVIDER_COUNTRY":
      new_formState = { ...action.current_service_provider_country_obj };
      return {
        ...prevState,
        current_service_provider_country_obj: new_formState,
      };
    case "DELETE_SERVICE_PROVIDER_COUNTRY_SUCCESS":
      let new_list = prevState.service_provider_country_list.filter(
        (current_rec) => current_rec.id !== action.record_id
      );
      return { ...prevState, service_provider_country_list: new_list };
    case "ADD_SERVICE_PROVIDER_COUNTRY_SUCCESS":
      let service_provider_country_new = true;
      let service_provider_country_list =
        prevState.service_provider_country_list.map(
          (service_provider_country) => {
            if (service_provider_country.id === action.new_record.id) {
              service_provider_country_new = false;
              return action.new_record;
            } else {
              return service_provider_country;
            }
          }
        );
      service_provider_country_new &&
        service_provider_country_list.push(action.new_record);
      const new_state = { ...prevState, service_provider_country_list };
      if (service_provider_country_new) {
        new_state.current_service_provider_country_obj =
          ServiceProviderCountryFormState;
      }
      return new_state;
    default:
      return prevState;
  }
};
