import React, { useState, useRef } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Button, Row, Col, FormGroup, Container } from "reactstrap";
import * as Yup from "yup";
import { HandleRolesSubmit } from "../../../../actions/user_roles_actions";
import { TextField, CustomModal } from "../../../../constants";
import { Formik, Form, Field, ErrorMessage } from "formik";

export const AddRoles = (props) => {
  const dispatch = useDispatch();
  const { is_processing, form_state } = useSelector(
    (state) => state.rolesState,
    shallowEqual
  );

  const formikRef = useRef();

  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

  const validate = Yup.object({
    name: Yup.string().required("name  is required"),
    description: Yup.string().required("description is required"),
  });
  const { modal, toggle } = props;

  return (
    <CustomModal
      modal={modal}
      toggle={toggle}
      tittle={form_state?.id ? "Edit Role" : "Add Role"}
      is_processing={is_processing}
    >
      <Formik
        enableReinitialize
        initialValues={{
          name: form_state.name,
          description: form_state.description,
        }}
        validationSchema={validate}
        validateOnChange={validateAfterSubmit}
        validateOnBlur={true}
        ref={formikRef}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          setValidateAfterSubmit(true);
          dispatch(HandleRolesSubmit(values, toggle, setErrors, setSubmitting));
        }}
      >
        {(formik) => (
          <Container>
            <Form>
              <FormGroup>
                <Row className="mt-3 ml-1">
                  <Col lg={12} sm={12} md={12} className="my-2">
                    <TextField
                      name="name"
                      type="text"
                      value={formik.values.name}
                      placeholder="Role Name"
                    />
                  </Col>
                  <Col lg={12} md={12} sm={12} className="my-2 ">
                    <Field
                      as="textarea"
                      name="description"
                      value={formik.values.description}
                      placeholder="Role description ..."
                      className={`custom-select form-control ${
                        formik.errors.description ? `is-invalid mb-2` : ""
                      }`}
                    ></Field>
                    <ErrorMessage
                      component="div"
                      name="description"
                      className="error"
                    />
                  </Col>
                  <Col lg={12} md={12} sm={12} className="mt-3 ">
                    <Button
                      type="submit"
                      className="btn btn-pay btn-block text-white"
                      block
                      disabled={is_processing}
                    >
                      <span className="pay-btn-text ">
                        {form_state?.id
                          ? is_processing
                            ? "... Updating"
                            : "Update"
                          : is_processing
                          ? "... Creating"
                          : "Create"}
                      </span>
                    </Button>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </Container>
        )}
      </Formik>
    </CustomModal>
  );
};
