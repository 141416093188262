import React, { useState, useRef } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { TextField, CustomModal } from "../../../../constants";
import { Button, Col, Container, FormGroup, Row } from "reactstrap";
import * as Yup from "yup";
import { HandleCurrencySubmit } from "../../../../actions/settings/currency_actions";
import { Formik, Form, Field, ErrorMessage } from "formik";

const AddCurrency = (props) => {
  const dispatch = useDispatch();
  const { is_processing, current_currency_obj } = useSelector(
    (state) => state.currencyState,
    shallowEqual
  );
  const { modal, toggle } = props;

  const formikRef = useRef();

  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

  const validate = Yup.object({
    currency_code: Yup.string().required("Currency code is required"),
    currency_name: Yup.string().required("Currency name is required"),
    description: Yup.string().nullable(),
  });

  return (
    <>
      <CustomModal
        modal={modal}
        toggle={toggle}
        tittle="Add Currency"
        is_processing={is_processing}
      >
        <Formik
          enableReinitialize
          initialValues={{
            currency_name: current_currency_obj.currency_name,
            currency_code: current_currency_obj.currency_code,
            description: current_currency_obj.description,
          }}
          validationSchema={validate}
          validateOnChange={validateAfterSubmit}
          validateOnBlur={true}
          ref={formikRef}
          onSubmit={(values, { setErrors, setSubmitting }) => {
            setValidateAfterSubmit(true);
            dispatch(
              HandleCurrencySubmit(values, toggle, setErrors, setSubmitting)
            );
          }}
        >
          {(formik) => (
            <Container>
              <Form>
                <FormGroup>
                  <Row className="mt-3 ml-1">
                    <Col lg={12} sm={12} md={12} className="my-2">
                      <TextField
                        name="currency_name"
                        type="text"
                        value={formik.values.currency_name}
                        placeholder="Currency name"
                      />
                    </Col>
                    <Col lg={12} sm={12} md={12} className="my-2">
                      <TextField
                        name="currency_code"
                        type="text"
                        value={formik.values.currency_code}
                        placeholder="Currency code"
                      />
                    </Col>
                    <Col lg={12} sm={12} md={12} className="my-2">
                      <Field
                        as="textarea"
                        name="description"
                        value={formik.values.description}
                        placeholder="Description"
                        className={`custom-select form-control ${
                          formik.errors.description ? `is-invalid mb-2` : ""
                        }`}
                      ></Field>
                      <ErrorMessage
                        component="div"
                        name="description"
                        className="error"
                      />
                    </Col>
                    <Col lg={12} sm={12} md={12} className="mt-3">
                      <Button
                        type="submit"
                        className="btn btn-pay btn-block text-white"
                        block
                        disabled={is_processing}
                      >
                        <span className="pay-btn-text ">
                          {is_processing ? "... Saving" : "Create"}
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
              </Form>
            </Container>
          )}
        </Formik>
      </CustomModal>
    </>
  );
};

export default AddCurrency;
