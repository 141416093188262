import React,{useEffect} from "react";
import { withRouter } from "react-router-dom";
import AuthRoutes from "../auth/component";
import { connect } from "react-redux";
import { AuthenicatedRoutes } from "./authenicatedRoutes";
import { checkAuthentication, signOut, RefreshAuthToken, fetchUserProfile, FetchUserRoles } from "../../actions/api/login_api";

import SessionTimeoutPrompt from "../auth/sessionTimeoutPrompt";

const MainAppLayout = ({isLoggedIn,checkAuthenticationStatus,...rest}) => {
  useEffect(() => {
    checkAuthenticationStatus(); //the user data might be in the session store, let's double check
  }, [checkAuthenticationStatus]);
  return isLoggedIn || sessionStorage.getItem("accessor") != null ? (
    <>
      <SessionTimeoutPrompt {...rest} />
      <AuthenicatedRoutes {...rest} />
    </>
  ) : (
    <AuthRoutes />
  );
};
//export default withRouter(MainAppRoutes)
const mapRootStateToProps = (state) => {
  return {
    isLoggedIn: state.authState.isAuthenticated,
    token: state.authState.token,
    user_roles: state.authState.user_roles,
    expiryTime: state.authState.exp_time,
  };
};

const mapRootDispatchToProps = (dispatch) => {
  return {
    logOutUser: (e) => dispatch(signOut(e)),
    fetchRoles: (id) => dispatch(FetchUserRoles(id)),
    fetchUserProfile: () => dispatch(fetchUserProfile()),
    checkAuthenticationStatus: () => dispatch(checkAuthentication()),
    tokenRefresh: () => dispatch(RefreshAuthToken()),
  };
};

export default withRouter(
  connect(mapRootStateToProps, mapRootDispatchToProps)(MainAppLayout)
);
