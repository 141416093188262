const CompanySubFormState = {
  id: "",
  subscription_plan_id: "",
};
const CompanySubInitState = {
  company_sub_list: [],
  current_company_sub_obj: CompanySubFormState,
  wallet_bal: 0,
};

export const CompanySubReducer = (prevState = CompanySubInitState, action) => {
  const form_state = prevState.current_company_sub_obj;
  let new_formState;
  switch (action.type) {
    case "COMPANY_SUB_ERROR":
      return { ...prevState };
    case "SET_BAL":
      return { ...prevState, wallet_bal: action.new_record };
    case "FETCH_COMPANY_SUB_SUCCESS":
      return { ...prevState, company_sub_list: action.payload.data };
    case "COMPANY_SUB_INPUT_CHANGE":
      new_formState = { ...form_state, ...action.changedField };
      return { ...prevState, current_company_sub_obj: new_formState };
    case "CLEAR_COMPANY_SUB_FORM":
      return { ...prevState, current_company_sub_obj: CompanySubInitState };
    case "DELETE_COMPANY_SUB_SUCCESS":
      let new_list = prevState.company_sub_list.filter(
        (current_rec) => current_rec.id !== action.record_id
      );
      return { ...prevState, company_sub_list: new_list };
    case "ADD_COMPANY_SUB_SUCCESS":
      let company_sub_new = true;
      let company_sub_list = prevState.company_sub_list.map((company) => {
        if (company.id === action.new_record.id) {
          company_sub_new = false;
          return action.new_record;
        } else {
          return company;
        }
      });
      company_sub_new && company_sub_list.push(action.new_record);
      const new_state = { ...prevState, company_sub_list };
      if (company_sub_new) {
        new_state.current_company_sub_obj = CompanySubInitState;
      }
      return new_state;
    default:
      return prevState;
  }
};
