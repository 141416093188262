import { configureStore } from "@reduxjs/toolkit";
import { AuthReducer } from "./authReducer";
import { userReducer } from "./userRegReducer";
import { MerchantReducer } from "./merchantReducer";
import { ProductReducer } from "./productsReducer";
import { ServiceProviderReducer } from "./serviceproviders/serviceproviderReducer";
import { ServiceProviderCountry } from "./serviceproviders/serviceprovidercountryReducer";
import { DocumentTypeReducer } from "./types/documentTypeReducer";
import { TransactionTypeReducer } from "./types/transactionTypeReducer";
import { ContactTypeReducer } from "./types/contactTypeReducer";
import { TicketTypeReducer } from "./types/ticketTypeReducer";
import { ServicesReducer } from "./servicesReducer";
import { CountriesReducer } from "./countriesReducer";
import { SubscriptionPlanReducer } from "./subscription_plans/subscriptionPlanReducer";
import { CurrencyReducer } from "./currencyReducer";
import { ExhangeRateReducer } from "./exchangeRateReducer";
import { CompanyReducer } from "./companyReducer";
import { ChargeRateReducer } from "./subscription_plans/chargeRatesReducer";
import { SPC_ProductReducer } from "./serviceproviders/serviceprovidercountryproductsReducer";
import { CompanySubReducer } from "./companySubReducer";
import { MerchantTicketsReducer } from "./merchantSupportReducer";
import { CompanyContactsReducer } from "./CompanyContactReducer";
import { CompanyDocumentsReducer } from "./CompanyDocumentReducer";
import { CollectionReducer } from "./collectionReducer";
import { PaymentReducer } from "./paymentReducer";
import { TransactionReducer } from "./transactionReducer";
import { DashboardReducer } from "./dashboardReducer";
import staffReducer from "./staffReducer";
import { rolesReducer } from "./rolesReducer";
import { permissionsReducer } from "./permissionReducer";
import { WithdrawReducer } from "./withdrawReducer";
import masterAccountReducer from "./masterAccountReducer";
import staffRollsReducer from "./staffRolesReducer";

export const store = configureStore({
  reducer: {
    master: masterAccountReducer,
    userRegState: userReducer,
    merchantState: MerchantReducer,
    companyState: CompanyReducer,
    collectionState: CollectionReducer,
    paymentState: PaymentReducer,
    transaction: TransactionReducer,
    dashboardState: DashboardReducer,
    companySubState: CompanySubReducer,
    companyDocumentState: CompanyDocumentsReducer,
    companyContactsState: CompanyContactsReducer,
    merchantTicketState: MerchantTicketsReducer,
    productState: ProductReducer,
    serviceprovider: ServiceProviderReducer,
    serviceprovidercountry: ServiceProviderCountry,
    transactiontypereducer: TransactionTypeReducer,
    documenttypereducer: DocumentTypeReducer,
    contactTypeState: ContactTypeReducer,
    tickettypereducer: TicketTypeReducer,
    servicesreducer: ServicesReducer,
    countryState: CountriesReducer,
    subscriptionplanreducer: SubscriptionPlanReducer,
    currencyState: CurrencyReducer,
    exchangeState: ExhangeRateReducer,
    chargerate: ChargeRateReducer,
    spcProductsState: SPC_ProductReducer,
    staffState: staffReducer,
    rolesState: rolesReducer,
    staffRolesState:staffRollsReducer,
    permissionState: permissionsReducer,
    authState: AuthReducer,
    withdrawState: WithdrawReducer,
  },
});
