import { encData, decData } from "../constants/constants";
import jwt_decode from "jwt-decode";

const authInitState = {
  token: "",
  session: {},
  success: false,
  message: "",
  server_time: "",
  all_privileges: "",
  profile: {},
  user_roles: decData(sessionStorage.getItem("profile"))
    ? decData(sessionStorage.getItem("profile")).roles[0].permissions
    : [],
  log_out: false,
  isAuthenticated: sessionStorage.getItem("accessor") !== null ? true : false,
  resetPassword: null,
  is_loading: false,
  exp_time: 0,
};

export const AuthReducer = (prevState = authInitState, action) => {
  let new_state = prevState;
  switch (action.type) {
    case "DO_AUTH_ERROR":
      //load toastr here
      new_state = {
        ...prevState,
        ...action.payload,
        isAuthenticated: false,
        show_message: true,
        message: action.message,
        is_loading: false,
      };
      break;
    case "DO_AUTH_SUCCESS":
      sessionStorage.setItem("accessor", encData(action.payload.access_token));
      sessionStorage.setItem("refresh", action.payload.refresh_token);
      try {
        const dToken = jwt_decode(action.payload.access_token);
        new_state = {
          ...prevState,
          success: null,
          message: null,
          isAuthenticated: true,
          log_out: false,
          exp_time: dToken.exp,
        };
      } catch (error) {
        console.error(error);
      }
      break;
    case "UPDATE_USER_SESSION":
      const token = sessionStorage.getItem("accessor");
      if (token) {
        try {
          const decoded = jwt_decode(decData(token));
          new_state = {
            isAuthenticated: false,
          };
          const currentTimeStamp =
            Math.floor(new Date().getTime() / 1000) - 3 * 60;
          const isAuthenticated = decoded.exp > currentTimeStamp;
          new_state = {
            ...prevState,
            ...new_state,
            isAuthenticated,
            exp_time: decoded.exp,
          };
        } catch (error) {
          console.error(error);
        }
      }
      break;

    case "SIGNOUT_USER":
      sessionStorage.clear();
      new_state = {
        ...prevState,
        isAuthenticated: false,
        token: "",
        is_loading:false,
        exp_time: 0,
      };
      break;
    case "LOGOUT_CONFIRMATION":
      var log_out = action.logout;
      new_state = { ...prevState, isAuthenticated: false, log_out };
      break;

    case "DO_SAVE_USER_DATA":
      const session_data = {
        ...action.user_session,
        success: null,
        message: null,
      };
      new_state = { ...prevState, ...session_data, isAuthenticated: true };
      break;
    case "DO_SAVE_FISCAL_YEAR":
      localStorage.removeItem("fiscal_year");
      localStorage.setItem("fiscal_year", JSON.stringify(action.fiscal_year));
      new_state = { ...prevState, ...action };
      break;
    case "EMAIL_SENT_SUCCESS":
      new_state = { ...prevState, resetPassword: action.sent };
      break;
    case "IS_LOADING":
      new_state = { ...prevState, is_loading: action.value, message: "" };
      break;
    case "FETCH_USER_ROLE":
      new_state = { ...prevState, user_roles: action.payload };
      sessionStorage.setItem("roles", encData(action.payload));
      break;
    case "FETCH_USER_PROFILE":
      new_state = { ...prevState, profile: action.payload };
      sessionStorage.setItem("profile", encData(action.payload));
      new_state = {
        ...prevState,
        user_roles: action.payload.roles.length
          ? action.payload.roles[0].permissions
          : [],
      };
      break;
    default:
  }
  return new_state;
};
