const WithdrawInitState = {
  withdraw_request_list: [],
  total: 0,
  is_processing: false,
  loading_withdraw: true,
};

export const WithdrawReducer = (prevState = WithdrawInitState, action) => {
  switch (action.type) {
    case "WITHDRAW_ERROR":
      return { ...prevState };
    case "FETCH_WITHDRAW_REQUEST_SUCCESS":
      const { total, last_page: pageCount } = action.payload;
      return {
        ...prevState,
        total,
        pageCount,
        withdraw_request_list: action.payload.data,
      };
    case "LOADING_WITHDRAW":
      return {
        ...prevState,
        loading_withdraw: action.payload,
      };
    case "PROCESSING":
      return { ...prevState, is_processing: action.payload };
    case "WITHDRAW_REQUEST_APPROVE_SUCCESS":
      return { ...prevState, is_processing: action.payload };
    case "DELETE_WITHDRAW_REQUEST_SUCCESS":
      let new_list = prevState.withdraw_request_list.filter(
        (current_rec) => current_rec.id !== action.trans_id
      );
      return { ...prevState, withdraw_request_list: new_list };
    default:
      return prevState;
  }
};
