import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { TextField, CustomModal } from "../../../../../constants";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Col, Container, Label, Button, FormGroup, Row } from "reactstrap";
import { HandleCountriesFetch } from "../../../../../actions/settings/countries_actions";
import { HandleCurrencyFetch } from "../../../../../actions/settings/currency_actions";
import * as Yup from "yup";
import { HandleServiceProviderCountrySubmit } from "../../../../../actions/settings/service_provider/service_provider_country_actions";

export const AddServiceProviderCountry = (props) => {
  const dispatch = useDispatch();
  const { countries_list } = useSelector(
    (state) => state.countryState,
    shallowEqual
  );
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  const formikRef = useRef();
  const { current_service_provider_country_obj, is_processing } = useSelector(
    (state) => state.serviceprovidercountry,
    shallowEqual
  );
  const currencyState = useSelector(
    (state) => state.currencyState,
    shallowEqual
  );

  const validate = Yup.object({
    spc_code: Yup.string().required("SPC code is required"),
    currency_id: Yup.string().required("Currency is required"),
    no_format: Yup.string().required("No_format is required"),
    country_id: Yup.string().required("Country is required"),
  });

  const { modal, toggle } = props;

  useEffect(() => {
    currencyState.currency_list.length === 0 && dispatch(HandleCurrencyFetch());
    countries_list.length === 0 && dispatch(HandleCountriesFetch());
  }, [currencyState.currency_list.length, countries_list.length, dispatch]);

  return (
    <>
      <CustomModal
        modal={modal}
        toggle={toggle}
        tittle={
          current_service_provider_country_obj.id !== undefined
            ? "Edit Service Provider Country"
            : "Add Service Provider Country"
        }
        is_processing={is_processing}
      >
        <Formik
          enableReinitialize
          initialValues={{
            spc_code: current_service_provider_country_obj.spc_code,
            currency_id: current_service_provider_country_obj.currency_id,
            no_format: current_service_provider_country_obj.no_format,
            country_id: current_service_provider_country_obj.country_id,
          }}
          validationSchema={validate}
          validateOnChange={validateAfterSubmit}
          validateOnBlur={true}
          ref={formikRef}
          onSubmit={(values, { setErrors, setSubmitting }) => {
            setValidateAfterSubmit(true);
            dispatch(
              HandleServiceProviderCountrySubmit(
                values,
                props.sp_id,
                toggle,
                setErrors,
                setSubmitting
              )
            );
          }}
        >
          {(formik) => (
            <Container>
              <Form>
                <FormGroup>
                  <Row>
                    <Col lg={12} md={12} sm={12} className="my-1">
                      <Label>Service Provider Code</Label>
                      <TextField
                        name="spc_code"
                        type="text"
                        value={formik.values.spc_code}
                        placeholder="Service Provider Code"
                      />
                    </Col>
                    <Col lg={12} md={12} sm={12} className="my-1">
                      <Label>Country</Label>
                      <Field
                        as="select"
                        name="country_id"
                        className={`custom-select form-control ${
                          formik.errors.country_id ? `is-invalid mb-2` : ""
                        }`}
                      >
                        <option defaultValue>Choose a country</option>
                        {countries_list.map((country) => (
                          <option value={country.id} key={country.id}>
                            {country.country_name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="country_id"
                        className="error"
                      />
                    </Col>
                    <Col lg={12} sm={12} md={12} className="my-1">
                      <label>Format</label>

                      <TextField
                        name="no_format"
                        type="text"
                        value={formik.values.no_format}
                        placeholder="078"
                      />
                    </Col>
                    <Col lg={12} md={12} sm={12} className="my-1">
                      <Label>Currency</Label>

                      <Field
                        as="select"
                        name="currency_id"
                        className={`custom-select form-control ${
                          formik.errors.country_id ? `is-invalid mb-2` : ""
                        }`}
                      >
                        <option defaultValue>Choose a currency</option>
                        {currencyState.currency_list.map((currency) => (
                          <option
                            value={currency.id}
                            key={currency.currency_id}
                          >
                            {currency.currency_name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="currency_id"
                        className="error"
                      />
                    </Col>
                    <Col lg={12} md={12} sm={12} className="mt-3 ">
                      <Button
                        type="submit"
                        disabled={is_processing}
                        className="btn pay-btn btn-block"
                        block
                      >
                        {current_service_provider_country_obj.id !== undefined
                          ? is_processing
                            ? "... Updating"
                            : "Update"
                          : is_processing
                          ? "... Adding"
                          : "Add"}
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
              </Form>
            </Container>
          )}
        </Formik>
      </CustomModal>
    </>
  );
};
