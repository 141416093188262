import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { HandleMerchantTicketFetch } from "../../../../../actions/merchant_support_ticket_action";
import { AddMerchantTicket } from "./add_merchant_ticket";
import { MerchantTicketTable } from "./merchant_ticket_table";
import { Button } from "reactstrap";

export const MerchantTicket = (props) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const dispatch = useDispatch();

  const merchant = useSelector(
    (state) => state.merchantState.current_merchant_obj,
    shallowEqual
  );

  useEffect(() => {
    merchant.id && dispatch(HandleMerchantTicketFetch(merchant.id));
  }, [merchant.id,dispatch]);

  return (
    <>
      <AddMerchantTicket toggle={toggle} modal={modal} />
      <Button
        className="btn ticket-btn my-3 shadow-none me-2"
        onClick={(e) => {
          toggle();
        }}
      >
        New Merchant Ticket{" "}
      </Button>
      <MerchantTicketTable {...props} toggle={toggle} />
    </>
  );
};

export default MerchantTicket;
