import React, { useRef, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Button } from "reactstrap";
import { Formik, Form, Field } from "formik";
import { HandleMerchantSubmit } from "../../../actions/merchant_action";
import { Row, Col } from "reactstrap";
import { TextField, CustomModal } from "../../../constants";
import * as Yup from "yup";

export const AddMerchant = (props) => {
  const dispatch = useDispatch();

  const { is_processing, current_merchant_obj } = useSelector(
    (state) => state.merchantState,
    shallowEqual
  );
  const formikRef = useRef();
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

  const validate = Yup.object({
    firstname: Yup.string().required("first name is required"),
    lastname: Yup.string().required("last name is required"),
    address: Yup.string().required("address is required"),
    password: Yup.string().required("password is required"),
    email: Yup.string().required("email is required"),
    phone_number: Yup.string().required("phone number is required"),
    gender: Yup.string().required("gender is required"),
  });

  const { modalState, toggleState } = props;

  return (
    <>
      <CustomModal
        modal={modalState}
        toggle={toggleState}
        tittle="Merchant Details"
        is_processing={is_processing}
      >
        <Formik
          enableReinitialize
          initialValues={{
            firstname: current_merchant_obj.firstname,
            lastname: current_merchant_obj.lastname,
            id: current_merchant_obj.id,
            phone_number: current_merchant_obj.phone_number,
            email: current_merchant_obj.email,
            password: current_merchant_obj.password,
            address: current_merchant_obj.address,
            tcs_accepted: current_merchant_obj.tcs_accepted,
            gender: current_merchant_obj.gender,
          }}
          validationSchema={validate}
          validateOnChange={validateAfterSubmit}
          validateOnBlur={true}
          ref={formikRef}
          onSubmit={(values, setErrors, setSubmitting) => {
            setValidateAfterSubmit(true);
            dispatch(
              HandleMerchantSubmit(
                values,
                toggleState,
                setErrors,
                setSubmitting
              )
            );
          }}
        >
          {(formik) => (
            <div>
              <Form className="p-2">
                <Row className="mt-2">
                  <Col>
                    <TextField
                      name="firstname"
                      type="text"
                      value={formik.values.firstname}
                      placeholder="FirstName"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <TextField
                      name="lastname"
                      type="text"
                      value={formik.values.lastname}
                      placeholder="Last Name"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6} sm={12} className="my-1">
                    <TextField
                      name="othernames"
                      type="text"
                      value={formik.values.othername}
                      placeholder="Other Name"
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <Field as="select" name="gender" className={`form-control`}>
                      <option>Gender</option>
                      <option value="0">Female</option>
                      <option value="1">Male</option>
                    </Field>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <TextField
                      name="phone_number"
                      type="text"
                      value={formik.values.phone_number}
                      placeholder="Phone Number"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextField
                      name="email"
                      type="text"
                      value={formik.values.email}
                      placeholder="Email"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextField
                      name="address"
                      type="text"
                      value={formik.values.address}
                      placeholder="Address"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6} sm={12} className="my-1">
                    <TextField
                      name="password"
                      type="password"
                      value={formik.values.password}
                      placeholder={
                        current_merchant_obj.id
                          ? "Leave this field if you are not updating the password"
                          : "Password"
                      }
                    />
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <TextField
                      name="password_confirmation"
                      type="password"
                      value={formik.values.firstname}
                      placeholder={
                        current_merchant_obj.id
                          ? "Leave empty if not updating the password"
                          : "Confirm password"
                      }
                    />
                  </Col>
                </Row>

                <Col lg={12} md={12} sm={12} className="mt-3">
                  <Button
                    className="btn btn-info sign-in-btn block my-1 shadow-none text-white"
                    type="submit"
                    data-bs-dismiss="modal"
                    block
                    disabled={is_processing}
                  >
                    {formik.values.id
                      ? is_processing
                        ? "... Updating"
                        : "Update"
                      : is_processing
                      ? "... Saving"
                      : "Save"}
                  </Button>
                </Col>
              </Form>
            </div>
          )}
        </Formik>
      </CustomModal>
    </>
  );
};
