import allconstants from "../../constants";
import {
  doAuthSuccess,
  doAuthError,
  doLogoutSuccess,
  is_Loading,
  profileFetchSuccess,
} from "../login_actions";

export const doAuthAction =
  (form_data, cb = null) =>
  (dispatch) => {
    dispatch(is_Loading(true));
    const url = allconstants.apiurl + "admin/login";
    const data =
      typeof form_data === "string" ? { data: form_data } : form_data;
    allconstants.axios
      .post(url, data, allconstants.headers)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchUserProfile(response.data.access_token));
          dispatch(doAuthSuccess(response.data, cb));
        } else if (response.data.status === 500) {
          dispatch(doAuthError("Wrong username/password! Please try again."));
        }
      })
      .catch((err) => {
        dispatch(doAuthError("Incorrect username or password."));
      });

    return null;
  };
export const signOut =
  (e = false) =>
  (dispatch) => {
    e && e.preventDefault();
    const url = allconstants.apiurl + "logout";
    allconstants.axios
      .delete(url, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 204) {
          dispatch(doLogoutSuccess("You have successfully signed out!"));
        }
      })
      .catch((err) => {
        doAuthError("Network/Server error");
        console.error("Network/Server error", err);
      });
    return null;
  };

export const fetchAdminRoles = (payload) => {
  return { type: "FETCH_USER_ROLE", payload };
};
export const emailSentSuccess = (val) => {
  return { type: "EMAIL_SENT_SUCCESS", sent: val };
};

export const forgotPassword = (data) => (dispatch) => {
  const url = allconstants.apiurl + "admin/forgot-password";
  allconstants.axios.post(url, data).then((res) => {
    if (res.status === 200 || res.status === 201) {
      dispatch(emailSentSuccess(true));
    } else {
    }
  });
};
export const checkAuthentication = () => {
  return { type: "UPDATE_USER_SESSION" };
};

export const RefreshAuthToken = () => (dispatch) => {
  const access_token = sessionStorage.getItem("refresh");
  const url = allconstants.apiurl + "admin/refresh-token";
  const data = {
    refresh_token: access_token,
  };
  allconstants.axios
    .post(url, data, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })

    .then((res) => {
      dispatch(doAuthSuccess(res.data));
    })
    .catch((err) => {});
};

export const resetPassword = (data) => (dispatch) => {
  const url = allconstants.apiurl + "admin/reset-password";
  allconstants.axios
    .post(url, data)
    .then((res) => {
      if (res.status === 201 || res.status === 200) {
      } else {
      }
    })
    .catch((err) => {
      doAuthError("Network/Server error");
      console.error("Network/Server error", err);
    });
};

export const FetchUserRoles = (user_id) => (dispatch) => {
  const url = allconstants.apiurl + "admin/" + user_id + "/role";
  allconstants.axios
    .get(url, { headers: allconstants.headers })
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        dispatch(fetchAdminRoles(res.data));
      } else {
        dispatch(doAuthError());
      }
    })
    .catch((err) => {
      doAuthError("Network/Server error");
      console.error("Network/Server error", err);
    });
};

export const fetchUserProfile =
  (token = false) =>
  (dispatch) => {
    const url = allconstants.apiurl + "profile";
    if (token) {
      allconstants.axios
        .get(url, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            dispatch(profileFetchSuccess(res.data));
          } else {
            dispatch(doAuthError());
          }
        })
        .catch((err) => {
          doAuthError("Network/Server error");
          console.error("Network/Server error", err);
        });
    }
  };
