import React, { useState } from "react";
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import {
  TextArea,
} from "../../../../../constants/constants";
import {
  HandleCompanyInput,
  handleApprovalStatusChange,
} from "../../../../../actions/company_action";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Container,
  ModalFooter,
} from "reactstrap";
import { editCompany } from "../../../../../actions/company_action";

export const ApproveActions = (props) => {
  const dispatch = useDispatch();
  const companyData = useSelector((state) => state.companyState.single_record, shallowEqual);

  switch (companyData.status) {
    case "pending_review":
      return (
        <>
          <Button size="sm"
            title="Approve Application"
            color="success"
            className="mx-1 text-light"
            onClick={(e) =>{
              e.preventDefault();
              props.toggle();
              dispatch(editCompany({ ...companyData, status: "approve" }));
            }
            }
          >
            <img
              src="/images/check_circle-white-18dp.svg"
              height="15px"
              width="15px"
              className="ml-1"
              alt="approve"
            />
            Approve
          </Button>
          <Button
            title="Reject Application"
            size="sm"
            color="warning"
            onClick={(e) => {
              e.preventDefault();
              props.toggle();
              dispatch(editCompany({ ...companyData, status: "reject" }));
            }}
            className="mx-1 text-light"
          >
            <img
              src="/images/report_problem-white-18dp.svg"
              height="15px"
              width="15px"
              className="ml-1"
              alt="reject"
            />
            Reject
          </Button>
          <Button size="sm" color="info"
            title="Request for changes"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              props.toggle();
              dispatch(editCompany({ ...companyData, status: "ask_for_changes" }));
            }}
            className="mx-1 text-light"
          >
            <img
              src="/images/edit_attributes-white-18dp.svg"
              height="15px"
              width="15px"
              className="ml-1"
              alt="changes"
            />
            Request for changes
          </Button>
          <Button size="sm" color="primary"
            title="Submit Changes"
            onClick={(e) => {
              e.preventDefault();
              props.toggle();
              dispatch(editCompany({ ...companyData, status: "submit_changes" }));
            }}
            className="mx-1 text-light"
          >
            <img
              src="/images/pending-white-18dp.svg"
              height="15px"
              width="15px"
              className="ml-1"
              alt="reject"
            />
            Submit changes
          </Button>
        </>
      );
    case "awaiting_changes":
      return (
        <>
          <Button size="sm" color="danger"
            title="Cancel Application"
            onClick={(e) => {
              e.preventDefault();
              props.toggle();
              dispatch(editCompany({ ...companyData, status: "cancel_changes" }));
            }}
            className="mx-1 text-light"
          >
            <img
              src="/images/highlight_off-white-18dp.svg"
              height="15px"
              width="15px"
              className="ml-1"
              alt="cancel"
            />
            Cancel
          </Button>
          <Button
            title="Submit Application Changes"
            size="sm"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              props.toggle();
              dispatch(editCompany({ ...companyData, status: "submit_changes" }));
            }}
            className="mx-1 text-light"
          >
            <img
              src="/images/pending-white-18dp.svg"
              height="15px"
              width="15px"
              className="ml-1"
              alt="reject"
            />
            Submit Changes
          </Button>
          <Button
            title="Reject Application"
            size="sm"
            color="warning"
            onClick={(e) => {
              e.preventDefault();
              props.toggle();
              dispatch(editCompany({ ...companyData, status: "reject" }));
            }}
            className="mx-1 text-light"
          >
            <img
              src="/images/report_problem-white-18dp.svg"
              height="15px"
              width="15px"
              className="ml-1"
              alt="reject"
            />
            Reject
          </Button>
        </>
      );
    case "accepted":
      return <p className="text-success">ACCEPTED</p>;
    case "rejected":
      return <Button size="sm" color="info"
            title="change"
            onClick={(e) => {
              e.preventDefault();
              props.toggle();
              dispatch(editCompany({ ...companyData, status: "ask_for_changes" }));
            }}
            className="mx-1 text-light"
          >
            <img
              src="/images/edit_attributes-white-18dp.svg"
              height="15px"
              width="15px"
              className="ml-1"
              alt="changes"
            />
            Ask for changes
          </Button>
    case "approved":
      return <p className="text-success">APPROVED</p>
    default:
      return (
        <p></p>
      );
  }
};

export const ApprovalComponent = () => {
  const dispatch = useDispatch();
  const formState = useSelector((state) => state.companyState.current_company_obj, shallowEqual);
  const isLoading = useSelector((state) => state.companyState.is_processing, shallowEqual);

  const [modalState,toggleModal] = useState(false);

  const toggle = () => {
    toggleModal(!modalState);
  }

  const changeStatusHandler = (e) =>{
    e.preventDefault();
    dispatch(handleApprovalStatusChange(formState));
  }
  const handleInput = (e) => dispatch(HandleCompanyInput(e));

  return (
    <span>
      <Modal isOpen={modalState} toggle={toggle}>
          <Form onSubmit={changeStatusHandler}>
        <ModalHeader toggle={toggle}>
          <span>Company Approval</span>
        </ModalHeader>
        <ModalBody>
            <Container>
              <FormGroup>
                <Row>
                  <Col><Label htmlFor="coment">Reason for your decision</Label></Col>
                </Row>
                <Row>
                  <Col className="my-2">
                    <TextArea disabled={isLoading}
                      handleChange={handleInput}
                      value={formState.reason_for_decision}
                      name="reason_for_decision"
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Container>
        </ModalBody>
        <ModalFooter>
          <Button className="pay-btn btn-block" disabled={isLoading}>
            <span className="pay-btn-text ">Save</span>
          </Button>
        </ModalFooter>
          </Form>
      </Modal>
      <ApproveActions toggle={toggle}/>
    </span>
  );
};
