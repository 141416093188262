import React, { useState } from "react";

import { Productstable } from "./productstable";
import AddProduct from "./add_product";

const Products = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <>
      <AddProduct modal={modal} toggle={toggle} />
      <Productstable toggle={toggle} />
    </>
  );
};
export default Products;
