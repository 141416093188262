import React, { useState } from "react";

import AddCountry from "./add_country";
import { CountriesTable } from "./countries_table";

const Countries = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <>
      <AddCountry modal={modal} toggle={toggle} />
      <CountriesTable toggle={toggle} />
    </>
  );
};

export default Countries;
