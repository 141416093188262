const exchangeFormState = {
  from_currency_id: "",
  to_currency_id: "",
  rate_amount: "",
  apply_datetime_from: "",
  apply_datetime_to: "",
};

const initialExchangeRateState = {
  exchange_rates: [],
  current_exchange_obj: exchangeFormState,
  is_processing: false,
  loading_exchange_rates: true,
};

export const ExhangeRateReducer = (
  prevState = initialExchangeRateState,
  action
) => {
  let form_state = prevState.current_exchange_obj;
  let new_formState;
  switch (action.type) {
    case "CREATE_EXCHANGE_RATE_SUCCESS":
      let exchange_new = true;
      let exchange_rates = prevState.exchange_rates.map((exchange) => {
        if (exchange.id === action.new_exchange.id) {
          exchange_new = false;
          return action.new_exchange;
        } else {
          return exchange;
        }
      });
      exchange_new && exchange_rates.push(action.new_exchange);

      const new_state = { ...prevState, exchange_rates };
      if (exchange_new) {
        new_state.current_exchange_obj = exchangeFormState;
      }

      return new_state;
    case "EXCHANGE_RATE_INPUT_CHANGE":
      new_formState = { ...form_state, ...action.changedField };

      return { ...prevState, current_exchange_obj: new_formState };
    case "LOADING_EXC_RATES":
      return {
        ...prevState,
        loading_exchange_rates: action.payload,
      };
    case "CLEAR_EXCHANGE_RATE_FORM":
      return { ...prevState, current_exchange_obj: exchangeFormState };

    case "FETCH_EXCHANGE_RATE_SUCCESS":
      return { ...prevState, exchange_rates: action.payload.data };
    case "EXCHANGE_RATE_ERROR":
      return { ...prevState };
    case "DELETE_EXCHANGE_RATE_SUCCESS":
      let new_list = prevState.exchange_rates.filter(
        (current_rec) => current_rec.id !== action.record_id
      );
      return { ...prevState, exchange_rates: new_list };
    case "EDIT_EXCHANGE_RATE":
      new_formState = { ...action.current_exchange_obj };
      return { ...prevState, current_exchange_obj: new_formState };
    case "EXCHANGE_REQUEST_PROCESSING":
      return { ...prevState, is_processing: action.value };

    default:
      return { ...prevState };
  }
};
