import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Actions } from "../../../../constants/constants";
import TblComp from "../../../../react_table";
import { Button } from "reactstrap";
import {
  editCountries,
  HandleCountriesDelete,
  HandleCountriesFetch,
} from "../../../../actions/settings/countries_actions";
import { clearCountriesForm } from "../../../../actions/settings/countries_actions";
import ExportCSV from "../../../../constants/Exports/xlsExport";
import DeleteModal from "../../../../constants/deleteModal";

export const CountriesTable = (props) => {
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState(false);
  const toggle = () => setModal(!modal);
  const dispatch = useDispatch();
  const { loading_countries, countries_list, is_processing } = useSelector(
    (state) => state.countryState,
    shallowEqual
  );

  const headers = ["Id", "Country name", "Country code"];
  useEffect(() => {
    countries_list.length === 0 && dispatch(HandleCountriesFetch());
  }, [countries_list.length, dispatch]);
  return (
    <>
      <Button
        className="btn payment-btn my-3 ml-2 text-white"
        onClick={(e) => {
          props.toggle();
          dispatch(clearCountriesForm(e));
        }}
      >
        Add Country{" "}
      </Button>
      <DeleteModal
        modal={modal}
        toggle={toggle}
        name="Country"
        handleDelete={() => dispatch(HandleCountriesDelete(selected,toggle))}
        is_processing={is_processing}
      />

      <ExportCSV
        csvData={countries_list}
        fileName="countries"
        headers={headers}
      />

      <TblComp
        columns={[
          {
            Header: "Country Name",
            accessor: "country_name",
            filter: "fuzzyText",
          },
          {
            Header: "Country Code",
            accessor: "country_code",
            filter: "fuzzyText",
          },
          {
            id: "actions", // Required because our accessor is not a string
            Header: "Action",
            maxWidth: 100,
            accessor: (full) => (
              <Actions
                current_table="Countries"
                editHandler={(selected_row) =>
                  dispatch(editCountries(selected_row))
                }
                data_item={full}
                link_url={""}
                deleteHandler={(selected_id) => [
                  toggle(),
                  setSelected(selected_id.id),
                ]}
              />
            ),
          },
        ]}
        data={countries_list}
        loading={loading_countries}
      />
    </>
  );
};
