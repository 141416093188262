import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import TblComp from "../../../react_table";
import { currFormat } from "../../../constants/GenHelpers";
import moment from "moment";
import { Button, ButtonGroup } from "reactstrap";
import { HandleTransactionReversal } from "../../../actions/transaction_action";
import TransactionDetail from "./transactionDetail";
import ExportCSV from "../../../constants/Exports/xlsExport";

export const TransactionsTable = (props) => {
  const { loading_transactions } = useSelector((state) => state.transaction);
  const dispatch = useDispatch();
  const txnState = useSelector((state) => state.transaction, shallowEqual);
  const [transaction, setSingle] = useState();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const ReverseTxn = (ref_no) => (e) => {
    e.preventDefault();
    dispatch(HandleTransactionReversal(ref_no));
  };

  const headers = [
    "Id",
    "Date",
    "Ref No",
    "Debit",
    "Credit",
    "Charges",
    "Balance",
    "Narrative",
    "Phone",
    "Company",
    "Status",
  ];

  const transactions = txnState.transactions.map(
    ({
      id,
      transaction_date,
      request_ref,
      debit,
      credit,
      charges,
      balance,
      transaction_note,
      msisdn,
      company_account,
      status_text,
    }) => ({
      id,
      transaction_date,
      request_ref,
      debit,
      credit,
      charges,
      balance,
      transaction_note,
      msisdn,
      company_account,
      status_text,
    })
  );
  return (
    <>
      <TransactionDetail
        transaction={transaction}
        isOpen={modal}
        toggle={toggle}
      />
      <ExportCSV
        csvData={transactions}
        fileName="transactions"
        headers={headers}
      />

      <TblComp
        columns={[
          {
            id: "transaction_date",
            Header: "Date",
            accessor: "transaction_date",
            filter: "fuzzyText",
            Cell: ({ value }) => (value ? moment(value).format("lll") : ""),
          },
          {
            Header: "Ref No.",
            maxWidth: 200,
            accessor: "request_ref",
            filter: "fuzzyText",
          },
          {
            id: "debit",
            Header: "Debit",
            accessor: "debit",
            filter: "fuzzyText",
            Cell: ({ value }) => (value ? currFormat(value * 1) : ""),
          },
          {
            id: "credit",
            Header: "Credit",
            accessor: "credit",
            filter: "fuzzyText",
            Cell: ({ value }) => (value ? currFormat(value * 1) : ""),
          },
          {
            Header: "Charge",
            accessor: "charges",
            filter: "fuzzyText",
            Cell: ({ value }) => (value ? currFormat(value * 1) : ""),
          },
          {
            id: "balance", // Required because our accessor is not a string
            Header: "Balance",
            accessor: "balance",
            Cell: ({ value }) => (value ? currFormat(value * 1) : ""),
          },
          {
            Header: "Narrative",
            accessor: "transaction_note",
            filter: "fuzzyText",
          },
          { Header: "Phone", accessor: "msisdn", filter: "fuzzyText" },
          {
            Header: "Company",
            maxWidth: 200,
            accessor: "company_account",
            filter: "fuzzyText",
            Cell: ({ value }) =>
              value.company ? value.company.company_name : value.account_no,
          },
          { Header: "Status", accessor: "status_text", filter: "fuzzyText" },
          {
            id: "actions", // Required because our accessor is not a string
            Header: "Action",
            maxWidth: 150,
            accessor: (full) => (
              <ButtonGroup>
                <Button
                  size="sm"
                  onClick={(e) => {
                    toggle();
                    e.preventDefault();
                    setSingle(full);
                  }}
                  title="View Transaction Details"
                  color="info"
                >
                  <img
                    src="/images/view.svg"
                    height="15px"
                    width="15px"
                    alt="view"
                  />
                </Button>
                {full.status === 3 && full.transaction_type_id === 2 && (
                  <Button
                    color="danger"
                    size="sm"
                    title="Reverse Transaction"
                    onClick={ReverseTxn(full.ref_no)}
                  >
                    Rev.
                  </Button>
                )}
              </ButtonGroup>
            ),
          },
        ]}
        data={txnState.transactions}
        total={txnState.total}
        pageCount={txnState.pageCount}
        loading={loading_transactions}
        serverSide={true}
        fetchData={props.fetchData}
      />
    </>
  );
};
