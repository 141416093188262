import React, { useState } from "react";
import { Addexchangerate } from "./addexchangerate";
import { ExchangerateTable } from "./exchangerateTable";

export const ExchangeRate = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <>
      <Addexchangerate modal={modal} toggle={toggle} />
      <ExchangerateTable toggle={toggle} />
    </>
  );
};

export default ExchangeRate;
