import React, { useState } from "react";
import DocumentTypeComp from "./documenttype/document_types";
import TransactionTypeComp from "./transactiontype/transaction_types";
import ContactTypeComp from "./contacttype/contact_types";
import TicketTypeComp from "./tickettype/ticket_types";

import { Card, Row, Col } from "reactstrap";
export const Types = () => {
  const [tab, setTab] = useState("transactiontypes");
  return (
    <Col md={11} sm={12} lg={11} className="pl-0">
      <Card>
        <Row className="ml-2 second-leve-nav">
          <nav className="nav nav-pills" style={{ height: "44px" }}>
            <a
              onClick={() => setTab("transactiontypes")}
              className={
                tab === "userdetails"
                  ? "nav-item nav-link"
                  : "nav-item nav-link in-active"
              }
              data-toggle="pill"
              href
            >
              <span
                className={
                  tab === "transactiontypes"
                    ? "btn  ticket-tab-active"
                    : "btn  ticket-tab"
                }
              >
                Transaction type details{" "}
              </span>
            </a>
            <a
              onClick={() => setTab("documenttype")}
              className={
                tab === "documenttype"
                  ? "nav-item nav-link"
                  : "nav-item nav-link in-active"
              }
              data-toggle="pill"
              href
            >
              <span
                className={
                  tab === "documenttype"
                    ? "btn  ticket-tab-active"
                    : "btn ticket-tab"
                }
              >
                Document type{" "}
              </span>
            </a>
            <a
              onClick={() => setTab("contacttype")}
              className={
                tab === "contacttype"
                  ? "nav-item nav-link"
                  : "nav-item nav-link in-active"
              }
              data-toggle="pill"
              href
            >
              <span
                className={
                  tab === "contacttype"
                    ? "btn  ticket-tab-active"
                    : "btn ticket-tab"
                }
              >
                Contact type{" "}
              </span>
            </a>
            <a
              onClick={() => setTab("tickettype")}
              className={
                tab === "tickettype"
                  ? "nav-item nav-link"
                  : "nav-item nav-link in-active"
              }
              data-toggle="pill"
              href
            >
              <span
                className={
                  tab === "tickettype"
                    ? "btn  ticket-tab-active"
                    : "btn ticket-tab"
                }
              >
                Ticket type{" "}
              </span>
            </a>
          </nav>
        </Row>
      </Card>
      {/* tabs */}
      {tab === "transactiontypes" && <TransactionTypeComp />}
      {tab === "documenttype" && <DocumentTypeComp />}
      {tab === "contacttype" && <ContactTypeComp />}
      {tab === "tickettype" && <TicketTypeComp />}
    </Col>
  );
};
