import allconstants from "../constants";

const MerchantTransFetchSuccess = (payload, msg) => {
  return { type: "FETCH_TRANSMERCHANT_SUCCESS", payload };
};

const AggregateTransFetchSuccess = (payload, msg) => {
  return { type: "FETCH_AGGREGATE_TRANS_SUCCESS", payload };
};

export const HandleTransactionInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return { type: "TRANSACTION_INPUT_CHANGE", changedField: { [name]: value } };
};

export const clearTransactionForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_TRANSACTION_FORM" };
};

const TransactionAddSuccess = (payload) => {
  return {
    type: "CREATE_TRANSACTION_SUCCESS",
    new_transaction: payload.request,
  };
};
const TransactionReversalSuccess = (payload) => {
  return { type: "REVERSE_TRANSACTION_SUCCESS" };
};
const InitTransaction = () => {
  return { type: "INIT_TRANSACTION" };
};

const TransactionReqError = (msg) => {
  return { type: "TRANSACTION_ERROR" };
};
export const IsLoading = (payload) => {
  return { type: "LOADING_TRANSACTIONS", payload };
};

export const HandleTransactionFetch =
  (params = {}) =>
  (dispatch) => {
    allconstants.axios
      .get(allconstants.apiurl + "transaction", {
        headers: allconstants.headers,
        params,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(MerchantTransFetchSuccess(response.data));
          dispatch(IsLoading(false));
        }
      })
      .catch((error) => {
        if (error.response) {
          dispatch(IsLoading(false));
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Bad/No internet connection");
        }
      });
  };

export const HandleTransactionSubmit =
  (form_data, company_account_id, toggle) => (dispatch) => {
    const url =
      allconstants.apiurl +
      "company_account/" +
      company_account_id +
      "/transaction";
    const req_type = form_data.id
      ? allconstants.axios.put
      : allconstants.axios.post;
    dispatch(InitTransaction());
    req_type(url, form_data, { headers: allconstants.headers })
      .then((response) => {
        dispatch(TransactionAddSuccess(response.data));
        toggle();
      })
      .catch((error) => {
        if (error.response) {
          TransactionReqError(error.response.message);
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.error("Poor/No internet connection");
        }
      });
  };

export const HandleTransactionReversal = (txn_id) => (dispatch) => {
  const url = allconstants.apiurl + "transaction/refund/" + txn_id;
  const req_type = allconstants.axios.put;
  dispatch(InitTransaction());
  req_type(url, {}, { headers: allconstants.headers })
    .then((response) => {
      dispatch(TransactionReversalSuccess(response.data));
    })
    .catch((error) => {
      if (error.response) {
        TransactionReqError(error.response.message);
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.error("Poor/No internet connection");
      }
    });
};

export const HandleAggregateTransactionFetch =
  (params = {}) =>
  (dispatch) => {
    allconstants.axios
      .get(allconstants.apiurl + "dashboard/transaction", {
        headers: allconstants.headers,
        params,
      })
      .then((response) => {
        dispatch(AggregateTransFetchSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Bad/No internet connection");
        }
      });
  };
