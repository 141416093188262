import React from "react";
import { Col, Row, Container, Card, CardBody } from "reactstrap";

const MailSuccess = (props) => {
  return (
    <>
      <div className="account-pages mt-5 mb-5">
        <Container>
          <Row className=" justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="bg-light">
                <CardBody className="p-4">
                  <div className="text-center w-75 m-auto">
                    <a href="index-2.html">
                      <span>
                        <img src="images/fav.png" alt="" height={30} />
                      </span>
                    </a>
                  </div>
                  <div className="mt-3 text-center">
                    <h3>Notice!</h3>
                    <p className="text-muted mt-2">
                      A Link has been sent to your inbox . please follow the
                      link to reset your password !
                    </p>
                  </div>
                </CardBody>{" "}
                {/* end card-body */}
              </Card>
              {/* end card */}
            </Col>{" "}
            {/* end col */}
          </Row>
          {/* end row */}
        </Container>
        {/* end container */}
      </div>
      {/* end page */}
    </>
  );
};
export default MailSuccess;
