import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Actions } from "../../../constants/constants";
import TblComp from "../../../react_table";
import {
  HandleMerchantDelete,
  HandleMerchantFetch,
} from "../../../actions/merchant_action";
import { Button } from "reactstrap";
import { fetchUserProfile } from "../../../actions/api/login_api";
import ExportCSV from "../../../constants/Exports/xlsExport";
import DeleteModal from "../../../constants/deleteModal";
import { checkInArray } from "../../../constants/GenHelpers";

export const MerchantTable = (props) => {
  const status = props.params.status ? props.params.status : "live";
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState(false);
  const toggle = () => setModal(!modal);
  const dispatch = useDispatch();
  const { loading_merchants, merchant_list, total, pageCount, is_processing } =
    useSelector((state) => state.merchantState, shallowEqual);

  const user_permissions = useSelector(
    (state) => state.authState.user_roles,
    shallowEqual
  );

  const fetchData = useCallback(
    (pageOptions) => {
      const fetchId = ++fetchIdRef.current;
      if (fetchId === fetchIdRef.current) {
        dispatch(HandleMerchantFetch({ status, ...pageOptions }));
      }
    },
    [dispatch, status]
  );

  useEffect(() => {
    fetchData();
    user_permissions.length === 0 && dispatch(fetchUserProfile());
  }, [dispatch, user_permissions,fetchData]);

  const fetchIdRef = useRef(0);

  const ActionExtension = (props) => {
    return (
      (status === "" ||
        status === "new" ||
        status === "live" ||
        status === "all" ||
        status === "trashed" ||
        status === "pending") && (
        <Actions
          data_item={props.full}
          link_url={"/merchant/detail/"}
          deleteHandler={(selected_id) => [
            toggle(),
            setSelected(selected_id.id),
          ]}
        />
      )
    );
  };

  const headers = ["Id", "Name", "Email", "Phone Contact", "Gender"];

  const merchants = merchant_list.map(
    ({ id, full_name, email, phone_number, gender_text }) => ({
      id,
      full_name,
      email,
      phone_number,
      gender_text,
    })
  );

  return (
    <>
      <DeleteModal
        modal={modal}
        toggle={toggle}
        name="merchant"
        handleDelete={() => dispatch(HandleMerchantDelete(selected, toggle))}
        is_processing={is_processing}
      />
      {checkInArray(user_permissions, "create-merchant","name") && (
        <Button
          className="text-white btn payment-btn my-3 me-4 shadow-none"
          onClick={props.toggleState}
        >
          {" "}
          Add Merchant
        </Button>
      )}

      <>
        <ExportCSV
          csvData={merchants}
          fileName={status + "-Merchants"}
          headers={headers}
        />
        <TblComp
          columns={[
            { Header: "Name", accessor: "full_name", filter: "fuzzyText" },
            { Header: "Email", accessor: "email", filter: "fuzzyText" },
            {
              Header: "Phone Contact",
              accessor: "phone_number",
              filter: "fuzzyText",
            },
            {
              Header: "Gender",
              accessor: "gender_text",
              filter: "fuzzyText",
            },
            {
              id: "actions", // Required because our accessor is not a string
              Header: "Action",
              maxWidth: 100,
              accessor: (full) => {
                return <ActionExtension full={full} />;
              },
            },
          ]}
          data={merchant_list}
          serverSide={true}
          fetchData={fetchData}
          loading={loading_merchants}
          total={total}
          pageCount={pageCount}
        />
      </>
    </>
  );
};
