const rolesFormState = {
  name: "",
  description: "",
};

const RoleInitailState = {
  role_list: [],
  form_state: rolesFormState,
  role_permissions: [],
  is_processing: false,
  isAdded: false,
};

export const rolesReducer = (prevState = RoleInitailState, action) => {
  switch (action.type) {
    case "CREATE_ROLE_SUCCESS":
      let role_new = true;
      let role_list = prevState.role_list.map((role) => {
        if (role.id === action.new_role.id) {
          role_new = false;
          return action.new_role;
        } else {
          return role;
        }
      });
      role_new && role_list.push(action.new_role);
      const new_state = { ...prevState, role_list };
      if (role_new) {
        new_state.form_state = rolesFormState;
      }
      return new_state;

    case "ROLE_INPUT_CHANGE":
      return {
        ...prevState,
        form_state: { ...prevState.form_state, ...action.changedField },
      };
    case "CLEAR_ROLE_FORM":
      return { ...prevState, form_state: rolesFormState };
    case "ROLES_ADD_SUCCESS":
      return { ...prevState, isAdded: action.payload };
    case "FETCH_ROLE_SUCCESS":
      return { ...prevState, role_list: action.payload.data };
    case "ROLE_ERROR":
      return { ...prevState };

    case "EDIT_ROLE":
      return { ...prevState, form_state: { ...action.form_state } };
    case "DELETE_ROLE_SUCCESS":
      let new_list = prevState.role_list.filter(
        (current_rec) => current_rec.id !== action.record_id
      );
      return { ...prevState, role_list: new_list };

    case "ROLE_REQUEST_PROCESSING":
      return { ...prevState, is_processing: action.value };
    case "ROLE_PERMISSION_FETCH_SUCCESS":
      return { ...prevState, role_permissions: action.payload.data };
    default:
      return { ...prevState };
  }
};
