import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
// import { HandleCompanyInput } from '../../../../../../actions/company_action'
import { HandleCompanySubFetch } from "../../../../../../actions/company_sub_actions";
import { HandleSubscriptionPlanFetch } from "../../../../../../actions/settings/subscription_plans/subscription_plan_actions";
import check from "../../../../../../assets/img/check-icon.svg";
import { AddSubPayment } from "./sub_payment";
import { Row, Col, Card, CardBody, CardFooter } from "reactstrap";

export const CompanySubscriptionCards = (props) => {
  const dispatch = useDispatch();

  const wallet_bal = useSelector(
    (state) => state.companySubState.wallet_bal,
    shallowEqual
  );
  const company_detail = useSelector(
    (state) => state.companyState.single_record,
    shallowEqual
  );
  const subscription_plan_list = useSelector(
    (state) => state.subscriptionplanreducer.subscription_plan_list,
    shallowEqual
  );

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [subplan, getSubPlan] = useState({});
  useEffect(() => {
    props.match.params.company_id &&
      dispatch(HandleCompanySubFetch(props.match.params.company_id));
    typeof subscription_plan_list !== "undefined" &&
      dispatch(HandleSubscriptionPlanFetch());
  }, [dispatch, props.match.params.company_id, subscription_plan_list]);

  return (
    <>
      <Row>
        {subscription_plan_list.map((val, key) => {
          if (
            typeof company_detail.active_plan !== "undefined" &&
            val.id === company_detail.active_plan.subscription_plan_id &&
            val.amount !== "0"
          ) {
            return (
              <Col lg={4} md={4} sm={12} className="my-2">
                <Card
                  className="shadow bg-light"
                  style={{ borderRadius: "10px", border: "2px solid #F87F0F" }}
                >
                  <div className="me-1 mt-2">
                    <img
                      className="float-end"
                      src={check}
                      height="20px"
                      width="20px"
                      alt=""
                    />
                  </div>
                  <CardBody>
                    <h5 className="card-title text-center subscription-header text-uppercase">
                      {val.subscription_plan_name}
                    </h5>
                    <p className="small text-center  text-muted">Active</p>
                    <hr />
                    <p className="d-flex small text-muted p-2 justify-content-center">
                      {val.description}
                    </p>
                  </CardBody>
                  <CardFooter
                    className="footer-color d-flex"
                    style={{ borderRadius: "0px 0px 10px 10px" }}
                  >
                    <span className="text-center font-weight-bold ms-2 text-light d-flex ">
                      <span className="me-1">UGX</span>
                      <span>{parseInt(val.amount).toLocaleString("en")}</span>
                    </span>
                    <span className="ms-3 text-center font-weight-bold text-light ">
                      Subscribed
                    </span>
                  </CardFooter>
                </Card>
              </Col>
            );
          } else if (
            typeof company_detail.active_plan !== "undefined" &&
            val.id !== company_detail.active_plan.subscription_plan_id &&
            val.amount === "0"
          ) {
            return (
              <Col lg={4} md={4} sm={12} className="my-2">
                <Card
                  className="shadow bg-light"
                  style={{ borderRadius: "10px", border: "2px solid #2580F8" }}
                >
                  <CardBody>
                    <h5 className="card-title text-center subscription-header-blue text-uppercase">
                      {val.subscription_plan_name}
                    </h5>
                    <p className="small text-center  text-muted">Subscribe</p>
                    <hr />
                    <p className="d-flex small text-muted p-2 justify-content-center">
                      {val.description}
                    </p>
                  </CardBody>
                  <CardFooter
                    className="footer-color-blue d-flex"
                    style={{ borderRadius: "0px 0px 10px 10px" }}
                  >
                    <span className="text-center font-weight-bold ms-2 text-light d-flex ">
                      <span className="me-1">UGX</span>
                      <span>{parseInt(val.amount).toLocaleString("en")}</span>
                    </span>
                    <a
                      onClick={() => getSubPlan(val)}
                      href="#upgrade-modal"
                      data-toggle="modal"
                      className="ms-3 text-center font-weight-bold text-light "
                    >
                      Subscribe
                    </a>
                  </CardFooter>
                </Card>
              </Col>
            );
          } else {
            return (
              <Col lg={4} md={4} sm={12} className="my-2">
                <Card
                  className="shadow bg-light"
                  style={{ borderRadius: "10px", border: "2px solid #2580F8" }}
                >
                  <CardBody>
                    <h5 className="card-title text-center subscription-header-blue text-uppercase">
                      {val.subscription_plan_name}
                    </h5>
                    <p className="small text-center  text-muted">Subscribe</p>
                    <hr />
                    <p className="d-flex small text-muted p-2 justify-content-center">
                      {val.description}
                    </p>
                  </CardBody>
                  <CardFooter
                    className="footer-color-blue d-flex"
                    style={{ borderRadius: "0px 0px 10px 10px" }}
                  >
                    <span className="text-center font-weight-bold ms-2 text-light d-flex ">
                      <span className="me-1">UGX</span>
                      <span>{parseInt(val.amount).toLocaleString("en")}</span>
                    </span>
                    <a
                      onClick={() => getSubPlan(val)}
                      href="#upgrade-modal"
                      data-toggle="modal"
                      className="ms-3 text-center font-weight-bold text-light "
                    >
                      Subscribe
                    </a>
                  </CardFooter>
                </Card>
              </Col>
            );
          }
        })}
      </Row>
      <AddSubPayment
        toggle={toggle}
        modal={modal}
        subplan={subplan}
        company_id={props.match.params.company_id}
        wallet_bal={wallet_bal}
      />
    </>
  );
};
export default CompanySubscriptionCards;
