import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { AddChargeRate } from "./add_charge_rate";
import { ChargeRateTable } from "./charge_rates_table";

const ChargeRate = (props) => {
  const subplan = useSelector(
    (state) => state.subscriptionplanreducer.single_record,
    shallowEqual
  );
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <>
      <AddChargeRate
        modal={modal}
        toggle={toggle}
        sub_plan_id={props.match.params.id}
      />
      <div className="d-flex">
        <h4>
          {Object.keys(subplan).length
            ? subplan.subscription_plan_name
            : "Loading..."}
        </h4>
      </div>
      <ChargeRateTable modal={modal} toggle={toggle} />
    </>
  );
};

export default ChargeRate;
