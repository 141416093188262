import React, { useState } from "react";
import AddProviderCountryProduct from "./add_service_provider_country_product";
import { SPCProductsTable } from "./product_table";

export const ServiceProviderProduct = (props) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <>
      <AddProviderCountryProduct
        modal={modal}
        toggle={toggle}
        spc_id={props.match.params.id}
      />

      <SPCProductsTable spc_id={props.match.params.id} toggle={toggle} />
    </>
  );
};

export default ServiceProviderProduct;
