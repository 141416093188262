const initialFormState = {
  id: "",
  product_name: "",
  description: "",
  spc_id: "",
};

const InitialSPC_productsState = {
  spc_product_list: [],
  spc_current_product_obj: initialFormState,
  is_processing: false,
};

export const SPC_ProductReducer = (
  prevState = InitialSPC_productsState,
  action
) => {
  let form_state = prevState.spc_current_product_obj;
  let new_formState;
  switch (action.type) {
    case "CREATE_SPC_PRODUCT_SUCCESS":
      let product_new = true;
      let spc_product_list = prevState.spc_product_list.map((product) => {
        if (product.id === action.product.id) {
          product_new = false;
          return action.new_product;
        } else {
          return product;
        }
      });
      product_new && spc_product_list.push(action.new_product);
      const new_state = { ...prevState, spc_product_list };
      if (product_new) {
        new_state.spc_current_product_obj = initialFormState;
      }
      return new_state;
    case "PROVIDER_COUNTRY_PRODUCT_REQUEST_PROCESSING":
      return { ...prevState, is_processing: action.value };
    case "PRODUCT_SPC_PRODUCT_CHANGE":
      new_formState = { ...form_state, ...action.changedField };
      return { ...prevState, spc_current_product_obj: new_formState };
    case "CLEAR_SPC_PRODUCT_FORM":
      return { ...prevState, spc_current_product_obj: initialFormState };

    case "FETCH_SPC_PRODUCT_SUCCESS":
      return { ...prevState, spc_product_list: action.payload.data };
    case "SPC_PRODUCT_ERROR":
      return { ...prevState };
    case "EDIT_SPC_PRODUCT":
      new_formState = { ...action.spc_current_product_obj };
      return { ...prevState, spc_current_product_obj: new_formState };
    case "DELETE_SPC_PRODUCT_SUCCESS":
      let new_list = prevState.spc_product_list.filter(
        (current_rec) => current_rec.id !== action.record_id
      );
      return { ...prevState, spc_product_list: new_list };
    default:
      return { ...prevState };
  }
};
