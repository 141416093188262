import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import TblComp from "../../../../../../react_table";

const ApprovalLogs = (props) => {
  const logs = useSelector(
    (state) => state.companyState.single_record.history,
    shallowEqual
  );
  return (
    <>
      <TblComp
        columns={[
          {
            Header: "Reason",
            accessor: "reason_for_decision",
            filter: "fuzzyText",
          },
          {
            Header: "Transition from",
            accessor: "transition",
            filter: "fuzzyText",
          },
          {
            Header: "Transition to",
            accessor: "current_state",
            filter: "fuzzyText",
          },
        ]}
        data={typeof logs !== "undefined" ? logs : []}
      />
    </>
  );
};

export default ApprovalLogs;
